import { paymentMethods$ } from "selectors/paymentMethod";
import store from "store";
import { AnswerData } from "../index";
import * as PaymentMethodActions from "actions/paymentMethod";
import * as PaymentMethodCreators from "creators/paymentMethod";
import { errorNotification } from "utils";
import i18next from "i18next";

// WSApi.CreatePaymentMethod
export const paymentMethodCreate = (data: AnswerData) => {
  const { getState, dispatch } = store;
  if (!data?.error) {
    const paymentMethod = data.response;
    const userPaymentMethods = paymentMethods$(getState());

    if (paymentMethod)
      dispatch(
        PaymentMethodActions.setPaymentList([
          ...userPaymentMethods,
          paymentMethod,
        ])
      );

    dispatch(PaymentMethodCreators.getDefaultPaymentMethod() as any);
  } else {
    errorNotification(i18next.t(data?.error));
  }
  dispatch(PaymentMethodActions.setPaymentErrorValue(data?.error || ""));
};
