import { AvailableRole } from "interfaces/state/availableRoles";
import * as Const from "../constants";

export const setAvailableRolesInit = () => {
  return {
    type: Const.AVAIL_ROLES_INIT,
  };
};

export const setAvailableRolesList = (payload: AvailableRole[]) => {
  return {
    type: Const.AVAIL_ROLES_SUCCESS,
    payload,
  };
};

export const setAvailableRolesListFail = () => {
  return {
    type: Const.AVAIL_ROLES_FAIL,
  };
};

export const setAvailableRolesListLoading = () => {
  return {
    type: Const.AVAIL_ROLES_LOADING,
  };
};
