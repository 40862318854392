import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import * as ScenarioActions from "actions/scenario";
import * as ScenarioVersionActions from "actions/scenarioVersion";
import { AnswerData } from "..";
import { makeScenarioParams, showDialogue } from "utils";
import { useLocation, useNavigate } from "react-router-dom";
import { privateRoute } from "routes";
import i18n from "i18n";
import * as ScenarioCreators from "creators/scenario";
import { scenarioVersions$ } from "selectors/scenarioVersion";
import { BranchType, Scenario } from "interfaces/state/scenario";

// WSApi.ScenarioStop
export const scenarioStop = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (!data.error) {
    const handleScenarioActiveBranches = (
      branch: BranchType,
      scenario: Scenario
    ) => {
      if (!scenario) return [];
      const newActiveBranches = scenario?.active_branches?.filter(
        (br) => br !== branch
      );

      return newActiveBranches || [];
    };
    const scenario = scenarioItem$(store.getState());
    if (data.response?.scenario_id === scenario?.scenario_id) {
      const newScenario = {
        ...scenario,
        scenario_status: data.response.status,
        version_id:
          data.response.version_id === data.response?.new_version_id ||
          !data.response?.new_version_id
            ? scenario?.version_id
            : data.response?.new_version_id,
        active_branches: handleScenarioActiveBranches(
          data.response.branch,
          scenario as Scenario
        ),
      };

      const scenario_id = data.response?.scenario_id;

      if (data?.response?.branch === "live") {
        const processing = {
          [scenario_id]: {
            scenario_id,
            isLoading: false,
          },
        };
        store.dispatch(ScenarioActions.setScenarioProcessing(processing));
      }

      store.dispatch(
        ScenarioActions.setScenarioSuccess(newScenario as Scenario)
      );

      const scenarioVersions = scenarioVersions$(store.getState());

      const versIndex = scenarioVersions?.findIndex(
        (v) => v.id === data.response.version_id
      );
      if (versIndex < 0) return;

      const branch = data.response.branch || "live";

      const newActiveBranches = Array.isArray(
        scenarioVersions[versIndex].active_branches
      )
        ? (scenarioVersions[versIndex].active_branches as string[]).filter(
            (el) => el !== branch
          )
        : [];

      scenarioVersions[versIndex].active_branches = newActiveBranches;
      store.dispatch(
        ScenarioVersionActions.setScenarioVersionList([...scenarioVersions])
      );

      if (
        data.response.delete_after &&
        (data.response?.version_id || data.response?.tag)
      ) {
        if (data?.response?.version_id && !data.response?.tag)
          store.dispatch(
            ScenarioCreators.deleteScenarioVersion(data.response.version_id)
          );

        if (data?.response?.tag) {
          store.dispatch(ScenarioCreators.deleteTag(data.response.tag));
        }
      }
    }
  } else {
    const scenario = scenarioItem$(store.getState());
    const scenario_id = data?.scenario_id || scenario?.scenario_id;
    const processing = {
      [scenario_id]: {
        scenario_id,
        isLoading: false,
      },
    };

    if (
      data.error === "scenario_does_not_exist_or_you_dont_have_enough_rights"
    ) {
      showDialogue({
        title: i18n.t("error"),
        text: i18n.t("scenario_does_not_exist_or_you_dont_have_enough_rights"),
        confirmButtonText: i18n.t("ok"),
        showCancelButton: false,
        confirmButtonColor: "#2084e1",
      }).then(() => {
        navigate(privateRoute.automation);
      });
    }

    if (data.error === "invalid_id") {
      showDialogue({
        title: i18n.t("error"),
        text: i18n.t("invalid_scenario_link"),
        confirmButtonText: i18n.t("ok"),
        showCancelButton: false,
        confirmButtonColor: "#2084e1",
      }).then(() => {
        navigate(privateRoute.automation);
      });
    }
    if (scenario_id) {
      store.dispatch(ScenarioActions.setScenarioProcessing(processing));
    }

    if (data?.error?.unfilled_blocks) {
      store.dispatch(
        ScenarioActions.getScenarioErrors(data.error.unfilled_blocks)
      );
      store.dispatch(ScenarioActions.openEmptyBlocksErrorModal(true));

      const isEdit = searchParams.get("edit") === "1";
      const isView = searchParams.get("view") === "1";
      const isDebug = searchParams.get("debug") === "1";
      const branch = searchParams.get("branch")!;
      const isViewVersion = searchParams.get("viewVersion") === "1";

      navigate(
        `${privateRoute.scenario}?${makeScenarioParams({
          scenario_id: data.error.scenario_id,
          version_id: data.error?.version_id,
          isEdit,
          isView,
          isDebug,
          isViewVersion,
          selectedBlock: null,
          branch,
        })}`
      );

      store.dispatch(
        ScenarioCreators.fetchScenarioById(
          data.error.scenario_id!,
          true,
          data.error.version_id
        ) as any
      );
    }
  }
};
