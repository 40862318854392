import { scenarioItem$ } from "selectors/scenario";
import { scenarioVersions$ } from "selectors/scenarioVersion";
import store from "store";
import { AnswerData } from "../index";
import * as ScenarioVersionActions from "actions/scenarioVersion";

// WSApi.SetActiveScenarioVersion
export const scenarioVersionSetActive = (data: AnswerData) => {
  if (!data.error) {
    const scenario = scenarioItem$(store.getState());
    if (scenario?.scenario_id === data.response.scenario_id) {
      // const newVersionId = data.response.version_id;
      const scenarioVersions = scenarioVersions$(store.getState()).map(
        (version) => ({
          ...version,
          // is_active: version.id === newVersionId,
        })
      );

      store.dispatch(
        ScenarioVersionActions.setScenarioVersionList(scenarioVersions)
      );
      store.dispatch(ScenarioVersionActions.setScenarioVersionLoading(false));
    }
  }
};
