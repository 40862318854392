import * as Const from "../constants";
import State from "../interfaces/state/questionnaire";

export const initState: State = {
  isLoading: false,
  isError: false,
  status: null,
  directions: {},
  roles: [],
  tasks: [],
  teams: [],
};

export default function questionnaire(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.QUESTIONNAIRE_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.QUESTIONNAIRE_SET_STATUS:
      return {
        ...state,
        status: action.payload,
      };

    case Const.QUESTIONNAIRE_SET_DIRECTIONS:
      return {
        ...state,
        directions: action.payload,
      };

    case Const.QUESTIONNAIRE_SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    case Const.QUESTIONNAIRE_SET_TASKS:
      return {
        ...state,
        tasks: action.payload,
      };

    case Const.QUESTIONNAIRE_SET_TEAMS:
      return {
        ...state,
        teams: action.payload,
      };

    case Const.QUESTIONNAIRE_LOADED_SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
      };

    case Const.QUESTIONNAIRE_LOADING:
      return {
        ...state,
        isError: false,
        isLoading: true,
      };

    case Const.QUESTIONNAIRE_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    default:
      return state;
  }
}
