import { Action } from "redux";
import State from "interfaces/state";
import * as ScenarioUsersActions from "actions/scenarioUsers";
import { WSApi } from "enums/wsApi";
import { sendWSMessage } from "utils";

export const fetchAllUsersByScenarioId = (scenario_id: string) => {
  return (dispatch: (action: Action) => void, _getState: () => State) => {
    dispatch(ScenarioUsersActions.setScenarioUsersLoading());

    const action = WSApi.GetAllScenarioUsers;

    sendWSMessage({ action, scenario_id });
  };
};

export const addNewUserByScenarioId = (scenario_id: string) => {
  return (dispatch: (action: Action) => void, _getState: () => State) => {
    dispatch(ScenarioUsersActions.setScenarioUsersLoading());

    const action = WSApi.AddNewUserToScenario;

    sendWSMessage({ action, scenario_id });
  };
};

export const deleteUserFromScenario = (scenario_id: string) => {
  return (dispatch: (action: Action) => void, _getState: () => State) => {
    dispatch(ScenarioUsersActions.setScenarioUsersLoading());

    const action = WSApi.DeleteUserFromScenario;

    sendWSMessage({ action, scenario_id });
  };
};
