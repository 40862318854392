import {
  setUnavailableServiceOrMethod,
  updateCheckInitOptions,
  updateScenarioInitModal,
  setOpenUnavailableServiceModal,
} from "actions/scenario";
import store from "store";
import { updateInitBlock } from "creators/scenario";
import { checkInitAuthData$ } from "selectors/scenario";
import { AnswerData } from "../index";
import * as ScenarioCreators from "creators/scenario";
import { getUnfilledBlocks } from "interceptors/scenario";
import { useLocation, useNavigate } from "react-router-dom";
import { errorFromResponse, successNotification } from "utils";
import i18n from "i18n";

// WSApi.CheckInitAuth | WSApi.ScenarioCheck
export const blockCheckInitAuthorization = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (!data?.error) {
    const { dispatch, getState } = store;

    if (data?.response?.branch) {
      dispatch(
        updateScenarioInitModal({
          isOpenInitAuthModal: true,
          initAuthModalData: data.response,
        })
      );
    } else {
      const checkInitAuthData = checkInitAuthData$(getState());
      const keys = Object.keys(checkInitAuthData);

      if (keys.length) {
        if (keys[0] === "change_init_auth" || keys[0] === "save_init_button") {
          const authorization_id = checkInitAuthData[keys[0]]?.authorization_id;
          authorization_id &&
            dispatch(
              updateInitBlock({
                authorization_id,
              })
            );
          successNotification(i18n.t("parametersWasUpdated"));
        } else if (keys[0] === "start_scenario") {
          const result = checkInitAuthData[keys[0]];
          const { scenario_id, version_id, branch } = result;

          dispatch(
            ScenarioCreators.startScenario(scenario_id, version_id, branch)
          );
        } else if (keys[0] === "debug_scenario_start") {
          const result = checkInitAuthData[keys[0]];
          const { json, version_id, branch } = result;

          dispatch(
            ScenarioCreators.debugScenarioStart(json, branch, version_id)
          );
        }
      }

      dispatch(updateCheckInitOptions({}));
    }
  }
  if (data?.error?.unfilled_blocks) {
    getUnfilledBlocks(
      data?.error?.unfilled_blocks as [],
      data?.error.scenario_id as string,
      data?.error?.editable_version_id as string,
      navigate
    );
  }

  if (data?.error?.reason) {
    errorFromResponse(data.error?.reason);
  }

  if (data?.error?.unavailable_service_or_methods) {
    const { dispatch } = store;
    const serviceMethodList = data?.error?.unavailable_service_or_methods;
    if (Array.isArray(serviceMethodList)) {
      const result: { [key: string]: [] } = {};

      serviceMethodList.forEach((service) => (result[service] = []));
      dispatch(setUnavailableServiceOrMethod(result));
      dispatch(setOpenUnavailableServiceModal(true));
    } else if (typeof serviceMethodList === "object") {
      const result: { [key: string]: [] } = {};

      Object.keys(serviceMethodList).forEach(
        (key) => (result[key] = serviceMethodList[key])
      );

      dispatch(setUnavailableServiceOrMethod(result));
      dispatch(setOpenUnavailableServiceModal(true));
    }
  }
};

export const scenarioCheck = blockCheckInitAuthorization;
