import { FC } from "react";
import { Button } from "components_new/button";
import { useTranslation } from "react-i18next";
import { SizeButton, VariantButton } from "components_new/button/utils";
import { useLocation } from "react-router-dom";
import { privateRoute, publicRoute } from "routes";
import style from "./supportLinkModal.module.scss";
import { btns } from "components_new/supportLink/utils";

interface IProps {
  onClose: () => void;
}

export const Modal: FC<IProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();

  const addNewPathByKey = (path: string, itemKey: string) => {
    let items = JSON.parse(localStorage.getItem(itemKey) || "[]");

    if (!Array.isArray(items)) {
      items = [];
    }

    items.push(path);

    localStorage.setItem(itemKey, JSON.stringify(items));
  };

  const handleClickSupport = () => {
    addNewPathByKey(pathname + search, "support-from");

    window.open(privateRoute.support, "_blank");
    onClose();
  };

  const handleClickFAQ = () => {
    addNewPathByKey(pathname + search, "help-faq-from");

    window.open(privateRoute.faq, "_blank");
    onClose();
  };

  const handleClickHelpCenter = () => {
    addNewPathByKey(pathname + search, "help-center-from-link");
    window.open(publicRoute.helpCenter, "_blank");

    onClose();
  };

  return (
    <div className={style.root}>
      <div className={style.title}>{t("helpCenter:helpCenterModalTitle")}</div>
      <div className={style.body}>
        {btns(handleClickSupport, handleClickFAQ, handleClickHelpCenter).map(
          ({ title, action }) => (
            <Button
              type="button"
              onClick={action}
              size={SizeButton.XL}
              variant={VariantButton.Primary}
              className={style.btn}
            >
              {t(title)}
            </Button>
          )
        )}
      </div>
    </div>
  );
};
