import * as Const from "../constants";
import { AutomationRoute } from "interfaces/state/automationRouting";
import { AutomationFileSize } from "pages/automation/containers/fileSystem";

export const setAutomationRoutingInit = () => {
  return {
    type: Const.AUTOMATION_ROUTE_INIT,
  };
};

export const deleteAutomationRouteFromIndex = (payload: number) => {
  return {
    type: Const.AUTOMATION_ROUTE_DELETE_FROM_TO,
    payload,
  };
};

export const setCurrentAutomationRoute = (payload: AutomationRoute | null) => {
  return {
    type: Const.SET_CURRENT_AUTOMATION_ROUTE,
    payload,
  };
};

export const setAutomationRouting = (payload: AutomationRoute[]) => {
  return {
    type: Const.SET_AUTOMATION_ROUTES,
    payload,
  };
};

export interface AutomationPaginationPayload {
  sort_by?: string;
  sort_direction?: string;
  limit?: number;
  offset?: number;
  page?: number;
}

export const updatePagination = (payload: AutomationPaginationPayload) => {
  return {
    type: Const.AUTOMATION_PAGINATION,
    payload,
  };
};

export const updateFilseSystemSize = (payload: AutomationFileSize) => {
  return {
    type: Const.AUTOMATION_SIZE,
    payload,
  };
};

export const updateSearch = (payload: string) => {
  return {
    type: Const.AUTOMATION_SEARCH,
    payload,
  };
};
