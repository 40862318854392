import { ChangeEvent, FC, FormEvent, useCallback, useState } from "react";
import cx from "classnames";
import { orderBy } from "lodash-es";
import { useTranslation } from "react-i18next";
import { categoryHeaders, overlayCategories } from "../utils";
import { ReactComponent as ArrowBottom } from "assets/svg/arrow-bottom.svg";
import { ReactComponent as MoreIcon } from "assets/svg/fi-more-horizontal.svg";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { companyCategories$ } from "selectors/scenarioCategories";
import { errorNotification } from "utils";
import {
  addScenarioCategory,
  companyCategoriesAdd,
  deleteCompanyCategory,
  deleteScenarioCategory,
  renameCompanyCategory,
} from "creators/scenarioCategories";
import { setScenarioCategories } from "actions/scenarioCategories";
import ScenarioHeaderPopover, {
  PopoverTheme,
} from "pages/scenario/components/scenarioHeaderPopover";
import { DropDownItem } from "pages/scenario/components/dropdownItem";
import { ScenarioCategory } from "interfaces/state/scenarioCategories";
import style from "./editCategories.module.scss";
import { Popup } from "components/popup";

interface IProps {
  isChange?: boolean;
  scenarioCategories?: ScenarioCategory[];
  scenario_id?: string;
  onClose?: () => void;
}

export const EditCategories: FC<IProps> = ({
  isChange,
  scenarioCategories,
  scenario_id,
  onClose,
}) => {
  const { t } = useTranslation("automation");
  const dispatch = useAppDispatch();
  const [newName, setNewName] = useState<string>("");
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [sort, setSort] = useState<boolean>(false);
  const companyCategories = useAppSelector(companyCategories$);
  const [isOpenDelete, setOpenDelete] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedId, setId] = useState<number | null>(null);
  const [isOpenRename, setOpenRename] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [addIds, setAddIds] = useState<number[]>(
    scenarioCategories?.map(({ id }) => id) || []
  );
  const [deleteIds, setDeleteIds] = useState<number[]>([]);

  const handleOpenOptions = (id: number, open: boolean) => {
    setOpen(open);
    setId(open ? id : null);
  };

  const handleChangeName = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    value.length <= 100
      ? setNewName(value)
      : errorNotification(t("automation:categoryNameLengthError"));
  };

  const ArrowCX = cx(style.arrow, {
    [style.asc]: sort,
  });

  const handleSortClick = useCallback(() => {
    setSort((prev) => !prev);
    const newCategories = orderBy(
      companyCategories,
      "name",
      !sort ? "asc" : "desc"
    );

    dispatch(
      setScenarioCategories({
        company_list: newCategories,
        isLoading: false,
        isError: false,
      })
    );
  }, [companyCategories, dispatch, sort]);

  const categoryById = () => {
    let result: { [key: string]: ScenarioCategory } = {};
    companyCategories.forEach((cat) => (result[cat.id] = cat));

    return result;
  };

  const handleCategoryAdd = useCallback(() => {
    if (!newName) return;
    const isExist = companyCategories.some((cat) => cat.name === newName);

    if (isExist) {
      errorNotification(t("existCategory"));
    } else {
      dispatch(companyCategoriesAdd(newName));
      setNewName("");
    }
  }, [companyCategories, dispatch, newName, t]);

  const MoreCX = (open: boolean) =>
    cx(style.icon, {
      [style.open]: open,
    });

  const handlePopoverClick = (item: string, id: number) => () => {
    setCurrentId(id);
    if (item === "renameCategory") {
      handleOpenRename();
      setName(categoryById()[id]?.name || "");
    } else {
      setOpenDelete(true);
    }
  };

  const handleSubmitDelete = () => {
    currentId && dispatch(deleteCompanyCategory(currentId));
    handleCloseDelete();
  };

  const handleCloseDelete = useCallback(() => setOpenDelete(false), []);

  const overlay = (id: number) => {
    return (
      <div className={style.items}>
        {overlayCategories.map((item) => (
          <DropDownItem
            key={item}
            onClick={handlePopoverClick(item, id)}
            text={t(item)}
            className={style.dropdownItem}
          />
        ))}
      </div>
    );
  };

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleAddCategory =
    ({ id }: ScenarioCategory) =>
    () => {
      setAddIds((prev) => [...prev, id]);
      setDeleteIds((prev) => prev.filter((c) => c !== id));
    };

  const handleDeleteCategory =
    ({ id }: ScenarioCategory) =>
    () => {
      setAddIds((prev) => prev.filter((c) => c !== id));
      setDeleteIds((prev) => [...prev, id]);
    };

  const getButton = ({ id, name }: ScenarioCategory) => {
    const selectedCategory = addIds?.find((cat) => cat === id);

    return selectedCategory ? (
      <button
        className={style.selected}
        onClick={handleDeleteCategory({ id, name })}
      >
        {"-"}
      </button>
    ) : (
      <button
        className={style.selected}
        onClick={handleAddCategory({ id, name })}
      >
        {"+"}
      </button>
    );
  };

  const CategoryCX = (selected: boolean) =>
    cx(style.category, { [style.open]: selected });

  const handleOpenRename = useCallback(() => setOpenRename(true), []);
  const handleCloseRename = useCallback(() => setOpenRename(false), []);
  const handleChangeCategoryName = useCallback(
    (value: string) => setName(value),
    []
  );
  const onApplyRenameCategory = useCallback(() => {
    currentId && dispatch(renameCompanyCategory(currentId, name));
    handleCloseRename();
  }, [currentId, dispatch, name, handleCloseRename]);

  const handleApply = () => {
    scenario_id && dispatch(addScenarioCategory(scenario_id, addIds));
    scenario_id &&
      deleteIds.length &&
      dispatch(deleteScenarioCategory(scenario_id, deleteIds));
    onClose && onClose();
  };

  return (
    <div className={style.root}>
      <div className={style.box}>
        <div className={style.title}>{t("allCategories")}</div>
        <div className={style.subtitle}>{t("allCategoriesModalSubtitle")}</div>
        {!isChange && (
          <form onSubmit={handleSubmitForm} className={style.header}>
            <input
              value={newName}
              onChange={handleChangeName}
              placeholder={t("addCategoryName")}
              className={style.search}
            />
            <button className={style.add} onClick={handleCategoryAdd}>
              <span>{t("create")}</span>
            </button>
          </form>
        )}
        <div className={style.body}>
          <div className={style.bodyHeader}>
            {categoryHeaders.map((header) => (
              <div
                key={header}
                className={style.headerItem}
                onClick={header === "name" ? handleSortClick : undefined}
              >
                {t(header)}
                {header === "name" && <ArrowBottom className={ArrowCX} />}
              </div>
            ))}
          </div>
          <div className={style.categories}>
            {companyCategories.map(({ id, name }) => (
              <div className={CategoryCX(isOpen && selectedId === id)} key={id}>
                <div className={style.name} title={name}>
                  {name}
                </div>
                {!!isChange && getButton({ id, name })}
                {!isChange && (
                  <ScenarioHeaderPopover
                    overlay={() => overlay(id)}
                    theme={PopoverTheme.LIGHT}
                    onOpen={(open: boolean) => {
                      handleOpenOptions(id, open);
                    }}
                    hideArrow
                    getOpenToChild
                    sideOffset={4}
                  >
                    {(open: boolean) => <MoreIcon className={MoreCX(open)} />}
                  </ScenarioHeaderPopover>
                )}
              </div>
            ))}
          </div>
          {!isChange && (
            <>
              <Popup
                isHideMainBtn
                isOpen={isOpenRename}
                onOpen={handleOpenRename}
                onClose={handleCloseRename}
                title={t("automation:renameCategory")}
                inputValue={name}
                onChange={handleChangeCategoryName}
                inputLabel={t("name")}
                placeholder={t("name")}
                maxInputLength={100}
                minInputLength={1}
                onApply={onApplyRenameCategory}
                errorMessage={t("automation:categoryNameLengthError")}
                errorText={t("automation:categoryNameLengthError")}
                disableOk={!name.trim()}
              />

              <Popup
                isHideMainBtn
                isOpen={isOpenDelete}
                onClose={handleCloseDelete}
                title={t("automation:deleteCategory")}
                subtitle={t("automation:deleteCategoryText")}
                onApply={handleSubmitDelete}
                hideInput
              />
            </>
          )}
          {!!isChange && (
            <div className={style.actions}>
              <button className={style.cancel} onClick={onClose}>
                {t("cancel")}
              </button>
              <button className={style.apply} onClick={handleApply}>
                {t("automation:applyChanges")}
              </button>{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
