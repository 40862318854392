import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { AnswerData } from "../index";
import * as ScenarioActions from "actions/scenario";

// WSApi.BlockUpdateCoordinates
export const blockUpdateCoordinates = (data: AnswerData) => {
  if (!data?.error) {
    const scenario = data.response?.scenario;
    const init = data.response?.init;
    const scenarioItem = scenarioItem$(store.getState());

    if (data.response?.scenario_id === scenarioItem?.scenario_id) {
      if (scenario) {
        store.dispatch(
          ScenarioActions.setScenarioSuccess({
            ...scenarioItem,
            scenario,
          })
        );
      }

      if (init) {
        store.dispatch(
          ScenarioActions.setScenarioSuccess({
            ...scenarioItem,
            init,
          })
        );
      }
    }
  }
};
