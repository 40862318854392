import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { FileSystemInfo } from "../fileSystemInfo";
import { Popup } from "components/popup";
import { ReactComponent as PlusIcon } from "assets/svg/fi-plus-circle.svg";
import { currentAutomationRoute$ } from "selectors/automationRouting";
import { createAutomationFolder } from "creators/folders";
import { createNewScenario } from "creators/scenario";
import style from "./fileSystemActions.module.scss";

export const FileSystemActions = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentAutomationRoute = useAppSelector(currentAutomationRoute$);
  const [newFolderName, setNewFolderName] = useState<string>("");
  const [newScenarioName, setNewScenarioName] = useState<string>("");
  const [isOpenNewFolder, setOpenNewFolder] = useState<boolean>(false);
  const [isOpenNewScenario, setOpenNewScenario] = useState<boolean>(false);
  const [isOpenAfterCreate, setOpenAfterCreate] = useState<boolean>(true);

  const handleOpenNewFolder = useCallback(() => setOpenNewFolder(true), []);
  const handleCloseNewFolder = useCallback(() => {
    setOpenNewFolder(false);
    setNewFolderName("");
  }, []);

  const handleChangeFolderName = (value: string) => setNewFolderName(value);

  const newFolderNameSubmit = () => {
    currentAutomationRoute &&
      dispatch(
        createAutomationFolder(
          currentAutomationRoute!.id! as number,
          newFolderName
        )
      );
    handleCloseNewFolder();
  };

  const handleOpenNewScenario = useCallback(() => setOpenNewScenario(true), []);
  const handleCloseNewScenario = useCallback(() => {
    setOpenNewScenario(false);
    setNewScenarioName("");
  }, []);
  const handleChangeScenarioName = (value: string) => setNewScenarioName(value);

  const handleChangeOpenAfterCreate = () => setOpenAfterCreate((prev) => !prev);

  const onSubmitCreateScenario = () => {
    dispatch(createNewScenario(newScenarioName, isOpenAfterCreate));
    handleCloseNewScenario();
  };
  return (
    <div className={style.root}>
      <FileSystemInfo />
      <Popup
        disabled={!currentAutomationRoute}
        mainBtnClassName={style.createFolder}
        mainBtnText={
          <div className={style.folder}>
            <PlusIcon />
            <div className={style.action}>{t("automation:newFolder")} </div>
          </div>
        }
        isOpen={isOpenNewFolder}
        onOpen={handleOpenNewFolder}
        onClose={handleCloseNewFolder}
        title={t("automation:createNewFolder")}
        inputValue={newFolderName}
        onChange={handleChangeFolderName}
        inputLabel={t("automation:folderName")}
        placeholder={t("automation:folderName")}
        maxInputLength={100}
        minInputLength={1}
        onApply={newFolderNameSubmit}
        errorText={t("folderName100Characters")}
        errorMessage={t("folderName100Characters")}
        disableOk={!newFolderName.trim()}
      />

      <Popup
        disabled={!currentAutomationRoute}
        mainBtnClassName={style.createScenario}
        mainBtnText={
          <div className={style.scenario}>
            <PlusIcon />
            <div className={style.action}>{t("automation:newScenario")} </div>
          </div>
        }
        isOpen={isOpenNewScenario}
        onOpen={handleOpenNewScenario}
        onClose={handleCloseNewScenario}
        title={t("automation:createScenario")}
        inputValue={newScenarioName}
        onChange={handleChangeScenarioName}
        inputLabel={t("automation:scenarioName")}
        placeholder={t("automation:scenarioName")}
        maxInputLength={100}
        minInputLength={1}
        checkboxText={t("automation:openAfterCreation")}
        isChecked={isOpenAfterCreate}
        onChangeCheckbox={handleChangeOpenAfterCreate}
        onApply={onSubmitCreateScenario}
        showCheckbox
        errorMessage={t("scenarioName100Characters")}
        errorText={t("scenarioName100Characters")}
        disableOk={!newScenarioName.trim()}
      />
    </div>
  );
};
