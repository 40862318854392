import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as TeamActions from "actions/team";

// WSApi.ChangeMemberRole
export const companyChangeMemberRole = (data: AnswerData) => {
  if (data.error) {
    store.dispatch(TeamActions.setChangeMemeberRoleError());
    errorFromResponse(data.error);
  } else {
    const memberRoles = data.response;

    store.dispatch(TeamActions.changeMemberRoleList(memberRoles));
  }
};
