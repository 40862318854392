import State from "interfaces/state";
import { Scenario } from "interfaces/state/scenario";
import { createSelector } from "reselect";

export const scenarioItem = (state: State) => state.scenario.scenarioItem;
export const scenarioItem$: (state: State) => Scenario | null | undefined =
  createSelector(scenarioItem, (scenario) => scenario);

export const isLoading$ = (state: State) => state.scenario.isLoading;
export const isError$ = (state: State) => state.scenario.isError;
export const selectedBlockId$ = (state: State) =>
  state.scenario.selectedBlockId;
export const currentBlock = (state: State) => state.scenario.cube;
export const currentBlock$ = createSelector(currentBlock, (block) => block);
export const tempBlockId$ = (state: State) => state.scenario.tempBlockId;
export const processing$ = (state: State) => state.scenario.processing;
export const blockWithErrors$ = (state: State) =>
  state.scenario.blockWithErrors;
export const method_name$ = (state: State) => state.scenario.method_name;
export const debugScenarioId$ = (state: State) =>
  state.scenario.debugScenarioId;

export const isOpenSelectBranchRightPanel$ = (state: State) =>
  state.scenario.isOpenSelectBranchRightPanel;
export const isOpenEmptyBlocksErrorModal$ = (state: State) =>
  state.scenario.isOpenEmptyBlocksErrorModal;
export const editableVersion$ = (state: State) =>
  state.scenario.editableVersion;
export const isOpenNotAllowedToEditModal$ = (state: State) =>
  state.scenario.isOpenNotAllowedToEditModal;
export const scenarioZoom$ = (state: State) => state.scenario.zoom;

export const savedInitBlock$ = (state: State) => state.scenario.initUpdates;
export const initAuthModalData$ = (state: State) =>
  state.scenario.initAuthModalData;
export const isOpenInitAuthModal$ = (state: State) =>
  state.scenario.isOpenInitAuthModal;

export const checkInitAuthData$ = (state: State) => state.scenario.checkInit;
export const isUpdateVariable$ = (state: State): boolean =>
  state.scenario.isUpdateVariable;

export const isOpenUnavailableServicesModal$ = (state: State): boolean =>
  state.scenario.isOpenUnavailableServicesModal;

export const unavailableServiceOrMethods$ = (state: State) =>
  state.scenario.unavailableServiceOrMethods;
