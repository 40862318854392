import { RootState } from "store";

export const isLoading$ = (state: RootState) =>
  state.journalScenariosList.isLoading;
export const isError$ = (state: RootState) =>
  state.journalScenariosList.isError;
export const list$ = (state: RootState) => state.journalScenariosList.list;
export const limit$ = (state: RootState) => state.journalScenariosList.limit;
export const offset$ = (state: RootState) => state.journalScenariosList.offset;
export const hasMore$ = (state: RootState) =>
  state.journalScenariosList.hasMore;
