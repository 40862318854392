import { FC, useCallback } from "react";
import cx from "classnames";
import Routing from "../routing";
import { AutomationFileSize } from "../fileSystem";
import { ReactComponent as ListIcon } from "assets/svg/fi-list.svg";
import { ReactComponent as GridIcon } from "assets/svg/fi-grid.svg";
import style from "./fileSystemHeader.module.scss";

interface IProps {
  onChangeSize: (size: AutomationFileSize) => void;
  size: AutomationFileSize;
  name?: string;
}

export const FileSystemHeader: FC<IProps> = ({ size, onChangeSize, name }) => {
  const ListIconCX = cx(style.icon, {
    [style.selected]: size === AutomationFileSize.TABLE,
  });

  const GridIconCX = cx(style.icon, {
    [style.selected]: size === AutomationFileSize.BLOCKS,
  });

  const handleSmallSizeClick = useCallback(
    () => onChangeSize(AutomationFileSize.TABLE),
    [onChangeSize]
  );
  const handleBigSizeClick = useCallback(
    () => onChangeSize(AutomationFileSize.BLOCKS),
    [onChangeSize]
  );

  return (
    <div className={style.root}>
      <Routing hideButton />
      <div className={style.actions}>
        <ListIcon className={ListIconCX} onClick={handleSmallSizeClick} />
        <GridIcon className={GridIconCX} onClick={handleBigSizeClick} />
      </div>
    </div>
  );
};
