import i18n from "i18n";
import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as MainFolderActions from "actions/mainFolder";
import * as AutomationRouteActions from "actions/automationRouting";
import * as FoldersCreators from "creators/folders";
import { AutomationRouteType } from "interfaces/state/automationRouting";
import {
  limit$,
  offset$,
  sortBy$,
  sortDirection$,
} from "selectors/automationRouting";

// WSApi.GetMainFolder
export const folderGetMain = (data: AnswerData) => {
  const { dispatch, getState } = store;
  if (data.error) {
    dispatch(MainFolderActions.setMainFolderError());
    errorFromResponse(data.error);
  } else {
    const searchParams = new URLSearchParams(window.location.search);
    const { id } = data.response;
    const searchPage = Number(searchParams.get("page"));
    const searchId = Number(searchParams.get("id"));
    const page = searchPage * 8;
    const resultPage = page - 8;
    const sortBy = sortBy$(getState());
    const sortDirection = sortDirection$(getState());
    const limit = limit$(getState());
    const offset = !isNaN(resultPage) ? resultPage : offset$(getState());

    const mainFolder = {
      id: id as number,
      title: i18n.t("automation:yourScenarios"),
      type: AutomationRouteType.FOLDER,
    };

    !isNaN(searchPage) &&
      dispatch(
        AutomationRouteActions.updatePagination({
          page: searchPage || 1,
          offset: offset < 0 ? 0 : offset,
        })
      );

    dispatch(
      FoldersCreators.fetchAllAutomationFiles(
        !isNaN(+searchId) && +searchId > 0 ? +searchId : id,
        sortBy,
        sortDirection,
        limit,
        offset < 0 ? 0 : offset
      ) as any
    );
    dispatch(AutomationRouteActions.setCurrentAutomationRoute(mainFolder));
    dispatch(MainFolderActions.setMainFolderSuccess(id));
  }
};
