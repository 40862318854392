import { ScenarioStatus } from "./scenarios";
import { ServiceParameter } from "./serviceParameter";

export interface CheckObject {
  [key: string]: string | number | any;
}

export default interface ScenarioState {
  isLoading: boolean;
  isError: boolean;
  scenarioItem: Scenario | null | undefined;
  selectedBlockId: null | string | number;
  cube: BlockExecuteMethod | BlockCaseOf | BlockExit | BlockCounter | null;
  tempBlockId: null | string;
  processing: { [key: string]: IScenarioPropcessing };
  blockWithErrors: IBlockError[];
  method_name: string | null;
  debugScenarioId: null | string;
  isOpenSelectBranchRightPanel: boolean;
  isOpenEmptyBlocksErrorModal: boolean;
  isOpenNotAllowedToEditModal: boolean;
  isOpenUnavailableServicesModal: boolean;
  editableVersion: IEditableVersion | null;
  zoom: number;
  isOpenInitAuthModal: boolean;
  initAuthModalData: null | InitAuthModalData;
  initUpdates: BlockInit | null;
  checkInit: { [key: string]: CheckObject };
  isUpdateVariable: boolean;
  unavailableServiceOrMethods: { [key: string]: string[] };
}

export interface InitAuthModalData {
  branch: BranchType;
  scenario_id: string;
  scenario_name: string;
}

export interface IEditableVersion {
  id: string;
  scenario_id: string;
}
export interface IBlockError {
  block_id: string;
  empty_args: { display_name: string; id: string; name: string }[];
  reason?: string;
}
export interface IScenarioPropcessing {
  scenario_id: string | number;
  isLoading: boolean;
}
export type BlockType =
  | BlockExecuteMethod
  | BlockFormBody
  | BlockCaseOf
  | BlockExit
  | BlockCounter
  | null;

export type Scenario = {
  account_id: string;
  active_branches: BranchType[];
  company_id: number;
  init: ScenarioInit;
  is_allowed_to_edit?: boolean;
  folder_id: number;
  name: string;
  pipeline: Pipeline[];
  scenario: AllScenarioBlocks[];
  scenario_id: string;
  scenario_status: ScenarioStatus /* active branches */;
  variables: IVariable[];
  version_id: string;
  services: string[];
};

export type AllScenarioBlocks =
  | BlockExecuteMethod
  | BlockCaseOf
  | BlockExit
  | BlockCounter;

export interface IVariable {
  name: string;
  id: string;
  value_type: "schema" | "input" | "library";
  value: string[] | FormBodyValueTypeLibraryValue[];
  from_block_id: string;
  data_type: string;
  service: string;
  list_keys_with_index: { [key: string]: null | number };
  path: string;
  path_type: string[];
}

export interface IScenarioVariable {
  scenario_id: string;
  from_block_id: string;
  data_type: string;
  value_type: VariableType;
  value?: IVarValue;
  name: string;
  path_to_list?: string[];
  path_to_value?: string[];
  path_to_compare_key?: string[];
  value_to_compare?: {
    value_type: "variable";
    value: string;
    name: "value";
  };
  nth_element?: number;
  id: string;
}

export enum VariableType {
  SCHEMA = "schema",
  LIST_OF_OBJECTS = "list_of_objects",
  LIST_NTH = "list_nth",
  LIBRARY = "library",
}

type IVarValue = string[] | FormBodyValueTypeLibraryValue;

export type ScenarioInit = {
  args: ScenarioArgs;
  branch?: Branch;
  event_service: string;
  type: string;
  event_name: string;
  method_name: string;
  name?: string;
  start_opts: { [key: string]: ServiceParameter };
  testing_start_opts: { [key: string]: ServiceParameter };
  x_coordinate?: number;
  y_coordinate?: number;
  authorization_id?: string;
  testing_authorization_id?: string;
  days_of_the_week?: WeekDaysType[];
  range?: string | null;
  frequency?: FrequencyType;
  arguments?: {};
  period?: {
    from: string;
    to: string;
  };
  interval?: number;
  timezone?: string;
};

export type FrequencyType = "daily" | "weekly" | "monthly" | "annually";

export type WeekDaysType =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export type StartOpts =
  | FormBodyValueTypeInput[]
  | FormBodyValueTypeSchema[]
  | FormBodyValueTypeTemplate[]
  | FormBodyValueTypeLibrary[];

export interface BlockInit extends ScenarioInit {
  type: "initial_block";
}

export type ScenarioArgs = {
  token: string;
};

export type Pipeline = {
  first?: boolean;
  id: string;
  next: string | null;
  in?: string;
  source_position?: null | string;
};

export type Block = {
  id: string;
  type?: string;
};

export type BlockExecuteMethod = {
  id: string | number;
  method_service: string;
  type: "execute_method";
  body_position: number;
  method_name: string;
  name?: string;
  arguments?: { [key: string]: ServiceParameter };
  testing_arguments?: { [key: string]: ServiceParameter };
  x_coordinate?: number;
  y_coordinate?: number;
  authorization_id?: string;
  testing_authorization_id?: string;
  start_opts?: [];
  event_service: string;
  branch: Branch;
};

export type BlockFormBody = {
  id: string | number;
  type: "form_body";
  frame: FormBodyValueTypes;
  name?: string;
  x_coordinate?: number;
  y_coordinate?: number;
  arguments?: {};
  method_name?: "";
  start_opts?: [];
  event_service: string;
};

export type FormBodyValueTypeInput = {
  value_type: "input";
  name: string;
  value: string;
};

export type FormBodyValueTypeVariable = {
  name: string;
  value_type: "variable";
  value: string;
};

export type FormBodyValueTypeSchema = {
  value_type: "schema";
  name: string;
  value: string[];
  data_position: number;
};

export type FormBodyValueTypeTemplate = {
  value_type: "template";
  name: string;
  value: (string | string[])[];
  data_position: number;
};

export type FormBodyValueTypeLibrary = {
  value_type: "library";
  name: string;
  value: FormBodyValueTypeLibraryValue;
};

export type FormBodyValueTypes = (
  | FormBodyValueTypeLibrary
  | FormBodyValueTypeTemplate
  | FormBodyValueTypeSchema
  | FormBodyValueTypeInput
  | FormBodyValueTypeMap
  | FormBodyValueTypeList
  | FormBodyValueTypeVariable
)[];

export type FormBodyValueType =
  | FormBodyValueTypeLibrary
  | FormBodyValueTypeTemplate
  | FormBodyValueTypeSchema
  | FormBodyValueTypeInput
  | FormBodyValueTypeMap
  | FormBodyValueTypeList
  | FormBodyValueTypeVariable;

export type FormBodyValueTypeLibraryValue = {
  lib_name: string;
  function_name: string;
  arguments: FormBodyValueTypes;
};

export type FormBodyValueTypeMap = {
  value_type: "map";
  name: string;
  value: FormBodyValueTypes;
};

export type FormBodyValueTypeList = {
  value_type: "list";
  name: string;
  value: (string | string[] | number[])[];
};

export type BlockCaseOf = {
  type: "case_of";
  id: string | number;
  patterns: CaseofTypes;
  name?: string;
  variable_to_compare: string;
  x_coordinate?: number;
  y_coordinate?: number;
  arguments?: [];
  method_name?: "";
  start_opts?: [];
  event_service: string;
  authorization_id?: string;
  testing_authorization_id?: string;
};

export type BlockCounter = {
  type: "counter";
  id: string | number;
  name?: string;
  x_coordinate?: number;
  y_coordinate?: number;
  arguments?: [];
  method_name?: "";
  start_opts?: [];
  event_service: string;
  authorization_id?: string;
  testing_authorization_id?: string;
};

export type BlockExit = {
  type: "exit";
  id: string | number;
  name?: string;
  x_coordinate?: number;
  y_coordinate?: number;
  arguments?: [];
  method_name?: "";
  start_opts?: [];
  event_service: string;
  authorization_id?: string;
  testing_authorization_id?: string;
};

export type CaseofTypes = (
  | CaseOneOf
  | CaseMoreAndLess
  | CaseFirstLetters
  | CaseEqual
)[];

export type CaseOneOf = {
  pattern_type: "one_of";
  destination_block: string;
  case: string[];
  block_id: string;
  args: FormBodyValueTypes;
};

export type CaseMoreAndLess = {
  pattern_type: "more_and_less";
  destination_block: string;
  case: string[];
  block_id: string;
  args: FormBodyValueTypes;
};

export type CaseFirstLetters = {
  pattern_type: "first_letters";
  destination_block: string;
  case: string[];
  block_id: string;
  args: FormBodyValueTypes;
};

export type CaseEqual = {
  pattern_type: CasePatternType;
  destination_block: string;
  args: FormBodyValueTypeInput[];
};

export type CasePatternType =
  | "equal"
  | "prefix"
  | "all"
  | "not_equal"
  | "contains"
  | "does_not_contain"
  | "greater_then"
  | "less_then"
  | "greater_then_or_equal"
  | "less_then_or_equal"
  | "exists"
  | "does_not_exist";

export enum Branch {
  LIVE = "live",
  TESTING = "testing",
  DEVEL = "devel",
}

export type BranchType = Branch.DEVEL | Branch.LIVE | Branch.TESTING;
