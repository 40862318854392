import { ILibFunction } from "interfaces/state/libFunction";
import * as Const from "../constants";

export const setFParamInit = () => {
  return {
    type: Const.F_PARAM_INIT,
  };
};

export const setFParamList = (
  payload: ILibFunction[],
  scenario_id: string,
  block_id: string
) => {
  return {
    type: Const.F_PARAM_SUCCESS,
    payload,
    scenario_id,
    block_id,
  };
};

export const setFParamError = () => {
  return {
    type: Const.F_PARAM_FAIL,
  };
};

export const setFParamLoading = () => {
  return {
    type: Const.F_PARAM_LOADING,
  };
};
