import { eventHandler } from "creators/ws";
import React from "react";
import { queue$, WebsocketItem$ } from "selectors/wsConnect";
import store from "store/index";
import Swal, { SweetAlertIcon, SweetAlertResult } from "sweetalert2";
import i18n from "i18n";
import "./dark.min.css";
import { BusinessType } from "interfaces/state/templates";
import marketing from "assets/png/templates/marketing.png";
import finance from "assets/png/templates/finance.png";
import PR from "assets/png/templates/PR.png";
import product from "assets/png/templates/product.png";
import sales from "assets/png/templates/sales.png";

import lampIcon from "assets/png/lamp.png";
import premIcon from "assets/png/plans/premIcon.png";
import enterpriseIcon from "assets/png/plans/enterpriseIcon.png";

import youcontrol from "assets/png/services/youc.png";
import zoom from "assets/png/services/zoom.png";
import monobank from "assets/png/services/mono.png";
import telegram from "assets/png/services/telegram.png";
import instagram from "assets/png/services/instagram.png";
import facebook_messenger from "assets/png/services/facebook_messenger.png";
import facebook from "assets/png/services/facebook.png";
import viberbot from "assets/png/services/viber.png";
import trello from "assets/png/services/trello.png";
import excel from "assets/png/services/excel.png";
import privatbank from "assets/png/services/privat.png";
import stripe from "assets/png/services/stripe.png";
import googlesheets from "assets/png/services/google_sheets.png";
import wayforpay from "assets/png/services/wayforpay.png";
import finmap from "assets/png/services/finmap.png";
import promua from "assets/png/services/promua.png";
import googledrive from "assets/png/services/google_drive.png";
import tiktok from "assets/png/services/tiktok.png";
import salesdrive from "assets/png/services/salesdrive.png";
import regtoevent from "assets/png/services/regtoev.png";
import chatGPT from "assets/png/services/chatGPT.png";
import googleDocs from "assets/png/services/google_docs.png";
import googleCalendar from "assets/png/services/google_calendar.png";
import googleForms from "assets/png/services/google_forms.png";

import webhook from "assets/png/blocktypes/webhook.png";
import schedule from "assets/png/blocktypes/schedule.png";
import execute_method from "assets/png/blocktypes/execute_method.png";
import counter from "assets/png/blocktypes/counter.png";
import case_of from "assets/png/blocktypes/case_of.png";

import { sid$ } from "selectors/auth";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import "dayjs/locale/uk";
import { PlanNameType } from "interfaces/state/billingInfo";
import { AuthLoader } from "components/authLoader";
import { Action } from "redux";

import * as Const from "constants/index";
import { WSInit } from "creators/wsConnect";

import {
  AllScenarioBlocks,
  CaseofTypes,
  IBlockError,
  Scenario,
} from "interfaces/state/scenario";
import { INIT_BLOCK } from "pages/scenario/constants";
import {
  IInputServiceParameter,
  ServiceParameterItem,
  ServiceParameterType,
  AllParameterTypes,
  ServiceParameter,
} from "interfaces/state/serviceParameter";
import { sendEvent } from "creators/googleAnalytics";
import {
  stringParameters,
  templateParameters,
  booleanParameters,
} from "components/inputParameter/utils";
import { PaymentSystem } from "actions/billingInfo";
import mcIcon from "assets/png/masterCard.png";
import visaIcon from "assets/png/visa.png";
import "i18next";

import { setWSQueue } from "actions/wsConnect";
import { WSApi } from "enums/wsApi";
import State from "interfaces/state";
import { processingEvents } from "creators/wsAnswers/entry";
import { useLocation, useNavigate } from "react-router-dom";
import { IMention } from "components/mentionList";

dayjs.extend(relativeTime);

export const SessionResponseError = {
  400: "Session does not exist!",
  403: "Forbidden",
  404: "Not found",
  500: "Internal server error",
};

export const errorResponseNotification = (
  status: 400 | 403 | 404 | 500,
  timer: number = 5000,
  message?: string
) => {
  const formattedMessage = capitalizeFirstLetter(
    replaceSymbolFromStr(" ", "_", message)
  );

  const answer = i18n.t(message);

  const resp = answer === message ? formattedMessage : answer;

  Swal.fire({
    title: "",
    text: message ? resp : SessionResponseError[status],
    icon: "error",
    toast: true,
    position: "bottom-right",
    timer,
    background: "#1D1D1D",
    customClass: {
      popup: "black-popup",
      title: "title-popup",
      container: "content-popup",
      htmlContainer: "html-container-popup",
      icon: "icon-popup",
      confirmButton: "confirm-btn-popup",
    },
  });
};

export const successNotification = (
  text: string,
  title: string = "",
  timer: number = 5000
) => {
  Swal.fire({
    title,
    text,
    icon: "success",
    toast: true,
    position: "bottom-right",
    timer,
    customClass: {
      popup: "black-popup",
      title: "title-popup",
      container: "content-popup",
      htmlContainer: "html-container-popup",
      icon: "icon-popup",
      confirmButton: "confirm-btn-popup",
    },
  });
};

export const errorNotification = (
  text: string,
  title: string = "",
  timer: number = 5000
) => {
  Swal.fire({
    title,
    text,
    icon: "error",
    timer,
    toast: true,
    position: "bottom-right",
    customClass: {
      popup: "black-popup",
      title: "title-popup",
      container: "content-popup",
      htmlContainer: "html-container-popup",
      icon: "icon-popup",
      confirmButton: "confirm-btn-popup",
    },
  });
};

interface IShowAlertMessage {
  title: string;
  html: string;
  timer?: number;
  timerProgressBar?: boolean;
  showCancelButton?: boolean;
  showCloseButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  showConfirmButton?: boolean;
  icon?: SweetAlertIcon;
}
export const showAlertWithMessage = ({
  title,
  html,
  timer = 15000,
  timerProgressBar = true,
  showCancelButton,
  showCloseButton,
  confirmButtonText,
  cancelButtonText,
  showConfirmButton,
  icon,
}: IShowAlertMessage) => {
  Swal.fire({
    title,
    html,
    timer,
    timerProgressBar,
    showCancelButton,
    showCloseButton,
    confirmButtonText,
    cancelButtonText,
    showConfirmButton,
    icon,
    background: "white",
    customClass: {
      title: "dark-title-popup",
    },
  });
};

export const envLinkSetter = () => {
  return window.location.origin;
};

export function withSuspense<T>(
  WrappedComponent: React.ComponentType<T | any>
) {
  return (props: any) => {
    return (
      <React.Suspense fallback={<AuthLoader />}>
        <WrappedComponent {...props} />
      </React.Suspense>
    );
  };
}

export const addSpaceToNumber = (value: string | number) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || 0;
};

export const addSpaceToCardNumber = (value: string | number | null) => {
  if (!value) return "";
  return value?.toString().replace(/(.{4})/g, "$1 ");
};

export const emailValidation = (email: string) => {
  return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email);
};

export function getCookie(name: string): string | null {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
    " " +
    sizes[i]
  ).toString();
}

export const planTranslater = (plan: PlanNameType) => {
  return plan && i18n.t(plan);
};

export const errorFromResponse = (error: string) => {
  if (error) {
    errorNotification(i18n.t(error));
    return i18n.t(error);
  } else {
    errorNotification(i18n.t("errorOccurred"));
    return i18n.t("errorOccurred");
  }
};

export const WS_CONNECTING = 0;
export const WS_OPEN = 1;
export const WS_CLOSING = 2;
export const WS_CLOSED = 3;

let websocketInstance: WebSocket | null = null;
let isConnecting: boolean = false;

export const webSocketProxyHandler = (
  dispatch: (action: Action) => void,
  getState: () => State,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) =>
  new Proxy(window.WebSocket, {
    construct: function (target: any, args: string | string[] | any): any {
      let instance: WebSocket;

      const connect = () => {
        instance = new target(...args);

        const openHandler = (event: Event) => {
          dispatch({ type: Const.WS_CONNECT, payload: "ok" } as any);
          setTimeout(() => dispatch(WSInit() as any));
          setInterval(() => dispatch(WSInit() as any), 30000);
        };

        const messageHandler = (event: MessageEvent) => {
          const message = event.data;
          const data = JSON.parse(message);

          if (data.error) {
            if (typeof data.error === "object") {
              const keys = Object.keys(data.error);
              if (keys.length > 0) {
                typeof data.error === "string" &&
                  errorNotification(i18n.t(keys[0]), undefined, 4000);
              }
            } else {
              typeof data?.error === "string" &&
                errorNotification(i18n.t(data.error), undefined, 4000);
            }
          }
          processingEvents(data, location, searchParams, navigate);
        };

        const errorHandler = (event: any) => {
          dispatch({ type: Const.WS_CONNECT, payload: "error" } as any);
        };

        const closeHandler = (event: CloseEvent) => {
          dispatch({ type: Const.WS_CONNECT, payload: "closing" } as any);
          instance.removeEventListener("open", openHandler);
          instance.removeEventListener("message", messageHandler);
          instance.removeEventListener("close", closeHandler);
          instance.removeEventListener("error", errorHandler);

          if (document.visibilityState !== "visible") {
            window.location.reload();
          } else {
            websocketInstance = webSocketProxyHandler(
              dispatch,
              getState,
              location,
              searchParams,
              navigate
            );
          }
        };

        instance.addEventListener("open", openHandler);
        instance.addEventListener("message", messageHandler);
        instance.addEventListener("close", closeHandler);
        instance.addEventListener("error", errorHandler);

        const sendProxy = new Proxy(instance.send, {
          apply: function (target, thisArg, args) {
            if (thisArg.readyState === 1) {
              target.apply(thisArg, args as any);
            } else {
              if (thisArg.readyState === 3) {
                if (!isConnecting) {
                  connect();
                }
              }
            }
          },
        });

        instance.send = sendProxy;

        websocketInstance = instance;

        return instance;
      };

      if (!websocketInstance && !isConnecting) {
        isConnecting = true;
        return connect();
      } else {
        return websocketInstance;
      }
    },
  });

export const sendWSMessage = (data: any) => {
  eventHandler(data);
  const wsFunction = WebsocketItem$(store.getState());
  const queue = queue$(store.getState());
  if (
    queue.includes(data?.action) &&
    data.action !== WSApi.UpdateBlock &&
    data.action !== WSApi.ScenarioStop
  ) {
    return;
  } else {
    const newQueue = [...queue, data.action];
    store.dispatch(setWSQueue(newQueue));
    setTimeout(() => {
      const newData = queue$(store.getState()).filter((q) => q !== data.action);
      store.dispatch(setWSQueue(newData));
    }, 2000);
    if (wsFunction && wsFunction.readyState === 1) {
      wsFunction.send(JSON.stringify(handleWSParams(data)));
    } else {
      setTimeout(() => {
        sendWSMessage(data);
      }, 2000);
      return;
    }
  }
};

export const changeTranslation = (lang: string) => {
  i18n.changeLanguage(lang);
  sendEvent("User", "Change", "Change language to " + lang, 1);
};

export const languageList = () => {
  return [
    { value: "uk" || "uk-UA", label: "UA" },
    { value: "en", label: "EN" },
  ];
};

export const languageLabel = (lang: "uk" | "en" | "uk-UA") => {
  if (lang === "uk" || lang === "uk-UA") {
    return "UA";
  } else {
    return lang.toUpperCase();
  }
};

type AnyArray = Array<string | number | Object | any>;

export const isEqual = (a: AnyArray, b: AnyArray) =>
  JSON.stringify(a) === JSON.stringify(b);

export const handleBusinessTypeIcon = (bt?: BusinessType) => {
  const icon: {
    PR: string;
    marketing: string;
    finance: string;
    product: string;
    sales: string;
  } = Object.freeze({
    marketing,
    finance,
    PR,
    product,
    sales,
  });

  if (bt && icon[bt]) {
    return icon[bt];
  }
};

export const handlePlanTypeIcon = (planType: PlanNameType) => {
  const icon: any = {
    free: lampIcon,
    premium: premIcon,
    enterprise: enterpriseIcon,
  };

  return planType && icon?.[planType.toLowerCase()]
    ? icon[planType.toLowerCase()]
    : icon.free;
};

export type BlockTypeIcon =
  | "webhook"
  | "schedule"
  | "execute_method"
  | "case_of"
  | "counter"
  | "execute"
  | "case of";

export const blockTypes = [
  "webhook",
  "schedule",
  "execute_method",
  "case_of",
  "counter",
  "execute",
  "case of",
  "розклад",
  "виконуючий",
  "варіативність",
  "лічильник",
];

export const handleBlockTypeIcon = (key: BlockTypeIcon) => {
  const icon: { [key in BlockTypeIcon]: string } = Object.freeze({
    counter,
    case_of,
    варіативність: case_of,
    execute_method,
    schedule,
    webhook,
    розклад: schedule,
    виконуючий: execute_method,
    execute: execute_method,
    лічильник: counter,
    "case of": case_of,
  });

  return icon?.[key] ? icon[key] : "";
};

export type ServiceIconType =
  | "monobank"
  | "telegram"
  | "instagram"
  | "facebook_messenger"
  | "facebook"
  | "trello"
  | "viberbot"
  | "excel"
  | "privatbank"
  | "stripe"
  | "googlesheets"
  | "youcontrol"
  | "googledrive"
  | "wayforpay"
  | "finmap"
  | "promua"
  | "zoom"
  | "viber"
  | "tiktok"
  | "instagram_for_professionals"
  | "salesdrive"
  | "regtoevent"
  | "chat_gpt"
  | "googledocs"
  | "googlecalendar"
  | "googleforms";

export const servicesWithIcons = [
  "monobank",
  "telegram",
  "instagram",
  "facebook_messenger",
  "trello",
  "viberbot",
  "excel",
  "privatbank",
  "stripe",
  "googlesheets",
  "youcontrol",
  "googledrive",
  "wayforpay",
  "finmap",
  "promua",
  "zoom",
  "viber",
  "tiktok",
  "instagram_for_professionals",
  "facebook",
  "salesdrive",
  "regtoevent",
  "chat_gpt",
  "googledocs",
  "googlecalendar",
  "googleforms",
];

export const cardManufacturers = ["mc", "visa"];

export const handleServiceIcon = (service: ServiceIconType | string) => {
  const icon: {
    monobank: string;
    telegram: string;
    instagram: string;
    facebook_messenger: string;
    facebook: string;
    trello: string;
    viberbot: string;
    excel: string;
    privatbank: string;
    stripe: string;
    googlesheets: string;
    youcontrol: string;
    googledrive: string;
    wayforpay: string;
    finmap: string;
    promua: string;
    zoom: string;
    viber: string;
    tiktok: string;
    instagram_for_professionals: string;
    salesdrive: string;
    regtoevent: string;
    chat_gpt: string;
    googledocs: string;
    googlecalendar: string;
    googleforms: string;
  } = Object.freeze({
    monobank,
    telegram,
    instagram,
    facebook_messenger,
    facebook,
    trello,
    viberbot,
    excel,
    privatbank,
    stripe,
    googlesheets,
    youcontrol,
    googledrive,
    wayforpay,
    finmap,
    promua,
    viber: viberbot,
    zoom,
    tiktok,
    instagram_for_professionals: instagram,
    salesdrive,
    regtoevent,
    chat_gpt: chatGPT,
    googledocs: googleDocs,
    googlecalendar: googleCalendar,
    googleforms: googleForms,
  });

  if ((service as ServiceIconType) && icon[service as ServiceIconType]) {
    return icon[service as ServiceIconType];
  }
};

export const handleServiceColor = (service: ServiceIconType) => {
  const colors = {
    monobank: "#010101",
    telegram: "#0088CC",
    instagram: "#DD2A7B",
    facebook_messenger: "#1778F2",
    facebook: "#1778F2",
    trello: "#008FE4",
    viberbot: "#644ACB",
    excel: "#1D6F42",
    privatbank: "#5FBA46",
    stripe: "#635AFF",
    googlesheets: "#1FA463",
    youcontrol: "#F3BA27",
    googledrive: "#FFD04B",
    wayforpay: "#F3BA27",
    finmap: "#F3BA27",
    promua: "#8B08F9",
    zoom: "#2D8CFF",
    viber: "#644ACB",
    tiktok: "#010101",
    instagram_for_professionals: "#DD2A7B",
    salesdrive: "#215F92",
    regtoevent: "#F3BA27",
    chat_gpt: "#74aa9d",
    googleforms: "#311B92",
  };

  return service in colors ? colors[service] : "grey";
};

export const handleCardIcon = (icon: PaymentSystem) => {
  const icons = {
    mc: mcIcon,
    visa: visaIcon,
  };

  return icons[icon];
};

export const handleWSParams = (params: object) => {
  return process.env.NODE_ENV === "production"
    ? params
    : { ...params, sid: sid$(store.getState()) };
};

export const DATE_DAY_FORMAT = "DDD";
export const SHORT_DATE_WITH_DAY = "D MMM, YYYY";
export const SHORT_DATE_FORMAT = "YYYY-MM-DD";
export const SHORT_DATE_WITH_SECONDS = "MM.DD HH:mm";
export const FULL_DATE_WITH_SECONDS = "YYYY-MM-DD HH:mm";
export const MONTH_DAY_H_M_A = "MMM DD, h:mm A";
export const MONTH_DAY_Y = "MMM DD, YYYY";
export const TIME_FORMAT = "h:mm A";

export const dayFrom = (date: string, locale: string) => {
  const currentdate = dayjs().format("YYYY-DD-MM");

  const nextDate = dayjs(date).format("YYYY-DD-MM");
  const difference = dayjs(currentdate).diff(nextDate, "day");

  if (difference < 1) {
    return dayjs(date).locale(locale).fromNow();
  } else if (difference < 7) {
    return dayjs(date).locale(locale).format(DATE_DAY_FORMAT);
  } else {
    return dayjs(date).format(SHORT_DATE_WITH_DAY);
  }
};

export const formatDateShort = () => {
  return dayjs().format(SHORT_DATE_FORMAT);
};

export const formatDateShortWithSeconds = () => {
  return dayjs().format(SHORT_DATE_WITH_SECONDS);
};

export const formatStrToDateShort = (str: string) => {
  return dayjs(str).format(SHORT_DATE_FORMAT);
};

export const formatStrFullDateWithSeconds = (str: string) => {
  return dayjs(str).format(FULL_DATE_WITH_SECONDS);
};

export interface GetCenterParams {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  sourcePosition?: "top" | "left" | "right" | "bottom";
  targetPosition?: "top" | "left" | "right" | "bottom";
}

const LeftOrRight = ["left", "right"];

export const getCenter = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition = "bottom",
  targetPosition = "top",
}: GetCenterParams): [number, number, number, number] => {
  const sourceIsLeftOrRight = LeftOrRight.includes(sourcePosition);
  const targetIsLeftOrRight = LeftOrRight.includes(targetPosition);

  // we expect flows to be horizontal or vertical (all handles left or right respectively top or bottom)
  // a mixed edge is when one the source is on the left and the target is on the top for example.
  const mixedEdge =
    (sourceIsLeftOrRight && !targetIsLeftOrRight) ||
    (targetIsLeftOrRight && !sourceIsLeftOrRight);

  if (mixedEdge) {
    const xOffset = sourceIsLeftOrRight ? Math.abs(targetX - sourceX) : 0;
    const centerX = sourceX > targetX ? sourceX - xOffset : sourceX + xOffset;

    const yOffset = sourceIsLeftOrRight ? 0 : Math.abs(targetY - sourceY);
    const centerY = sourceY < targetY ? sourceY + yOffset : sourceY - yOffset;

    return [centerX, centerY, xOffset, yOffset];
  }

  const xOffset = Math.abs(targetX - sourceX) / 2;
  const centerX = targetX < sourceX ? targetX + xOffset : targetX - xOffset;

  const yOffset = Math.abs(targetY - sourceY) / 2;
  const centerY = targetY < sourceY ? targetY + yOffset : targetY - yOffset;

  return [centerX, centerY, xOffset, yOffset];
};

export interface GetSmoothStepPathParams {
  sourceX: number;
  sourceY: number;
  sourcePosition?: "top" | "left" | "right" | "bottom";
  targetX: number;
  targetY: number;
  targetPosition?: "top" | "left" | "right" | "bottom";
  borderRadius?: number;
  centerX?: number;
  centerY?: number;
}

const bottomLeftCorner = (x: number, y: number, size: number): string =>
  `L ${x},${y - size}Q ${x},${y} ${x + size},${y}`;
const leftBottomCorner = (x: number, y: number, size: number): string =>
  `L ${x + size},${y}Q ${x},${y} ${x},${y - size}`;
const bottomRightCorner = (x: number, y: number, size: number): string =>
  `L ${x},${y - size}Q ${x},${y} ${x - size},${y}`;
const rightBottomCorner = (x: number, y: number, size: number): string =>
  `L ${x - size},${y}Q ${x},${y} ${x},${y - size}`;
const leftTopCorner = (x: number, y: number, size: number): string =>
  `L ${x + size},${y}Q ${x},${y} ${x},${y + size}`;
const topLeftCorner = (x: number, y: number, size: number): string =>
  `L ${x},${y + size}Q ${x},${y} ${x + size},${y}`;
const topRightCorner = (x: number, y: number, size: number): string =>
  `L ${x},${y + size}Q ${x},${y} ${x - size},${y}`;
const rightTopCorner = (x: number, y: number, size: number): string =>
  `L ${x - size},${y}Q ${x},${y} ${x},${y + size}`;

export function getSmoothStepPath({
  sourceX,
  sourceY,
  sourcePosition = "bottom",
  targetX,
  targetY,
  targetPosition = "top",
  borderRadius = 5,
  centerX,
  centerY,
}: GetSmoothStepPathParams): string {
  const [_centerX, _centerY, offsetX, offsetY] = getCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });
  const cornerWidth = Math.min(borderRadius, Math.abs(targetX - sourceX));
  const cornerHeight = Math.min(borderRadius, Math.abs(targetY - sourceY));
  const cornerSize = Math.min(cornerWidth, cornerHeight, offsetX, offsetY);
  const leftAndRight = ["left", "right"];
  const cX = typeof centerX !== "undefined" ? centerX : _centerX;
  const cY = typeof centerY !== "undefined" ? centerY : _centerY;

  let firstCornerPath = null;
  let secondCornerPath = null;

  if (sourceX <= targetX) {
    firstCornerPath =
      sourceY <= targetY
        ? bottomLeftCorner(sourceX, cY, cornerSize)
        : topLeftCorner(sourceX, cY, cornerSize);
    secondCornerPath =
      sourceY <= targetY
        ? rightTopCorner(targetX, cY, cornerSize)
        : rightBottomCorner(targetX, cY, cornerSize);
  } else {
    firstCornerPath =
      sourceY < targetY
        ? bottomRightCorner(sourceX, cY, cornerSize)
        : topRightCorner(sourceX, cY, cornerSize);
    secondCornerPath =
      sourceY < targetY
        ? leftTopCorner(targetX, cY, cornerSize)
        : leftBottomCorner(targetX, cY, cornerSize);
  }

  if (
    leftAndRight.includes(sourcePosition) &&
    leftAndRight.includes(targetPosition)
  ) {
    if (sourceX <= targetX) {
      firstCornerPath =
        sourceY <= targetY
          ? rightTopCorner(cX, sourceY, cornerSize)
          : rightBottomCorner(cX, sourceY, cornerSize);
      secondCornerPath =
        sourceY <= targetY
          ? bottomLeftCorner(cX, targetY, cornerSize)
          : topLeftCorner(cX, targetY, cornerSize);
    }
  } else if (
    leftAndRight.includes(sourcePosition) &&
    !leftAndRight.includes(targetPosition)
  ) {
    if (sourceX <= targetX) {
      firstCornerPath =
        sourceY <= targetY
          ? rightTopCorner(targetX, sourceY, cornerSize)
          : rightBottomCorner(targetX, sourceY, cornerSize);
    } else {
      firstCornerPath =
        sourceY <= targetY
          ? leftTopCorner(targetX, sourceY, cornerSize)
          : leftBottomCorner(targetX, sourceY, cornerSize);
    }
    secondCornerPath = "";
  } else if (
    !leftAndRight.includes(sourcePosition) &&
    leftAndRight.includes(targetPosition)
  ) {
    if (sourceX <= targetX) {
      firstCornerPath =
        sourceY <= targetY
          ? bottomLeftCorner(sourceX, targetY, cornerSize)
          : topLeftCorner(sourceX, targetY, cornerSize);
    } else {
      firstCornerPath =
        sourceY <= targetY
          ? bottomRightCorner(sourceX, targetY, cornerSize)
          : topRightCorner(sourceX, targetY, cornerSize);
    }
    secondCornerPath = "";
  }

  return `M ${sourceX},${sourceY}${firstCornerPath}${secondCornerPath}L ${targetX},${targetY}`;
}

export function paramsToObject(entries: any) {
  const result = {} as any;
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

export const urlSearchToObject = function () {
  var search = window.location.search.substring(1);
  const ur = new URLSearchParams(search);

  return paramsToObject(ur.entries());
};

export const objFromSearch = function (search: string) {
  var s = search.substring(1);
  const ur = new URLSearchParams(s);

  return paramsToObject(ur.entries());
};

export const serializeUrlSearchParams = (obj: Object) => {
  return Object.entries(obj)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
};

export function escapeRegExp(string: string) {
  // eslint-disable-next-line no-useless-escape
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}
export const getFirstLetter = (s?: string) => {
  return s?.charAt(0);
};

export function capitalizeFirstLetter(string?: string) {
  return string ? getFirstLetter(string)?.toUpperCase() + string.slice(1) : "";
}

export const parseDataFromStorage = (key: string) => {
  const result = localStorage.getItem(key);

  return result ? result.split('"').join("") : "";
};

export const replaceSymbolFromStr = (
  newPart: string,
  oldPart: string,
  value?: string
) => {
  return value?.replaceAll(oldPart, newPart);
};

export const arrayToString = (delimiter: string, arr: string[]) => {
  return arr.join(delimiter);
};

export const stringToArray = (delimiter: string, str: string) => {
  return str.split(delimiter);
};

export const findBlockName = (
  scenario: Scenario["scenario"],
  id: string,
  initBlockName?: string
) => {
  const result = scenario?.find((cube) => cube.id === id);

  if (id === INIT_BLOCK || id === "initial") {
    return initBlockName;
  }

  return result?.name || id;
};

export const getColorByString = (string: string = "") => {
  let hash = 0;
  for (let i = 0; i < string?.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value?.toString(16))?.substr(-2);
  }
  return colour;
};

export const shadeColor = (color: string, percent: number) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(String((R * (100 + percent)) / 100));
  G = parseInt(String((G * (100 + percent)) / 100));
  B = parseInt(String((B * (100 + percent)) / 100));

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  let RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  let GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  let BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};

export function lightOrDark(color: string | any) {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color?.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "light";
  } else {
    return "dark";
  }
}

export const childRender = (
  p: ServiceParameterItem,
  name: IInputServiceParameter["name"]
): IInputServiceParameter => {
  if (p?.child_parameters) {
    const keys = Object.keys(p!.child_parameters!);
    return {
      value: keys.map((nk) => childRender(p!.child_parameters![nk]!, nk)),
      value_type: "nested_object",
      name,
    };
  } else {
    if (
      p?.type === ServiceParameterType.NOT_EMPTY_LIST ||
      p?.type === ServiceParameterType.LIST
    ) {
      if (p?.possible_entities?.length) {
        return {
          value_type: "list",
          value: p?.possible_entities?.map((pe) => childRender(pe, undefined)),
          name,
        };
      } else {
        return {
          value: [] as string[],
          value_type: "list",
          name,
        };
      }
    } else if (p?.type === ServiceParameterType.ONE_OF) {
      return {
        value: [],
        value_type: "" as any,
        name: undefined,
      };
    } else {
      return {
        value: "" as string,
        value_type: "input",
        name,
      };
    }
  }
};

export const inputParameterRender = (
  p: ServiceParameterItem,
  name: IInputServiceParameter["name"]
): IInputServiceParameter => {
  if (p?.child_parameters) {
    const keys = Object.keys(p!.child_parameters!);
    return {
      value: keys.map((nk) => childRender(p!.child_parameters![nk]!, nk)),
      value_type: "nested_object",
      name,
    };
  } else {
    if (
      p?.type === ServiceParameterType.NOT_EMPTY_LIST ||
      p?.type === ServiceParameterType.ONE_OF ||
      p?.type === ServiceParameterType.LIST
    ) {
      if (p?.possible_entities?.length) {
        return {
          value_type: "list",
          value: [],
          name,
        };
      } else {
        return {
          value: [],
          value_type: "list",
          name,
        };
      }
    } else {
      return {
        value: "",
        value_type: "input",
        name,
      };
    }
  }
};

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
export const alphabet = alpha.map((x) => String.fromCharCode(x));

export const inputValueHandler = (
  type: AllParameterTypes
): AllParameterTypes => {
  if (stringParameters.includes(type)) {
    return ServiceParameterType.TEMPLATE;
  } else if (templateParameters.includes(type)) {
    return ServiceParameterType.TEMPLATE;
  } else if (booleanParameters.includes(type)) {
    return ServiceParameterType.TEMPLATE;
  } else {
    return type;
  }
};

export const parameterConstructor = (
  parameters: {
    [key: string]: ServiceParameter;
  },
  init?: boolean
): { [key: string]: ServiceParameter } => {
  const keys = Object.keys(parameters);
  let result = {};

  keys.forEach((id) => {
    const startValue =
      parameters[id]?.value === 0 || parameters[id]?.value
        ? parameters[id]?.value
        : [];

    const value = init
      ? []
      : parameters[id]?.type === ServiceParameterType.BOOLEAN
      ? parameters[id]?.value || false
      : !startValue && startValue !== 0
      ? []
      : startValue;

    const value_type = {
      string:
        (parameters[id]?.value &&
          Array.isArray(parameters[id]?.value) &&
          Array.isArray(parameters[id]?.value[0])) ||
        (parameters[id]?.value && Array.isArray(parameters[id]?.value))
          ? "template"
          : parameters[id].type,
      integer: Array.isArray(parameters[id]?.value) ? "template" : "integer",
    };

    const type = parameters[id].type;

    result = {
      ...result,
      [id]: {
        id,
        children: parameters[id].children,
        value:
          type === ServiceParameterType.INTEGER && value === "" ? [] : value,
        value_type: [
          ServiceParameterType.STRING,
          ServiceParameterType.INTEGER,
        ].includes(parameters[id].type)
          ? value_type[type]
          : type,
        type: parameters[id].type,
        parent: parameters[id].parent,
        name: parameters[id].name,
        display_name: parameters[id]?.display_name,
        description: parameters[id].description,
        required: parameters[id]?.required,
      },
    };
  });

  return result;
};

export const businessTypes = ["PR", "marketing", "product", "sales", "finance"];

export const defaultTimes = [
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export function getTimezoneOffset() {
  function z(n: number) {
    return (n < 10 ? "0" : "") + n;
  }
  var offset = new Date().getTimezoneOffset();
  var sign = offset < 0 ? "+" : "-";
  offset = Math.abs(offset);
  return sign + z((offset / 60) | 0) + z(offset % 60);
}

export const getNumberOfMonth = () => {
  return dayjs().get("month");
};

export const getNextMonth = (prevMonth: number) => {
  return dayjs()
    .month(prevMonth !== 11 ? prevMonth + 1 : 0)
    .format("MMM");
};

export const getCurrentMonth = () => {
  return dayjs().format("MMM");
};

export const getBlocksWithErrors = (
  args: {
    [key: string]: ServiceParameter;
  },
  setError: (v: boolean) => void
) => {
  const keys = Object.keys(args);

  const result = keys
    .map((key) => {
      if (args![key].value_type === "nested_object") {
        return (
          args![key].required &&
          Array.isArray(!args![key]?.children) &&
          !args![key]?.children?.length &&
          args![key]?.parent === "0"
        );
      } else if (args![key].type === ServiceParameterType.BOOLEAN) {
        return (
          args![key].required &&
          args![key]?.parent === "0" &&
          args![key]?.value !== false &&
          args![key]?.value !== true &&
          !(args![key]?.value as any)?.length
        );
      } else {
        return (
          (args![key].required &&
            !(args![key].value as any)?.length &&
            args![key].type !== ServiceParameterType.INTEGER) ||
          (args![key].required &&
            Array.isArray(args![key].value) &&
            !(args![key].value as [])?.length &&
            (args![key].type === ServiceParameterType.INTEGER
              ? Array.isArray(args![key].value as any)
                ? !(args![key].value as [])?.length
                : typeof args![key].value !== "number"
              : false) &&
            args![key]?.parent === "0")
        );
      }
    })
    ?.filter(Boolean);

  setError(!!result?.length);
};

export const nextPaymentDate = () => {
  const currentMonthNumber = getNumberOfMonth();
  const nextMonth = getNextMonth(currentMonthNumber);
  const year = dayjs().year();
  const nextYear = dayjs()
    .year(year + 1)
    .format("YYYY");
  if (currentMonthNumber === 11) {
    return `1 ${nextMonth} ${nextYear}`;
  } else {
    return `${nextMonth} 1, ${year}`;
  }
};

interface IShowDialogProps {
  title: string;
  icon?: SweetAlertIcon;
  html?: string | HTMLElement | JQuery | undefined;
  showCloseButton?: boolean;
  showCancelButton?: boolean;
  focusConfirm?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  text?: string;
  confirmButtonColor?: string;
  showConfirmButton?: boolean;
}

export const showDialogue = ({
  title,
  icon,
  text,
  html,
  showCloseButton = true,
  showCancelButton = true,
  focusConfirm,
  confirmButtonText = "OK",
  cancelButtonText = "CANCEL",
  showConfirmButton = true,
  confirmButtonColor,
}: IShowDialogProps): Promise<SweetAlertResult<any>> => {
  return Swal.fire({
    title,
    text,
    icon,
    html,
    showCloseButton,
    showCancelButton,
    showConfirmButton,
    focusConfirm,
    confirmButtonText,
    cancelButtonText,
    confirmButtonColor,
    customClass: {
      container: "bpm-alert-container",
      popup: "bpm-alert-popup",
    },
  });
};

export const getBlockName = (
  index: number,
  scenario?: AllScenarioBlocks[]
): string | undefined => {
  const allNameIndexes = scenario?.map((bl) => {
    const blockIndex = bl?.name?.split(" ")[1];
    const blockName = bl?.name?.split(" ")[0];

    if (blockName === "Block") {
      return blockIndex;
    } else {
      return "";
    }
  });
  if (allNameIndexes?.includes(index?.toString())) {
    return getBlockName(index + 1, scenario);
  } else {
    return `Block ${index}`;
  }
};

export const getPaginationItemsCount = (height: number) =>
  Math.floor(height / 92);

interface IScenarioParams {
  scenario_id?: string | null;
  version_id?: string | null;
  isEdit: boolean | null | string;
  isView: boolean | null | string;
  isDebug: boolean | null | string;
  isViewVersion: boolean | null | string;
  selectedBlock?: string | null | number;
  branch?: string;
}

export const makeScenarioParams = ({
  scenario_id,
  version_id,
  isEdit,
  isView,
  isDebug,
  isViewVersion,
  selectedBlock,
  branch = "devel",
}: IScenarioParams) => {
  return `id=${scenario_id}&branch=${branch}&version=${version_id}&edit=${
    isEdit ? 1 : 0
  }&view=${isView && !isEdit ? 1 : 0}&debug=${isDebug ? 1 : 0}&viewVersion=${
    isViewVersion ? 1 : 0
  }&selectedBlock=${selectedBlock}`;
};

export const getBlockAgrs = (err: IBlockError) => {
  return err?.empty_args
    ? `${err.empty_args.map((i) => i.display_name).join(", ")}`
    : "";
};

export const getRandomString = () => Math.random().toString(36).substring(2, 7);

export const envWSSetter = window.location.host.includes("localhost")
  ? "app.devel.digitalbpm.io"
  : window.location.host;

export const landingLink = () => {
  if (window.location.host.includes("localhost")) {
    return "https://devel.digitalbpm.io";
  } else {
    return "https://" + window.location.host.replace("app.", "");
  }
};

export const wsURL = `wss://${envWSSetter}/ws`;

export const isPatternMatch = (
  patterns: CaseofTypes,
  value: string = "",
  match: string = ""
): boolean =>
  patterns.some((pt) => pt.pattern_type === match && value === match);

export const isMobile = () => {
  const userAgent = navigator.userAgent;
  const screenWidth = window.innerWidth;

  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  const isMobileDevice = toMatch.some((toMatchItem) => {
    return userAgent.match(toMatchItem);
  });

  const isNarrowScreen = screenWidth <= 768;

  return isMobileDevice && isNarrowScreen;
};

export const validateExpirationDate = (expiryDate: string): boolean => {
  const dateRegex = /^(\d{1,2})\/(\d{2})$/;
  if (!dateRegex.test(expiryDate)) {
    return false;
  }

  const [monthStr, yearStr] = expiryDate.split("/");
  const month = parseInt(monthStr, 10);
  const year = parseInt(yearStr, 10);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear() % 100;
  const currentMonth = currentDate.getMonth() + 1;
  if (year < currentYear || (year === currentYear && month <= currentMonth)) {
    return false;
  }

  if (month < 1 || month > 12) {
    return false;
  }

  return true;
};

export const EMAIL_LIMIT = 200;

export const paramMatch = (
  param: "view" | "edit" | "debug" | "viewVersion"
) => {
  const result = {
    view: "isView",
    debug: "isDebug",
    edit: "isEdit",
    viewVersion: "isViewVersion",
  };

  return result[param];
};

export function syntaxHighlight(json: string) {
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    // eslint-disable-next-line no-useless-escape
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match) {
      var cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
}

export function isValidJSON(jsonString: string) {
  try {
    JSON.parse(jsonString);
  } catch (error) {
    return false;
  }
  return true;
}

export const isValidHexColor = (color: string): boolean => {
  const hexColorRegex = /^#([0-9A-Fa-f]{3}){1,2}$/;
  return hexColorRegex.test(color);
};

export const generateVariableList = (scenarioItem: Scenario): IMention[] => {
  const allVariables = scenarioItem?.variables.map((v) => {
    const listKeys = v.list_keys_with_index;
    let newKeys: { [key: string]: any } = {};

    if (listKeys) {
      Object.keys(listKeys).forEach((key) => {
        newKeys = {
          ...listKeys,
          [key]: isNaN(listKeys[key])
            ? ""
            : String(listKeys[key]) === "0"
            ? (null as any)
            : (String(listKeys[key]) as string),
        };
      });
    }

    return {
      title: v.name,
      value: v.id,
      name: findBlockName(
        scenarioItem.scenario,
        v.from_block_id,
        scenarioItem?.init?.name
      ),
      path: v.path,
      keys: newKeys,
      from_block_id: v.from_block_id,
      path_type: v.path_type,
    };
  });

  return allVariables || [];
};
