import { defaultMethod$ } from "selectors/paymentMethod";
import store from "store";
import { AnswerData } from "../index";
import * as PaymentMethodActions from "actions/paymentMethod";

// WSApi.DeletePaymentMethod
export const paymentMethodDelete = (data: AnswerData) => {
  if (!data?.error) {
    if (data?.response?.payment_methods?.length) {
      store.dispatch(
        PaymentMethodActions.setPaymentList(data.response.payment_methods)
      );
    }

    if (data?.response?.deleted_id === defaultMethod$(store.getState())?.id) {
      store.dispatch(PaymentMethodActions.setDefaultPayment(null));
    }
  }
};
