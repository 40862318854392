import * as Const from "../constants";
import { ScheduleContentAction } from "interfaces/state/scheduleContent";

export const setScheduleContentInit = () => {
  return {
    type: Const.SCHEDULE_CONTENT_INIT,
  };
};

export const getScheduleContent = (
  payload: ScheduleContentAction["payload"]
): ScheduleContentAction => {
  return {
    type: Const.SCHEDULE_CONTENT_SUCCESS,
    payload,
  };
};

export const setScheduleContentLoading = () => {
  return {
    type: Const.SCHEDULE_CONTENT_LOADING,
  };
};

export const setScheduleContentError = () => {
  return {
    type: Const.SCHEDULE_CONTENT_FAIL,
  };
};
