import i18n from "i18n";
import { errorFromResponse, successNotification } from "utils";
import { AnswerData } from "../index";

// WSApi.ChangeEmailFirstStep
export const accountChangeEmailStep1 = (data: AnswerData) => {
  if (data.error) {
    errorFromResponse(data.error);
  } else {
    successNotification(i18n.t("confirmationHasBeenSent"));
  }
};
