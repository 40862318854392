import {
  num_of_items$,
  scenarioVersions$,
  tags$,
  page$,
} from "selectors/scenarioVersion";
import store from "store";
import { AnswerData } from "../index";
import * as ScenarioVersionActions from "actions/scenarioVersion";
import { getPaginationItemsCount } from "utils";

// WSApi.SetTagScenarioVersion
export const scenarioVersionSetTag = (data: AnswerData) => {
  if (!data?.error) {
    const scenarioVersions = scenarioVersions$(store.getState());
    if (!data?.response?.id) {
      throw new Error("Empty response data for set new tag");
    } else {
      const existedVersion = scenarioVersions.find(
        (version) => version.id === data.response.id
      );
      const currentPage = page$(store.getState()) || 1;

      if (!existedVersion?.id && Number(currentPage) === 1) {
        const tags = [data.response.tag, ...tags$(store.getState())];
        store.dispatch(
          ScenarioVersionActions.setScenarioVersionTags([...tags])
        );

        const num_of_items = num_of_items$(store.getState());
        const versionContainer = document.querySelector("#version-container");

        if (versionContainer?.clientHeight) {
          const maxItemsPerPage = getPaginationItemsCount(
            versionContainer!.clientHeight!
          );

          if (num_of_items > maxItemsPerPage) {
            const index = maxItemsPerPage - (scenarioVersions.length + 1);
            const scenarioSlicedVersions = scenarioVersions.slice(0, index);
            const newVersionList = [data.response, ...scenarioSlicedVersions];

            store.dispatch(
              ScenarioVersionActions.setScenarioVersionList(newVersionList)
            );
            store.dispatch(
              ScenarioVersionActions.setScenarioVersionItems(num_of_items + 2)
            );
          }
        }
      } else if (
        existedVersion &&
        !existedVersion?.tag &&
        data?.response?.tag
      ) {
        const index = scenarioVersions.findIndex(
          (version) => version.id === data.response.id
        );
        const newTag = data.response.tag;
        scenarioVersions[index].tag = newTag;
        const tags = [newTag, ...tags$(store.getState())];
        store.dispatch(ScenarioVersionActions.setScenarioVersionTags(tags));

        store.dispatch(
          ScenarioVersionActions.setScenarioVersionList([...scenarioVersions])
        );
      }
      store.dispatch(ScenarioVersionActions.setScenarioVersionLoading(false));
    }
  }
};
