import * as Const from "../constants";
import State from "../interfaces/state/scenarioEvents";

export const initState: State = {
  isLoading: false,
  isError: false,
  list: [],
  limit: 50,
  offset: 0,
  hasMore: false,
  scenario_id: "",
  scenario_name: "",
  version: "",
  branch: null,
  event_id: null,
};

export default function scenarioEvents(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.SCENARIO_EVENTS_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.SCENARIO_EVENTS_SUCCESS:
      const { list, event_id, scenario_id, scenario_name, version, branch } =
        action.payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        list,
        event_id,
        scenario_id,
        scenario_name,
        version,
        branch,
      };

    case Const.SCENARIO_EVENTS_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.SCENARIO_EVENTS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.SCENARIO_EVENTS_PAGINATION:
      const { offset } = action.payload;
      return {
        ...state,
        offset,
      };

    case Const.SCENARIO_EVENTS_HAS_MORE:
      return {
        ...state,
        hasMore: action.payload,
      };

    default:
      return state;
  }
}
