import store from "store";
import { errorFromResponse, formatDateShortWithSeconds } from "utils";
import { AnswerData } from "../index";
import * as DashboardInfoActions from "actions/dashboardInfo";

// WSApi.StatTransAndTasks
export const statisticGetTransactionsAndTasks = (data: AnswerData) => {
  if (data.error) {
    errorFromResponse(data.error);
    store.dispatch(DashboardInfoActions.setDashboardInfoError());
  } else {
    store.dispatch(
      DashboardInfoActions.setDashboardInfoSuccess({
        ...data.response,
        date: formatDateShortWithSeconds(),
      } as DashboardInfoActions.IDashboardPayload)
    );
  }
};
