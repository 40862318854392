import * as Const from "../constants";
import State from "../interfaces/state/paymentMethod";

export const initState: State = {
  isLoading: false,
  isError: false,
  list: [],
  defaultMethod: null,
  errorWhileCreateNewMethod: "",
};

export default function paymentMethod(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.PAY_METHOD_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.PAY_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: action.payload,
      };

    case Const.PAY_METHOD_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.PAY_METHOD_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.PAY_METHOD_DEFAULT:
      return {
        ...state,
        defaultMethod: action.payload,
      };

    case Const.PAY_METHOD_ERROR_VALUE:
      return {
        ...state,
        errorWhileCreateNewMethod: action.payload,
      };

    default:
      return state;
  }
}
