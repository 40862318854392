import * as Const from "../constants";
import State, { LibraryType } from "../interfaces/state/library";

export const initState: State = {
  isLoading: false,
  isError: false,
  libType: LibraryType.CONVERTER,
  list: {},
  isOpenPanel: false,
  position: 0,
  scrollToTop: 0,
};

export default function library(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.LIB_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.LIB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: {
          ...state.list,
          [action.scenario_id]: {
            ...state.list[action.scenario_id],
            [action.block_id]: action.payload,
          },
        },
      };

    case Const.LIB_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.LIB_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.LIB_TYPE:
      return {
        ...state,
        list: action.payload,
      };

    case Const.LIB_PANEL:
      return {
        ...state,
        isOpenPanel: action.payload,
      };

    case Const.LIB_POSITION:
      return {
        ...state,
        position: action.payload,
      };

    case Const.LIB_SCROLL_TO_TOP:
      return {
        ...state,
        scrollToTop: action.payload,
      };

    default:
      return state;
  }
}
