import i18n from "i18n";
import store from "store";
import { errorFromResponse, successNotification } from "utils";
import { AnswerData } from "../index";
import * as TeamActions from "actions/team";

// WSApi.CompanyGetLinkInvitation
export const companyGetInvitationLink = (data: AnswerData) => {
  if (data.error) {
    errorFromResponse(data.error);
  } else {
    if (navigator && data.response) {
      navigator.clipboard.writeText(data.response);
      successNotification(i18n.t("linkWasCopied"));
    }

    store.dispatch(TeamActions.setInviteLink(data.response));
  }
};
