import { number_of_members$ } from "selectors/team";
import store from "store";
import { errorFromResponse, successNotification } from "utils";
import { AnswerData } from "../index";
import * as TeamActions from "actions/team";
import i18n from "i18n";

// WSApi.AddMember
export const companyAddMember = (data: AnswerData) => {
  if (data.error) {
    store.dispatch(TeamActions.setLoadingInvite(false));
    store.dispatch(TeamActions.setChangeMemeberRoleError());
    errorFromResponse(data.error);
  } else {
    successNotification(i18n.t("inviteWasSent"));
    store.dispatch(TeamActions.setLoadingInvite(false));
    store.dispatch(TeamActions.addNewMemberToList(data.response));

    const num = number_of_members$(store.getState())
      ? number_of_members$(store.getState())! + 1
      : 1;
    store.dispatch(TeamActions.setMemberCount(num));
  }
};
