import { useCallback } from "react";
import { ReactComponent as SupportIcon } from "assets/svg/support.svg";
import style from "./supportLink.module.scss";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { isOpenHelpCenterModal$ } from "selectors/auth";
import { setOpenHelpCenterModal } from "actions/auth";
import { ModalWindow } from "components/modal";
import { Modal } from "./components/modal";

export const SupportLink = () => {
  const isOpenHelpCenterModal = useAppSelector(isOpenHelpCenterModal$);
  const dispatch = useAppDispatch();

  const handleClick = useCallback(
    () => dispatch(setOpenHelpCenterModal(!isOpenHelpCenterModal)),
    [dispatch, isOpenHelpCenterModal]
  );

  const handleClose = useCallback(() => {
    dispatch(setOpenHelpCenterModal(false));
  }, [dispatch]);

  return (
    <div onClick={handleClick} className={style.root}>
      <SupportIcon className={style.icon} />

      <ModalWindow
        isOpen={isOpenHelpCenterModal}
        isHideButton
        content={<Modal onClose={handleClose} />}
        onClose={handleClose}
      />
    </div>
  );
};
