import State from "interfaces/state";
import { Action } from "redux";
import * as SensitiveDataActions from "actions/sensitiveData";
import { WSApi } from "enums/wsApi";
import { sendWSMessage } from "utils";
import { scenarioItem$, selectedBlockId$ } from "selectors/scenario";
import { INIT_BLOCK } from "pages/scenario/constants";
import { company_role$ } from "selectors/auth";
import { MemberRole } from "interfaces/state/team";

export const fetchSensitiveDataList = (service_name: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(SensitiveDataActions.setSensDataLoading());
    const action = WSApi.SensitiveDataGetAll;
    dispatch(SensitiveDataActions.setSensDataList([]));

    const scenarioItem = scenarioItem$(getState());
    const scenario_id = scenarioItem?.scenario_id;
    const block_id = selectedBlockId$(getState())
      ? selectedBlockId$(getState())
      : INIT_BLOCK;

    sendWSMessage({ action, service_name, scenario_id, block_id });
  };
};

export const saveSensitiveData = (
  service_name: string,
  name: string,
  data: Object,
  branch: string
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const company_role = company_role$(getState());
    if (company_role === MemberRole.USER) {
      // errorNotification(i18n.t("forbidden"));
      return;
    }
    dispatch(SensitiveDataActions.setSensDataLoading());
    const action = WSApi.SensitiveDataSave;

    const scenarioItem = scenarioItem$(getState());
    const scenario_id = scenarioItem?.scenario_id;
    const block_id = selectedBlockId$(getState())
      ? selectedBlockId$(getState())
      : INIT_BLOCK;

    sendWSMessage({
      action,
      service_name,
      name,
      data,
      scenario_id,
      block_id,
      branch,
    });
  };
};

export const deleteFromDataStorage = (data_id: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const company_role = company_role$(getState());
    if (company_role === MemberRole.USER) {
      // errorNotification(i18n.t("forbidden"));
      return;
    }
    dispatch(SensitiveDataActions.setSensDataLoading());
    const action = WSApi.SensitiveDataDelete;

    const scenarioItem = scenarioItem$(getState());
    const scenario_id = scenarioItem?.scenario_id;
    const block_id = selectedBlockId$(getState())
      ? selectedBlockId$(getState())
      : INIT_BLOCK;

    sendWSMessage({ action, data_id, scenario_id, block_id });
  };
};
