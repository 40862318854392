import State from "interfaces/state/scenarioEvents";
import * as Const from "../constants";

export const setJournalScenariosInit = () => {
  return {
    type: Const.JOURNAL_SCENARIOS_LIST_INIT,
  };
};

export const setJournalScenariosEvents = (payload: State["list"]) => {
  return {
    type: Const.JOURNAL_SCENARIOS_LIST_SUCCESS,
    payload,
  };
};

export const setJournalScenariosPending = () => {
  return {
    type: Const.JOURNAL_SCENARIOS_LIST_LOADING,
  };
};

export const setJournalScenariosError = () => {
  return {
    type: Const.JOURNAL_SCENARIOS_LIST_FAIL,
  };
};

export interface JournalScenariosPagination {
  offset: number;
}

export const setJournalScenariosPagination = (
  payload: JournalScenariosPagination
) => {
  return {
    type: Const.JOURNAL_SCENARIOS_LIST_PAGINATION,
    payload,
  };
};

export const setHasMore = (payload: boolean) => ({
  type: Const.JOURNAL_SCENARIOS_LIST_HAS_MORE,
  payload,
});
