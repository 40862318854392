import React, { memo } from "react";

import { Handle } from "react-flow-renderer";
import style from "./customNodeForFileSystem.module.scss";

const CustomNodeForFileSystem = memo((props) => {
  const { type } = props.data;
  return (
    <div className={style.root}>
      <Handle
        type="target"
        position="left"
        style={{ background: "rgba(255,255,255,0)" }}
        className={"invisible"}
        onConnect={(params) => console.log("handle onConnect", params)}
      />
      <>{props.data.label}</>

      {type === "case_of" && (
        <Handle
          type="source"
          position="top"
          id="a"
          style={{
            background: "#2084E1",
          }}
        />
      )}

      {type === "case_of" && (
        <Handle
          type="source"
          position="right"
          id="b"
          style={{
            background: "#2084E1",
          }}
        />
      )}

      {type !== "case_of" ? (
        <Handle
          type="source"
          position="right"
          id="b"
          style={{
            background: "#2084E1",
          }}
        />
      ) : (
        <> </>
      )}

      {type === "case_of" ? (
        <Handle
          type="source"
          position="bottom"
          id="c"
          style={{
            background: "#2084E1",
            // left: "calc(50% + 0.5px)",
            top: "calc(100% - 2px)",
          }}
        />
      ) : null}
    </div>
  );
});

export default CustomNodeForFileSystem;
