import { IInputServiceParameter } from "interfaces/state/serviceParameter";
import { Scenario } from "interfaces/state/scenario";
import { BLOCK_COMPARISON_TYPE_ALL } from "../constants";

export const lineStyle = { stroke: "#2084E1", strokeWidth: 2 };
export const edgeType = "smoothstep";
export const stylesLine = {
  type: edgeType,
  animated: false,
  arrowHeadType: "arrow",
  style: lineStyle,
};

const isHorizontal = (direction: "LR" | "TB") => direction === "LR";

export const setTargetPositionByDirection = (direction: "LR" | "TB") => {
  return isHorizontal(direction) ? "left" : "top";
};

export const setSourcePositionByDirection = (direction: "LR" | "TB") => {
  return isHorizontal(direction) ? "right" : "bottom";
};

export const handlerInParameter = (v: string | number | Object | boolean) => {
  if (v === undefined) return;
  if (typeof v === "string") {
    if (v.toLowerCase() === "true") {
      return true;
    } else if (v.toLowerCase() === "false") {
      return false;
    } else {
      if (v[0] === "[" && v[v.length - 1] === "]") {
        return JSON.parse(v);
      } else {
        const res = Number(v);
        if (res) {
          return res;
        } else {
          return v;
        }
      }
    }
  } else {
    return v;
  }
};

export const valueTypeInParamHandler = (
  exitParameter: IInputServiceParameter,
  scenarioItem: Scenario | null | undefined
) => {
  const { value } = exitParameter;
  const variableIds = scenarioItem?.variables.map((variable) => variable.id);
  const isVariable = variableIds?.includes(exitParameter.value as any);
  if (isVariable) {
    return "variable";
  } else {
    if (typeof value === "string") {
      if (value[0] === "[" && value[value.length - 1] === "]") {
        return "list";
      } else {
        return "input";
      }
    } else {
      return exitParameter.value_type;
    }
  }
};

export const X_STEP = 242;
export const Y_STEP = 200;

export const TOP_CONNECTION = "a";
export const RIGHT_CONNECTION = "b";
export const BOTTOM_CONNECTION = "c";

export const patterns = [
  { label: "equal", value: "equal" },
  { label: "prefix", value: "prefix" },
  { label: "not equal", value: "not_equal" },
  { label: "contains", value: "contains" },
  { label: "does not contain", value: "does_not_contain" },
  { label: "greater then", value: "greater_then" },
  { label: "less then", value: "less_then" },
  { label: "greater then or equal", value: "greater_then_or_equal" },
  { label: "less then or equal", value: "less_then_or_equal" },
  { label: "exists", value: "exists" },
  { label: "does not exist", value: "does_not_exist" },
  { label: BLOCK_COMPARISON_TYPE_ALL, value: "all" },
];
