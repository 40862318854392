import * as FoldersActions from "actions/folders";
import State from "interfaces/state";
import { Action } from "redux";
import { WSApi } from "enums/wsApi";
import { sendWSMessage } from "utils";
import {
  sortBy$,
  sortDirection$,
  limit$,
  offset$,
  search$,
  currentAutomationRoute$,
} from "selectors/automationRouting";
import { RootState } from "reducers";
import { selectedCategories$ } from "selectors/scenarioCategories";
import { updateSearch } from "actions/automationRouting";

export const fetchAllAutomationFiles = (
  id: number,
  s_by?: string,
  s_direction?: string,
  lim?: number,
  offs?: number,
  catgs?: number[],
  withoutSearch?: boolean
) => {
  return (dispatch: (action: Action) => void, getState: () => RootState) => {
    const savedSortBy = sortBy$(getState());
    const savedSortDirection = sortDirection$(getState());
    const savedLimit = limit$(getState());
    const savedOffset = offset$(getState());
    const savedCategories = selectedCategories$(getState());
    const sort_by = s_by ? s_by : savedSortBy;
    const sort_direction = s_direction ? s_direction : savedSortDirection;
    const limit = typeof lim === "number" ? lim : savedLimit;
    const offset = typeof offs === "number" ? offs : savedOffset;

    const categories = catgs ? catgs : savedCategories;
    const name = search$(getState());
    dispatch(FoldersActions.setFoldersLoading());

    !!withoutSearch && dispatch(updateSearch(""));

    const action =
      !!withoutSearch || !name
        ? WSApi.GetAllFolders
        : WSApi.FolderScenarioSearch;

    sendWSMessage({
      action,
      id,
      sort_by,
      sort_direction,
      limit,
      offset,
      categories,
      name: withoutSearch ? "" : name,
    });
  };
};

export const createAutomationFolder = (parent_id: number, name: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.CreateFolder;

    sendWSMessage({ action, parent_id, name });
  };
};

export const renameFolder = (id: number, new_name: string) => () => {
  const action = WSApi.RenameFolder;

  sendWSMessage({ action, id, new_name });
};

export const deleteFolder = (id: number) => {
  return () => {
    const action = WSApi.DeleteFolder;

    sendWSMessage({ action, id });
  };
};

export const deleteScenario = (scenario_id: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.DeleteScenario;

    sendWSMessage({ action, scenario_id });
  };
};

export const renameScenario = (
  scenario_id: string,
  new_scenario_name: string
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.RenameScenario;

    sendWSMessage({ action, scenario_id, new_scenario_name });
  };
};

export const searchScenariosInFolders =
  (name: string, limit: number, offset: number) =>
  (_: (action: Action) => void, getState: () => RootState) => {
    const action = WSApi.FolderScenarioSearch;
    const id = currentAutomationRoute$(getState())?.id;

    sendWSMessage({ action, name, limit, offset, id });
  };

export const moveFolderToAnotherFolder =
  (id: number, new_parent_id: number) => () => {
    const action = WSApi.FolderMoveFolderToFolder;

    sendWSMessage({ action, id, new_parent_id });
  };

export const moveScenarioToAnotherFolder =
  (id: number, scenario_id: number | string) => () => {
    const action = WSApi.FolderMoveScenarioToFolder;

    sendWSMessage({ action, id, scenario_id });
  };
