// import { Periodicity } from "actions/billingInfo";

import { AppRole } from "actions/auth";
import { MemberRole } from "./team";

export default interface AuthState {
  isLoading: boolean;
  isError: boolean;
  isAuth: boolean;
  sid: string;
  status_session: StatusSession;
  email?: string;
  first_name?: string;
  member_id: number;
  avatar_url?: string;
  company_name: string | null;
  company_role?: MemberRole;
  company_status: CompanyStatus;
  consent_on_cookie: boolean;
  plan_id?: number;
  plan_name?: string;
  isNeedToHideHeader: boolean;
  current_period_end: string;
  isValidToken: boolean;
  isOpenHelpCenterModal: boolean;
  language: null | string;
  role: AppRole;
}

export enum CompanyStatus {
  ACTIVE = "active",
  BLOCKED = "blocked",
  ERROR = "error",
  HAS_DEBT = "has_debt",
}

export enum StatusSession {
  INITIAL = "initial",
  LOADED = "loaded",
  UNKNOWN = "unknown",
  UNCONFIRMED_EMAIL = "unconfirmed_email",
  PLAN_SELECTED = "plan_selected",
  PLAN_SELECTION = "plan_selection",
  PAID = "paid",
  CONFIRMED_EMAIL = "confirmed_email",
  QUESTIONNAIRE = "questionnaire",
  CLOSED = "closed",
  SET_NAME_AND_EMAIL = "set_name_and_email",
  OPEN = "open",
  SET_NAME_AND_PASS = "set_name_and_pass",
}
