import {
  num_of_items$,
  scenarioVersions$,
  page$,
} from "selectors/scenarioVersion";
import store from "store";
import { AnswerData } from "..";
import * as ScenarioVersionActions from "actions/scenarioVersion";

// WSApi.RestoreScenarioVersion
export const scenarioVersionRestorePrevious = (data: AnswerData) => {
  if (!data?.error) {
    const scenarioVersions = scenarioVersions$(store.getState()).map(
      (version) => ({ ...version, is_allowed_to_edit: false })
    );
    const existedVersion = scenarioVersions.find(
      (version) => version.id === data.response.id
    );
    const currentPage = page$(store.getState()) || 1;

    if (!existedVersion && Number(currentPage) === 1) {
      const newVersions = [data.response, ...scenarioVersions].slice(0, -1);
      const num_of_items = num_of_items$(store.getState());

      store.dispatch(
        ScenarioVersionActions.setScenarioVersionList(newVersions)
      );
      store.dispatch(
        ScenarioVersionActions.setScenarioVersionItems(num_of_items + 2)
      );
    }
  }
};
