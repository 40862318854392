import store from "store";
import { AnswerData } from "../index";
import * as DashboardInfoActions from "actions/dashboardInfo";

// WSApi.DashboarUpdateTask
export const statisticUpdateTasks = (data: AnswerData) => {
  if (!data.error) {
    store.dispatch(DashboardInfoActions.updateDashTasks(data.response));
  }
};
