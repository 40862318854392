import { CompanyStatus } from "interfaces/state/auth";
import State from "../interfaces/state";

export const isLoading$ = (state: State) => state.billingInfo.isLoading;
export const isError$ = (state: State) => state.billingInfo.isError;
export const mask$ = (state: State) => state.billingInfo.mask;
export const periodicity$ = (state: State) => state.billingInfo.periodicity;
export const plan_id$ = (state: State) => state.billingInfo.plan_id;
export const plan_name$ = (state: State) => state.billingInfo.plan_name;
export const price$ = (state: State) => state.billingInfo.price;
export const maxSpeedPerHour$ = (state: State) =>
  state.billingInfo.max_processing_speed_per_hour;
export const payment_system$ = (state: State) =>
  state.billingInfo.payment_system;
export const csvHistoryLink$ = (state: State) =>
  state.billingInfo.csvHistoryLink;
export const maxMembers$ = (state: State) => state.billingInfo.max_members;
export const expMonth$ = (state: State) => state.billingInfo.exp_month;
export const expYear$ = (state: State) => state.billingInfo.exp_year;
export const numberOfMembers$ = (state: State) =>
  state.billingInfo.number_of_members;
export const status$ = (state: State) => state.billingInfo.status;
export const maxSpeedPerSecond$ = (state: State) =>
  state.billingInfo.max_processing_speed_per_second;
export const maxRequestSize$ = (state: State) =>
  state.billingInfo.max_request_size;
export const nextPayment$ = (state: State) => state.billingInfo.next_payment;
export const pricePerSeat$ = (state: State) => state.billingInfo.price_per_seat;
export const seats$ = (state: State) => state.billingInfo.seats;
export const taskQueue$ = (state: State) => state.billingInfo.task_queue;
export const transactionsPerMonth$ = (state: State) =>
  state.billingInfo.transactions_per_month;

export const showDebtError$ = (state: State) => {
  const nextPayment = state.billingInfo.next_payment;

  return (
    nextPayment?.company_status === CompanyStatus.ERROR ||
    nextPayment?.company_status === CompanyStatus.HAS_DEBT ||
    nextPayment?.company_status === CompanyStatus.BLOCKED
  );
};
