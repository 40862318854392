import { createSelector } from "reselect";
import State from "../interfaces/state";

export const isLoading$ = (state: State) => state.company.isLoading;
export const isError$ = (state: State) => state.company.isError;
export const companyList = (state: State) => state.company.list;
export const companyList$ = createSelector(
  [companyList],
  (companies) => companies
);
