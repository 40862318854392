import { WebhookMethodItem } from "interfaces/state/webhookMethod";
import * as Const from "../constants";

export const setWebHookMethodInit = () => {
  return {
    type: Const.WHOOK_METHOD_INIT,
  };
};

export const loadWebHookMethodSuccess = (payload: WebhookMethodItem[]) => {
  return {
    type: Const.WHOOK_METHOD_SUCCESS,
    payload,
  };
};

export const setWebHookMethodLoading = (payload: boolean) => {
  return {
    type: Const.WHOOK_METHOD_LOADING,
    payload,
  };
};

export const setWebHookMethodFail = () => {
  return {
    type: Const.WHOOK_METHOD_FAIL,
  };
};
