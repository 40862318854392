import * as RadixPopover from "@radix-ui/react-popover";
import cx from "classnames";
import style from "./dropDownItem.module.scss";

interface IProps {
  onClick: () => void;
  className: string;
  text: string;
  hotKey?: string;
  icon?: JSX.Element;
  isSelected?: boolean;
}

export const DropDownItem = ({
  onClick,
  className,
  text,
  hotKey,
  icon,
  isSelected,
}: IProps) => {
  const TextCX = cx(style.text, {
    [style.selected]: isSelected,
  });

  const HotkeyCX = cx(style.hotKey, {
    [style.selected]: isSelected,
  });

  return (
    <RadixPopover.Trigger className={className} onClick={onClick}>
      {icon && icon}
      <div className={TextCX} title={text}>
        {text}
      </div>
      {!!hotKey && <div className={HotkeyCX}>{hotKey}</div>}
    </RadixPopover.Trigger>
  );
};
