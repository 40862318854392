import { FC, useMemo } from "react";
import { AutomationScenario } from "interfaces/state/scenarios";
import { ServiceIcon } from "components/serviceIcon";
import style from "./scenarioServices.module.scss";

interface IProps {
  scenario: AutomationScenario;
}

export const ScenarioServices: FC<IProps> = ({ scenario }) => {
  const firstServices = useMemo(
    () => scenario.services.slice(0, 3),
    [scenario]
  );
  
  const serviceCount = useMemo(
    () => scenario.services.length - firstServices.length,
    [firstServices.length, scenario.services.length]
  );

  return (
    <div className={style.root}>
      {firstServices.map((service) => (
        <div key={service} className={style.connector}>
          <ServiceIcon service={service} variant="xsmall" />
        </div>
      ))}
      {serviceCount > 0 && (
        <div className={style.serviceCount}>{`+${serviceCount}`}</div>
      )}
    </div>
  );
};