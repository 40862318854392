import State from "interfaces/state";
import { Action } from "redux";
import { WSApi } from "enums/wsApi";
import { sendWSMessage } from "utils";
import * as MethodReplyActions from "actions/methodReply";
import * as BillingInfoActions from "actions/billingInfo";

interface ICreateMethodRequest {
  card: string;
  exp_month: number;
  exp_year: number;
  cvc: number;
  default_method?: boolean;
}

export const createPaymentMethod = ({
  card,
  exp_month,
  exp_year,
  cvc,
  default_method,
}: ICreateMethodRequest) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.CreatePaymentMethod;

    const year = String(exp_year).length === 2 ? 2000 + exp_year : exp_year;

    dispatch(MethodReplyActions.setMethodReplyLoading());
    sendWSMessage({
      action,
      card,
      exp_month,
      exp_year: year,
      cvc,
      default_method,
    });
  };
};

export const getDefaultPaymentMethod = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.DefaultPaymentMethod;

    dispatch(MethodReplyActions.setMethodReplyLoading());
    sendWSMessage({ action });
  };
};

export const getPaymentMethods = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.PaymentMethods;

    dispatch(MethodReplyActions.setMethodReplyLoading());
    sendWSMessage({ action });
  };
};

interface IDeleteMethodRequest {
  id: number;
}

export const deletePaymentMethod = ({ id }: IDeleteMethodRequest) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.DeletePaymentMethod;

    dispatch(MethodReplyActions.setMethodReplyLoading());
    sendWSMessage({ action, id });
  };
};

export const setDefaultPaymentMethod = ({ id }: IDeleteMethodRequest) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.DefaultPaymentMethodSet;

    dispatch(MethodReplyActions.setMethodReplyLoading());
    sendWSMessage({ action, id });
  };
};

type UpdateMethodRequest = {
  id: string;
  exp_month: number;
  exp_year: number;
};

export const updatePaymentMethod = ({
  id,
  exp_month,
  exp_year,
}: UpdateMethodRequest) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.UpdatePaymentMethod;

    dispatch(MethodReplyActions.setMethodReplyLoading());
    sendWSMessage({ action, id, exp_month, exp_year });
  };
};

export const payAgain = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.TryAgainPayment;

    dispatch(BillingInfoActions.setBillingInfoLoading());

    sendWSMessage({ action });
  };
};
