export default interface LangState {
  lang: LANGUAGE;
}

export enum LANGUAGE {
  RU = "ru",
  UK = "uk",
  UA = "uk-UA",
  EN = "en",
}
