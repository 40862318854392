import { combineReducers } from "redux";

import auth from "./auth";
import createAcc from "./createAcc";
import confirm from "./confirm";
import resendEmail from "./resendEmail";
import sendPass from "./sendPass";
import changeEmail from "./changeEmail";
import plans from "./plans";
import subscription from "./subscription";
import questionnaire from "./questionnaire";
import dashboardInfo from "./dashboardInfo";
import team from "./team";
import availableRoles from "./availableRoles";
import confirmNewUser from "./confirmNewUser";
import mainFolder from "./mainFolder";
import folders from "./folders";
import scenarios from "./scenarios";
import automationRouting from "./automationRouting";
import scenario from "./scenario";
import uploadAvatar from "./uploadAvatar";
import billingInfo from "./billingInfo";
import cancelSubscription from "./cancelSubscription";
import wsConnect from "./wsConnect";
import paymentHistory from "./paymentHistory";
import updateSubscription from "./updateSubscription";
import lang from "./lang";
import checkInvitationLink from "./checkInvitationLink";
import company from "./company";
import templates from "./templates";
import templateItem from "./templateItem";
import scenarioUsers from "./scenarioUsers";
import service from "./service";
import webhookMethod from "./webhookMethod";
import serviceParameter from "./serviceParameter";
import methodReply from "./methodReply";
import sensitiveData from "./sensitiveData";
import library from "./library";
import libFunction from "./libFunction";
import funcParameter from "./funcParameter";
import availableVariable from "./availableVariable";
import availableReplyMethod from "./availableReplyMethod";
import indicators from "./indicators";
import scenarioCounters from "./scenarioCounters";
import scheduleContent from "./scheduleContent";
import debug from "./debug";
import paymentMethod from "./paymentMethod";
import scenarioVersion from "./scenaroVersion";
import scenarioCategories from "./scenarioCategories";
import journalEvents from "./journalEvents";
import scenarioEvents from "./scenarioEvents";
import journalScenariosList from "./journalScenariosList";
import variablePanel from "./variablePanel";
import servicesPage from "./servicesPage";
import connectorItem from "./connectorItem";

const rootReducer = combineReducers({
  auth,
  createAcc,
  confirm,
  resendEmail,
  sendPass,
  changeEmail,
  plans,
  subscription,
  questionnaire,
  dashboardInfo,
  team,
  availableRoles,
  confirmNewUser,
  mainFolder,
  folders,
  scenarios,
  automationRouting,
  scenario,
  uploadAvatar,
  billingInfo,
  cancelSubscription,
  wsConnect,
  paymentHistory,
  updateSubscription,
  lang,
  checkInvitationLink,
  company,
  templates,
  templateItem,
  scenarioUsers,
  service,
  webhookMethod,
  serviceParameter,
  methodReply,
  sensitiveData,
  library,
  libFunction,
  funcParameter,
  availableVariable,
  availableReplyMethod,
  indicators,
  scenarioCounters,
  scheduleContent,
  debug,
  paymentMethod,
  scenarioVersion,
  scenarioCategories,
  journalEvents,
  scenarioEvents,
  journalScenariosList,
  variablePanel,
  servicesPage,
  connectorItem,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
