import store from "store";
import { AnswerData } from "../index";
import * as ScenarioActions from "actions/scenario";
import { scenarioItem$ } from "selectors/scenario";

// WSApi.UpdateVariable
export const variableUpdate = (data: AnswerData) => {
  if (!data?.error) {
    const { dispatch, getState } = store;

    const scenario = scenarioItem$(getState());

    const newVariableId = data.response.id;

    const newVariablesIndex = scenario.variables.findIndex(
      (v) => v.id === newVariableId
    );

    dispatch(ScenarioActions.setUpdateVariable(false));

    if (newVariablesIndex >= 0) {
      const variables = [...scenario.variables];
      variables[newVariablesIndex] = data.response;

      dispatch(ScenarioActions.setScenarioSuccess({ ...scenario, variables }));
    }
  }
};
