import * as Const from "../constants";
import State from "../interfaces/state/checkInvitationLink";

export const initState: State = {
  isLoading: false,
  isError: false,
  isConfirmed: false,
  token: null,
};

export default function checkInvitationLink(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.CHECK_INV_LINK_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.CHECK_INV_LINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isConfirmed: true,
        isError: false,
      };

    case Const.CHECK_INV_LINK_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case Const.CHECK_INV_LINK_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.CHECK_INV_LINK_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
