import * as Const from "../constants";
import State from "../interfaces/state/availableVariable";

export const initState: State = {
  isLoading: false,
  isError: false,
  list: {},
  uuid: null,
  currentVariableId: null,
  isNeedToShowNth: false,
  currentDraggableVariableId: null,
};

export default function availableVariable(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.AVAIL_VAR_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.AVAIL_VAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: {
          ...state.list,
          [action.scenario_id]: {
            ...state.list?.[action.scenario_id],
            [action.block_id]: action.payload,
          },
        },
      };

    case Const.AVAIL_VAR_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.AVAIL_VAR_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.AVAIL_VAR_UUID:
      return {
        ...state,
        uuid: action.payload,
      };

    case Const.AVAIL_VAR_ID:
      return {
        ...state,
        currentVariableId: action.payload,
      };

    case Const.AVAIL_VAR_NTH:
      return {
        ...state,
        isNeedToShowNth: action.payload,
      };

    case Const.CURRENT_DRAG_VAR_ID:
      return {
        ...state,
        currentDraggableVariableId: action.payload,
      };

    default:
      return state;
  }
}
