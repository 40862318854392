import {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
} from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Folder } from "interfaces/state/folders";
import { ReactComponent as FolderIcon } from "assets/svg/folder.svg";
import { FolderModals } from "components/folderModals";
import { dayFrom } from "utils";
import style from "./tableFolder.module.scss";
import { usePrevious } from "utils/customHooks";

interface IProps {
  item: Folder;
  droppedId: null | string | number;
  onClick: (folder: Folder) => void;
  handleShowParentFolder: (value: boolean) => void;
  onFolderToAnotherFolder: (id: number, new_parent_id: number) => void;
  onScenarioToAnotherFolder: (id: number, scenario: number | string) => void;
  handleDroppedId: (val: null | string | number) => void;
}

export const TableFolder: FC<IProps> = ({
  item: { name, modified },
  item,
  onClick,
  droppedId,
  handleShowParentFolder,
  onFolderToAnotherFolder,
  onScenarioToAnotherFolder,
  handleDroppedId,
}) => {
  const { i18n } = useTranslation();
  const handleClick = useCallback(() => onClick(item), [item, onClick]);
  const [isOpen, setOpen] = useState<boolean>(false);
  const active = useRef<boolean>(false);
  const prevActive = usePrevious(active.current);
  const RootCX = cx(style.root, { [style.focused]: isOpen });
  const handleOpen = useCallback((open: boolean) => setOpen(open), []);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    // e.preventDefault();
    e.dataTransfer.setData("text/plain", item.id.toString());
    active.current = true;

    if (item.id) {
      handleDroppedId(item.id);
      setTimeout(() => {
        if (active.current) handleShowParentFolder(true);
      }, 1000);
    }
  };

  useEffect(() => {
    if (prevActive && !active.current) {
      handleShowParentFolder(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevActive]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (item && droppedId && droppedId !== item.id) {
      if (typeof droppedId === "string") {
        onScenarioToAnotherFolder(item.id, droppedId);
      } else {
        droppedId !== item.id &&
          onFolderToAnotherFolder(droppedId as number, item.id);
      }

      handleDroppedId(null);
    }
    handleShowParentFolder(false);
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    handleShowParentFolder(false);
    active.current = false;
  };

  const onWrapperClick = () => (active.current = true);

  const onMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    if (event.button === 2) {
      active.current = false;
      handleShowParentFolder(false);
    }
  };

  return (
    <div
      className={RootCX}
      draggable
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnd={handleDragEnd}
      onClick={onWrapperClick}
      onMouseUp={onMouseUp}
    >
      <div className={style.info}>
        <FolderIcon className={style.icon} />
        <div className={style.name} onClick={handleClick}>
          {name}
        </div>
      </div>
      <div />
      <div />
      <div />
      <div className={style.modified}>{dayFrom(modified, i18n.language)}</div>
      <div />
      <div className={style.action}>
        <FolderModals item={item} onOpen={handleOpen} />
      </div>
    </div>
  );
};
