import * as Const from "../constants";
import State from "../interfaces/state/confirmNewUser";

export const initState: State = {
  isLoading: false,
  isError: false,
  isConfirmedEmailSend: false,
  email: "",
  confirmError: "",
  isUserHasBeenAlreadyRegistered: false,
  isConfirmed: false,
  userName: "",
  companyName: "",
};

export default function confirmNewUser(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.CONFIRM_INIT_NEW_USER:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.CONFIRM_NEW_USER_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
        isConfirmedEmailSend: false,
      };

    case Const.CONFIRM_NEW_USER_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isConfirmedEmailSend: false,
      };

    case Const.CONFIRM_NEW_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isConfirmedEmailSend: true,
      };

    case Const.SET_UNCONFIRMED_NEW_USER:
      return {
        ...state,
        isConfirmedEmailSend: false,
      };

    case Const.SET_NEW_USER_CONFIRMED:
      return {
        ...state,
        isConfirmed: true,
      };

    case Const.SET_NEW_USER_EMAIL:
      return {
        ...state,
        email: action.payload,
      };

    case Const.SET_NEW_USER_ERROR_TEXT:
      return {
        ...state,
        confirmError: action.payload,
      };

    case Const.SET_USER_ALREADY_REGISTERED:
      return {
        ...state,
        isUserHasBeenAlreadyRegistered: true,
      };

    case Const.SET_NEW_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };

    case Const.SET_NEW_USER_COMPANY_NAME:
      return {
        ...state,
        companyName: action.payload,
      };
    default:
      return state;
  }
}
