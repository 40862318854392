import store from "store";
import { AnswerData } from "../index";
import * as JournalEventsActions from "actions/scenarioEvents";
import { journalEvents$, limit$, offset$ } from "selectors/scenarioEvents";

// WSApi.JournalGetByEvent
export const journalGetByEvent = (data: AnswerData) => {
  const { dispatch, getState } = store;

  if (!data?.error) {
    const offset = offset$(getState());
    const journalEvents = journalEvents$(getState());
    const limit = limit$(getState());

    dispatch(JournalEventsActions.setHasMore(data.response.length === limit));

    const {
      processed_blocks,
      event_id,
      scenario_id,
      scenario_name,
      version,
      branch,
    } = data.response;

    dispatch(
      JournalEventsActions.setScenarioEvents({
        list:
          offset === 0
            ? processed_blocks
            : [...journalEvents, ...processed_blocks],
        event_id,
        scenario_id,
        scenario_name,
        version,
        branch,
      })
    );
  }

  data?.error && dispatch(JournalEventsActions.setScenarioEventsError());
};
