import * as Const from "../constants";
import { SensitiveDataItem } from "interfaces/state/sensitiveData";
import { Action } from "redux";

export interface ActionAddSensitiveData extends Action {
  payload: SensitiveDataItem[];
}

export interface ActionDeleteSensitiveData extends Action {
  payload: string;
}

export const setSensDataInit = (): Action => {
  return {
    type: Const.SENS_DATA_INIT,
  };
};

export const setSensDataList = (
  payload: SensitiveDataItem[]
): ActionAddSensitiveData => {
  return {
    type: Const.SENS_DATA_SUCCESS,
    payload,
  };
};

export const setSensDataError = (): Action => {
  return {
    type: Const.SENS_DATA_FAIL,
  };
};

export const setSensDataLoading = (): Action => {
  return {
    type: Const.SENS_DATA_LOADING,
  };
};
