import store from "store";
import { AnswerData } from "../index";
import * as PaymentMethodActions from "actions/paymentMethod";

// WSApi.PaymentMethods
export const paymentMethodGet = (data: AnswerData) => {
  if (Array.isArray(data?.response)) {
    store.dispatch(PaymentMethodActions.setPaymentList(data.response));
  }
};
