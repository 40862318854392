import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as FoldersActions from "actions/folders";
import {
  currentAutomationRoute$,
  limit$,
  offset$,
  page$,
  sortBy$,
  sortDirection$,
} from "selectors/automationRouting";
import { privateRoute } from "routes";
import * as FoldersCreators from "creators/folders";
import * as AutomationActions from "actions/automationRouting";
import { useLocation, useNavigate } from "react-router-dom";
import { selectedCategories$ } from "selectors/scenarioCategories";
import { folders$ } from "selectors/folders";

// WSApi.DeleteFolder
export const folderDelete = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch, getState } = store;

  if (data?.error) {
    errorFromResponse(data.error);
    dispatch(FoldersActions.setFoldersError());
  } else {
    const sortBy = sortBy$(getState());
    const sortDirection = sortDirection$(getState());
    const limit = limit$(getState());
    const offset = offset$(getState());
    const currentAutomationRoute = currentAutomationRoute$(getState());
    const selectedCategories = selectedCategories$(getState());
    const page = page$(getState());

    dispatch(FoldersActions.deleteFolderItem(data.response.deleted_folders));

    if (data.response?.go_to_folder) {
      if (data.response.deleted_folders.includes(currentAutomationRoute?.id)) {
        const folders = folders$(getState());

        const offsetByfoldersLength =
          !folders.length && page !== 1 ? offset - limit : offset;

        if (!folders.length && page !== 1) {
          dispatch(
            AutomationActions.updatePagination({
              page: page - 1,
              offset: offset - limit,
            })
          );
        }

        navigate(
          `${privateRoute.automation}?id=${data.response?.go_to_folder}&page=${
            !folders.length && page !== 1 ? page - 1 : page
          }`
        );

        dispatch(
          FoldersCreators.fetchAllAutomationFiles(
            data.response?.go_to_folder,
            sortBy,
            sortDirection,
            limit,
            offsetByfoldersLength,
            selectedCategories
          ) as any
        );
      } else {
        const folders = folders$(getState());

        const offsetByfoldersLength =
          !folders.length && page !== 1 ? offset - limit : offset;

        if (!folders.length && page !== 1) {
          dispatch(
            AutomationActions.updatePagination({
              page: page - 1,
              offset: offset - limit,
            })
          );
        }

        navigate(
          `${privateRoute.automation}?id=${data.response?.go_to_folder}&page=${
            !folders.length && page !== 1 ? page - 1 : page
          }`
        );

        currentAutomationRoute &&
          dispatch(
            FoldersCreators.fetchAllAutomationFiles(
              +currentAutomationRoute.id,
              sortBy,
              sortDirection,
              limit,
              offsetByfoldersLength,
              selectedCategories
            )
          );
      }
    }
  }
};
