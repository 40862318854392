import React, { memo } from "react";
import { useAppSelector } from "hooks/redux";

import { Handle } from "react-flow-renderer";
import style from "./customNodeForTemplate.module.scss";
import { templateItem$ } from "selectors/templateItem";
import { INIT_BLOCK } from "pages/scenario/constants/index";

const CustomNodeForTemplate = memo((props) => {
  const templateItem = useAppSelector(templateItem$);
  const memoPipeline = React.useMemo(
    () => templateItem?.pipeline,
    [templateItem]
  );
  const [isNeedToShowTopHandle, setTopHandleShow] = React.useState(false);
  const [isNeedToShowBottomHandle, setBottomHandleShow] = React.useState(false);
  const [isNeedToShowRightHandle, setRightHandleShow] = React.useState(false);
  const [isShowRight, setRightShow] = React.useState(false);

  React.useLayoutEffect(() => {
    if (memoPipeline && props.data.type === "case_of") {
      const top = memoPipeline.findIndex(
        (p) =>
          (p.source_position === "a" && `${p.id}` === `${props.id}`) ||
          (p.source_position === "a" && `${p.next}` === `${props.id}`)
      );
      setTopHandleShow(top >= 0);
      const bottom = memoPipeline.findIndex(
        (p) =>
          (p.source_position === "c" && `${p.id}` === `${props.id}`) ||
          (p.source_position === "c" && `${p.next}` === `${props.id}`)
      );
      setBottomHandleShow(bottom >= 0);

      const right = memoPipeline.findIndex(
        (p) =>
          (p.source_position === "b" && `${p.id}` === `${props.id}`) ||
          (p.source_position === "b" && `${p.next}` === `${props.id}`)
      );

      const inId = memoPipeline.findIndex((p) => `${p.id}` === `${props.id}`);
      const outId = memoPipeline.findIndex(
        (p) => `${p.next}` === `${props.id}`
      );
      setRightHandleShow(right >= 0 && inId >= 0 && outId >= 0);
    }
  }, [props, memoPipeline]);

  React.useLayoutEffect(() => {
    if (templateItem.pipeline) {
      const right = templateItem.pipeline.findIndex(
        (p) => `${p.in}` === `${props?.id}`
      );
      setRightShow(right >= 0);
    }
  }, [props, templateItem.pipeline]);

  const { type } = props.data;
  return (
    <div className={style.root}>
      <Handle
        type="target"
        position="left"
        style={{ background: "rgba(255,255,255,0)" }}
        className="invisible"
        onConnect={(params) => console.log("handle onConnect", params)}
      />
      <>{props.data.label}</>

      {type === "case_of" && (
        <Handle
          type="source"
          position="top"
          id="a"
          style={{
            background: "#2084E1",
            opacity: isNeedToShowTopHandle ? 1 : 0,
          }}
        />
      )}

      {type === "case_of" && (
        <Handle
          type="source"
          position="right"
          id="b"
          style={{
            background: "#2084E1",
            opacity: isNeedToShowRightHandle ? 1 : 0,
          }}
        />
      )}

      {type !== "case_of" ? (
        <Handle
          type="source"
          position="right"
          id="b"
          style={{
            background: "#2084E1",
            opacity: isShowRight || props?.id === INIT_BLOCK ? 1 : 0,
          }}
        />
      ) : (
        <> </>
      )}

      {type === "case_of" ? (
        <Handle
          type="source"
          position="bottom"
          id="c"
          style={{
            background: "#2084E1",
            // left: "calc(50% + 0.5px)",
            top: "calc(100% - 2px)",
            opacity: isNeedToShowBottomHandle ? 1 : 0,
          }}
        />
      ) : null}
    </div>
  );
});

export default CustomNodeForTemplate;
