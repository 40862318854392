import store from "store";
import { AnswerData } from "../index";
import * as CompanyActions from "actions/company";
import { companyList$ } from "selectors/company";

// WSApi.CompanyDelete
export const companyDelete = (data: AnswerData) => {
  const { dispatch, getState } = store;
  if (!data?.error) {
    const company_id = data.response.company_id;
    const companies = [...companyList$(getState())].filter(
      (company) => company.company_id !== company_id
    );

    dispatch(CompanyActions.setCompanyList(companies));
  }
};
