import React from "react";
import {
  getMarkerEnd,
  // getBezierPath,
  // getSmoothStepPath,
} from "react-flow-renderer";
import { getSmoothStepPath } from "utils";
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  arrowHeadType,
  markerEndId,
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
}) => {
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  // const edgePath = getBezierPath({
  //   sourceX,
  //   sourceY,
  //   sourcePosition,
  //   targetX,
  //   targetY,
  //   targetPosition,
  // });

  const smoothPath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition, // optional
    targetX: targetX + 3,
    targetY,
    targetPosition, // optional
    borderRadius: 140, // optional
  });
  return (
    <g>
      <path
        fill="none"
        stroke="#1B243D"
        strokeWidth={2}
        d={smoothPath}
        markerEnd={markerEnd}
      />
    </g>
  );
};
