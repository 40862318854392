import { useLocation, useNavigate } from "react-router-dom";
import { privateRoute } from "routes";
import { list$ } from "selectors/debug";
import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { makeScenarioParams } from "utils";
import { AnswerData } from "../index";
import * as DebugActions from "actions/debug";

// WSApi.PassDebug
export const scenarioDebugPass = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch, getState } = store;
  const scenarioId = scenarioItem$(getState())?.scenario_id;

  if (scenarioId === data?.scenario_id) {
    const searchParams = new URLSearchParams(window.location.search);
    const isEdit = searchParams.get("edit") === "1";
    const isView = searchParams.get("view") === "1";
    const isDebug = searchParams.get("debug") === "1";
    const version_id = searchParams.get("version");

    const debugListForCurrentScenario = list$(getState());
    const newDebugAnswerItem = {
      block_id: data?.block_id,
      isError: data?.response.status === "error",
      body:
        data?.response.status === "error"
          ? JSON.stringify(data?.response?.body)
          : JSON.stringify(data?.response),
      date: data?.datetime,
    };

    dispatch(
      DebugActions.setDebugList([
        ...debugListForCurrentScenario,
        newDebugAnswerItem,
      ])
    );

    const blockId = data?.block_id;
    const branch = searchParams.get("branch")!;

    navigate(
      `${privateRoute.scenario}?${makeScenarioParams({
        scenario_id: scenarioId,
        version_id,
        isEdit,
        isView,
        isDebug,
        isViewVersion: false,
        selectedBlock: null,
        branch,
      })}`
    );

    if (data?.error) {
      dispatch(DebugActions.setDebugErrorReason(data?.error));
      dispatch(DebugActions.setDebugLoading(false));
      dispatch(DebugActions.setDebugIdWhereWasError(blockId));
    }
  }
};
