import store from "store";
import { AnswerData } from "../index";
import * as IndicatorActions from "actions/indicators";

// WSApi.GetIndicators
export const indicatorGet = (data: AnswerData) => {
  if (data?.error) {
    store.dispatch(IndicatorActions.setIndicatorsError());
  } else {
    store.dispatch(IndicatorActions.setIndicators(data?.response));
  }
};
