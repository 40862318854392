import * as Const from "../constants";
import State from "../interfaces/state/changeEmail";

export const initState: State = {
  isLoading: false,
  isError: false,
  new_email: "",
  link: "",
  isConfirmed: false,
};

export default function changeEmail(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.CHANGE_EMAIL_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.CHANGE_EMAIL_PARAMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        new_email: action.payload.new_email,
        link: action.payload.link,
      };

    case Const.CHANGE_EMAIL_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
        isConfirmed: false,
      };

    case Const.CHANGE_EMAIL_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isConfirmed: true,
      };

    default:
      return state;
  }
}
