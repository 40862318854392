import { useTranslation } from "react-i18next";
import { tableHeaderItems } from "../utils";
import style from "./tableHeader.module.scss";

export const TableHeader = () => {
  const { t } = useTranslation();
  return (
    <div className={style.root}>
      {tableHeaderItems.map((item) => (
        <div key={item} className={style.item}>
          {t(`automation:${item}`)}
        </div>
      ))}
    </div>
  );
};
