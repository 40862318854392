import { fetchPDFFile } from "creators/paymentHistory";
import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";

// WSApi.DownloadPdfPaymentHistory
export const paymentGetPdfReceipt = (data: AnswerData) => {
  if (data.error) {
    errorFromResponse(data.error);
  } else {
    store.dispatch(fetchPDFFile(data.response) as any);
  }
};
