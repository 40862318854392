import store from "store";
import * as WSConnectAction from "actions/wsConnect";
import { errorFromResponse } from "utils";
import { AnswerData } from "./index";

// WSApi.Init
export const initialization = (data: AnswerData) => {
  if (!data || "error" in data) {
    store.dispatch(WSConnectAction.setWSConnectStatus("error"));
    errorFromResponse(data.error);
  } else {
    if (data.response === "ok") {
      store.dispatch(WSConnectAction.setWSConnectStatus("ok"));
      store.dispatch(WSConnectAction.setWSConnected(true));
    }
  }
};
