import { setWSQueue } from "actions/wsConnect";
import { eventHandler } from "creators/ws";
import store from "store";
import { useLocation, useNavigate } from "react-router-dom";
import { queue$ } from "selectors/wsConnect";
import * as answer from "./index";

export const processingEvents = (
  data: answer.AnswerData,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { event } = eventHandler(data);
  if ((answer as answer.AnswerData)?.[event]) {
    (answer as answer.AnswerData)?.[event](
      data,
      location,
      searchParams,
      navigate
    );

    const { dispatch, getState } = store;

    const queue = queue$(getState());

    const newQueue = queue.filter((item) => item !== data.action);
    dispatch(setWSQueue(newQueue));
  } else {
    console.error(`Unhandled event ${data.action}`);
  }
};
