import State from "interfaces/state";
import { WSApi } from "enums/wsApi";
import { Action } from "redux";
import { sendWSMessage } from "utils";
import * as MainFolderActions from "actions/mainFolder";

export const fetchMainFolderData = () => {
  return (dispatch: (action: Action) => void, _getState: () => State) => {
    const action = WSApi.GetMainFolder;
    dispatch(MainFolderActions.setMainFolderLoading());

    sendWSMessage({ action });
  };
};
