import * as Const from "../constants";
import State, {
  ScenarioCategoriesAction,
} from "../interfaces/state/scenarioCategories";

export const initState: State = {
  isLoading: false,
  isError: false,
  list: [],
  company_list: [{ id: 0, name: "allCategories" }],
  selected_list: [],
};

export default function scenarioCategories(
  state: State = initState,
  action: ScenarioCategoriesAction
): State {
  switch (action.type) {
    case Const.SCENARIO_CATEGORIES_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.SCENARIO_CATEGORIES_SUCCESS:
      const { list, isError, isLoading, company_list, selected_list } =
        action.payload;
      return {
        ...state,
        isLoading,
        isError,
        list: list || state.list,
        company_list: company_list || state.company_list,
        selected_list: selected_list || state.selected_list,
      };

    default:
      return state;
  }
}
