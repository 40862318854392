import store from "store";
import { AnswerData } from "../index";
import * as LibraryActons from "actions/library";

// WSApi.GetLibraries
export const libraryGetAll = (data: AnswerData) => {
  if (!data?.error) {
    store.dispatch(
      LibraryActons.setLibList(
        data.response.result,
        data.response.scenario_id,
        data.response.block_id
      )
    );
  }
};
