import { RootState } from "store";

export const isLoading$ = (state: RootState) => state.scenarioEvents.isLoading;
export const isError$ = (state: RootState) => state.scenarioEvents.isError;
export const journalEvents$ = (state: RootState) => state.scenarioEvents.list;
export const limit$ = (state: RootState) => state.scenarioEvents.limit;
export const offset$ = (state: RootState) => state.scenarioEvents.offset;
export const hasMore$ = (state: RootState) => state.scenarioEvents.hasMore;
export const branch$ = (state: RootState) => state.scenarioEvents.branch;
export const event_id$ = (state: RootState) => state.scenarioEvents.event_id;
export const scenario_id$ = (state: RootState) =>
  state.scenarioEvents.scenario_id;
export const scenario_name$ = (state: RootState) =>
  state.scenarioEvents.scenario_name;
export const version$ = (state: RootState) => state.scenarioEvents.version;
