import store from "store";
import { AnswerData } from "../index";
import * as ScenarioCounters from "actions/scenarioCounters";

// WSApi.GetAvailableCounter
export const counterGetAvailable = (data: AnswerData) => {
  if (data?.error) {
    store.dispatch(ScenarioCounters.setScenarioCountersFail());
  } else {
    store.dispatch(ScenarioCounters.setScenarioCounters(data.response));
  }
};
