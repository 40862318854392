import * as Const from "../constants";
import State from "../interfaces/state/connectorItem";

export const initState: State = {
  isLoading: false,
  isError: false,
  connector: null,
};

export default function connectorItem(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.CONNECTOR_ITEM_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.CONNECTOR_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        connector: action.payload,
      };

    case Const.CONNECTOR_ITEM_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.CONNECTOR_ITEM_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
