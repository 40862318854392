import { AutomationFileSize } from "pages/automation/containers/fileSystem";

export default interface AutomationRoutingState {
  list: AutomationRoute[];
  current: AutomationRoute | null;
  sort_by: SortBy;
  sort_direction: SortDirection;
  limit: number;
  offset: number;
  size: AutomationFileSize;
  search: string;
  page: number;
}

export enum SortBy {
  NAME = "name",
  SCENARIO = "scenario",
  FOLDER = "folder",
  MODIFIED = "modified",
}

export enum SortDirection {
  UP = "up",
  DOWN = "down",
}

export enum AutomationRouteType {
  SCENARIO = "scenario",
  FOLDER = "folder",
}

export type AutomationRoute = {
  id: number | string;
  type: AutomationRouteType.FOLDER | AutomationRouteType.SCENARIO;
  title: string;
};
