import { fetchTeamList } from "creators/team";
import { useLocation, useNavigate } from "react-router-dom";
import store from "store";
import { AnswerData } from "../index";
import * as TeamActions from "actions/team";
import * as AuthCreators from "creators/auth";

// WSApi.CompanyChange
export const companyChange = (
  data: AnswerData,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch } = store;
  if (!data?.error) {
    dispatch(fetchTeamList());
    dispatch(TeamActions.setCompanyId(data.response.company_id));
    dispatch(TeamActions.setCompanyName(data.response.company_name));
    dispatch(AuthCreators.getSessionStatus(navigate, location, searchParams));
  }
};
