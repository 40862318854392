import { Branch } from "interfaces/state/scenario";
import {
  IScenarioVersionUser,
  ScenarioVersion,
} from "interfaces/state/scenarioVersion";
import * as Const from "../constants";

export const setScenarioVersionInit = () => {
  return {
    type: Const.SCENARIO_VERSION_INIT,
  };
};

export const setScenarioVersionList = (payload: ScenarioVersion[]) => {
  return {
    type: Const.SCENARIO_VERSION_LIST,
    payload,
  };
};

export const setScenarioVersionError = () => {
  return {
    type: Const.SCENARIO_VERSION_FAIL,
  };
};

export const setScenarioVersionLoading = (payload: boolean) => {
  return {
    type: Const.SCENARIO_VERSION_LOADING,
    payload,
  };
};

export const setScenarioVersionItems = (payload: number) => {
  return {
    type: Const.SCENARIO_VERSION_ITEMS,
    payload,
  };
};

export const setScenarioVersionUsers = (payload: IScenarioVersionUser[]) => {
  return {
    type: Const.SCENARIO_VERSION_USERS,
    payload,
  };
};

export const setScenarioVersionTags = (payload: string[]) => {
  return {
    type: Const.SCENARIO_VERSION_TAGS,
    payload,
  };
};

export const updateScenarioVersionPage = (payload: number) => {
  return {
    type: Const.SCENARIO_VERSION_PAGE,
    payload,
  };
};

export const updateScenarioBranch = (payload: Branch) => ({
  type: Const.SCENARIO_VERSION_BRANCH,
  payload,
});

export const setLastVersionItem = (payload: boolean) => ({
  type: Const.SCENARIO_VERSION_LAST_ITEM,
  payload,
});
