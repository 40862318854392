import store from "store";
import { AnswerData } from "../index";
import * as ScenarioCategoriesActions from "actions/scenarioCategories";

// WSApi.GetAllCompanyCategories
export const companyCategoriesGetAll = (data: AnswerData) => {
  if (!data?.error) {
    const { dispatch } = store;

    dispatch(
      ScenarioCategoriesActions.setScenarioCategories({
        isLoading: false,
        isError: false,
        company_list: data.response,
      })
    );
  }
};
