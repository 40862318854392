import * as Const from "../constants";
import State from "../interfaces/state/variablePanel";

export const initState: State = {
  activeId: null,
  prevActiveId: null,
  isLoading: false,
  isError: false,
  prediction: [],
  searchResultByBlockId: {},
  selectedBlockId: null,
  temporaryVariable: "",
  activeParameterIndex: null,
};

export default function variablePanel(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.VARIABLE_PANEL_INIT:
    case Const.SCENARIO_ITEM_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.VARIABLE_PANEL_ID:
      return {
        ...state,
        activeId: action.payload,
        prevActiveId: action.payload === null ? null : state.prevActiveId,
      };

    case Const.PREV_VARIABLE_PANEL_ID:
      return {
        ...state,
        prevActiveId: action.payload,
      };

    case Const.VARIABLE_PANEL_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        isError: false,
      };

    case Const.VARIABLE_PANEL_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.VARIABLE_PREDICTION:
      return {
        ...state,
        prediction: action.payload,
      };

    case Const.VARIABLE_SEARCH_RESULT:
      return {
        ...state,
        searchResultByBlockId: action.payload,
      };

    case Const.VARIABLE_SELECTED_BLOCK_ID:
      return {
        ...state,
        selectedBlockId: action.payload,
      };

    case Const.VARIABLE_PANEL_TEMP_VAR:
      return {
        ...state,
        temporaryVariable: action.payload,
      };

    case Const.VARIABLE_PANEL_ACTIVE_PARAMETER_INDEX:
      return {
        ...state,
        activeParameterIndex: action.payload,
      };

    default:
      return state;
  }
}
