import * as Const from "../constants";
import State from "../interfaces/state/plans";

export const initState: State = {
  isLoading: false,
  isError: false,
  list: [],
};

export default function plans(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.PLANS_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: action.payload,
      };

    case Const.PLANS_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.PLANS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
