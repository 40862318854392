import store from "store";
import { errorFromResponse, successNotification } from "utils";
import { AnswerData } from "../index";

import * as CancelSubsctionActions from "actions/cancelSubscription";
import * as AuthCreators from "creators/auth";
import * as BillingInfoCreators from "creators/billingInfo";

import i18n from "i18n";
import { useLocation, useNavigate } from "react-router-dom";

// WSApi.CancelSubscription
export const subscriptionCancel = (
  data: AnswerData,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (data.error) {
    store.dispatch(CancelSubsctionActions.setCancelSubscriptionFail());
    errorFromResponse(data.error);
  } else {
    store.dispatch(CancelSubsctionActions.setCancelSubscriptionSuccess());
    successNotification(i18n.t("subscrCanceled"));
    store.dispatch(BillingInfoCreators.fetchBillingInfo() as any);
    store.dispatch(
      AuthCreators.getSessionStatus(
        navigate,
        location,
        searchParams,
        true,
        false
      ) as any
    );
  }
};
