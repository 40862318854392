import { createSelector } from "reselect";
import State from "../interfaces/state";

export const isLoading$ = (state: State) => state.auth.isLoading;
export const isError$ = (state: State) => state.auth.isError;
export const email$ = (state: State) => state.auth.email;
export const sid$ = (state: State) => state.auth.sid;
export const first_name$ = (state: State) => state.auth.first_name;
export const status_session$ = (state: State) => state.auth.status_session;
export const member_id$ = (state: State) => state.auth.member_id;
export const plan_name$ = (state: State) => state.auth.plan_name;
export const plan_id$ = (state: State) => state.auth.plan_id;

export const sidMemoized$ = createSelector(sid$, (sid) => {
  return sid;
});

export const isNeedToHideHeader$ = (state: State) =>
  state.auth.isNeedToHideHeader;

export const avatar$ = (state: State) => state.auth.avatar_url;
export const company_role$ = (state: State) => state.auth.company_role;
export const cookieApplied$ = (state: State) => state.auth.consent_on_cookie;
export const appRole$ = (state: State) => state.auth.role;
export const company_name$ = (state: State) => state.auth.company_name;
export const isValidToken$ = (state: State) => state.auth.isValidToken;
export const isOpenHelpCenterModal$ = (state: State) =>
  state.auth.isOpenHelpCenterModal;
