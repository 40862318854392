export default interface LibraryState {
  isLoading: boolean;
  isError: boolean;
  libType: LibraryType;
  list: { [key: string]: { [key: string]: ILibrary[] } };
  isOpenPanel: boolean;
  position: number;
  scrollToTop: number;
}

export enum LibraryType {
  FORMATTER = "formatter",
  FILTER = "filter",
  CONVERTER = "converter",
}

export interface ILibrary {
  description: string;
  name: string;
  type: LibraryState["libType"];
  id: string;
}
