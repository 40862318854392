import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as TemplatesListActions from "actions/templates";

// WSApi.TemplatesList
export const templateGetAll = (data: AnswerData) => {
  if (data.error) {
    store.dispatch(TemplatesListActions.setTemplateListFail());
    errorFromResponse(data.error);
  } else {
    store.dispatch(
      TemplatesListActions.setTemplateList(data.response.templates)
    );
    store.dispatch(
      TemplatesListActions.setTemplatesAmount(
        data.response.all_templates_amount
      )
    );
  }
};
