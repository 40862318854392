import store from "store";
import { AnswerData } from "../index";
import * as ScenarioVersionActions from "actions/scenarioVersion";

// WSApi.GetScenarioVersionByTag
export const scenarioVersionGetByTag = (data: AnswerData) => {
  const { dispatch } = store;
  if (!data?.error) {
    const versions = [data.response];

    dispatch(ScenarioVersionActions.setScenarioVersionList(versions));
    dispatch(ScenarioVersionActions.setScenarioVersionLoading(false));
    dispatch(ScenarioVersionActions.setScenarioVersionItems(1));
    dispatch(ScenarioVersionActions.updateScenarioVersionPage(1));
  }
};
