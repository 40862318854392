import { Action } from "redux";
import * as ScenarioCategoriesActions from "actions/scenarioCategories";
import * as FoldersActions from "actions/folders";
import { WSApi } from "enums/wsApi";
import { sendWSMessage } from "utils";
import { RootState } from "reducers";
import { scenarioItem$ } from "selectors/scenario";
import { folders$ } from "selectors/folders";
import { AutomationRouteType } from "interfaces/state/automationRouting";

export const fetchScenarioCategories = () => {
  return (dispatch: (action: Action) => void, getState: () => RootState) => {
    const scenario_id = scenarioItem$(getState())?.scenario_id;

    dispatch(
      ScenarioCategoriesActions.setScenarioCategories({
        isLoading: true,
        isError: false,
        list: [],
      })
    );

    const action = WSApi.GetAllScenarioCategories;

    sendWSMessage({ action, scenario_id });
  };
};

export const addScenarioCategory = (
  scenario_id: string,
  categories: number[]
) => {
  return (dispatch: (action: Action) => void, getState: () => RootState) => {
    dispatch(
      ScenarioCategoriesActions.setScenarioCategories({
        isLoading: true,
        isError: false,
      })
    );

    const action = WSApi.AddScenarioCategory;

    sendWSMessage({ action, scenario_id, categories });
  };
};

export const deleteScenarioCategory = (
  scenario_id: string,
  categories: number[]
) => {
  return (dispatch: (action: Action) => void, getState: () => RootState) => {
    dispatch(
      ScenarioCategoriesActions.setScenarioCategories({
        isLoading: true,
        isError: false,
      })
    );

    const action = WSApi.DeleteScenarioCategory;

    sendWSMessage({ action, scenario_id, categories });
  };
};

export const companyCategoriesAdd = (name: string) => {
  return (dispatch: (action: Action) => void, getState: () => RootState) => {
    const scenario_id = scenarioItem$(getState())?.scenario_id;

    dispatch(
      ScenarioCategoriesActions.setScenarioCategories({
        isLoading: true,
        isError: false,
      })
    );

    const action = WSApi.AddCompanyCategory;

    sendWSMessage({ action, scenario_id, name });
  };
};

export const fetchCompanyCategories = () => {
  return (dispatch: (action: Action) => void) => {
    dispatch(
      ScenarioCategoriesActions.setScenarioCategories({
        isLoading: true,
        isError: false,
      })
    );

    const action = WSApi.GetAllCompanyCategories;

    sendWSMessage({ action });
  };
};

export const deleteCompanyCategory = (id: number) => {
  return (dispatch: (action: Action) => void, getState: () => RootState) => {
    const scenario_id = scenarioItem$(getState())?.scenario_id;

    const folders = folders$(getState());
    const newFolders = [...folders].map((folder) =>
      folder.type === AutomationRouteType.SCENARIO
        ? {
            ...folder,
            categories: folder.categories.filter((cat) => cat.id !== id),
          }
        : folder
    );

    dispatch(FoldersActions.setFoldersSuccess(newFolders));
    dispatch(
      ScenarioCategoriesActions.setScenarioCategories({
        isLoading: true,
        isError: false,
      })
    );

    const action = WSApi.DeleteCompanyCategory;

    sendWSMessage({ action, scenario_id, id });
  };
};

export const renameCompanyCategory = (id: number, new_name: string) => {
  return (dispatch: (action: Action) => void, getState: () => RootState) => {
    dispatch(
      ScenarioCategoriesActions.setScenarioCategories({
        isLoading: true,
        isError: false,
      })
    );

    const action = WSApi.RenameCompanyCategory;

    sendWSMessage({ action, id, new_name });
  };
};
