import store from "store";
import { AnswerData } from "../index";
import * as PaymentMethodActions from "actions/paymentMethod";
import { PaymentMethod } from "interfaces/state/paymentMethod";

// WSApi.DefaultPaymentMethod
export const paymentMethodGetDefault = (data: AnswerData) => {
  if (!data?.error) {
    const defaultPaymentMethod = data?.response;
    if (defaultPaymentMethod)
      store.dispatch(
        PaymentMethodActions.setDefaultPayment(
          defaultPaymentMethod as PaymentMethod | null
        )
      );
  }
};
