import FoldersState, { Folder } from "interfaces/state/folders";
import { AutomationScenario } from "interfaces/state/scenarios";
import * as Const from "../constants";

export const setFoldersInit = () => {
  return {
    type: Const.FOLDERS_INIT,
  };
};

export const setFoldersSuccess = (payload: FoldersState["list"]) => {
  return {
    type: Const.FOLDERS_SUCCESS,
    payload,
  };
};

export const setFoldersError = () => {
  return {
    type: Const.FOLDERS_FAIL,
  };
};

export const setFoldersLoading = () => {
  return {
    type: Const.FOLDERS_LOADING,
  };
};

export const addNewFolder = (payload: Partial<Folder | AutomationScenario>) => {
  return {
    type: Const.FOLDERS_ADD_NEW,
    payload,
  };
};

export const deleteFolderItem = (deleted_folders: number[]) => {
  return {
    type: Const.FOLDERS_DELETE,
    deleted_folders,
  };
};

export const addSnapshotToScenarioInFolder = (
  scenario_id: string,
  scenario_snapshot: string
) => {
  return {
    type: Const.FOLDERS_ADD_SNAPSHOT_TO_SCENARIO,
    scenario_id,
    scenario_snapshot,
  };
};

export interface MaxFoldersValues {
  max_folders: number;
  max_items_length: number;
  max_scenarios: number;
}

export const updateMaxValues = (payload: MaxFoldersValues) => {
  return {
    type: Const.FOLDERS_MAX_VALUES,
    payload,
  };
};
