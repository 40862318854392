import store from "store";
import { AnswerData } from "../index";
import * as AuthCreators from "creators/auth";
import * as WSConnectAction from "actions/wsConnect";
import { useLocation, useNavigate } from "react-router-dom";
import { company_id$ } from "selectors/team";
import { errorNotification } from "utils";
import i18n from "i18n";
import { companyList$ } from "selectors/company";
import { setCompanyList } from "actions/company";

// WSApi.AcctountDeleteCompany
export const accountDeleteCompany = (
  data: AnswerData,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch, getState } = store;

  const company_id = company_id$(getState());

  if (!data?.error && company_id === data.response.company_id) {
    dispatch(
      AuthCreators.logOut(
        location,
        navigate,
        searchParams,
        searchParams.get("token")
      ) as any
    );
    dispatch(WSConnectAction.setWSConnected(false));
    errorNotification(i18n.t("deleteCompanyMessage"), "", 10000);
  }

  const companyList = [...companyList$(getState())].filter(
    (company) => company.company_id !== data.response.company_id
  );

  dispatch(setCompanyList(companyList));
};
