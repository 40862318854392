import * as Const from "../constants";
import { Action } from "redux";
import {
  PredictionItem,
  SearchVariableResult,
} from "interfaces/state/variablePanel";

export const setVariablePanelInit = (): Action => {
  return {
    type: Const.VARIABLE_PANEL_INIT,
  };
};

export const setVariablePanelId = (payload: string | null | number) => {
  return {
    type: Const.VARIABLE_PANEL_ID,
    payload,
  };
};

export const setPrevVariablePanelId = (payload: string | null | number) => {
  return {
    type: Const.PREV_VARIABLE_PANEL_ID,
    payload,
  };
};

export const setVariablePanelLoading = (payload: boolean) => {
  return {
    type: Const.VARIABLE_PANEL_LOADING,
    payload,
  };
};

export const setVariablePanelError = () => {
  return {
    type: Const.VARIABLE_PANEL_ERROR,
  };
};

export const setPredictionVariables = (payload: PredictionItem[]) => ({
  type: Const.VARIABLE_PREDICTION,
  payload,
});

export const setVariableResult = (payload: {
  [key: string]: { [key: string]: SearchVariableResult };
}) => ({
  type: Const.VARIABLE_SEARCH_RESULT,
  payload,
});

export const setVariableSelectedBlockId = (payload: string | null) => ({
  type: Const.VARIABLE_SELECTED_BLOCK_ID,
  payload,
});

export const setTempVariable = (payload: string) => ({
  type: Const.VARIABLE_PANEL_TEMP_VAR,
  payload,
});

export const setActiveParameterIndex = (payload: number | null) => ({
  type: Const.VARIABLE_PANEL_ACTIVE_PARAMETER_INDEX,
  payload,
});
