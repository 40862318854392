import store from "store";
import { AnswerData } from "../index";
import * as ScenarioActions from "actions/scenario";
import { privateRoute } from "routes";
import { makeScenarioParams } from "utils";
import { useLocation, useNavigate } from "react-router-dom";

// WSApi.GetDebugScenario
export const scenarioDebugGet = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (!data?.error) {
    const { dispatch } = store;
    dispatch(ScenarioActions.setScenarioSuccess(data.response.scenario));

    if (
      window.location.pathname === privateRoute.scenario ||
      window.location.pathname === privateRoute.scenario + "/"
    ) {
      const isEdit = searchParams.get("edit") === "1";
      const isView = searchParams.get("view") === "1";
      const isDebug = searchParams.get("debug") === "1";
      const branch = searchParams.get("branch")!;
      // const isGrid = searchParams.get("isGrid");

      navigate(
        `${privateRoute.scenario}?${makeScenarioParams({
          scenario_id: data.response.scenario?.scenario_id,
          version_id: data.response.scenario?.version_id,
          isEdit,
          isView,
          isDebug,
          isViewVersion: false,
          selectedBlock: null,
          branch,
        })}`
      );
    }
  }
};
