import {
  ServicePageItem,
  ServicePageStatus,
} from "interfaces/state/servicesPage";
import * as Const from "../constants";

export const setServicePageInit = () => ({
  type: Const.SERVICE_PAGE_INIT,
});

export const loadServicePageSuccess = (payload: {
  connectors: ServicePageItem[];
  total_connectors: number;
}) => ({
  type: Const.SERVICE_PAGE_SUCCESS,
  payload,
});

export const setServicePageLoading = () => ({
  type: Const.SERVICE_PAGE_FAIL,
});

export const setServicePageFail = () => ({
  type: Const.SERVICE_PARAM_FAIL,
});

export const setServicePageStatus = (payload: ServicePageStatus) => ({
  type: Const.SERVICE_PAGE_STATUS,
  payload,
});

export const setServicePageSortBy = (payload: string) => ({
  type: Const.SERVICE_PAGE_SORT_BY,
  payload,
});

export const setNeedChanges = (
  isNeedToSaveChanges: boolean,
  isOpenSaveChangesPopup: boolean,
  nextRouteAfterSaveChanges: string
) => ({
  type: Const.SERVICE_PAGE_SAVE_CHANGES,
  isNeedToSaveChanges,
  isOpenSaveChangesPopup,
  nextRouteAfterSaveChanges,
});

export const setTryToSaveData = (payload: boolean) => ({
  type: Const.SERVICE_PAGE_TRY_TO_SAVE_DATA,
  payload,
});

export const setHasDataToSave = (payload: boolean) => ({
  type: Const.SERVICE_PAGE_HAS_DATA_TO_SAVE,
  payload,
});
