import State from "interfaces/state";
import { Action } from "redux";
import { WSApi } from "enums/wsApi";
import { sendWSMessage } from "utils";
import * as PaymentHistoryActions from "actions/paymentHistory";
import { company_role$ } from "selectors/auth";
import { MemberRole } from "interfaces/state/team";

export type SortByPayment =
  | "card"
  | "date"
  | "cost"
  | "order_number"
  | "status";

export type SortByDirection = "down" | "up";

export const fetchPaymentHistory = (
  limit: number = 10,
  offset: number = 0,
  sort_by: SortByPayment,
  sort_direction: SortByDirection
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const company_role = company_role$(getState());

    if (company_role !== MemberRole.OWNER) return;

    if (limit > offset && offset > 0) {
      dispatch(PaymentHistoryActions.setPaymentHistoryHasmore(false));
      return;
    } else {
      const action = WSApi.GetAllPayments;
      if (offset === 0) {
        dispatch(PaymentHistoryActions.setPaymentHistoryInit());
      }
      dispatch(PaymentHistoryActions.setPaymentHistoryOffset(offset));
      dispatch(PaymentHistoryActions.setPaymentHistoryLoading(true));

      sendWSMessage({ action, limit, offset, sort_by, sort_direction });
    }
  };
};

export const deletePaymentHistoryItem = (payment_id: number) => {
  return (dispatch: (action: Action) => void, _getState: () => State) => {
    const action = WSApi.DeletePayment;
    sendWSMessage({ action, payment_id });
  };
};

export const fetchPDFPaymentHistoryItemLink = (payment_id: number) => {
  return (dispatch: (action: Action) => void, _getState: () => State) => {
    const action = WSApi.DownloadPdfPaymentHistory;

    sendWSMessage({ action, payment_id });
  };
};

export const fetchPDFFile = (url: string) => {
  return (_dispatch: (action: Action) => void, _getState: () => State) => {
    const filename = `${new Date()}.pdf`;
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
};
