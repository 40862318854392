import { ServiceItem } from "interfaces/state/service";
import * as Const from "../constants";

export const setServiceInit = () => {
  return {
    type: Const.SERVICE_INIT,
  };
};

export const loadServiceSuccess = (payload: ServiceItem[]) => {
  return {
    type: Const.SERVICE_SUCCESS,
    payload,
  };
};

export const setServiceLoading = () => {
  return {
    type: Const.SERVICE_LOADING,
  };
};

export const setServiceFail = () => {
  return {
    type: Const.SERVICE_FAIL,
  };
};
