import State from "interfaces/state/scenarioEvents";
import * as Const from "../constants";

export const setScenarioEventsInit = () => {
  return {
    type: Const.SCENARIO_EVENTS_INIT,
  };
};

export const setScenarioEvents = (payload: {
  list: State["list"];
  event_id: State["event_id"];
  branch: State["branch"];
  scenario_id: State["scenario_id"];
  scenario_name: State["scenario_name"];
  version: State["version"];
}) => {
  return {
    type: Const.SCENARIO_EVENTS_SUCCESS,
    payload,
  };
};

export const setScenarioEventsPending = () => {
  return {
    type: Const.SCENARIO_EVENTS_LOADING,
  };
};

export const setScenarioEventsError = () => {
  return {
    type: Const.SCENARIO_EVENTS_FAIL,
  };
};

export interface ScenarioEventPagination {
  offset: number;
}

export const setScenarioEventsPagination = (
  payload: ScenarioEventPagination
) => {
  return {
    type: Const.SCENARIO_EVENTS_PAGINATION,
    payload,
  };
};

export const setHasMore = (payload: boolean) => ({
  type: Const.SCENARIO_EVENTS_HAS_MORE,
  payload,
});
