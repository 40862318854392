import * as Const from "../constants";
import { WSConnectState } from "interfaces/state/wsConnect";

export const setWSConnectStatus = (payload: WSConnectState["connect"]) => {
  return {
    type: Const.WS_CONNECT,
    payload,
  };
};

export const setWSConnectStatusInit = () => {
  return {
    type: Const.WS_CONNECT_INIT,
  };
};

export const setWSConnected = (payload: boolean) => {
  return {
    type: Const.WS_CONNECT_SET_CONNECTED,
    payload,
  };
};

export const setWebsocketItem = (payload: null | WebSocket) => {
  return {
    type: Const.WS_CONNECT_WEBSOCKET_ITEM,
    payload,
  };
};

export const setWSQueue = (payload: string[]) => {
  return {
    type: Const.WS_CONNECT_QUEUE,
    payload,
  };
};
