import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MouseEvent,
} from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { dayFrom } from "utils";
import { AutomationScenario } from "interfaces/state/scenarios";
import { ScenarioModals } from "components/scenarioModals";
import { ScenarioServices } from "components/scenarioServices";
import { ReactComponent as ScenarioIcon } from "assets/svg/scenario.svg";
import style from "./tableScenario.module.scss";
import { usePrevious } from "utils/customHooks";

interface IProps {
  item: AutomationScenario;
  droppedId: null | string | number;
  onClick: (item: AutomationScenario) => void;
  handleShowParentFolder: (value: boolean) => void;
  onScenarioToAnotherFolder: (id: number, scenario: number | string) => void;
  handleDroppedId: (val: null | string | number) => void;
}

export const TableScanerio: FC<IProps> = ({
  item: { name, modified, categories, active_branches, accounts },
  item,
  onClick,
  droppedId,
  handleShowParentFolder,
  onScenarioToAnotherFolder,
  handleDroppedId,
}) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [isOpenChangeCategory, setOpenCategory] = useState<boolean>(false);

  const active = useRef<boolean>(false);
  const prevActive = usePrevious(active.current);

  const handleClick = useCallback(
    () => !open && onClick(item),
    [item, onClick, open]
  );

  useEffect(() => {
    if (prevActive && !active.current) {
      handleShowParentFolder(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevActive]);

  const handleOpen = useCallback(() => setOpenCategory(true), []);

  const getCategories = useMemo(
    () =>
      !!categories?.length && (
        <div className={style.category} onClick={handleOpen}>
          {categories[0]?.name}
        </div>
      ),
    [categories, handleOpen]
  );

  const getActiveBranches = useMemo(
    () => active_branches?.join(" && "),
    [active_branches]
  );

  const getTruncatedText = useMemo(
    () =>
      categories.length > 1 && (
        <div className={style.extraCategories} onClick={handleOpen}>{` +${
          categories.length - 1
        }`}</div>
      ),
    [categories, handleOpen]
  );

  const handleCloseChangeCategory = () => setOpenCategory(false);
  const RootCX = cx(style.root, { [style.focused]: open });

  const getScenarioUsers = useMemo(
    () =>
      !!accounts?.length && (
        <div className={style.category} title={accounts[0]}>
          {accounts[0]}
        </div>
      ),
    [accounts]
  );

  const getAllUsers = useMemo(
    () =>
      accounts?.length > 1 ? (
        <div className={style.extraCategories}>{` +${
          accounts.length - 1
        }`}</div>
      ) : null,
    [accounts.length]
  );

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    // e.preventDefault();
    e.dataTransfer.setData("text/plain", item.scenario_id.toString());
    active.current = true;

    if (item.scenario_id) {
      handleDroppedId(item.scenario_id);
      setTimeout(() => {
        if (active.current) handleShowParentFolder(true);
      }, 1000);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (
      item &&
      droppedId &&
      droppedId !== item.scenario_id &&
      typeof droppedId === "number"
    ) {
      onScenarioToAnotherFolder(droppedId as number, item.scenario_id);
      handleDroppedId(null);
    }
    handleShowParentFolder(false);
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    handleShowParentFolder(false);
    active.current = false;
  };

  const onWrapperClick = () => (active.current = true);

  const onMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    if (event.button === 2) {
      active.current = false;
      handleShowParentFolder(false);
    }
  };

  return (
    <div
      className={RootCX}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnd={handleDragEnd}
      onClick={onWrapperClick}
      onMouseUp={onMouseUp}
      draggable
    >
      <div className={style.info}>
        <ScenarioIcon />
        <div className={style.name} onClick={handleClick}>
          {name}
        </div>
      </div>
      <ScenarioServices scenario={item} />
      <div className={style.categories}>
        {getCategories}
        {getTruncatedText}
      </div>
      <div className={style.status}>{getActiveBranches}</div>
      <div className={style.modified}>{dayFrom(modified, i18n.language)}</div>
      <div className={style.categories}>
        {getScenarioUsers}
        {getAllUsers}
      </div>
      <div className={style.action}>
        <ScenarioModals
          item={item}
          onOpen={setOpen}
          isOpenChangeCategoryProp={isOpenChangeCategory}
          onOpenChangeCategory={handleCloseChangeCategory}
        />
      </div>
    </div>
  );
};
