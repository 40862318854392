import {
  ServiceParameterItem,
  IInputServiceParameter,
  InputServiceParameter,
  ServiceParameter,
} from "interfaces/state/serviceParameter";
import * as Const from "../constants";

export const setServiceParamInit = () => {
  return {
    type: Const.SERVICE_PARAM_INIT,
  };
};

export const loadServiceParamSuccess = (
  payload: { [key: string]: ServiceParameterItem },
  scenarioId: string,
  blockId: string
) => {
  return {
    type: Const.SERVICE_PARAM_SUCCESS,
    payload,
    scenarioId,
    blockId,
  };
};

export const setServiceParamLoading = () => {
  return {
    type: Const.SERVICE_PARAM_LOADING,
  };
};

export const setServiceParamFail = () => {
  return {
    type: Const.SERVICE_PARAM_FAIL,
  };
};

export const setInputParameterKeys = (
  scenarioId: string,
  blockId: string,
  payload: string[]
) => {
  return {
    type: Const.SERVICE_KEYS,
    scenarioId,
    blockId,
    payload,
  };
};

export const setInputParameter = (
  scenarioId: string,
  blockId: string,
  payload: { [key: string]: InputServiceParameter }
) => {
  return {
    type: Const.SERVICE_IN_PARAMETER,
    scenarioId,
    blockId,
    payload,
  };
};

export const editInputParameter = (
  scenarioId: string,
  blockId: string,
  path: (string | number)[],
  payload: IInputServiceParameter[]
) => {
  return {
    type: Const.SERVICE_IN_PARAMETER_EDIT,
    scenarioId,
    blockId,
    path,
    payload,
  };
};

export const setInitialServiceParameter = (
  scenarioId: string,
  blockId: string,
  payload: IInputServiceParameter[]
) => {
  return {
    type: Const.SERVICE_SET_INITIAL_PARAM,
    scenarioId,
    blockId,
    payload,
  };
};

export const pushToServiceParameter = (
  scenarioId: string,
  blockId: string,
  payload: any,
  path: (number | string)[]
) => {
  return {
    type: Const.SERVICE_IN_PARAMETER_PUSH,
    scenarioId,
    blockId,
    payload,
    path,
  };
};

export const deleteServiceParameter = (
  scenarioId: string,
  blockId: string,
  path: (number | string)[]
) => {
  return {
    type: Const.SERVICE_IN_PARAMETER_DELETE,
    scenarioId,
    blockId,
    path,
  };
};

export const receiveServiceParameters = (
  scenarioId: string,
  blockId: string,
  payload: { [key: string]: InputServiceParameter }
) => {
  return {
    type: Const.SERVICE_PARAMETERS_RECEIVE,
    scenarioId,
    blockId,
    payload,
  };
};

export const receiveServiceParametersForComponent = (
  scenarioId: string,
  blockId: string,
  payload: { [key: string]: ServiceParameter }
) => {
  return {
    type: Const.SERVICE_PARAMETERS_FOR_COMPONENT_RECEIVE,
    scenarioId,
    blockId,
    payload,
  };
};

export const setServiceParameters = (
  scenarioId: string,
  blockId: string,
  payload: { [key: string]: ServiceParameter }
) => {
  return {
    type: Const.SERVICE_PARAMETERS_VALUE,
    scenarioId,
    blockId,
    payload,
  };
};

export const setAddedNewParameters = (
  blockId: string,
  payload: { [key: string]: ServiceParameter }
) => {
  return {
    type: Const.SERVICE_ADDED_NEW_PARAMS,
    blockId,
    payload,
  };
};
