import * as Const from "../constants";
import { IDebugBlockAnswer } from "interfaces/state/debug";

export const setDebugLoading = (payload: boolean) => {
  return {
    type: Const.DEBUG_LOADING,
    payload,
  };
};

export const setDebugInit = () => {
  return {
    type: Const.DEBUG_INIT,
  };
};

export const setDebugError = () => {
  return {
    type: Const.DEBUG_FAIL,
  };
};

export const setDebugValue = (payload: string) => {
  return {
    type: Const.DEBUG_VALUE,
    payload,
  };
};

export const setDebugErrorReason = (payload: string) => {
  return {
    type: Const.DEBUG_ERROR_REASON,
    payload,
  };
};

export const setDebugIdWhereWasError = (payload: string | null) => {
  return {
    type: Const.DEBUG_ID_WHERE_WAS_ERROR,
    payload,
  };
};

export const setDebugList = (payload: IDebugBlockAnswer[]) => {
  return {
    type: Const.DEBUG_LIST,
    payload,
  };
};
