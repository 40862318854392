// isLoading: false,
//   isError: false,
//   token: "",
//   link: "",
//   isConfirmed: false,

import State from "../interfaces/state";

export const isLoading$ = (state: State) => state.confirm.isLoading;
export const isError$ = (state: State) => state.confirm.isError;
export const isConfirmed$ = (state: State) => state.confirm.isConfirmed;
export const link$ = (state: State) => state.confirm.link;
export const token$ = (state: State) => state.confirm.token;
export const confirmError$ = (state: State) => state.confirm.confirmError;
