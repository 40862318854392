import { WSApi } from "enums/wsApi";
import { useLocation, useNavigate } from "react-router-dom";
import { privateRoute } from "routes";
import { currentBlock$, scenarioItem$ } from "selectors/scenario";
import store from "store";
import { getBlockName, makeScenarioParams } from "utils";
import { AnswerData } from "../index";
import * as ScenarioActions from "actions/scenario";
import * as ScenarioCreators from "creators/scenario";

// WSApi.AddBlock || WSApi.UpdateBlockType
export const blockAdd = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch } = store;
  if (!data?.error) {
    const scenarioItem = scenarioItem$(store.getState());
    if (scenarioItem?.scenario_id === data.response?.scenario_id) {
      const newScenario = {
        scenario: data.response.scenario,
        pipeline: data.response.pipeline,
        variables: data?.response?.variables
          ? data.response.variables
          : scenarioItem?.variables,
      };

      if (data.action === WSApi.AddBlock) {
        if (window.location.pathname.includes(privateRoute.scenario)) {
          const searchParams = new URLSearchParams(window.location.search);
          const isEdit = searchParams.get("edit") === "1";
          const isView = searchParams.get("view") === "1";
          const isDebug = searchParams.get("debug") === "1";
          const isViewVersion = searchParams.get("viewVersion") === "1";
          // const isGrid = searchParams.get("isGrid");
          const selectedBlock = data?.response?.block_id;

          const currBlock = data?.response?.scenario?.find(
            (sc: any) => sc.id === selectedBlock
          );
          dispatch(ScenarioActions.selectBlock(currBlock));
          dispatch(ScenarioActions.setBlockId(selectedBlock));
          const branch = searchParams.get("branch")!;

          if (data.response?.child_block_id) {
            setTimeout(() => {
              dispatch(
                ScenarioCreators.updateMultipleBlockPositions(
                  data.response.block_id as string,
                  data.response.child_block_id
                ) as any
              );
            }, 500);
          }

          navigate(
            `${privateRoute.scenario}?${makeScenarioParams({
              scenario_id: scenarioItem?.scenario_id,
              version_id: scenarioItem?.version_id,
              isEdit,
              isView,
              isDebug,
              isViewVersion,
              selectedBlock,
              branch,
            })}`
          );
        }
      }

      const block = currentBlock$(store.getState());
      const pipeline = scenarioItem?.pipeline;
      const scenario = scenarioItem?.scenario;
      if (pipeline && data.action !== WSApi.UpdateBlockType) {
        const blockIndex = pipeline?.findIndex(
          (p) => p?.id === data.response.block_id
        );

        if (blockIndex >= 0) {
          dispatch(
            ScenarioCreators.updateBlock({
              id: data.response.block_id,
              name: block?.name
                ? block?.name
                : getBlockName(blockIndex + 2, scenario),
            }) as any
          );
        } else {
          dispatch(
            ScenarioCreators.updateBlock({
              id: data.response.block_id,
              name: getBlockName((scenario!.length || 0) + 2, scenario),
            }) as any
          );
        }
      }

      dispatch(ScenarioActions.refreshScenario());
      dispatch(
        ScenarioActions.setScenarioSuccess({
          ...scenarioItem,
          ...newScenario,
        })
      );
    }
  }
};

export const blockUpdateType = blockAdd;
