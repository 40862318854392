import React, { CSSProperties, ReactNode } from "react";
import style from "./button.module.scss";
import cx from "classnames";
import { SizeButton, VariantButton } from "./utils";

interface IProps {
  variant: VariantButton;
  size?: SizeButton;
  children?: string | ReactNode;
  icon?: JSX.Element;
  disabled?: boolean;
  styles?: CSSProperties;
  className?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
}

export const Button = ({
  children,
  variant,
  size,
  disabled,
  icon,
  styles,
  className = "",
  onClick,
  type,
}: IProps) => {
  const RootCX = cx(style.root, {
    [style.primary]: variant === VariantButton.Primary,
    [style.secondary]: variant === VariantButton.Secondary,
    [style.module]: variant === VariantButton.Module,
    [style.back]: variant === VariantButton.Back,
    [style.xl]: size === SizeButton.XL,
    [style.l]: size === SizeButton.L,
    [style.m]: size === SizeButton.M,
    [style.s]: size === SizeButton.S,
    [className]: className,
  });

  return (
    <button
      style={styles}
      className={RootCX}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {!!icon && <div className={style.icon}>{icon}</div>}
      {children}
    </button>
  );
};
