import React from "react";
import State from "interfaces/state";
import AutomationRoutingState, {
  AutomationRoute,
  AutomationRouteType,
} from "interfaces/state/automationRouting";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  automationRouting$,
  limit$,
  offset$,
  sortBy$,
  sortDirection$,
} from "selectors/automationRouting";
import { RouteItem } from "./components/routeItem";
import { ReactComponent as ArrowLeft } from "assets/svg/arrow-left-o.svg";
import * as AutomationRoutingActions from "actions/automationRouting";
import * as FolderCreators from "creators/folders";
import * as ScenariosCreators from "creators/scenario";
import { RouteComponentProps, withRouter } from "utils/router";
import { Button, ButtonVariant } from "components/button";
import { privateRoute } from "routes";
import style from "./routing.module.scss";

interface OwnProps {
  hideButton?: boolean;
}

interface IStateProps {
  automationRouting: AutomationRoute[];
  sortBy: AutomationRoutingState["sort_by"];
  sortDirection: AutomationRoutingState["sort_direction"];
  limit: number;
  offset: number;
}

interface IDispatchProps {
  fetchAllAutomationFiles: (
    id: number,
    sort_by: string,
    sort_direction: string,
    limit: number,
    offset: number
  ) => void;
  fetchScenarioById: (id: string) => void;
  deleteAutomationRouteFromIndex: (index: number) => void;
  setCurrentAutomationRoute: (route: AutomationRoute) => void;
  updatePagination: (
    payload: AutomationRoutingActions.AutomationPaginationPayload
  ) => void;
}

interface IProps
  extends IStateProps,
    IDispatchProps,
    OwnProps,
    RouteComponentProps,
    WithTranslation {}

class Routing extends React.PureComponent<IProps> {
  private onClick = (route: AutomationRoute) => {
    const {
      fetchAllAutomationFiles,
      navigate,
      sortBy,
      sortDirection,
      updatePagination,
    } = this.props;

    if (route.type === AutomationRouteType.FOLDER) {
      navigate(`${privateRoute.automation}?id=${route.id}&page=1`);
      updatePagination({ limit: 8, offset: 0, page: 1 });

      fetchAllAutomationFiles(route.id as number, sortBy, sortDirection, 8, 0);
    }
  };

  handleBackClick = () => {
    const {
      fetchAllAutomationFiles,
      fetchScenarioById,
      deleteAutomationRouteFromIndex,
      setCurrentAutomationRoute,
      navigate,
      automationRouting,
      sortBy,
      sortDirection,
      limit,
      offset,
    } = this.props;

    if (automationRouting.length <= 1) {
      navigate(-1);
      return;
    }

    if (automationRouting.length === 2) {
      navigate(privateRoute.automation);
      fetchAllAutomationFiles(
        Number(automationRouting[0].id),
        sortBy,
        sortDirection,
        limit,
        offset
      );
      deleteAutomationRouteFromIndex(0);
      setCurrentAutomationRoute(automationRouting[0]);
      return;
    }

    if (
      automationRouting.length &&
      automationRouting[automationRouting.length - 2].type ===
        AutomationRouteType.FOLDER
    ) {
      const id = automationRouting[automationRouting.length - 2].id;

      navigate(`${privateRoute.automation}?id=${id}`);

      fetchAllAutomationFiles(
        automationRouting[automationRouting.length - 2].id as number,
        sortBy,
        sortDirection,
        limit,
        offset
      );
    } else if (
      automationRouting.length &&
      automationRouting[automationRouting.length - 2].type ===
        AutomationRouteType.SCENARIO
    ) {
      fetchScenarioById(
        automationRouting[automationRouting.length - 1].id as string
      );
      deleteAutomationRouteFromIndex(automationRouting.length - 2);
      setCurrentAutomationRoute(
        automationRouting[automationRouting.length - 2]
      );
    }
  };

  render() {
    const { t, automationRouting, hideButton } = this.props;
    return (
      <div className={style.root}>
        {!hideButton && (
          <Button
            variant={ButtonVariant.LIGHT_BLUE}
            onClick={this.handleBackClick}
            className={style.btn}
            value={
              <>
                <ArrowLeft className={style.arrowLeft} />
                <span className={style.btnText}>{t("back")}</span>
              </>
            }
          />
        )}

        <div className={style.routeContent}>
          {automationRouting.map((route, index) => {
            return (
              <div key={route.id} className={style.rout}>
                <RouteItem index={index} onClick={this.onClick} route={route} />
                {index !== automationRouting.length - 1 && (
                  <ArrowLeft className={style.slash} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    automationRouting: automationRouting$(state),
    sortBy: sortBy$(state),
    sortDirection: sortDirection$(state),
    limit: limit$(state),
    offset: offset$(state),
  };
};

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  props: OwnProps
) => {
  return {
    ...stateProps,
    fetchAllAutomationFiles: dispatchProps.fetchAllAutomationFiles,
    fetchScenarioById: dispatchProps.fetchScenarioById,
    deleteAutomationRouteFromIndex:
      dispatchProps.deleteAutomationRouteFromIndex,
    setCurrentAutomationRoute: dispatchProps.setCurrentAutomationRoute,
    updatePagination: dispatchProps.updatePagination,
    ...props,
  };
};

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      {
        fetchAllAutomationFiles: FolderCreators.fetchAllAutomationFiles,
        fetchScenarioById: ScenariosCreators.fetchScenarioById,
        deleteAutomationRouteFromIndex:
          AutomationRoutingActions.deleteAutomationRouteFromIndex,
        setCurrentAutomationRoute:
          AutomationRoutingActions.setCurrentAutomationRoute,
        updatePagination: AutomationRoutingActions.updatePagination,
      },
      mergeProps
    )(Routing)
  )
);
