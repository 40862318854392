import * as Const from "../constants";
import { Action } from "redux";
import { CompanyStatus } from "interfaces/state/auth";
import { PlanNameType } from "interfaces/state/billingInfo";

export const setBillingInfoInit = (): Action => {
  return {
    type: Const.BILLING_INFO_INIT,
  };
};

export enum PaymentSystem {
  MASTERCARD = "mc",
  VISA = "visa",
}

export enum Periodicity {
  YEAR = "year",
  MONTH = "month",
}
export interface IBillingInfoParams {
  plan_id: number;
  plan_name: PlanNameType;
  payment_system: PaymentSystem | null;
  mask: string | null;
  periodicity: Periodicity;
  price: number | null;
  max_processing_speed_per_hour: number | null;
  max_members: string | null;
  exp_month: null | number;
  exp_year: null | number;
  number_of_members: number | null;
  status: CompanyStatus | null;
  max_processing_speed_per_second: number | null;
  max_request_size: number | null;
  next_payment: IBillingNextPayment | null;
  price_per_seat: number | null;
  seats: number | null;
  task_queue: number | null;
  transactions_per_month: string | null;
}

export interface IBillingNextPayment {
  company_status: CompanyStatus;
  subscriptions?: IBillingNextPaymentSubscription[];
  next_payment?: string;
  price?: number;
  invoices?: IBillingNextPaymentInvoice[];
  error?: string;
}

export interface IBillingNextPaymentInvoice {
  amount: number;
  date: string;
}

export interface IBillingNextPaymentSubscription {
  period_ended: string;
  period_started: string;
  price: number;
}

export const setBillingInfoSuccess = (payload: IBillingInfoParams) => {
  return {
    type: Const.BILLING_INFO_PARAMS_SUCCESS,
    payload,
  };
};

export const setBillingInfoFail = (): Action => {
  return {
    type: Const.BILLING_INFO_FAIL,
  };
};

export const setBillingInfoLoading = (): Action => {
  return {
    type: Const.BILLING_INFO_LOADING,
  };
};

export const setBillingCSVLink = (payload: string) => {
  return {
    type: Const.BILLINK_CSV_LINK,
    payload,
  };
};
