import { useLocation, useNavigate } from "react-router-dom";
import store from "store";
import { AnswerData } from "../index";
import * as ServicePageActions from "actions/servicesPage";
import * as AdminCreators from "creators/admin";
import { services$, service_count$ } from "selectors/servicesPage";
import { successNotification } from "utils";
import i18next from "i18next";

// WSApi.AdminConnectorUpdate
export const adminConnectorUpdate = (
  data: AnswerData,
  location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch, getState } = store;
  if (!data?.error) {
    const responseId = data.response?.connector[0]?.id;

    if (responseId && location.pathname.includes(responseId)) {
      successNotification(i18next.t("addService:changes_saved_successfully"));
    } else {
    }
  } else {
    dispatch(ServicePageActions.setServicePageFail());
  }
};
