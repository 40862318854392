import React from "react";
import style from "./deleteCubeModal.module.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, ButtonVariant } from "components/button";

interface OwnProps {
  onClose: () => void;
  onSubmit: () => void;
}

interface IProps extends OwnProps, WithTranslation {}
class DeleteBlockModalComponent extends React.PureComponent<IProps> {
  onApply = () => {
    this.props.onSubmit();
  };

  onCancel = () => {
    this.props.onClose();
  };

  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const { t } = this.props;
    return (
      <div className={style.root}>
        <form className={style.box} onSubmit={this.onSubmit}>
          <div className={style.head}>{t("deleteBlock")}</div>
          <div className={style.text}>{t("deleteBlockText")}</div>

          <Button
            variant={ButtonVariant.BLUE_WHITE}
            className={style.apply}
            onClick={this.onApply}
            value={"OK"}
            type="submit"
          />

          <Button
            variant={ButtonVariant.WHITE_RED}
            className={style.cancel}
            onClick={this.onCancel}
            value={t("cancel")}
          />
        </form>
      </div>
    );
  }
}

export const DeleteBlockModal = withTranslation()(DeleteBlockModalComponent);
