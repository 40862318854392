import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

/** @deprecated Use `React Router hooks` instead */
export interface RouteComponentProps {
  location: ReturnType<typeof useLocation>;
  params: Record<string, string>;
  navigate: ReturnType<typeof useNavigate>;
  searchParams: URLSearchParams;
}
/** @deprecated Use `React Router hooks` instead */
export const withRouter = <Props extends RouteComponentProps>(
  Component: React.ComponentType<Props>
) => {
  return (props: Omit<Props, keyof RouteComponentProps>) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    return (
      <Component
        {...(props as Props)}
        location={location}
        params={params}
        navigate={navigate}
        searchParams={searchParams}
      />
    );
  };
};
