import * as Const from "../constants";
import State from "../interfaces/state/sendPass";

export const initState: State = {
  isLoading: false,
  isError: false,
};

export default function sendPass(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.SEND_PASS_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.SEND_PASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.SEND_PASS_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.SEND_PASS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
