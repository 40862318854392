import * as Const from "../constants";
import State, {
  ScheduleContentAction,
} from "../interfaces/state/scheduleContent";

export const initState: State = {
  isLoading: false,
  isError: false,
  schedule: null,
};

export default function scheduleContent(
  state: State = initState,
  action: ScheduleContentAction
): State {
  switch (action.type) {
    case Const.SCHEDULE_CONTENT_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.SCHEDULE_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        schedule: action.payload,
      };

    case Const.SCHEDULE_CONTENT_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.SCHEDULE_CONTENT_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
