import store from "store";
import { AnswerData } from "../index";
import * as AuthActions from "actions/auth";

// WSApi.DeleteUserAvatar
export const accountDeleteAvatar = (data: AnswerData) => {
  if (!data?.error) {
    store.dispatch(AuthActions.setAuthAvatarUrl(undefined));
  }
};
