import { useLocation, useNavigate } from "react-router-dom";
import store from "store";
import { AnswerData } from "../index";
import * as ServicePageActions from "actions/servicesPage";
import { services$, service_count$ } from "selectors/servicesPage";
import { setConnectorItem } from "actions/connectorItem";
import { connectorItem$ } from "selectors/connectorItem";

// WSApi.ConnectorsUpdateStatus
export const adminConnectorsUpdateStatus = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  _navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch, getState } = store;
  if (!data?.error) {
    const services = services$(getState());
    const service_count = service_count$(getState());
    const connectorItem = connectorItem$(getState());

    if (connectorItem) {
      dispatch(setConnectorItem({ ...connectorItem, ...data.response }));
    }

    const index = services.findIndex(
      (service) => service.id === data?.response?.id
    );

    if (index >= 0) {
      const newServices = [...services];
      newServices[index] = data.response;

      dispatch(
        ServicePageActions.loadServicePageSuccess({
          connectors: newServices,
          total_connectors: service_count,
        })
      );
    }
  } else {
    dispatch(ServicePageActions.setServicePageFail());
  }
};
