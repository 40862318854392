import * as Const from "../constants";
import State from "../interfaces/state/journalEvents";

export const initState: State = {
  isLoading: false,
  isError: false,
  list: [],
  limit: 50,
  offset: 0,
  hasMore: false,
  editableVersionId: null,
};

export default function journalEvents(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.JOURNAL_EVENTS_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.JOURNAL_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: action.payload,
      };

    case Const.JOURNAL_EVENTS_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.JOURNAL_EVENTS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.JOURNAL_EVENTS_PAGINATION:
      const { offset } = action.payload;
      return {
        ...state,
        offset,
      };

    case Const.JOURNAL_EVENTS_HAS_MORE:
      return {
        ...state,
        hasMore: action.payload,
      };

    case Const.JOURNAL_EVENTS_EDITABLE_VERSION:
      return {
        ...state,
        editableVersionId: action.payload,
      };

    default:
      return state;
  }
}
