import * as Const from "../constants";
import State, { CompanyStatus, StatusSession } from "../interfaces/state/auth";
import { setCookieApply } from "interceptors";
import * as Interceptors from "interceptors/index";
import { IParams } from "actions/auth";

export const initState: State = {
  isLoading: false,
  isError: false,
  isAuth: false,
  sid: "",
  status_session: StatusSession.INITIAL,
  email: undefined,
  first_name: undefined,
  avatar_url: undefined,
  company_name: null,
  company_role: undefined,
  company_status: CompanyStatus.ACTIVE,
  member_id: 0,
  consent_on_cookie: Interceptors.getCookieApply(),
  plan_id: undefined,
  plan_name: undefined,
  isNeedToHideHeader: false,
  current_period_end: "",
  isValidToken: false,
  isOpenHelpCenterModal: false,
  language: null,
  role: null,
};

export default function auth(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.AUTH_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
        status_session: StatusSession.UNKNOWN,
      };

    case Const.AUTH_PARAMS_SUCCESS:
      const {
        sid,
        status,
        email,
        first_name,
        company_name,
        plan_name,
        plan_id,
        avatar_url,
        company_role,
        consent_on_cookie,
        current_period_end,
        company_status,
        member_id,
        language,
        role,
      } = action.payload as IParams;

      return {
        ...state,
        isLoading: false,
        isError: false,
        sid,
        status_session: status,
        email,
        first_name,
        plan_name,
        plan_id,
        avatar_url,
        company_role,
        consent_on_cookie,
        company_name,
        current_period_end,
        company_status,
        member_id,
        language,
        role
      };

    case Const.AUTH_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
        isAuth: false,
      };

    case Const.AUTH_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.AUTH_END_OF_LOADING:
      return {
        ...state,
        isLoading: false,
        isAuth: true,
      };

    case Const.AUTH_SESSION_STATUS:
      return {
        ...state,
        status_session: action.payload,
      };

    case Const.AUTH_SET_NEW_NAME_FROM_WS:
      return {
        ...state,
        first_name: action.payload,
      };

    case Const.AUTH_HIDE_HEADER:
      return {
        ...state,
        isNeedToHideHeader: action.payload,
      };

    case Const.AUTH_SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };

    case Const.AUTH_AVATAR_URL: {
      return {
        ...state,
        avatar_url: action.payload,
      };
    }

    case Const.AUTH_APPLY_COOKIE: {
      setCookieApply(action.payload);
      return {
        ...state,
        consent_on_cookie: action.payload,
      };
    }

    case Const.AUTH_SET_COMPANY_NAME: {
      return {
        ...state,
        company_name: action.payload,
      };
    }

    case Const.AUTH_TOKEN_VALIDATION: {
      return {
        ...state,
        isValidToken: action.payload,
      };
    }

    case Const.AUTH_ERROR_PAYLOAD: {
      return {
        ...state,
        isError: action.payload,
      };
    }

    case Const.AUTH_COMPANY_ROLE: {
      return {
        ...state,
        company_role: action.payload,
      };
    }

    case Const.AUTH_HELP_CENTER_MODAL: {
      return {
        ...state,
        isOpenHelpCenterModal: action.payload,
      };
    }

    default:
      return state;
  }
}
