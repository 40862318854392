import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as FoldersActions from "actions/folders";
import * as AutomationRouteActions from "actions/automationRouting";
import { automationRouting$ } from "selectors/automationRouting";
import { folders$ } from "selectors/folders";
import { Folder } from "interfaces/state/folders";

// WSApi.RenameFolder
export const folderRename = (data: AnswerData) => {
  const { dispatch, getState } = store;

  if (data?.error) {
    errorFromResponse(data.error);
    dispatch(FoldersActions.setFoldersError());
  } else {
    const { id, new_name } = data.response;
    const routing = automationRouting$(getState());
    const folders = [...folders$(getState())];

    const folderIndex = folders.findIndex(
      (folder) => (folder as Folder).id === id
    );

    if (folderIndex >= 0) {
      folders[folderIndex].name = new_name;
      dispatch(FoldersActions.setFoldersSuccess(folders));
    }

    const index = routing.findIndex((route) => route.id === id);

    if (index >= 0) {
      routing[index].title = new_name;
      const newRouting = [...routing];
      dispatch(AutomationRouteActions.setAutomationRouting(newRouting));
    }
  }
};
