import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { AnswerData } from "../index";
import * as ScenarioActions from "actions/scenario";
import {
  num_of_items$,
  scenarioVersions$,
  tags$,
  users$,
} from "selectors/scenarioVersion";
import { IScenarioVersionUser } from "interfaces/state/scenarioVersion";
import * as ScenarioVersionActions from "actions/scenarioVersion";
import { getPaginationItemsCount, makeScenarioParams } from "utils";
import { useLocation, useNavigate } from "react-router-dom";
import { privateRoute } from "routes";

// WSApi.CreateScenarioVersion
export const scenarioVersionCreate = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const scenario = scenarioItem$(store.getState());
  if (data?.error) return;

  if (data.response.scenario_id === scenario?.scenario_id) {
    store.dispatch(
      ScenarioActions.setScenarioSuccess({
        ...scenario,
        version_id: data.response.version_without_tag.id,
      })
    );

    const scenarioVersions = scenarioVersions$(store.getState());

    const tags = tags$(store.getState());
    const newTags = data.response?.version_with_tag?.tag
      ? [data.response?.version_with_tag?.tag, ...tags]
      : tags;

    const users = users$(store.getState());
    if (data.response?.version_with_tag) {
      let newAuthors: IScenarioVersionUser[] = [];

      [data.response.version_with_tag.authors, ...users].forEach((author) => {
        const isIncludes = newAuthors.some(
          (a) => author.author_id === a.author_id
        );
        if (!isIncludes) {
          newAuthors.push(author);
        }
      });

      store.dispatch(
        ScenarioVersionActions.setScenarioVersionUsers(newAuthors)
      );
    }

    const versionContainer = document.querySelector("#version-container");
    const number_of_items = num_of_items$(store.getState());

    const newItemsCount = data.response?.version_with_tag
      ? number_of_items + 2
      : number_of_items + 1;
    store.dispatch(
      ScenarioVersionActions.setScenarioVersionItems(newItemsCount)
    );
    store.dispatch(ScenarioVersionActions.setScenarioVersionTags(newTags));

    const newVersions = data.response?.version_with_tag
      ? [data.response.version_without_tag, data.response.version_with_tag]
      : [data.response.version_without_tag];
    if (versionContainer?.clientHeight) {
      const maxItemsPerPage = getPaginationItemsCount(
        versionContainer!.clientHeight!
      );

      if (scenarioVersions.length >= maxItemsPerPage) {
        const newVersionList = [...newVersions, ...scenarioVersions].slice(
          0,
          data.response?.version_with_tag ? -2 : -1
        );
        store.dispatch(
          ScenarioVersionActions.setScenarioVersionList(newVersionList)
        );
      } else {
        const newScenarioVersions = [...newVersions, ...scenarioVersions];
        store.dispatch(
          ScenarioVersionActions.setScenarioVersionList(newScenarioVersions)
        );
      }
    } else {
      const newScenarioVersions = [...newVersions, ...scenarioVersions];
      store.dispatch(
        ScenarioVersionActions.setScenarioVersionList(newScenarioVersions)
      );
    }

    store.dispatch(ScenarioVersionActions.setScenarioVersionLoading(false));
    const id = scenario?.scenario_id;
    const searchParams = new URLSearchParams(window.location.search);
    const isEdit = searchParams.get("edit") === "1";
    const isView = searchParams.get("view") === "1";
    const isDebug = searchParams.get("debug") === "1";
    const isViewVersion = searchParams.get("viewVersion") === "1";
    const branch = searchParams.get("branch")!;

    navigate(
      `${privateRoute.scenario}?${makeScenarioParams({
        scenario_id: id,
        version_id: data.response?.version_without_tag?.id,
        isEdit,
        isView,
        isDebug,
        isViewVersion,
        selectedBlock: null,
        branch,
      })}`
    );
  }
};
