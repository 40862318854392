import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as FoldersActions from "actions/folders";
import * as FolderCreators from "creators/folders";
import { currentAutomationRoute$ } from "selectors/automationRouting";

// WSApi.FolderMoveFolderToFolder
export const folderMoveFolderToFolder = (data: AnswerData) => {
  const { dispatch, getState } = store;

  if (data.error) {
    errorFromResponse(data.error);
    dispatch(FoldersActions.setFoldersError());
  } else {
    currentAutomationRoute$(getState())?.id &&
      dispatch(
        FolderCreators.fetchAllAutomationFiles(
          +currentAutomationRoute$(getState())!.id
        )
      );
  }
};
