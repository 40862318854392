import uniq from "lodash-es/uniq";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "i18n";
import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import * as ScenarioActions from "actions/scenario";
import * as ScenarioVersionActions from "actions/scenarioVersion";
import { AnswerData } from "..";
import { errorFromResponse, showDialogue } from "utils";
import { privateRoute } from "routes";
import { scenarioVersions$ } from "selectors/scenarioVersion";
import { BranchType, Scenario } from "interfaces/state/scenario";
import { getUnfilledBlocks } from "interceptors/scenario";

//  WSApi.ScenarioStart
export const scenarioStart = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (!data.error) {
    const handleScenarioActiveBranches = (
      branch: BranchType,
      scenario: Scenario
    ) => {
      if (!scenario) return [branch];
      const newActiveBranches = uniq([...scenario?.active_branches, branch]);

      return newActiveBranches;
    };
    const scenario = scenarioItem$(store.getState());
    if (data.response?.scenario_id === scenario?.scenario_id) {
      const scenario_status =
        data.response.branch === "live"
          ? data.response.status
          : scenario?.scenario_status;

      const newScenario = {
        ...scenario,
        scenario_status,
        version_id:
          data.response.version_id === data.response?.new_version_id ||
          !data.response?.new_version_id
            ? scenario?.version_id
            : data.response?.new_version_id,
        active_branches: handleScenarioActiveBranches(
          data.response.branch,
          scenario as Scenario
        ),
      };

      const scenario_id = data.response?.scenario_id;

      if (data?.response?.branch === "live") {
        const processing = {
          [scenario_id]: {
            scenario_id,
            isLoading: false,
          },
        };
        store.dispatch(ScenarioActions.setScenarioProcessing(processing));
      }

      store.dispatch(
        ScenarioActions.setScenarioSuccess(newScenario as Scenario)
      );

      const scenarioVersions = scenarioVersions$(store.getState());

      const oldStartedVersionOnCurrentBranchIndex = scenarioVersions.findIndex(
        (sc) => sc.active_branches.includes(data.response.branch)
      );
      if (oldStartedVersionOnCurrentBranchIndex >= 0) {
        scenarioVersions[
          oldStartedVersionOnCurrentBranchIndex
        ].active_branches = scenarioVersions[
          oldStartedVersionOnCurrentBranchIndex
        ].active_branches.filter((br) => br !== data.response.branch);
      }

      const versIndex = scenarioVersions?.findIndex(
        (v) => v.id === data.response.version_id
      );
      if (versIndex < 0) return;

      scenarioVersions[versIndex].active_branches = [
        ...scenarioVersions[versIndex].active_branches,
        data.response.branch,
      ];
      scenarioVersions[versIndex].successfully_started = true;

      store.dispatch(
        ScenarioVersionActions.setScenarioVersionList([...scenarioVersions])
      );
    }
  } else {
    const scenario = scenarioItem$(store.getState());
    const scenario_id = data?.scenario_id || scenario?.scenario_id;
    const processing = {
      [scenario_id]: {
        scenario_id,
        isLoading: false,
      },
    };

    if (
      data.error === "scenario_does_not_exist_or_you_dont_have_enough_rights"
    ) {
      showDialogue({
        title: i18n.t("error"),
        text: i18n.t("scenario_does_not_exist_or_you_dont_have_enough_rights"),
        confirmButtonText: i18n.t("ok"),
        showCancelButton: false,
        confirmButtonColor: "#2084e1",
      }).then(() => {
        navigate(privateRoute.automation);
      });
    }

    if (data.error === "invalid_id") {
      showDialogue({
        title: i18n.t("error"),
        text: i18n.t("invalid_scenario_link"),
        confirmButtonText: i18n.t("ok"),
        showCancelButton: false,
        confirmButtonColor: "#2084e1",
      }).then(() => {
        navigate(privateRoute.automation);
      });
    }

    if (scenario_id) {
      store.dispatch(ScenarioActions.setScenarioProcessing(processing));
    }

    if (data?.error?.reason) {
      errorFromResponse(data.error?.reason);
    }

    if (data?.error?.unfilled_blocks) {
      getUnfilledBlocks(
        data?.error?.unfilled_blocks as [],
        data?.error.scenario_id as string,
        data?.error?.editable_version_id as string,
        navigate
      );
    }
  }
};
