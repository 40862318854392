import { useLocation, useNavigate } from "react-router-dom";
import store from "store";
import * as AuthCreators from "creators/auth";
import * as WSConnectAction from "actions/wsConnect";

import { AnswerData } from "../index";
import { isLoading$, status_session$ } from "selectors/auth";
import { StatusSession } from "interfaces/state/auth";

// WSApi.LogOut
export const accountLogOut = (
  _data: AnswerData,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { getState, dispatch } = store;
  const status_session = status_session$(getState());
  const isLoading = isLoading$(getState());

  if (status_session === StatusSession.OPEN && !isLoading) {
    dispatch(
      AuthCreators.logOut(
        location,
        navigate,
        searchParams,
        searchParams.get("token")
      ) as any
    );
    dispatch(WSConnectAction.setWSConnected(false));
    
    setTimeout(() => window.location.reload(), 200);
  }
};
