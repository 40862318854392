import store from "store";
import { AnswerData } from "../index";
import { member_id$ } from "selectors/auth";
import { team$ } from "selectors/team";
import * as AuthActions from "actions/auth";
import * as TeamActions from "actions/team";

// WSApi.AccountChangeAvatar
export const accountChangeAvatar = (data: AnswerData) => {
  const { dispatch, getState } = store;
  if (!data?.error) {
    dispatch(AuthActions.setAuthAvatarUrl(data.response));

    const memberId = member_id$(getState());
    const team = team$(getState());
    const index = team.findIndex((member) => member.member_id === memberId);

    const newTeam = [...team];
    newTeam[index].avatar_url = data.response;

    dispatch(TeamActions.setTeamList(newTeam));
  }
};
