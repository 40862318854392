import { ServiceParameterType } from "interfaces/state/serviceParameter";

export const templateParameters = [
  ServiceParameterType.NUMBER,
  ServiceParameterType.INTEGER,
  ServiceParameterType.LIST_OF_INTEGERS,
  ServiceParameterType.ARRAY,
  ServiceParameterType.ENUM,
  ServiceParameterType.BINARY,
  ServiceParameterType.POSITIVE_INTEGER,
  ServiceParameterType.DECIMAL,
  ServiceParameterType.LIST_OF_STRINGS,
  ServiceParameterType.LIST_OF_LISTS,
  ServiceParameterType.EMAIL,
];

export const nestedObjectParameters = [
  ServiceParameterType.NESTED_OBJECT,
  ServiceParameterType.ARRAY_OF_NESTED_OBJECTS,
  ServiceParameterType.LIST_OF_NESTED_OBJECTS,
];

export const stringParameters = [
  ServiceParameterType.NOT_EMPTY_LIST,
  ServiceParameterType.STRING,
];

export const booleanParameters = [ServiceParameterType.BOOLEAN];
export const oneOfParameters = [ServiceParameterType.ONE_OF];
export const listParameters = [ServiceParameterType.LIST];
