import store from "store";
import * as AuthActions from "actions/auth";
import { errorFromResponse, successNotification } from "utils";
import i18n from "i18n";
import { AnswerData } from "../index";

// WSApi.ChangeFirstName
export const accountChangeFirstName = (data: AnswerData) => {
  if (data.error) {
    errorFromResponse(data.error);
  } else {
    store.dispatch(AuthActions.setNewNameFromWS(data.response.first_name));
    successNotification(
      i18n.t("changedNameSuccess"),
      i18n.t("congratulations")
    );
  }
};
