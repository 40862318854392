import React, { FC } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowLeftIcon } from "assets/svg/new-svg/24x24/arrow-left.svg";

import style from "./emptyFolder.module.scss";

interface IProps {
  onClick: () => void;
  isNeedToShowBtn: boolean;
  search: string;
}

export const EmptyFolder: FC<IProps> = ({
  onClick,
  isNeedToShowBtn,
  search,
}) => {
  const { t } = useTranslation();

  const TextCX = cx(style.text, {
    [style.withoutBtn]: !isNeedToShowBtn,
  });

  return (
    <div className={style.root}>
      <div className={TextCX}>
        {search.length ? t("emptySearch") : t("emptyFolder")}
      </div>
      {isNeedToShowBtn && (
        <button className={style.btn} onClick={onClick}>
          <ArrowLeftIcon />
          <span className={style.btnText}>{t("back")}</span>
        </button>
      )}
    </div>
  );
};
