import * as Const from "../constants";

export const setUpdateSubscriptionInit = () => {
  return {
    type: Const.UPD_SUBS_INIT,
  };
};

interface IUpdateSubscriptionResponse {
  data: string;
  signature: string;
}
export const setUpdateSubscriptionData = (
  payload: IUpdateSubscriptionResponse
) => {
  return {
    type: Const.UPD_SUBS_SUCCESS,
    payload,
  };
};

export const setUpdateSubscriptionFail = () => {
  return {
    type: Const.UPD_SUBS_FAIL,
  };
};

export const setUpdateSubscriptionLoading = () => {
  return {
    type: Const.UPD_SUBS_LOADING,
  };
};
