import { CompanyStatus, StatusSession } from "interfaces/state/auth";
import { MemberRole } from "interfaces/state/team";
import * as Const from "../constants";

export const setAuthInit = () => {
  return {
    type: Const.AUTH_INIT,
  };
};

export type AppRole = "admin" | "user";

export interface IParams {
  sid: string;
  status: StatusSession;
  email?: string;
  first_name?: string;
  avatar_url?: string;
  company_name: string | null;
  company_role: MemberRole;
  company_status: CompanyStatus;
  consent_on_cookie: boolean;
  language: string;
  plan_id: number;
  current_period_end: string;
  plan_name: string;
  member_id: number;
  role: AppRole;
}

export const setAuthParams = (payload: IParams) => {
  return {
    type: Const.AUTH_PARAMS_SUCCESS,
    payload,
  };
};

export const setAuthFail = () => {
  return {
    type: Const.AUTH_FAIL,
  };
};

export const setAuthLoading = () => {
  return {
    type: Const.AUTH_LOADING,
  };
};

export const setAuthLoadingEnd = () => {
  return {
    type: Const.AUTH_END_OF_LOADING,
  };
};

export const setSessionStatus = (payload: string) => {
  return {
    type: Const.AUTH_SESSION_STATUS,
    payload,
  };
};

export const setInitialApp = () => {
  return {
    type: Const.LOG_OUT,
  };
};

export const setNewNameFromWS = (payload: string) => {
  return {
    type: Const.AUTH_SET_NEW_NAME_FROM_WS,
    payload,
  };
};

export const hideHeader = (payload: boolean) => {
  return {
    type: Const.AUTH_HIDE_HEADER,
    payload,
  };
};

export const setEmail = (payload: string) => {
  return {
    type: Const.AUTH_SET_EMAIL,
    payload,
  };
};

export const setAuthAvatarUrl = (payload: string | undefined) => {
  return {
    type: Const.AUTH_AVATAR_URL,
    payload,
  };
};

export const applyCookie = (payload: boolean) => {
  return {
    type: Const.AUTH_APPLY_COOKIE,
    payload,
  };
};

export const setCompanyName = (payload: string | null) => {
  return {
    type: Const.AUTH_SET_COMPANY_NAME,
    payload,
  };
};

export const tokenValidation = (payload: boolean) => {
  return {
    type: Const.AUTH_TOKEN_VALIDATION,
    payload,
  };
};

export const setAuthError = (payload: boolean) => {
  return {
    type: Const.AUTH_ERROR_PAYLOAD,
    payload,
  };
};

export const updateCompanyRole = (payload: MemberRole) => {
  return {
    type: Const.AUTH_COMPANY_ROLE,
    payload,
  };
};

export const setOpenHelpCenterModal = (payload: boolean) => {
  return {
    type: Const.AUTH_HELP_CENTER_MODAL,
    payload,
  };
};
