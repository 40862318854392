import * as Const from "../constants";
import { IReply } from "interfaces/state/methodReply";

export const setAvailReplyMethodsInit = () => {
  return {
    type: Const.AVAIL_R_METHODS_INIT,
  };
};

export const setAvailReplyMethodsList = (
  payload: IReply[],
  scenario_id: string = "test",
  block_id: string = "test"
) => {
  return {
    type: Const.AVAIL_R_METHODS_SUCCESS,
    payload,
    scenario_id,
    block_id,
  };
};

export const setAvailReplyMethodsListFail = () => {
  return {
    type: Const.AVAIL_R_METHODS_FAIL,
  };
};

export const setAvailReplyMethodsListLoading = () => {
  return {
    type: Const.AVAIL_R_METHODS_LOADING,
  };
};
