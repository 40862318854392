import { useLocation, useNavigate } from "react-router-dom";
import { scenarioItem$ } from "selectors/scenario";
import { num_of_items$, scenarioVersions$ } from "selectors/scenarioVersion";
import store from "store";
import { AnswerData } from "../index";
import * as ScenarioVersionActions from "actions/scenarioVersion";
import * as ScenarioActions from "actions/scenario";
import * as ScenarioCreators from "creators/scenario";
import { privateRoute } from "routes";
import { getPaginationItemsCount, makeScenarioParams } from "utils";
import { Branch } from "interfaces/state/scenario";

// WSApi.DeleteScenarioVersion
export const scenarioVersionDelete = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (!data?.error) {
    const { getState, dispatch } = store;
    const scenario = scenarioItem$(getState());
    if (data.response.scenario_id === scenario?.scenario_id) {
      const scenarioVersions = scenarioVersions$(getState());
      const newScenarioVersions = scenarioVersions.filter(
        (version) => version.id !== data.response.version_id
      );

      if (!newScenarioVersions.length) {
        const containerHeight =
          document.getElementById("version-container")?.clientHeight;

        const maxItemsPerPage = getPaginationItemsCount(containerHeight!);

        dispatch(ScenarioVersionActions.updateScenarioBranch(Branch.DEVEL));
        dispatch(ScenarioVersionActions.updateScenarioVersionPage(1));
        dispatch(
          ScenarioCreators.fetchAllScenarioVersions(
            maxItemsPerPage,
            0,
            undefined,
            undefined,
            undefined,
            "devel"
          )
        );
        dispatch(ScenarioVersionActions.setLastVersionItem(true));
      } else {
        const activeVersion = newScenarioVersions[0];

        if (
          activeVersion?.id &&
          scenario?.version_id === data.response.version_id
        ) {
          const searchParams = new URLSearchParams(window.location.search);
          const id = scenario?.scenario_id;
          const isEdit = searchParams.get("edit") === "1";
          const isView = searchParams.get("view") === "1";
          const isDebug = searchParams.get("debug") === "1";
          const isViewVersion = searchParams.get("viewVersion") === "1";
          const number_of_items = num_of_items$(getState());
          const branch = searchParams.get("branch")!;
          const current_version_id = searchParams.get("version");

          dispatch(
            ScenarioVersionActions.setScenarioVersionItems(
              number_of_items - 1 || 0
            )
          );

          dispatch(
            ScenarioActions.setScenarioSuccess({
              ...scenario,
              version_id: activeVersion?.id,
            })
          );

          navigate(
            `${privateRoute.scenario}?${makeScenarioParams({
              scenario_id: id,
              version_id: activeVersion?.id,
              isEdit,
              isView,
              isDebug,
              isViewVersion,
              selectedBlock: null,
              branch,
            })}`
          );

          if (current_version_id === data.response.version_id) {
            dispatch(
              ScenarioCreators.fetchScenarioById(id!, true, activeVersion?.id)
            );
          }
        }
      }

      dispatch(
        ScenarioVersionActions.setScenarioVersionList(newScenarioVersions)
      );
      dispatch(ScenarioVersionActions.setScenarioVersionLoading(false));
    }
  }
};
