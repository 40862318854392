export const sortItems = [
  {
    id: 0,
    name: "automation:name",
  },
  {
    id: 1,
    name: "automation:foldersFirst",
  },
  {
    id: 2,
    name: "automation:scenariosFirst",
  },
  {
    id: 3,
    name: "automation:newFirst",
  },
  {
    id: 4,
    name: "automation:oldFirst",
  },
];

export const tableHeaderItems = [
  "name",
  "services",
  "category",
  "status",
  "modified",
  "members",
];

export const categoryHeaders = ["name", "action"];

export const overlayCategories = ["renameCategory", "deleteCategory"];

export const getSortDirection = (direction: string) => {
  interface DirectionResponse {
    [value: string]: { type: string; direction: string };
  }

  const result: DirectionResponse = {
    name: { type: "name", direction: "up" },
    foldersFirst: { type: "folder", direction: "up" },
    scenariosFirst: { type: "scenario", direction: "up" },
    newFirst: { type: "modified", direction: "down" },
    oldFirst: { type: "modified", direction: "up" },
  };

  return direction in result ? result?.[direction] : result.name;
};
