import i18n from "i18n";
import store from "store";
import { errorFromResponse, successNotification } from "utils";
import { AnswerData } from "../index";
import * as AvailableRolesActions from "actions/availableRoles";
import * as PaymentHistoryActions from "actions/paymentHistory";
import { paymentsHistory$ } from "selectors/paymentHistory";

// WSApi.DeletePayment
export const paymentDelete = (data: AnswerData) => {
  const { dispatch, getState } = store;
  if (data.error) {
    store.dispatch(AvailableRolesActions.setAvailableRolesListFail());
    errorFromResponse(data.error);
  } else {
    if (data.response.payment_id) {
      const paymentHistory = paymentsHistory$(getState());
      const payment_id = data.response.payment_id;
      const newPaymentHistory = paymentHistory.filter(
        (ph) => ph.payment_id !== payment_id
      );

      dispatch(
        PaymentHistoryActions.setPaymentHistorySuccess(newPaymentHistory)
      );

      successNotification(i18n.t("entryDebitDeleted"), "", 8000);
    }
  }
};
