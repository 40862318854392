import React, { MouseEvent } from "react";
import cx from "classnames";
import { withTranslation, WithTranslation } from "react-i18next";
import { Folder as IFolder } from "interfaces/state/folders";
import { dayFrom } from "utils";
import { DropDownItem } from "pages/scenario/components/dropdownItem";
import { FolderModals } from "../folderModals";
import { Popup } from "components/popup";
import style from "./folder.module.scss";

interface IStateProps {}

interface OwnProps {
  folder: IFolder;
  droppedId: null | string | number;
  onClick: (folder: IFolder) => void;
  rename: (folder_id: number, payload: string) => void;
  deleteFolder: (folder_id: number) => void;
  handleShowParentFolder: (value: boolean) => void;
  onFolderToAnotherFolder: (id: number, new_parent_id: number) => void;
  handleDroppedId: (val: null | string | number) => void;
  onScenarioToAnotherFolder: (id: number, scenario: number | string) => void;
  onDropEnd: () => void;
}

interface IDispatchProps {}

interface IProps
  extends IStateProps,
    OwnProps,
    IDispatchProps,
    WithTranslation {}

class FolderComponent extends React.Component<IProps> {
  state = {
    isEditPopupOpen: false,
    isDeletePopupOpen: false,
    isOpen: false,
    name: this.props.folder.name,
    active: false,
  };

  componentDidUpdate(_pr, ps) {
    if (ps.active && this.state.active) {
      this.props.handleShowParentFolder(false);
    }
  }

  onEditOpen = () => {
    this.setState({ isEditPopupOpen: true });
  };

  onCloseEditPopup = () => this.setState({ isEditPopupOpen: false });

  onDeleteOpen = () => {
    this.setState({ isDeletePopupOpen: true });
  };

  onCloseDeletePopup = () => this.setState({ isDeletePopupOpen: false });

  onFolderClick = () => {
    const { onClick, folder } = this.props;
    !this.state.isOpen && onClick(folder);
  };

  onSubmitRename = () => {
    const { rename, folder } = this.props;
    rename(folder.id, this.state.name);
    this.onCloseEditPopup();
  };

  onSubmitDelete = () => {
    const { deleteFolder, folder } = this.props;
    deleteFolder(folder.id);
    this.onCloseDeletePopup();
  };

  btns = [
    {
      className: style.dropdownItem,
      text: this.props.t("automation:renameFolder"),
      onClick: this.onEditOpen,
    },
    {
      className: style.dropdownItem,
      text: this.props.t("automation:deleteFolder"),
      onClick: this.onDeleteOpen,
    },
  ];

  overlay = () => {
    const { language } = this.props.i18n;

    const ContentCX = cx(style.content, {
      [style.uk]: language === "uk",
      [style.en]: language === "en",
    });

    return (
      <div className={ContentCX}>
        <div className={style.triangle} />
        <div className={style.contentBtns}>
          {this.btns.map((button) => (
            <DropDownItem key={button.text} {...button} />
          ))}
        </div>
      </div>
    );
  };

  handleOpenPopover = (isOpen: boolean) =>
    setTimeout(() => this.setState({ isOpen }), 200);

  handleNameChange = (name: string) => this.setState({ name });

  render() {
    const { folder, i18n, t, droppedId } = this.props;
    const RootCX = cx(style.root, {
      [style.focused]: this.state.isOpen || droppedId === folder.id,
    });

    const TopLabelCX = cx(style.topLabel, {});

    const MiddleLabelCX = cx(style.middleLabel, {});

    const BodyCX = cx(style.body, {});

    const FooterCX = cx(style.footer, {});

    const handleDragStart = (
      e: React.DragEvent<HTMLDivElement>,
      item: IFolder
    ) => {
      // e.preventDefault();
      e.dataTransfer.setData("text/plain", item.id.toString());
      this.setState({ active: true });

      if (item.id) {
        this.props.handleDroppedId(item.id);
        setTimeout(() => {
          if (this.state.active) this.props.handleShowParentFolder(true);
        }, 1000);
      }
    };

    const handleDragOver = (
      e: React.DragEvent<HTMLDivElement>,
      item: IFolder
    ) => {
      e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>, item: IFolder) => {
      e.preventDefault();

      if (item && this.props.droppedId && this.props.droppedId !== item.id) {
        if (typeof this.props.droppedId === "string") {
          this.props.onScenarioToAnotherFolder(item.id, this.props.droppedId);
        } else {
          this.props.onFolderToAnotherFolder(
            this.props.droppedId as number,
            item.id
          );
        }
      }
      this.props.handleDroppedId(null);
      this.props.handleShowParentFolder(false);
    };

    const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      this.props.handleShowParentFolder(false);
      this.props.onDropEnd();
      this.setState({ active: false });
    };

    const onWrapperClick = () => this.setState({ active: true });
    const onMouseUp = (event: MouseEvent<HTMLDivElement>) => {
      if (event.button === 2) {
        this.props.handleShowParentFolder(false);

        this.setState({ active: false });
      }
    };

    return (
      <div
        className={RootCX}
        draggable
        onDragStart={(e) => handleDragStart(e, folder)}
        onDragOver={(e) => handleDragOver(e, folder)}
        onDrop={(e) => handleDrop(e, folder)}
        onDragEnd={handleDragEnd}
        onClick={onWrapperClick}
        onMouseUp={onMouseUp}
      >
        <div className={style.popover}>
          <FolderModals item={folder} onOpen={this.handleOpenPopover} />
        </div>
        <div className={TopLabelCX} onClick={this.onFolderClick} />
        <div className={MiddleLabelCX} />

        <div className={BodyCX} onClick={this.onFolderClick}>
          <div className={style.count}>{folder.number_of_files}</div>
        </div>
        <div className={FooterCX} onClick={this.onFolderClick}>
          <div className={style.name} title={folder.name}>
            {folder.name}
          </div>
          <div className={style.modified}>
            {dayFrom(folder.modified, i18n.language)}
          </div>
        </div>
        <Popup
          mainBtnClassName={style.createScenario}
          isHideMainBtn
          inputValue={this.state.name}
          onChange={this.handleNameChange}
          isOpen={this.state.isEditPopupOpen}
          onClose={this.onCloseEditPopup}
          title={t("changeFolderName")}
          onApply={this.onSubmitRename}
          placeholder={t("folderName")}
          inputLabel={t("folderName")}
          maxInputLength={100}
          minInputLength={1}
          errorText={
            this.state.name.length > 100
              ? t("folderName100Characters")
              : this.state.name.length < 1
              ? t("tooShortName")
              : undefined
          }
          disableOk={!this.state.name.trim()}
        />

        <Popup
          mainBtnClassName={style.createScenario}
          isHideMainBtn
          hideInput
          isOpen={this.state.isDeletePopupOpen}
          onClose={this.onCloseDeletePopup}
          title={t("deleteFolder")}
          subtitle={t("deleteFolderText", { folder: folder.name })}
          onApply={this.onSubmitDelete}
        />
      </div>
    );
  }
}

export const Folder = withTranslation()(FolderComponent);
