import i18n from "i18n";
import store from "store";
import { successNotification } from "utils";
import { AnswerData } from "../index";
import * as PaymentMethodActions from "actions/paymentMethod";

// WSApi.DefaultPaymentMethodSet
export const paymentMethodSetDefault = (data: AnswerData) => {
  if (!data?.error && data?.response) {
    store.dispatch(PaymentMethodActions.setDefaultPayment(data?.response));
    successNotification(i18n.t("settings:defaultMethodUpdated"));
  }
};
