import store from "store";
import { AnswerData } from "../index";
import * as MethodReplyAction from "actions/methodReply";

// WSApi.AllBlockReplyMethods
export const serviceGetMethodReply = (data: AnswerData) => {
  if (!data?.error) {
    store.dispatch(MethodReplyAction.setMethodReplySuccess(data.response));
  }
};
