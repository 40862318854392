import { useLocation, useNavigate } from "react-router-dom";
import * as UpdateSubscriptionActions from "actions/updateSubscription";
import * as BillingInfoCreators from "creators/billingInfo";
import * as AuthCreators from "creators/auth";

import store from "store";
import { errorFromResponse, successNotification } from "utils";
import { privateRoute } from "routes";
import i18n from "i18n";
import { AnswerData } from "../index";
import { WSApi } from "enums/wsApi";
import { errorWhileCreateNewMethod$ } from "selectors/paymentMethod";

// WSApi.UpdateSubscription || WSApi.UpdateSubscriptionNew
export const subscriptionUpdateExisting = (
  data: AnswerData,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch, getState } = store;
  if (data.error) {
    dispatch(UpdateSubscriptionActions.setUpdateSubscriptionFail());
    errorFromResponse(data.error);
    if (data?.error === "you need to add payment method") {
      navigate(`${privateRoute.billing}?add_card=true`);
    }
  } else {
    dispatch(BillingInfoCreators.fetchBillingInfo() as any);

    if (
      (data.action === WSApi.UpdateSubscription &&
        !errorWhileCreateNewMethod$(getState())) ||
      data.action === WSApi.UpdateSubscriptionNew
    ) {
      store.dispatch(
        AuthCreators.getSessionStatus(navigate, location, searchParams) as any
      );
      successNotification(i18n.t("settings:subscriptionUpdated"));
      navigate(privateRoute.billing);
    }
  }
};

export const subscriptionUpdate = subscriptionUpdateExisting;
