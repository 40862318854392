import { RootState } from "store";

export const isLoading$ = (state: RootState) => state.journalEvents.isLoading;
export const isError$ = (state: RootState) => state.journalEvents.isError;
export const journalEvents$ = (state: RootState) => state.journalEvents.list;
export const limit$ = (state: RootState) => state.journalEvents.limit;
export const offset$ = (state: RootState) => state.journalEvents.offset;
export const hasMore$ = (state: RootState) => state.journalEvents.hasMore;
export const editableVersionId$ = (state: RootState) =>
  state.journalEvents.editableVersionId;
