import store from "store";
import { AnswerData } from "../index";
import * as LibFuncActons from "actions/libFunction";

// WSApi.GetFunctions
export const libraryGetFunctions = (data: AnswerData) => {
  if (!data?.error) {
    store.dispatch(
      LibFuncActons.setFuncList(
        data.response.result,
        data.response.scenario_id,
        data.response.block_id
      )
    );
  }
};
