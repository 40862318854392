import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./style.scss";
import { Button, ButtonVariant } from "components/button";
import { ReactComponent as CloseIcon } from "assets/svg/cross.svg";

interface IProps {
  onOpen?: () => void;
  onClose?: () => void;
  btnClassName?: string;
  btnValue?: string | React.ReactNode;
  content: React.ReactNode;
  disabled?: boolean;
  isOpen?: boolean;
  isHideButton?: boolean;
  isFullWidth?: boolean;
}
export class ModalWindow extends React.PureComponent<IProps> {
  state = {
    isOpen: false,
  };

  private handleOpen = () => {
    this.setState({
      isOpen: true,
    });
    if (this.props.onOpen) this.props.onOpen();
  };

  private handleClose = () => {
    this.setState({
      isOpen: false,
    });
    if (this.props.onClose) this.props.onClose();
  };

  private isOpenPopup = () => {
    if (this.props.isOpen !== undefined) {
      return this.props.isOpen;
    } else {
      return this.state.isOpen;
    }
  };

  render() {
    const {
      btnClassName,
      btnValue,
      content,
      disabled,
      isHideButton,
      isFullWidth,
    } = this.props;

    return (
      <>
        {!isHideButton && (
          <Button
            variant={ButtonVariant.INVISIBLE}
            onClick={this.handleOpen}
            isDisable={disabled}
            className={btnClassName ? btnClassName : undefined}
            value={btnValue as JSX.Element}
          />
        )}
        <Modal
          open={this.isOpenPopup()}
          onClose={this.handleClose}
          center
          closeIcon={<CloseIcon />}
          classNames={{ modal: isFullWidth ? "modal-response" : "modal-800" }}
        >
          {content}
        </Modal>
      </>
    );
  }
}
