import { ILibFunction } from "interfaces/state/libFunction";
import * as Const from "../constants";

export const setFuncInit = () => {
  return {
    type: Const.FUNC_INIT,
  };
};

export const setFuncList = (
  payload: ILibFunction[],
  scenario_id: string,
  block_id: string
) => {
  return {
    type: Const.FUNC_SUCCESS,
    payload,
    scenario_id,
    block_id,
  };
};

export const setFuncError = () => {
  return {
    type: Const.FUNC_FAIL,
  };
};

export const setFuncLoading = () => {
  return {
    type: Const.FUNC_LOADING,
  };
};
