import store from "store";
import { AnswerData } from "../index";
import * as ReplyMethodAction from "actions/availableReplyMethod";

// WSApi.ServiceAvaiableMethods
export const serviceGetAvailableMethodReply = (data: AnswerData) => {
  if (!data?.error) {
    store.dispatch(
      ReplyMethodAction.setAvailReplyMethodsList(
        data?.response?.data,
        data.response?.scenario_id,
        data.response?.block_id
      )
    );
  }
};
