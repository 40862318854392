import DashboardInfoState from "interfaces/state/dashboardInfo";
import * as Const from "../constants";

export const setDashboardInfoInit = () => {
  return {
    type: Const.DASH_INFO_INIT,
  };
};

export interface IDashboardPayload {
  completed_tasks: DashboardInfoState["completed_tasks"];
  services: DashboardInfoState["services"];
  graph_by_tasks: DashboardInfoState["graph_by_tasks"];
  graph_by_transactions: DashboardInfoState["graph_by_transactions"];
  number_services: DashboardInfoState["number_services"];
  number_services_max: DashboardInfoState["number_services_max"];
  scenarios: DashboardInfoState["scenarios"];
  scenarios_max: DashboardInfoState["scenarios_max"];
  transactions: DashboardInfoState["transactions"];
  transactions_max: DashboardInfoState["transactions_per_month"];
}

export const setDashboardInfoSuccess = (payload: IDashboardPayload) => {
  return {
    type: Const.DASH_INFO_SUCCESS,
    payload,
  };
};

export const setDashboardInfoError = () => {
  return {
    type: Const.DASH_INFO_FAIL,
  };
};

export const setDashboardInfoLoading = () => {
  return {
    type: Const.DASH_INFO_LOADING,
  };
};

export interface IUpdateTasksPayload {
  date: string;
  day_trans: number;
  month_tasks: number;
}

export const updateDashTasks = (payload: IUpdateTasksPayload) => {
  return {
    type: Const.DASH_UPD_TASKS,
    payload,
  };
};

export interface IUpdateTransPayload {
  date: string;
  day_trans: number;
  month_trans: number;
}

export const updateDashTransactions = (payload: IUpdateTransPayload) => {
  return {
    type: Const.DASH_UPD_TRANSACTIONS,
    payload,
  };
};
