import cx from "classnames";
import { useTranslation } from "react-i18next";
import {
  ServiceIconType,
  servicesWithIcons,
  handleServiceIcon,
  getFirstLetter,
} from "utils";
import { Tooltip, TooltipType } from "components/tooltip";
import style from "./serviceIcon.module.scss";

interface IProps {
  onClick?: () => void;
  service?: ServiceIconType | string;
  variant?: "small" | "xsmall";
  uuid?: string;
}

export const ServiceIcon = ({ service, variant, onClick, uuid }: IProps) => {
  const { t } = useTranslation(["services"]);

  const RootCX = cx(style.root, {
    [style.small]: variant === "small",
    [style.xsmall]: variant === "xsmall",
  });

  const WithoutIconCX = cx(style.withoutIcon, {
    [style.small]: variant === "small",
    [style.xsmall]: variant === "xsmall",
  });

  const rederService = () => {
    return service && servicesWithIcons.includes(service) ? (
      <img
        src={handleServiceIcon(service)}
        alt={"service"}
        className={RootCX}
        onClick={onClick}
      />
    ) : (
      <div className={WithoutIconCX} onClick={onClick}>
        {getFirstLetter(service)}
      </div>
    );
  };

  return (
    <>
      <Tooltip
        id={uuid ? uuid : service!}
        place={"topRight"}
        type={TooltipType.Dark}
        uuid={uuid}
        overlay={<span>{t(service!)}</span>}
      >
        {rederService()}
      </Tooltip>
    </>
  );
};
