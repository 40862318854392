import { fetchTeamList } from "creators/team";
import store from "store";
import { errorFromResponse } from "utils";
import * as TeamActions from "actions/team";
import { AnswerData } from "../index";
import { number_of_members$ } from "selectors/team";

// WSApi.DeleteMember
export const companyDeleteMember = (data: AnswerData) => {
  if (data.error) {
    errorFromResponse(data.error);
  } else {
    const member_id = data.response.member_id;
    const number_of_members = number_of_members$(store.getState()) || 1;
    store.dispatch(TeamActions.setMemberCount(number_of_members! - 1));
    store.dispatch(TeamActions.deleteMemberById(member_id));
    store.dispatch(fetchTeamList() as any);

    window.location.reload();
  }
};
