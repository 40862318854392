import store from "store";
import { AnswerData } from "../index";
import * as ScheduleContentAction from "actions/scheduleContent";

// WSApi.BlockGetInfoForSchedule
export const blockGetInfoForSchedule = (data: AnswerData) => {
  if (!data?.error) {
    store.dispatch(ScheduleContentAction.getScheduleContent(data.response));
  }
};
