import { indicators$ } from "selectors/indicators";
import store from "store";
import { AnswerData } from "../index";
import * as IndicatorActions from "actions/indicators";

// WSApi.DeleteIndicator
export const indicatorDelete = (data: AnswerData) => {
  const { getState, dispatch } = store;
  if (!data?.error) {
    const indicators = indicators$(getState());

    const indicatorsAfterDelete = indicators?.filter(
      (indicator) => indicator?.id !== data?.response?.id
    );

    dispatch(IndicatorActions.setIndicators(indicatorsAfterDelete));
  }
};
