import store from "store";
import { AnswerData } from "..";
import * as ParameterActions from "actions/serviceParam";
import { selectedBlockId$ } from "selectors/scenario";
import { updateBlockInputParameters } from "interceptors/serviceParameter";

// WSApi.ScenarioParameter
export const serviceGetAllParameters = (data: AnswerData) => {
  const { dispatch, getState } = store;
  if (data?.error) {
    dispatch(ParameterActions.setServiceParamFail());
  } else {
    const selectedBlockId = selectedBlockId$(getState());
    if (data.block_id === selectedBlockId) {
      updateBlockInputParameters(
        data.response,
        data.scenario_id,
        data.block_id,
        data.is_update
      )(getState(), dispatch);
    }
  }
};
