import { WSApi } from "enums/wsApi";
import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as BillingInfoActions from "actions/billingInfo";

// WSApi.BillingInfo || WSApi.TryAgainPayment;
export const subscriptionGetBillingInfo = (data: AnswerData) => {
  const { getState, dispatch } = store;
  if (data.error) {
    errorFromResponse(data.error);
    dispatch(BillingInfoActions.setBillingInfoFail());
  } else {
    const oldBillingData = getState().billingInfo;
    const additionData =
      data.action === WSApi.TryAgainPayment ? oldBillingData : {};
    dispatch(
      BillingInfoActions.setBillingInfoSuccess({
        ...additionData,
        ...data.response,
      } as BillingInfoActions.IBillingInfoParams)
    );
  }
};

export const invoicePayPrevious = subscriptionGetBillingInfo;
