import * as Const from "../constants";
import State from "../interfaces/state/updateSubscription";

export const initState: State = {
  isLoading: false,
  isError: false,
  data: "",
  signature: "",
};

export default function updateSubscription(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.UPD_SUBS_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.UPD_SUBS_SUCCESS:
      const { data, signature } = action.payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        data,
        signature,
      };

    case Const.UPD_SUBS_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.UPD_SUBS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
