import i18n from "i18n";
import * as Const from "../constants";
import State from "../interfaces/state/mainFolder";

export const initState: State = {
  isLoading: false,
  isError: false,
  folder_id: null,
  folder_name: i18n.t(["automation"], "yourScenarios"),
};

export default function mainFolder(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.MAIN_FOLDER_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.MAIN_FOLDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        folder_id: action.folder_id,
      };

    case Const.MAIN_FOLDER_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.MAIN_FOLDER_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
