import State from "../interfaces/state";

export const isLoading$ = (state: State) => state.servicesPage.isLoading;
export const isError$ = (state: State) => state.servicesPage.isError;
export const services$ = (state: State) => state.servicesPage.list;
export const service_count$ = (state: State) => state.servicesPage.count;
export const sort_by$ = (state: State) => state.servicesPage.sort_by;
export const status$ = (state: State) => state.servicesPage.status;

export const isNeedToSaveChanges$ = (state: State) =>
  state.servicesPage.isNeedToSaveChanges;
export const isOpenSaveChangesPopup$ = (state: State) =>
  state.servicesPage.isOpenSaveChangesPopup;
export const nextRouteAfterSaveChanges$ = (state: State) =>
  state.servicesPage.nextRouteAfterSaveChanges;

export const hasDataToSave$ = (state: State) =>
  state.servicesPage.hasDataToSave;

export const tryToSaveData$ = (state: State) =>
  state.servicesPage.tryToSaveData;
