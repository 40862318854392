import { Action } from "redux";

import State from "interfaces/state";
import { sendWSMessage } from "utils";
import { WSApi } from "enums/wsApi";
import { status_session$ } from "selectors/auth";

export const WSInit = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.Init;
    if (status_session$(getState()) === "open") {
      sendWSMessage({ action });
    }
  };
};
