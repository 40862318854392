import isEqual from "lodash-es/isEqual";
import { team$ } from "selectors/team";
import store from "store";
import { errorFromResponse } from "utils";
import * as SubscriptionAction from "actions/subscription";
import * as TeamActions from "actions/team";

import { AnswerData } from "../index";

// WSApi.CompanyGetMembers
export const companyGetMembers = (data: AnswerData) => {
  if (data.error) {
    store.dispatch(TeamActions.setTeamListFail());
    errorFromResponse(data.error);
  }
  if (data?.response) {
    if (isEqual(team$(store.getState()), data.response.members) === false) {
      store.dispatch(TeamActions.setTeamList(data.response.members));
    }
    store.dispatch(TeamActions.setMemberCount(data.response.number_of_members));
    store.dispatch(TeamActions.setCompanyId(data.response.company_id));
    store.dispatch(TeamActions.setCompanyName(data.response.company_name));
    store.dispatch(SubscriptionAction.setMaxMembers(data.response.max_members));
    store.dispatch(
      SubscriptionAction.setTeamMembers(data.response.number_of_members)
    );
  }
};
