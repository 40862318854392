import * as Const from "../constants";
import State from "interfaces/state/methodReply";

export const initState: State = {
  isLoading: false,
  isError: false,
  methodReply: {},
  selectedOutputList: {},
  pathToList: [],
  pathToValue: [],
  pathToCompareKey: [],
};

export default function methodReply(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.METHOD_REPLY_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.METHOD_REPLY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        methodReply: action.payload,
      };

    case Const.METHOD_REPLY_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.METHOD_REPLY_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.SELECTED_OUTPUT_LIST:
      return {
        ...state,
        selectedOutputList: action.payload,
      };

    case Const.SET_PATH_TO_VALUE:
      return {
        ...state,
        pathToValue: action.payload,
      };

    case Const.SET_PATH_TO_LIST:
      return {
        ...state,
        pathToList: action.payload,
      };

    case Const.SET_PATH_TO_COMPARE_KEY:
      return {
        ...state,
        pathToCompareKey: action.payload,
      };

    default:
      return state;
  }
}
