import State from "interfaces/state";

export const scenarioVersions$ = (state: State) => state.scenarioVersion.list;
export const isError$ = (state: State) => state.scenarioVersion.isError;
export const isLoading$ = (state: State) => state.scenarioVersion.isLoading;
export const num_of_items$ = (state: State) =>
  state.scenarioVersion.num_of_items;
export const users$ = (state: State) => state.scenarioVersion.users;
export const tags$ = (state: State) => state.scenarioVersion.tags;
export const page$ = (state: State) => state.scenarioVersion.page;
export const branch$ = (state: State) => state.scenarioVersion.branch;
export const isLast$ = (state: State) => state.scenarioVersion.isLast;
