import { ScenarioUser } from "interfaces/state/scenarioUsers";
import * as Const from "../constants";

export const setScenariosUsersInit = () => {
  return {
    type: Const.SCENARIO_USERS_INIT,
  };
};

export const addNewUserToScenario = (
  payload: ScenarioUser,
  scenario_id: string
) => {
  return {
    type: Const.SCENARIO_USERS_ADD_USER,
    scenario_id,
    payload,
  };
};

export const addAllUsersToScenario = (
  payload: ScenarioUser[],
  scenario_id: string
) => {
  return {
    type: Const.SCENARIO_USERS_ADD_ALL_USERS,
    scenario_id,
    payload,
  };
};

export const deleteUserFromScenario = (
  scenario_id: string,
  user_email: string
) => {
  return {
    type: Const.SCENARIO_USERS_DELETE_USER,
    scenario_id,
    user_email,
  };
};

export const setScenarioUsersLoading = () => {
  return {
    type: Const.SCENARIO_USERS_LOADING,
  };
};

export const setScenarioUsersError = () => {
  return {
    type: Const.SCENARIO_USERS_FAIL,
  };
};
