import { Member } from "interfaces/state/team";
import * as Const from "../constants";

export const setTeamInit = () => {
  return {
    type: Const.TEAM_INIT,
  };
};

export const setTeamList = (payload: Member[]) => {
  return {
    type: Const.TEAM_SUCCESS,
    payload,
  };
};

export const setTeamListFail = () => {
  return {
    type: Const.TEAM_FAIL,
  };
};

export const setTeamListLoading = () => {
  return {
    type: Const.TEAM_LOADING,
  };
};

export const changeMemberRole = (member_id: number, role: string) => {
  return {
    type: Const.TEAM_CHANGE_MEMBER_ROLE,
    member_id,
    role,
  };
};

export const setChangeMemeberRoleLoading = () => {
  return {
    type: Const.TEAM_CHANGE_MEMBER_ROLE_LOADING,
  };
};

export const setChangeMemeberRoleError = () => {
  return {
    type: Const.TEAM_CHANGE_MEMBER_ROLE_ERROR,
  };
};

export const setCompanyName = (payload: null | string) => {
  return {
    type: Const.TEAM_COMPANY_NAME,
    payload,
  };
};

export const setCompanyId = (payload: null | number) => {
  return {
    type: Const.TEAM_COMPANY_ID,
    payload,
  };
};

export const setMemberCount = (payload: null | number) => {
  return {
    type: Const.TEAM_MEMBER_COUNT,
    payload,
  };
};

export const addNewMemberToList = (payload: Member) => {
  return {
    type: Const.TEAM_ADD_NEW_MEMBER,
    payload,
  };
};

export const setInviteLink = (payload: string | null) => {
  return {
    type: Const.TEAM_INVITE_LINK,
    payload,
  };
};

export const deleteMemberById = (member_id: number) => {
  return {
    type: Const.TEAM_DELETE_MEMBER,
    member_id,
  };
};

export const changeMemberRoleList = (payload: Partial<Member>[]) => {
  return {
    type: Const.TEAM_CHANGE_MEMBER_ROLE_LIST,
    payload,
  };
};

export const updateMemberData = (payload: Member) => {
  return {
    type: Const.TEAM_UPDATE_MEMBER_DATA,
    payload,
  };
};

export const setLoadingInvite = (payload: boolean) => {
  return {
    type: Const.LOADING_INVITE,
    payload,
  };
};
