import {
  BlockExecuteMethod as BlockExecuteType,
  BlockFormBody as BlockFormBodyType,
  BlockCaseOf as BlockCaseOfType,
  BlockExit as BlockExitType,
  BlockInit as BlockInitType,
  BlockCounter as BlockCounterType,
} from "interfaces/state/scenario";
import { CSSTransition } from "react-transition-group";
import { Block } from "../block";

interface OwnProps {
  block:
    | BlockExecuteType
    | BlockFormBodyType
    | BlockCaseOfType
    | BlockExitType
    | BlockInitType
    | BlockCounterType
    | any;
  isEdit?: boolean;
  isDebug?: boolean;
  selectedBlockId: string | number;
  setBlock: (id: string | number) => void;
}

interface IProps extends OwnProps {}

const style = {
  width: 202,
  height: 112,
  background: "transparent",
  borderRadius: 8,
  padding: 0,
  boxSizing: "border-box",
  zIndex: 10,
};

const position = { x: 0, y: 0 };

export const cubeRenderElement = ({
  block,
  isEdit,
  isDebug,
  selectedBlockId,
  setBlock,
}: IProps) => {
  const returnedData = {
    id: block.id.toString(),
    type: "",
    position,
    style: { ...style, zIndex: selectedBlockId === block?.id ? 1 : 0 },
    x_coordinate: block.x_coordinate,
    y_coordinate: block.y_coordinate,
  };

  return {
    ...returnedData,
    data: {
      label: (
        <CSSTransition
          in
          timeout={1000}
          classNames="init-animation"
          unmountOnExit
        >
          <Block
            isEdit={!!isEdit}
            isDebug={!!isDebug}
            item={block}
            setBlock={setBlock}
          />
        </CSSTransition>
      ),
      type: block.type,
    },
  };
};
