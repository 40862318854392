import { indicators$ } from "selectors/indicators";
import store from "store";
import { AnswerData } from "../index";
import * as IndicatorActions from "actions/indicators";

//  WSApi.UpdateIndicatorTransaction
export const indicatorsUpdateTransactions = (data: AnswerData) => {
  const { getState, dispatch } = store;
  const indicators = [...indicators$(getState())];

  const ids = data.response.map(
    (i: { id: number; transactions: number }) => i.id
  );

  indicators.forEach((indicator) => {
    if (ids.includes(indicator.id)) {
      const indexOfIndicator = indicators.findIndex(
        (i) => i.id === indicator.id
      );
      const indexOfCounter = ids.findIndex((i: number) => i === indicator.id);
      if (indexOfIndicator >= 0) {
        indicators[indexOfIndicator].transactions =
          data.response[indexOfCounter].transactions;
      }
    }
  });

  dispatch(IndicatorActions.setIndicators(indicators));
};
