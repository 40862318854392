import i18n from "i18n";
import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import {
  errorFromResponse,
  makeScenarioParams,
  parameterConstructor,
} from "utils";
import { AnswerData } from "../index";
import * as ScenarioActions from "actions/scenario";
import * as AutomationRouteActions from "actions/automationRouting";
import * as ParameterActions from "actions/serviceParam";
import { privateRoute } from "routes";
import { useLocation, useNavigate } from "react-router-dom";
import { company_role$, plan_name$ } from "selectors/auth";
import { MemberRole } from "interfaces/state/team";
import { AutomationRouteType } from "interfaces/state/automationRouting";
import { Folder } from "interfaces/state/folders";
import { Branch } from "interfaces/state/scenario";
import { ServiceParameter } from "interfaces/state/serviceParameter";
import { INIT_BLOCK } from "pages/scenario/constants";

// WSApi.GetScenario
export const scenarioGet = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch, getState } = store;
  if (data?.error) {
    errorFromResponse(data.error);
    dispatch(ScenarioActions.setScenarioError());
  } else {
    const currentScenarioId = scenarioItem$(getState())?.scenario_id;
    const planName = plan_name$(getState());
    const {
      scenario: { scenario_id, init },
      scenario,
      scenario_status,
      path,
    } = data.response;

    const key =
      init?.branch === Branch.TESTING && planName === "Enterprise"
        ? "testing_start_opts"
        : "start_opts";
    const revertKey =
      key === "start_opts" ? "testing_start_opts" : "start_opts";

    if (
      (!init[key] || !Object.keys(init[key])?.length) &&
      init[revertKey] &&
      Object.keys(init[revertKey])?.length
    ) {
      dispatch(
        ParameterActions.setServiceParameters(
          scenario_id,
          INIT_BLOCK,
          parameterConstructor(
            init[revertKey] as {
              [key: string]: ServiceParameter;
            },
            true
          )
        )
      );
    }
    if (scenario_id === currentScenarioId) {
      dispatch(ScenarioActions.refreshScenario());
    }

    const searchParams = new URLSearchParams(window.location.search);

    const company_role = company_role$(getState());

    const isEditParam =
      data.response.scenario.is_allowed_to_edit &&
      company_role !== MemberRole.USER &&
      searchParams.get("view") !== "1";

    dispatch(
      ScenarioActions.setScenarioSuccess({
        ...scenario,
        scenario_status,
      })
    );

    if (path) {
      dispatch(AutomationRouteActions.setAutomationRoutingInit());

      const isDebug = searchParams.get("debug") === "1";
      const isViewVersion = searchParams.get("viewVersion") === "1";
      const branch = searchParams.get("branch");
      const version_id = searchParams.get("version")!;
      const scenario_id = searchParams.get("id")!;

      const { journalEvents, journalEventLog, journalEventsDetails } =
        privateRoute;

      const { pathname } = window.location;
      if (
        [journalEvents, journalEventLog, journalEventsDetails].some((url) =>
          pathname.includes(url)
        )
      )
        return;

      navigate(
        `${privateRoute.scenario}?${makeScenarioParams({
          scenario_id,
          version_id,
          isEdit: isDebug ? false : isEditParam,
          isView: isDebug ? false : !isEditParam,
          isDebug,
          isViewVersion,
          selectedBlock: null,
          branch: branch === "null" ? "devel" : branch!,
        })}`
      );

      const automationRouting = path.map((folder: Folder) => ({
        id: folder.id,
        type: AutomationRouteType.FOLDER,
        title:
          folder.name === "start_folder"
            ? i18n.t(["automation"], "yourScenarios")
            : folder.name,
      }));

      dispatch(AutomationRouteActions.setAutomationRouting(automationRouting));
      dispatch(AutomationRouteActions.setCurrentAutomationRoute(path[0]));
    }
  }
};
