import i18n from "i18n";
import { INIT_BLOCK } from "pages/scenario/constants";
import { scenarioItem$, selectedBlockId$ } from "selectors/scenario";
import { sensitiveDataList$ } from "selectors/sensitiveData";
import store from "store";
import { parseDataFromStorage, successNotification } from "utils";
import * as ScenarioCreators from "creators/scenario";
import * as SensitiveDataActions from "actions/sensitiveData";

import { AnswerData } from "../index";

// WSApi.SetAuthData
export const serviceSetAuthorizationData = (data: AnswerData) => {
  if (!data?.error) {
    const scenario_id = parseDataFromStorage("scenario_id");
    const block_id = parseDataFromStorage("block_id");
    const scenario = scenarioItem$(store.getState());
    const currBlockId = selectedBlockId$(store.getState());

    if (scenario_id === scenario?.scenario_id && block_id === currBlockId) {
      if (block_id === INIT_BLOCK) {
        store.dispatch(
          ScenarioCreators.updateInitBlock({
            authorization_id: data?.response?.data_id,
          }) as any
        );
      } else {
        store.dispatch(
          ScenarioCreators.updateBlock({
            authorization_id: data?.response?.data_id,
          }) as any
        );
      }

      const sensitiveData = sensitiveDataList$(store.getState());
      const serviceName = parseDataFromStorage("service-name");
      const defaultSDSName = `${serviceName} ${
        sensitiveData?.length ? sensitiveData?.length + 1 : 1
      }`;
      store.dispatch(
        SensitiveDataActions.setSensDataList([
          ...sensitiveData,
          {
            id: data?.response?.data_id,
            name: data?.response?.account_name || defaultSDSName,
          },
        ])
      );

      localStorage.removeItem("scenario_id");
      localStorage.removeItem("block_id");
      localStorage.removeItem("service-name");
      successNotification(i18n.t("scenario:newAuthDataWasSuccessfullyAdded"));
    }
  }
};
