import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { ScenarioCategory } from "interfaces/state/scenarioCategories";
import { DropDownItem } from "pages/scenario/components/dropdownItem";
import { ReactComponent as SearchIcon } from "assets/svg/fi-search.svg";
import ScenarioHeaderPopover, {
  PopoverTheme,
} from "pages/scenario/components/scenarioHeaderPopover";
import { ModalWindow } from "components/modal";
import { ReactComponent as SelectIcon } from "assets/svg/fi-check.svg";
import { EditCategories } from "../editCategories";
import style from "./headerDropdown.module.scss";

interface IProps {
  value: ScenarioCategory | null;
  items: ScenarioCategory[];
  isNeedSearch?: boolean;
  isEdit?: boolean;
  categories?: number[];
  onChange: (val: ScenarioCategory) => void;
  onClear?: () => void;
  label?: string;
  removeLabelNS?: boolean;
  rootClassName?: string;
}

export const HeaderDropdown: FC<IProps> = ({
  value,
  items,
  isNeedSearch,
  isEdit,
  categories,
  label,
  removeLabelNS,
  onChange,
  onClear,
  rootClassName,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleClick = useCallback(
    (item: ScenarioCategory) => () => {
      const newItem = { ...item };
      newItem.name = item.name.includes(":")
        ? item.name.split(":")[1]
        : item.name;

      onChange(newItem);
    },
    [onChange]
  );

  const onSearch = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setSearch(value),
    []
  );

  const DropdownCX = useCallback(
    (isSelected: boolean) =>
      cx(style.dropdownItem, {
        [style.selected]: isSelected,
        [style.simple]: !isEdit,
        [style.edit]: isEdit,
      }),
    [isEdit]
  );

  const handleOpenEdit = () => {
    setOpen(true);
  };

  const filteredItems = useMemo(
    () =>
      !search
        ? items
        : items?.filter(
            (item, index) =>
              items
                ?.map((it) => it.name.toLowerCase())
                .indexOf(search.toLowerCase()) === index ||
              item.name.toLowerCase().includes(search.toLowerCase())
          ),
    [items, search]
  );

  const OverlayCX = cx(style.overlay, { [style.edit]: isEdit });

  const overlay = useMemo(
    () => (
      <div className={OverlayCX}>
        {isNeedSearch && (
          <div className={style.search}>
            <SearchIcon />
            <input
              value={search}
              onChange={onSearch}
              placeholder={t("automation:findCategory")}
            />
          </div>
        )}
        <div className={style.items}>
          {filteredItems?.map((item) => (
            <DropDownItem
              key={item.id}
              onClick={handleClick(item)}
              text={!isEdit ? t(`${item.name}`) : item.name}
              className={DropdownCX(
                isEdit ? !!categories?.includes(item.id) : item.id === value?.id
              )}
              icon={
                (
                  isEdit
                    ? !!categories?.includes(item.id)
                    : item.id === value?.id
                ) ? (
                  <SelectIcon className={style.checkIcon} />
                ) : undefined
              }
            />
          ))}
        </div>
        {isEdit && (
          <div className={style.buttons}>
            <div className={style.edit} onClick={handleOpenEdit}>
              {t("automation:categoriesSettings")}
            </div>
            <div className={style.clear} onClick={onClear}>
              {t("automation:clear")}
            </div>
          </div>
        )}
      </div>
    ),
    [
      DropdownCX,
      OverlayCX,
      categories,
      filteredItems,
      handleClick,
      isEdit,
      isNeedSearch,
      onClear,
      onSearch,
      search,
      t,
      value?.id,
    ]
  );

  const content = () => (
    <div className={style.content}>
      {!!label && <div className={style.label}></div>}
      <div className={style.text}>
        {removeLabelNS ? value?.name : t(`automation:${value?.name}`)}
      </div>
    </div>
  );

  console.log(value, "value?.name");

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <div className={`${style.root} ${rootClassName}`}>
      <ScenarioHeaderPopover
        overlay={() => overlay}
        theme={PopoverTheme.LIGHT}
        arrowClassName={style.arrowClassName}
      >
        {content()}
      </ScenarioHeaderPopover>
      <ModalWindow
        isOpen={isOpen}
        isHideButton
        content={<EditCategories />}
        onClose={handleClose}
      />
    </div>
  );
};
