import State from "interfaces/state";
import { createSelector } from "reselect";

export const isLoading$ = (state: State) => state.paymentHistory.isLoading;
export const isError$ = (state: State) => state.paymentHistory.isError;
export const paymentsHistory = (state: State) => state.paymentHistory.list;
export const paymentsHistory$ = createSelector(
  [paymentsHistory],
  (payments) => payments
);

export const hasMore$ = (state: State) => state.paymentHistory.hasMore;
export const offset$ = (state: State) => state.paymentHistory.offset;
