import * as Const from "../constants";
import State from "../interfaces/state/serviceParameter";
import * as immutable from "object-path-immutable";

export const initState: State = {
  isLoading: false,
  isError: false,
  param: {},
  keys: {},
  inputParameter: {},
  initialParameter: {},
  parameter: {},
  parameterForComponent: {},
  newParameters: {},
};

export default function serviceParameter(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.SERVICE_PARAM_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.SERVICE_PARAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        param: {
          ...state.param,
          [action.scenarioId]: {
            ...state.param[action.scenarioId],
            [action.blockId]: action.payload,
          },
        },
      };

    case Const.SERVICE_PARAM_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.SERVICE_PARAM_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.SERVICE_KEYS:
      return {
        ...state,
        keys: {
          ...state.keys,
          [action.scenarioId]: {
            ...state.keys[action.scenarioId],
            [action.blockId]: action.payload,
          },
        },
      };

    case Const.SERVICE_IN_PARAMETER:
      return {
        ...state,
        inputParameter: {
          ...state.inputParameter,
          [action.scenarioId]: {
            ...state.inputParameter[action.scenarioId],
            [action.blockId]: action.payload,
          },
        },
      };

    case Const.SERVICE_SET_INITIAL_PARAM:
      return {
        ...state,
        initialParameter: {
          ...state.initialParameter,
          [action.scenarioId]: {
            ...state.initialParameter[action.scenarioId],
            [action.blockId]: action.payload,
          },
        },
      };

    case Const.SERVICE_IN_PARAMETER_EDIT:
      const path = action.path.join(".");
      const data = state.inputParameter?.[action.scenarioId]?.[action.blockId];
      if (!data) {
        return { ...state };
      }

      const value = immutable.set(data, path, action.payload);
      return {
        ...state,
        inputParameter: {
          ...state.inputParameter,
          [action.scenarioId]: {
            ...state.inputParameter[action.scenarioId],
            [action.blockId]: value,
          },
        },
      };

    case Const.SERVICE_IN_PARAMETER_PUSH:
      const pushPath = action.path.join(".");
      const pushData =
        state.inputParameter?.[action.scenarioId]?.[action.blockId];

      const pushValue = immutable.push(pushData, pushPath, action.payload);
      return {
        ...state,
        inputParameter: {
          ...state.inputParameter,
          [action.scenarioId]: {
            ...state.inputParameter[action.scenarioId],
            [action.blockId]: pushValue,
          },
        },
      };

    case Const.SERVICE_IN_PARAMETER_DELETE:
      const deletePath = action.path.join(".");
      const deleteData =
        state.inputParameter?.[action.scenarioId]?.[action.blockId];

      const deleteValue = immutable.del(deleteData, deletePath);
      return {
        ...state,
        inputParameter: {
          ...state.inputParameter,
          [action.scenarioId]: {
            ...state.inputParameter[action.scenarioId],
            [action.blockId]: deleteValue,
          },
        },
      };

    case Const.SERVICE_PARAMETERS_RECEIVE:
      return {
        ...state,
        parameter: {
          ...state.parameter,
          [action.scenarioId]: {
            ...state.parameter[action.scenarioId],
            [action.blockId]: action.payload,
          },
        },
      };

    case Const.SERVICE_PARAMETERS_FOR_COMPONENT_RECEIVE:
      const keys = Object.keys(action.payload);
      let dataForComponent = {};

      keys.forEach((key) => {
        dataForComponent = {
          ...dataForComponent,
          [key]: {
            id: key,
            children: action.payload[key].children,
            value: [],
            value_type: action.payload[key].type,
            type: action.payload[key].type,
            parent: action.payload[key].parent,
            name: action.payload[key].name,
            display_name: action.payload[key].display_name,
            description: action.payload[key].description,
            required: action.payload[key].required,
          },
        };
      });

      return {
        ...state,
        isLoading: false,
        isError: false,
        parameterForComponent: {
          ...state.parameterForComponent,
          [action.scenarioId]: {
            ...state.parameterForComponent[action.scenarioId],
            [action.blockId]: dataForComponent,
          },
        },
      };

    case Const.SERVICE_PARAMETERS_VALUE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        parameterForComponent: {
          ...state.parameterForComponent,
          [action.scenarioId]: {
            ...state.parameterForComponent[action.scenarioId],
            [action.blockId]: action.payload,
          },
        },
      };

    case Const.SERVICE_ADDED_NEW_PARAMS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        newParameters: {
          ...state.newParameters,
          [action.blockId]: action.payload,
        },
      };

    default:
      return state;
  }
}
