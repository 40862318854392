import {
  limit$,
  offset$,
  sortBy$,
  sortDirection$,
} from "selectors/automationRouting";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as FolderActions from "actions/folders";
import * as FolderCreators from "creators/folders";
import store from "store";
import { selectedCategories$ } from "selectors/scenarioCategories";

// WSApi.CreateFolder
export const folderCreate = (data: AnswerData) => {
  const { getState, dispatch } = store;
  if (data.error) {
    errorFromResponse(data.error);
    dispatch(FolderActions.setFoldersError());
  } else {
    const { parent_id } = data.response;

    const sort_by = sortBy$(getState());
    const sort_direction = sortDirection$(getState());
    const limit = limit$(getState());
    const offset = offset$(getState());
    const selectedCategories = selectedCategories$(getState());

    dispatch(
      FolderCreators.fetchAllAutomationFiles(
        parent_id,
        sort_by,
        sort_direction,
        limit,
        offset,
        selectedCategories
      )
    );
  }
};
