import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { AnswerData } from "../index";
import * as ScenarioVersionActions from "actions/scenarioVersion";
import * as ScenarioCreators from "creators/scenario";
import { isLast$ } from "selectors/scenarioVersion";
import { useLocation, useNavigate } from "react-router-dom";
import { privateRoute } from "routes";
import { ScenarioVersion } from "interfaces/state/scenarioVersion";
import { makeScenarioParams } from "utils";

// WSApi.GetScenarioVersions
export const scenarioVersionGetAll = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (!data.error) {
    const { dispatch, getState } = store;
    const scenario = scenarioItem$(getState());
    const isLast = isLast$(getState());
    if (scenario?.scenario_id === data?.response?.scenario_id) {
      dispatch(
        ScenarioVersionActions.setScenarioVersionList(data.response.versions)
      );

      dispatch(
        ScenarioVersionActions.setScenarioVersionItems(
          data.response.number_of_items
        )
      );
      dispatch(
        ScenarioVersionActions.setScenarioVersionUsers(data.response.authors)
      );
      dispatch(
        ScenarioVersionActions.setScenarioVersionTags(data.response.tags)
      );

      if (isLast) {
        dispatch(ScenarioVersionActions.setLastVersionItem(false));

        if (data.response.versions.length) {
          const currentVersion = data.response.versions[0] as ScenarioVersion;
          const scenario_id = scenario?.scenario_id;
          const selectedBlock = searchParams.get("selectedBlock");

          navigate(
            `${privateRoute.scenario}?${makeScenarioParams({
              scenario_id,
              version_id: currentVersion.id,
              isEdit: true,
              isView: false,
              isDebug: false,
              isViewVersion: true,
              selectedBlock,
              branch: "devel",
            })}`
          );

          dispatch(
            ScenarioCreators.fetchScenarioById(
              scenario_id!,
              undefined,
              currentVersion.id
            ) as any
          );
        }
      }
    }
  }
  store.dispatch(ScenarioVersionActions.setScenarioVersionLoading(false));
};
