import { PaymentHistory } from "interfaces/state/paymentHistory";
import * as Const from "../constants";

export const setPaymentHistoryInit = () => {
  return {
    type: Const.PAY_HISTORY_INIT,
  };
};

export const setPaymentHistorySuccess = (payload: PaymentHistory[]) => {
  return {
    type: Const.PAY_HISTORY_SUCCESS,
    payload,
  };
};

export const setPaymentHistoryError = () => {
  return {
    type: Const.PAY_HISTORY_FAIL,
  };
};

export const setPaymentHistoryLoading = (payload: boolean) => {
  return {
    type: Const.PAY_HISTORY_LOADING,
    payload,
  };
};

export const setPaymentHistoryHasmore = (payload: boolean) => {
  return {
    type: Const.PAY_HISTORY_HAS_MORE,
    payload,
  };
};

export const setPaymentHistoryOffset = (payload: number) => {
  return {
    type: Const.PAY_HISTORY_OFFSET,
    payload,
  };
};
