import { AnswerData } from "..";
import * as ScenarioActions from "actions/scenario";
import * as ScenarioCreators from "creators/scenario";
import store from "store";
import { useLocation, useNavigate } from "react-router-dom";
import { privateRoute } from "routes";
import { getPaginationItemsCount, makeScenarioParams } from "utils";
import { scenarioItem$ } from "selectors/scenario";
import { page$ } from "selectors/scenarioVersion";

// WSApi.ScenarioUnlockVersion
export const scenarioVersionUnlock = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch } = store;

  const scenarioItem = scenarioItem$(store.getState());
  if (!data?.error && data.response.scenario_id === scenarioItem?.scenario_id) {
    const searchParams = new URLSearchParams(window.location.search);
    const scenario_id = data.response?.scenario_id;
    const version_id = data.response?.version_id;
    const isDebug = searchParams.get("debug") === "1";
    const selectedBlock = searchParams.get("selectedBlock");
    const branch = searchParams.get("branch")!;
    const isViewVersion = searchParams.get("viewVersion") === "1";

    const isViewVersionOpened = document.getElementById("version-panel");

    if (!isViewVersionOpened) {
      dispatch(
        ScenarioActions.setScenarioSuccess({
          ...scenarioItem,
          is_allowed_to_edit: true,
        })
      );

      navigate(
        `${privateRoute.scenario}?${makeScenarioParams({
          scenario_id,
          version_id,
          isEdit: true,
          isView: false,
          isDebug,
          isViewVersion,
          selectedBlock,
          branch,
        })}`
      );

      dispatch(
        ScenarioCreators.fetchScenarioById(scenario_id!, true, version_id)
      );

      const currentPage = page$(store.getState()) || 1;

      const containerHeight =
        document.getElementById("version-container")?.clientHeight;

      const maxItemsPerPage = getPaginationItemsCount(containerHeight!);

      setTimeout(
        () =>
          dispatch(
            ScenarioCreators.fetchAllScenarioVersions(
              maxItemsPerPage,
              (Number(currentPage) - 1) * maxItemsPerPage
            )
          ),
        500
      );
    } else {
      dispatch(
        ScenarioActions.setEditableScenario({
          id: data.response?.version_id,
          scenario_id: data.response?.scenario_id,
        })
      );
    }
  }
};
