import cx from "classnames";
import {
  AllScenarioBlocks,
  Scenario,
  ScenarioInit,
} from "interfaces/state/scenario";
import { ReactComponent as Heart } from "assets/svg/heart-full.svg";
import { ReactComponent as TriggerIcon } from "assets/svg/new-svg/20x20/trigger.svg";
import { ReactComponent as FiCalendarIcon } from "assets/svg/fi-calendar.svg";
import { ReactComponent as ExecuteIcon } from "assets/svg/new-svg/20x20/webhook=20x20.svg";
import { ReactComponent as AttentionIcon } from "assets/svg/attention.svg";
import { ReactComponent as CaseOfIcon } from "assets/svg/new-svg/20x20/case-of_20x20.svg";
import { ReactComponent as CounterIcon } from "assets/svg/new-svg/24x24/counter.svg";
import { ReactComponent as CheckIcon } from "assets/svg/check-2.svg";
import style from "./block.module.scss";

export const handleBlockIcon = (
  type: AllScenarioBlocks["type"] | "initial_block",
  schedule: boolean,
  error: boolean,
  success: boolean,
  grey?: boolean
) => {
  const ExecuteCX = cx(style.execute, {
    [style.grey]: grey,
  });

  const CounterCX = cx(style.counter, {
    [style.grey]: grey,
  });

  const WebhookCX = cx(style.webhook, {
    [style.grey]: grey,
  });

  const GreyCX = cx(style.blockIcon, {
    [style.grey]: grey,
  });

  const AttentionCX = cx(style.attentionIcon, {
    [style.grey]: grey,
  });

  const result = {
    execute_method: <ExecuteIcon className={ExecuteCX} />,
    case_of: <CaseOfIcon />,
    exit: <></>,
    counter: <CounterIcon className={CounterCX} />,
    initial_block: schedule ? (
      <FiCalendarIcon className={GreyCX} />
    ) : (
      <TriggerIcon className={WebhookCX} />
    ),
  };

  if (error) return <AttentionIcon className={AttentionCX} />;
  if (success) return <CheckIcon className={GreyCX} />;

  return type ? result[type] : <Heart className={GreyCX} />;
};

export const handleBlockName = (
  type: AllScenarioBlocks["type"] | "initial_block",
  schedule: boolean
) => {
  const result = {
    execute_method: "Execute",
    case_of: "Case of",
    exit: "Exit",
    counter: "Counter",
    initial_block: schedule ? "Schedule" : "Webhook",
  };

  return type ? result[type] : "New";
};

export const hasPrevBlock = (
  scenario: Scenario,
  item?: AllScenarioBlocks
): boolean =>
  scenario?.pipeline?.some(
    (pipe) => pipe?.next === item?.id || (pipe?.id === item?.id && pipe?.first)
  );

export const hasTopBlock = (scenario: Scenario, item?: AllScenarioBlocks) =>
  item?.type === "case_of"
    ? scenario?.pipeline?.some(
        (pipe) =>
          pipe.id === item?.id && pipe?.next && pipe?.source_position === "a"
      )
    : false;

export const hasBottomBlock = (scenario: Scenario, item?: AllScenarioBlocks) =>
  item?.type === "case_of"
    ? scenario?.pipeline?.some(
        (pipe) =>
          pipe.id === item?.id && pipe?.next && pipe?.source_position === "c"
      )
    : false;

export const hasNextBlock = (scenario: Scenario, item?: AllScenarioBlocks) =>
  scenario?.pipeline?.some(
    (pipe) =>
      (pipe?.in === item?.id && pipe?.source_position === "b") ||
      (pipe.id === item?.id && pipe?.next && pipe?.source_position === "b") ||
      (pipe.id === item?.id && pipe?.next && item?.type !== "case_of")
  );

export const isAlreadyCreatedRight = (
  scenario: Scenario,
  init?: ScenarioInit,
  item?: AllScenarioBlocks
) => (init ? scenario?.scenario?.length : hasNextBlock(scenario, item));
