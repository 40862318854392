import { Branch } from "interfaces/state/scenario";
import * as Const from "../constants";
import State from "../interfaces/state/scenarioVersion";

export const initState: State = {
  isLoading: false,
  isError: false,
  list: [],
  num_of_items: 0,
  users: [],
  tags: [],
  page: 1,
  branch: Branch.DEVEL,
  isLast: false,
};

export default function scenarioVersion(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.SCENARIO_VERSION_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.SCENARIO_VERSION_LIST:
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: action.payload,
      };

    case Const.SCENARIO_VERSION_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.SCENARIO_VERSION_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case Const.SCENARIO_VERSION_ITEMS:
      return {
        ...state,
        num_of_items: action.payload,
      };

    case Const.SCENARIO_VERSION_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case Const.SCENARIO_VERSION_TAGS:
      return {
        ...state,
        tags: action.payload,
      };

    case Const.SCENARIO_VERSION_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case Const.SCENARIO_VERSION_BRANCH:
      return {
        ...state,
        branch: action.payload,
      };

    case Const.SCENARIO_VERSION_LAST_ITEM:
      return {
        ...state,
        isLast: action.payload,
      };

    default:
      return state;
  }
}
