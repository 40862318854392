import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { AnswerData } from "../index";
import * as DebugActions from "actions/debug";

//  WSApi.DebugFinished
export const scenarioDebugFinished = (data: AnswerData) => {
  const { dispatch, getState } = store;

  const scenarioId = scenarioItem$(getState())?.scenario_id;

  if (data.scenario_id === scenarioId) {
    dispatch(DebugActions.setDebugLoading(false));
  }
};
