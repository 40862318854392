import { useLocation, useNavigate } from "react-router-dom";
import store from "store";
import { AnswerData } from "../index";
import * as ConnectorItemActions from "actions/connectorItem";

// WSApi.ConnectorsMethodsGet
export const adminConnectorsMethodsGet = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  _navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch } = store;
  if (!data?.error) {
    dispatch(ConnectorItemActions.setConnectorItem(data.response || []));
  } else {
    dispatch(ConnectorItemActions.setConnectorItemFail());
  }
};
