import * as Const from "../constants";
import { ScenarioCategoriesAction, ScenarioCategoriesPayload } from "interfaces/state/scenarioCategories";

export const setScenarioCategoriesInit = () => {
  return {
    type: Const.SCENARIO_CATEGORIES_INIT,
  };
};

export const setScenarioCategories = (
  payload: ScenarioCategoriesPayload
): ScenarioCategoriesAction => {
  return {
    type: Const.SCENARIO_CATEGORIES_SUCCESS,
    payload,
  };
};
