import { addNewOption } from "interceptors/serviceParameter";
import store from "store";
import { AnswerData } from "../index";

// WSApi.BlockAddOption
export const blockAddParameterOption = (data: AnswerData) => {
  if (!data?.error) {
    const { dispatch, getState } = store;
    addNewOption(data.response)(getState, dispatch);
  }
};
