import React, { useState } from "react";
import ReactFlow, {
  ReactFlowProvider,
  isNode,
  Node,
  Edge,
  ArrowHeadType,
  Position,
  PanOnScrollMode,
  OnLoadParams,
} from "react-flow-renderer";
import dagre from "dagre";

import ConnectionLine from "pages/scenario/components/connectionLine";
import { INIT_BLOCK } from "pages/scenario/constants";
import { cubeRenderElement } from "pages/scenario/components/cubeRenderElement";

import {
  stylesLine,
  setTargetPositionByDirection,
  setSourcePositionByDirection,
} from "pages/scenario/utils";
import {
  Pipeline,
  AllScenarioBlocks,
  ScenarioInit,
} from "interfaces/state/scenario";
import style from "./cubePreview.module.scss";
import CustomNodeForFileSystem from "pages/scenario/components/customNodeForFileSystem";
import CustomNodeForTemplate from "pages/scenario/components/customNodeForTemplate";
import { Spinner } from "components/spinner";
import { useAppSelector } from "hooks/redux";
import { selectedBlockId$ } from "selectors/scenario";
import { Block } from "pages/scenario/components/block";

const ReactFlowP = ReactFlowProvider as any;

interface OwnProps {
  pipeline?: Pipeline[];
  scenario?: AllScenarioBlocks[];
  init?: ScenarioInit;
  isFileSystem?: boolean;
}
interface IProps extends OwnProps {}

const BlockPreview = ({ pipeline, scenario, init, isFileSystem }: IProps) => {
  const [elements, setElements] = useState([] as Node[] | Edge[]);
  const [isLoading, setLoading] = React.useState(true);
  const [reactflowInstance, setReactflowInstance] = useState(
    null as OnLoadParams | null
  );

  const selectedBlockId = useAppSelector(selectedBlockId$);

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const getLayoutedElements = React.useCallback(
    (elements: any, direction = "LR") => {
      dagreGraph.setGraph({ rankdir: direction });

      elements
        .filter((it: any) => it)
        .forEach((element: any) => {
          if (isNode(element)) {
            element.type = "customNode";
            dagreGraph.setNode(element.id, {
              width: 202,
              height: 120,
              padding: 100,
            });
          } else {
            const el = element as Edge;
            el.type = "custom";
            el.arrowHeadType = "arrow" as ArrowHeadType;
            dagreGraph.setEdge(el.source, el.target);
          }
        });

      dagre.layout(dagreGraph);
      return elements
        .filter((l: any) => l && l.target !== "not-found")
        .map((el: any) => {
          if (isNode(el)) {
            el.targetPosition = setTargetPositionByDirection(
              direction as any
            ) as Position;
            el.sourcePosition = setSourcePositionByDirection(
              direction as any
            ) as Position;

            const nodeWithPosition = dagreGraph.node(el.id);

            let x = (el as any)?.x_coordinate;
            let y = (el as any)?.y_coordinate;
            el.position = {
              x: x ? x : nodeWithPosition.x + Math.random() / 1000,
              y: y ? y : nodeWithPosition.y,
            };
          }
          return el;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const sourceHandler = React.useCallback((item: Pipeline) => {
    if (item?.next && item?.id) {
      return item.id.toString();
    } else if (!item?.next && item?.in) {
      return item.in.toString();
    } else if (!item?.next && item?.id) {
      return item.id.toString();
    } else if (item?.next && item?.in) {
      return item.in.toString();
    }
  }, []);

  const pipelineRender = React.useCallback(() => {
    const result = pipeline?.map((item) => {
      return {
        id: `${item.id}-line-${item.next}`,
        source: sourceHandler(item),
        target: item.next ? item.next?.toString() : "",
        sourceHandle: item?.source_position ? item?.source_position : "c",
        ...stylesLine,
        type: "custom",
      };
    });
    if (result) {
      return result?.filter((it) => it?.target);
    } else {
      return [];
    }
  }, [pipeline, sourceHandler]);

  const scenarioRender = React.useCallback(() => {
    if (scenario?.length) {
      return scenario?.map((block) => {
        const isEdit = false;
        const isDebug = false;
        return cubeRenderElement({
          block,
          isEdit,
          isDebug,
          selectedBlockId,
          setBlock: () => "",
        });
      });
    } else {
      return [];
    }
  }, [scenario, selectedBlockId]);

  React.useEffect(() => {
    if (scenarioRender() && pipelineRender()) {
      const data = getLayoutedElements([
        ...scenarioRender(),
        ...pipelineRender(),
      ]);
      setElements(data);
    }
  }, [setElements, scenarioRender, pipelineRender, getLayoutedElements]);

  React.useEffect(() => {
    if (init) {
      const initialBlock = {
        id: INIT_BLOCK,
        data: {
          label: (
            <Block
              init={init}
              isEdit={false}
              isDebug={false}
              setBlock={() => ""}
            />
          ),
        },
        type: INIT_BLOCK,
        x_coordinate: init.x_coordinate,
        y_coordinate: init.y_coordinate,
      };

      const nextIndexAfterInitial = pipeline?.findIndex((item) => item.first);

      if (!nextIndexAfterInitial && nextIndexAfterInitial! < 0) return;
      const target =
        nextIndexAfterInitial! >= 0
          ? `${pipeline?.[nextIndexAfterInitial!].id?.toString()}`
          : "not-found";

      const initialPipeline = {
        id: `initial-line`,
        source: INIT_BLOCK,
        target,
        ...stylesLine,
        type: "custom",
      };

      const data = getLayoutedElements([
        initialBlock,
        ...scenarioRender(),
        initialPipeline,
        ...pipelineRender(),
      ]);
      setElements(data);
    }
  }, [scenarioRender, pipelineRender, getLayoutedElements, init, pipeline]);

  const edgeTypes = {
    custom: ConnectionLine,
  };

  const nodeTypes = {
    customNode: isFileSystem ? CustomNodeForFileSystem : CustomNodeForTemplate,
  };

  const onLoad = React.useCallback(
    (rfi: any) => {
      if (!reactflowInstance) {
        setReactflowInstance(rfi);
        setLoading(false);
      }
    },
    [reactflowInstance]
  );

  React.useEffect(() => {
    if (reactflowInstance && elements.length > 0) {
      reactflowInstance!.fitView();
    }
  }, [reactflowInstance, elements.length]);

  return (
    <div className={style.root}>
      <ReactFlowP>
        <ReactFlow
          id="react-flow-container"
          className="flow-container"
          onLoad={onLoad}
          elements={elements}
          elementsSelectable={false}
          nodesDraggable={false}
          paneMoveable={false}
          panOnScroll={false}
          snapToGrid={true}
          zoomOnScroll={false}
          preventScrolling={false}
          panOnScrollMode={"free" as PanOnScrollMode.Free}
          connectionLineComponent={ConnectionLine as any}
          snapGrid={[40, 40]}
          arrowHeadColor="#2084E1"
          edgeTypes={edgeTypes as any}
          nodeTypes={nodeTypes as any}
          zoomOnDoubleClick={false}
          zoomOnPinch={false}
          onlyRenderVisibleElements={true}
          maxZoom={0.6}
          minZoom={0}
        />
      </ReactFlowP>

      {isLoading && (
        <div className={style.spinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
};
export default BlockPreview;
