import * as Const from "../constants";
import { ScenarioCountersAction } from "interfaces/state/scenarioCounters";

export const setScenarioCountersInit = () => {
  return {
    type: Const.SCENARIO_COUNTERS_INIT,
  };
};

export const setScenarioCounters = (
  payload: ScenarioCountersAction["payload"]
): ScenarioCountersAction => {
  return {
    type: Const.SCENARIO_COUNTERS_SUCCESS,
    payload,
  };
};

export const setScenarioCountersFail = () => {
  return {
    type: Const.SCENARIO_COUNTERS_FAIL,
  };
};

export const setScenarioCountersLoading = () => {
  return {
    type: Const.SCENARIO_COUNTERS_LOADING,
  };
};
