import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as TemplateActions from "actions/templateItem";

// WSApi.Template
export const templateGet = (data: AnswerData) => {
  if (data.error) {
    store.dispatch(TemplateActions.setTemplateItemFail());
    errorFromResponse(data.error);
  } else {
    store.dispatch(TemplateActions.setTemplateItem(data.response?.template));
  }
};
