import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { AnswerData } from "../index";
import * as ScenarioActions from "actions/scenario";

// WSApi.DeleteVariable
export const variableDelete = (data: AnswerData) => {
  const { getState, dispatch } = store;

  const scenario = scenarioItem$(getState());
  if (scenario?.scenario_id === data.response?.scenario_id) {
    dispatch(
      ScenarioActions.setScenarioSuccess({
        ...scenario,
        variables: data.response.variables,
        scenario: data.response.scenario,
      })
    );
  }
};
