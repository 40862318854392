import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as AvailableRolesActions from "actions/availableRoles";

// WSApi.CompanyRoles
export const companyGetRoles = (data: AnswerData) => {
  if (data.error) {
    store.dispatch(AvailableRolesActions.setAvailableRolesListFail());
    errorFromResponse(data.error);
  } else {
    store.dispatch(AvailableRolesActions.setAvailableRolesList(data.response));
  }
};
