import * as Const from "../constants";
import State from "../interfaces/state/paymentHistory";

export const initState: State = {
  isLoading: false,
  isError: false,
  list: [],
  hasMore: false,
  offset: 0,
};

export default function paymentHistory(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.PAY_HISTORY_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.PAY_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: action.payload,
      };

    case Const.PAY_HISTORY_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.PAY_HISTORY_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: action.payload,
      };

    case Const.PAY_HISTORY_HAS_MORE:
      return {
        ...state,
        hasMore: action.payload,
      };

    case Const.PAY_HISTORY_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };

    default:
      return state;
  }
}
