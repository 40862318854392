import { PaymentHistory } from "interfaces/state/paymentHistory";
import store from "store";
import { AnswerData } from "../index";
import * as PaymentHistoryActions from "actions/paymentHistory";
import { errorFromResponse } from "utils";
import { offset$, paymentsHistory$ } from "selectors/paymentHistory";

// WSApi.GetAllPayments
export const paymentGetAll = (data: AnswerData) => {
  store.dispatch(PaymentHistoryActions.setPaymentHistoryLoading(false));
  if (data.error) {
    store.dispatch(PaymentHistoryActions.setPaymentHistoryError());
    errorFromResponse(data.error);
  } else {
    const offset = offset$(store.getState());
    const paymentHistory = paymentsHistory$(store.getState());

    const allItemsLength = data.response.length + paymentHistory.length;
    store.dispatch(
      PaymentHistoryActions.setPaymentHistoryHasmore(
        allItemsLength > offset && data.response.length !== 0
      )
    );

    const newPaymentHistory = [...paymentHistory, ...data.response];

    store.dispatch(
      PaymentHistoryActions.setPaymentHistorySuccess(
        newPaymentHistory as PaymentHistory[]
      )
    );
  }
};
