import LangState from "interfaces/state/lang";
import * as Const from "../constants";

export const setLangInit = () => {
  return {
    type: Const.LANG_INIT,
  };
};

export const setLanguage = (payload: LangState["lang"]) => {
  return {
    type: Const.LANG_CHANGED,
    payload,
  };
};
