import * as Const from "../constants";
import State, { Folder } from "../interfaces/state/folders";

export const initState: State = {
  isLoading: false,
  isError: false,
  list: [],
  max_folders: 0,
  max_items_length: 0,
  max_scenarios: 0,
};

export default function folders(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.FOLDERS_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.FOLDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: action.payload,
      };

    case Const.FOLDERS_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.FOLDERS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.FOLDERS_ADD_NEW:
      return {
        ...state,
        list: [action.payload, ...state.list],
      };

    case Const.FOLDERS_DELETE:
      const folders = [...state.list] as Folder[];
      const filderedFolders = folders.filter(
        (folder) => !action.deleted_folders.includes(folder.id)
      );

      return {
        ...state,
        list: filderedFolders,
      };

    case Const.FOLDERS_MAX_VALUES:
      const { max_folders, max_items_length, max_scenarios } = action.payload;

      return {
        ...state,
        max_folders,
        max_items_length,
        max_scenarios,
      };

    default:
      return state;
  }
}
