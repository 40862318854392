import { privateRoute } from "routes";
import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { makeScenarioParams } from "utils";
import { AnswerData } from "../index";
import * as DebugActions from "actions/debug";
import * as ScenarioCreators from "creators/scenario";
import { useLocation, useNavigate } from "react-router-dom";

// WSApi.SaveDebugChanges
export const scenarioReplaceWithDebug = (
  data: AnswerData,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch, getState } = store;

  dispatch(DebugActions.setDebugLoading(false));
  if (!data?.error && data?.response?.scenario_id) {
    const scenario = scenarioItem$(getState());
    dispatch(
      ScenarioCreators.fetchScenarioById(
        data?.response?.scenario_id,
        undefined,
        scenario?.version_id
      ) as any
    );

    if (
      location.pathname === privateRoute.scenario + "/" ||
      location.pathname === privateRoute.scenario
    ) {

      const branch = searchParams.get("branch")!;

      navigate(
        `${privateRoute.scenario}?${makeScenarioParams({
          scenario_id: data.response.scenario?.scenario_id,
          version_id: data.response.scenario?.version_id,
          isEdit: true,
          isView: false,
          isDebug: false,
          isViewVersion: false,
          selectedBlock: null,
          branch
        })}`
      );
    }
  }
};
