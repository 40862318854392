import { Template } from "interfaces/state/templates";
import * as Const from "../constants";

export const setTemplateInit = () => {
  return {
    type: Const.TEMPLATES_INIT,
  };
};

export const setTemplateList = (payload: Template[]) => {
  return {
    type: Const.TEMPLATES_SUCCESS,
    payload,
  };
};

export const setTemplateListFail = () => {
  return {
    type: Const.TEMPLATES_FAIL,
  };
};

export const setTemplateListLoading = () => {
  return {
    type: Const.TEMPLATES_LOADING,
  };
};

export const setTemplatesAmount = (payload: number) => {
  return {
    type: Const.TEMPLATES_AMOUNT,
    payload,
  };
};
