import * as Const from "../constants";

export const setCancelSubscriptionInit = () => {
  return {
    type: Const.CANCEL_SUBSCRIPTION_INIT,
  };
};

export const setCancelSubscriptionSuccess = () => {
  return {
    type: Const.CANCEL_SUBSCRIPTION_SUCCESS,
  };
};

export const setCancelSubscriptionFail = () => {
  return {
    type: Const.CANCEL_SUBSCRIPTION_FAIL,
  };
};

export const setCancelSubscriptionLoading = () => {
  return {
    type: Const.CANCEL_SUBSCRIPTION_LOADING,
  };
};
