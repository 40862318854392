import { useLocation, useNavigate } from "react-router-dom";
import store from "store";
import { AnswerData } from "../index";
import * as ConnectorItemActions from "actions/connectorItem";
import { connectorItem$ } from "selectors/connectorItem";

// WSApi.ConnectorsMethodUpdateStatus
export const adminConnectorsMethodsUpdateStatus = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  _navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch, getState } = store;

  if (!data?.error) {
    const connectorItem = connectorItem$(getState());

    const newConnectorItem = { ...connectorItem };

    const index = connectorItem.methods.findIndex(
      (method) => method.id === data?.response?.id
    );

    if (index >= 0) {
      const newMethods = [...(connectorItem?.methods || [])];
      newMethods[index] = data.response;
      newConnectorItem.methods = newMethods;

      dispatch(ConnectorItemActions.setConnectorItem(newConnectorItem || null));
    }
  } else {
    dispatch(ConnectorItemActions.setConnectorItemFail());
  }
};
