import { scenarioItem$, selectedBlockId$ } from "selectors/scenario";
import store from "store";
import * as SensitiveDataActions from "actions/sensitiveData";

import { AnswerData } from "../index";

// WSApi.SensitiveDataGetAll
export const sensitiveDataGetAll = (data: AnswerData) => {
  if (!data.error) {
    const block_id = selectedBlockId$(store.getState());
    const scenario_id = scenarioItem$(store.getState())?.scenario_id;
    if (
      data.response.block_id === block_id &&
      scenario_id === data.response.scenario_id
    ) {
      Array.isArray(data.response.result) &&
        store.dispatch(
          SensitiveDataActions.setSensDataList(data.response.result)
        );
    }
  }
};
