import store from "store";
import { AnswerData } from "../index";
import * as JournalEventsActions from "actions/journalScenariosList";
import { list$, limit$, offset$ } from "selectors/journalScenariosList";

// WSApi.JournalGetAvailableScenarios
export const journalGetAvailableScenarios = (data: AnswerData) => {
  const { dispatch, getState } = store;

  if (!data?.error) {
    const offset = offset$(getState());
    const journalEvents = list$(getState());
    const limit = limit$(getState());

    dispatch(JournalEventsActions.setHasMore(data.response.length === limit));

    dispatch(
      JournalEventsActions.setJournalScenariosEvents(
        offset === 0 ? data.response : [...journalEvents, ...data.response]
      )
    );
  }

  data?.error && dispatch(JournalEventsActions.setJournalScenariosError());
};

// journal.search_by_available_scenarios

export const journalSearchByAvailableScenarios = (data: AnswerData) => {
  const { dispatch, getState } = store;

  if (!data?.error) {
    const offset = offset$(getState());
    const journalEvents = list$(getState());
    const limit = limit$(getState());

    dispatch(JournalEventsActions.setHasMore(data.response.length === limit));

    dispatch(
      JournalEventsActions.setJournalScenariosEvents(
        offset === 0 ? data.response : [...journalEvents, ...data.response]
      )
    );
  }

  data?.error && dispatch(JournalEventsActions.setJournalScenariosError());
};
