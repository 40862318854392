import * as Const from "../constants";
import State from "../interfaces/state/createAcc";

export const initState: State = {
  isLoading: false,
  isError: false,
  email: "",
  link: "",
  first_name: "",
  recaptcha: null,
  isShowConfirmPage: false,
};

export default function createAcc(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.CREATE_ACC_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.CREATE_ACC_SUCCESS:
      const { email, link, first_name, recaptcha } = action.payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        email,
        link,
        first_name,
        recaptcha,
      };

    case Const.CREATE_ACC_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.CREATE_ACC_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.IS_NEED_TO_SHOW_CONFIRM_PAGE:
      return {
        ...state,
        isShowConfirmPage: action.payload,
      };

    case Const.CREATE_ACC_CHANGE_EMAIL:
      return {
        ...state,
        email: action.payload,
      };

    default:
      return state;
  }
}
