export const publicRoute = {
  main: "/",
  plans: "/app/plans",
  logIn: "/app/log-in",
  passRecoveryFirstStep: "/app/password-recovery/step-1",
  passRecoverySecondStep: "/app/password-recovery/step-2",
  passRecoveryThirdStep: "/app/password-recovery/step-3",
  setEmail: "/app/joining/set_email",
  setName: "/app/joining/set_name",
  confirmChangeEmail: "/app/confirm-change-email",
  checkInvitation: "/app/check-invitation",
  confirmJoining: "/app/confirm_joining",
  registration: "/app/registration",
  questionnaire: "/app/questionnaire",
  closed: "/app/closed",
  plan_selected: "/app/plan_selected",
  set_name_and_email: "/app/joining/set-name-and-pass",
  emailWasSent: "/app/email-was-sent",
  unconfirmed: "/app/unconfirm-email",
  planIsNotSelected: "/app/plan-is-not-selected",
  changeEmail: "/app/change-email",
  notFound: "/app/404",
  helpCenter: "/app/help-center",
  helpRegistationBPM: "/app/help-center/registration-digitalbpm",
  helpCreateScenario: "/app/help-center/creating-a-scenario",
  helpVersionHistory: "/app/help-center/version-history",
  helpBlockWebhook: "/app/help-center/block-types/webhook",
  helpBlockSchedule: "/app/help-center/block-types/schedule",
  helpBlockExecute: "/app/help-center/block-types/execute",
  helpBlockCaseOf: "/app/help-center/block-types/case-of",
  helpBlockCounter: "/app/help-center/block-types/counter",
  helpDeleteGoogleSheets:
    "/app/help-center/authorization-management/deleting-authorizations-google-sheets",
  helpDeleteGoogleDrive:
    "/app/help-center/authorization-management/deleting-authorizations-google-drive",
  helpDeleteExcel:
    "/app/help-center/authorization-management/deleting-authorizations-excel",
  helpDeleteTiktok:
    "/app/help-center/authorization-management/deleting-authorizations-tiktok",
  helpExampleGoogleSheets: "/app/help-center/using/google-sheets",
  helpExampleGoogleDrive: "/app/help-center/using/google-drive",
  helpExampleExcel: "/app/help-center/using/excel",
  helpExampleTiktok: "/app/help-center/using/tiktok",
  helpCreateVerionTag: "/app/help-center/creating-a-tag-for-version",
  helpSortingAndSearchingVersions:
    "/app/help-center/sorting-and-searching-versions",
  helpRestoreVersion: "/app/help-center/restore-version",
  helpBranches: "/app/help-center/branches/list",
  helpBranchesMain: "/app/help-center/branches",
  helpBranchesFreeAndPremium:
    "/app/help-center/branches-free-and-premium-users",
  helpBranchesEnterprise: "/app/help-center/branches-enterprise-users",
  privacyServices: "/app/privacy-policy/digital-bpm-services",
  privacyEurope: "/app/privacy-policy/european-privacy-rights",
  privacyApplicant: "/app/privacy-policy/applicant-privacy-statement",
  privacyCookieNotice: "/app/privacy-policy/cookie-notice",
  termsOfUseAcceptableUsePolicy: "/app/terms-of-use/acceptable-use-policy",
};

export const privateRoute = {
  main: "/",
  dashboard: "/app/dashboard",
  process: "/app/process",
  automation: "/app/automation",
  faq: "/app/faq",
  settings: "/app/settings",
  exit: "/app/exit",
  team: "/app/team",
  plans: "/app/plans",
  billing: "/app/billing",
  setEmail: "/app/joining/set_email",
  setName: "/app/joining/set_name",
  confirmChangeEmail: "/app/confirm-change-email",
  scenario: "/app/automation/scenario",
  updateExistingPlan: "/app/update-existing-plan",
  updateSelectedPlan: "/app/update-selected-plan",
  checkInvitation: "/app/check-invitation",
  confirmJoining: "/app/confirm_joining",
  templates: "/app/automation/templates",
  template: "/app/automation/template",
  folder: "/app/automation/folder",
  redirectOauth2: "/app/automation/success/redirect/oauth2",
  templateView: "/app/automation/template/view",
  notFound: "/app/404",
  support: "/app/support",
  journalEvents: "/app/event/scenario",
  journalEventsDetails: "/app/event/details",
  journalEventLog: "/app/event/list",
  services: "/app/admin/service/list",
  users: "/app/admin/user/list",
  teams: "/app/admin/team/list",
  templateAdmin: "/app/admin/template/list",
  roles: "/app/admin/role/list",
  addService: "/app/admin/service/add",
  addMethod: "/app/admin/method/add",
  connectorItem: "/app/admin/service/:name/:connectorId",
};

export const routeWithoutSessionStatus = {
  passRecovery: "/app/password-recovery",
  confirmChangeEmail: "/app/confirm-change-email",
  confirm: "/app/confirm",
  setName: "/app/joining/set_name",
  setEmail: "/app/joining/set_email",
  checkInvitation: "/app/check-invitation",
  confirmJoining: "/app/confirm_joining",
  setNameAndPass: "/app/joining/set-name-and-pass",
  backendTest: "/app/backend-test",
  zoomverify: "/zoomverify",
  privacyPolicy: "/app/privacy-policy",
  termsOfUse: "/app/terms-of-use",
  apihub: "/apihub/hook",
};

export const routeWithoutRedirect = {
  support: "/app/support",
  documentation: "/app/documentation",
  notFound: "/app/404",
  confirm: "/app/confirm",
  confirmJoining: "/app/confirm_joining",
  emailWasSent: publicRoute.emailWasSent,
  changeEmail: publicRoute.changeEmail,
  plans: privateRoute.plans,
  helpCenter: publicRoute.helpCenter,
  helpRegistationBPM: publicRoute.helpRegistationBPM,
  helpCreateScenario: publicRoute.helpCreateScenario,
  helpVersionHistory: publicRoute.helpVersionHistory,
  helpBlockWebhook: publicRoute.helpBlockWebhook,
  helpBlockSchedule: publicRoute.helpBlockSchedule,
  helpBlockExecute: publicRoute.helpBlockExecute,
  helpBlockCaseOf: publicRoute.helpBlockCaseOf,
  helpBlockCounter: publicRoute.helpBlockCounter,
  helpDeleteGoogleSheets: publicRoute.helpDeleteGoogleSheets,
  helpDeleteGoogleDrive: publicRoute.helpDeleteGoogleDrive,
  helpDeleteExcel: publicRoute.helpDeleteExcel,
  helpDeleteTiktok: publicRoute.helpDeleteTiktok,
  helpExampleGoogleSheets: publicRoute.helpExampleGoogleSheets,
  helpExampleGoogleDrive: publicRoute.helpExampleGoogleDrive,
  helpExampleExcel: publicRoute.helpExampleExcel,
  helpExampleTiktok: publicRoute.helpExampleTiktok,
  helpCreateVerionTag: publicRoute.helpCreateVerionTag,
  helpSortingAndSearchingVersions: publicRoute.helpSortingAndSearchingVersions,
  helpRestoreVersion: publicRoute.helpRestoreVersion,
  helpBranches: publicRoute.helpBranches,
  helpBranchesMain: publicRoute.helpBranchesMain,
  helpBranchesFreeAndPremium: publicRoute.helpBranchesFreeAndPremium,
  helpBranchesEnterprise: publicRoute.helpBranchesEnterprise,
  privacyServices: publicRoute.privacyServices,
  privacyEurope: publicRoute.privacyEurope,
  privacyApplicant: publicRoute.privacyApplicant,
  privacyCookieNotice: publicRoute.privacyCookieNotice,
  termsOfUseAcceptableUsePolicy: publicRoute.termsOfUseAcceptableUsePolicy,
  faq: privateRoute.faq,
};

export const publicKeys = Object.keys(publicRoute);
export const privateKeys = Object.keys(privateRoute);
export const keysWithoutSessionStatus = Object.keys(privateRoute);
export const publicRouteValues = Object.values(publicRoute);
export const privateRouteValues = Object.values(privateRoute);
export const routesWithoutRedirects = Object.values(routeWithoutRedirect);
export const routeWithoutSessionStatusValues = Object.values(
  routeWithoutSessionStatus
);
