import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import { member_id$ } from "selectors/auth";
import { MemberRole } from "interfaces/state/team";
import { team$ } from "selectors/team";
import * as AuthActions from "actions/auth";
import * as TeamActions from "actions/team";

//  WSApi.UpdateMemberData
export const companyUpdateMemberData = (data: AnswerData) => {
  const { dispatch, getState } = store;
  if (data.error) {
    errorFromResponse(data.error);
  } else {
    dispatch(TeamActions.updateMemberData(data.response));
    const memberId = member_id$(getState());

    if (
      data.response.member_id === memberId &&
      !data.response.avatar_url &&
      data.response.avatar_url !== null
    ) {
      data.response.role &&
        dispatch(AuthActions.updateCompanyRole(data.response.role));

      if (data.response.role === MemberRole.OWNER) {
        window.location.reload();
      }
    }

    if (data.response.member_id) {
      const team = team$(getState());

      const index = team.findIndex(
        (member) => member.member_id === data.response.member_id
      );

      const newTeam = [...team];

      if ("avatar_url" in data.response) {
        newTeam[index].avatar_url = data.response.avatar_url;
      }

      if (data.response?.email) {
        newTeam[index].email = data.response.email;
      }

      if (data.response?.first_name) {
        newTeam[index].first_name = data.response.first_name;
      }

      if (data.response?.role) {
        newTeam[index].role = data.response.role;
      }

      if (data.response?.status) {
        newTeam[index].status = data.response.status;
      }

      dispatch(TeamActions.setTeamList(newTeam));

      if (
        data.response.member_id === memberId &&
        "avatar_url" in data.response
      ) {
        dispatch(AuthActions.setAuthAvatarUrl(data.response.avatar_url));
      }
    }
  }
};
