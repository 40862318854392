import JournalEventsState from "interfaces/state/journalEvents";
import * as Const from "../constants";

export const setjournalEventsInit = () => {
  return {
    type: Const.JOURNAL_EVENTS_INIT,
  };
};

export const setjournalEvents = (payload: JournalEventsState["list"]) => {
  return {
    type: Const.JOURNAL_EVENTS_SUCCESS,
    payload,
  };
};

export const setjournalEventsPending = () => {
  return {
    type: Const.JOURNAL_EVENTS_LOADING,
  };
};

export const setjournalEventsError = () => {
  return {
    type: Const.JOURNAL_EVENTS_FAIL,
  };
};

export interface JournalEventPagination {
  offset: number;
}

export const setJournalEventsPagination = (payload: JournalEventPagination) => {
  return {
    type: Const.JOURNAL_EVENTS_PAGINATION,
    payload,
  };
};

export const setHasMore = (payload: boolean) => ({
  type: Const.JOURNAL_EVENTS_HAS_MORE,
  payload,
});

export const setEditableVersionId = (payload: string | null) => ({
  type: Const.JOURNAL_EVENTS_EDITABLE_VERSION,
  payload,
});
