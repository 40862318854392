import { indicators$ } from "selectors/indicators";
import store from "store";
import { AnswerData } from "../index";
import * as IndicatorActions from "actions/indicators";

//  WSApi.AddIndicator
export const indicatorAdd = (data: AnswerData) => {
  const { getState, dispatch } = store;
  if (!data?.error) {
    const indicators = indicators$(getState());

    dispatch(IndicatorActions.setIndicators([...indicators, data.response]));
  }
};
