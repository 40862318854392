import { useLocation, useNavigate } from "react-router-dom";
import { privateRoute } from "routes";
import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { makeScenarioParams } from "utils";
import { AnswerData } from "../index";
import * as ScenarioActions from "actions/scenario";
import * as ScenarioCreators from "creators/scenario";

// WSApi.AddFirstBlock
export const blockAddFirst = (
  data: AnswerData,
  location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (!data.error) {
    const scenarioItem = scenarioItem$(store.getState());
    if (scenarioItem?.scenario_id === data.response?.scenario_id) {
      const newScenario = {
        scenario: data.response.scenario,
        pipeline: data.response.pipeline,
      };
      store.dispatch(
        ScenarioActions.setScenarioSuccess({
          ...scenarioItem,
          ...newScenario,
        })
      );

      store.dispatch(
        ScenarioCreators.updateBlock({
          name: "Block 2",
          id: data.response?.block_id,
        }) as any
      );

      if (
        window.location.pathname === privateRoute.scenario + "/" ||
        window.location.pathname === privateRoute.scenario
      ) {
        const searchParams = new URLSearchParams(window.location.search);

        const isEdit = searchParams.get("edit") === "1";
        const isView = searchParams.get("view") === "1";
        const branch = searchParams.get("branch")!;
        const selectedBlock = data?.response?.block_id;

        navigate(
          `${privateRoute.scenario}?${makeScenarioParams({
            scenario_id: scenarioItem?.scenario_id,
            version_id: scenarioItem?.version_id,
            isEdit,
            isView,
            isDebug: false,
            isViewVersion: false,
            selectedBlock,
            branch,
          })}`
        );
      }
    }
  }
};
