import * as React from "react";
import { Spinner } from "../spinner";
import cx from "classnames";
import style from "./button.module.scss";

export enum ButtonVariant {
  LIGHT_BLUE = "light-blue",
  WHITE_RED = "white-red",
  BLUE_WHITE = "blue-white",
  BLACK_WHITE = "black-white",
  INVISIBLE = "invisible",
  WHITE_BLUE = "white-blue",
}

interface IProps {
  value: string | JSX.Element;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading?: boolean;
  isDisable?: boolean;
  className?: string;
  containerClassname?: string;
  variant: ButtonVariant;
  tabIndex?: number;
  ariaLabel?: string;
  type?: "submit" | "reset" | "button";
}

export class Button extends React.PureComponent<IProps> {
  onBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { onClick } = this.props;
    if (onClick) onClick(e);
  };

  render() {
    const {
      value,
      containerClassname,
      onClick,
      isLoading,
      isDisable,
      className,
      variant,
      tabIndex,
      ariaLabel,
      type,
    } = this.props;

    const ButtonCX = cx([style.button], {
      [className as string]: className,
      [style.black]: variant === "black-white",
      [style.blue]: variant === "blue-white",
      [style.light]: variant === "light-blue",
      [style.white]: variant === "white-red",
      [style.invisible]: variant === "invisible",
      [style.whiteBlue]: variant === "white-blue",
    });

    const RootCX = cx(style.root, {
      [containerClassname || ""]: !!containerClassname,
    });

    return (
      <div className={RootCX}>
        <button
          className={ButtonCX}
          onClick={onClick}
          disabled={isDisable}
          tabIndex={tabIndex}
          aria-label={ariaLabel}
          type={type}
        >
          {!isLoading && value}
        </button>
        {isLoading && (
          <div className={style.spinner}>
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}
