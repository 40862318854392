import { privateRoute } from "routes";
import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { makeScenarioParams } from "utils";
import { AnswerData } from "../index";
import * as ScenarioCreators from "creators/scenario";
import * as DebugActions from "actions/debug";
import { useLocation, useNavigate } from "react-router-dom";

//  WSApi.ExitDebugScenario
export const scenarioDebugExit = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch, getState } = store;
  if (!data?.error) {
    const searchParams = new URLSearchParams(window.location.search);
    const branch = searchParams.get("branch")!;

    navigate(
      `${privateRoute.scenario}?${makeScenarioParams({
        scenario_id: data.response.scenario_id,
        version_id: data.response.version_id,
        isEdit: true,
        isView: false,
        isDebug: false,
        isViewVersion: false,
        selectedBlock: null,
        branch
      })}`
    );

    const scenario = scenarioItem$(getState());

    dispatch(
      ScenarioCreators.fetchScenarioById(
        data?.response?.scenario_id,
        undefined,
        scenario?.version_id
      ) as any
    );
  }
  dispatch(DebugActions.setDebugLoading(false));
};
