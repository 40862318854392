import {
  useCallback,
  useRef,
  MouseEvent,
  useMemo,
  useState,
  useEffect,
} from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import BlockPreview from "pages/templateItem/components/cubePreview";
import { AutomationScenario } from "interfaces/state/scenarios";
import { dayFrom } from "utils";
import { useIntersectionObserver, usePrevious } from "utils/customHooks";
import { ScenarioServices } from "components/scenarioServices";
import { ScenarioModals } from "components/scenarioModals";
import { ReactComponent as HeartIcon } from "assets/svg/heart-full-old.svg";
import style from "./scenario.module.scss";

interface IDispatchProps {}
interface IStateProps {}

interface OwnProps {
  droppedId: number | null | string;
  scenario: AutomationScenario;
  onScenarioToAnotherFolder: (id: number, scenario: number | string) => void;
  onClick: (scenario: AutomationScenario) => void;
  handleShowParentFolder: (val: boolean) => void;
  handleDroppedId: (id: string | null | number) => void;
  onDropEnd: () => void;
}

interface IProps extends IDispatchProps, IStateProps, OwnProps {}

export const Scenario = ({
  scenario,
  droppedId,
  onClick,
  onScenarioToAnotherFolder,
  handleShowParentFolder,
  handleDroppedId,
  onDropEnd,
}: IProps) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const active = useRef<boolean>(false);
  const prevActive = usePrevious(active.current);
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const [isOpenChangeCategory, setOpenCategory] = useState<boolean>(false);
  const isVisible = !!entry?.isIntersecting;
  const { name, modified, categories, active_branches, full_scenario } =
    scenario;

  const getScenarioCategories = useMemo(
    () => !!categories?.length && categories[0]?.name,
    [categories]
  );

  useEffect(() => {
    if (prevActive && !active.current) {
      handleShowParentFolder(false);
    }
  }, [prevActive, handleShowParentFolder]);

  const getActiveBranches = useMemo(
    () => active_branches?.join(" && "),
    [active_branches]
  );

  const onScenarioClick = useCallback(() => {
    !open && onClick(scenario);
  }, [onClick, open, scenario]);

  const handleTopLevelClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  const handleOpenPopover = (isOpen: boolean) =>
    setTimeout(() => setOpen(isOpen), 200);
  const handleCloseChangeCategory = () => setOpenCategory(false);
  const handleOpen = useCallback(() => setOpenCategory(true), []);
  const getTruncatedText = useMemo(
    () =>
      categories.length > 1 && (
        <div className={style.extraCategories} onClick={handleOpen}>{` +${
          categories.length - 1
        }`}</div>
      ),
    [categories, handleOpen]
  );

  const RootCX = cx(style.root, { [style.focused]: open });

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData("text/plain", scenario.scenario_id.toString());
    active.current = true;
    if (scenario.scenario_id) {
      handleDroppedId(scenario.scenario_id);
      setTimeout(() => {
        if (active.current) handleShowParentFolder(true);
      }, 1000);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (
      scenario &&
      droppedId &&
      droppedId !== scenario.scenario_id &&
      typeof droppedId === "number"
    ) {
      onScenarioToAnotherFolder(droppedId as number, scenario.scenario_id);
      handleDroppedId(null);
    }
    handleShowParentFolder(false);
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    handleShowParentFolder(false);
    onDropEnd();
    active.current = false;
  };

  const onWrapperClick = () => (active.current = true);
  const onMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    if (event.button === 2) {
      active.current = false;
      handleShowParentFolder(false);
    }
  };

  const formattedDate = useMemo(() => {
    let dateStr = dayFrom(modified, i18n.language);
    dateStr = dateStr.replace("minutes", "min.");
    return dateStr;
  }, [modified, i18n.language]);

  return (
    <div
      className={RootCX}
      ref={ref}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnd={handleDragEnd}
      draggable
      onClick={onWrapperClick}
      onMouseUp={onMouseUp}
    >
      <div className={style.body} onClick={onScenarioClick}>
        <div className={style.topLevel} onClick={handleTopLevelClick}>
          <ScenarioServices scenario={scenario} />
          <div className={style.statusContainer}>
            <div className={style.status}>{getActiveBranches}</div>
            <div className={style.modals}>
              <ScenarioModals
                item={scenario}
                onOpen={handleOpenPopover}
                isOpenChangeCategoryProp={isOpenChangeCategory}
                onOpenChangeCategory={handleCloseChangeCategory}
              />
            </div>
          </div>
        </div>
        <div className={style.preview}>
          {full_scenario?.init?.name && isVisible && (
            <BlockPreview
              pipeline={full_scenario?.pipeline}
              scenario={full_scenario?.scenario}
              init={full_scenario?.init}
              isFileSystem
            />
          )}
          {!full_scenario?.init?.name && isVisible && (
            <div className={style.emptyScenario}>
              <HeartIcon />
            </div>
          )}
        </div>
      </div>
      <div className={style.footer}>
        <div className={style.footerWrapper}>
          <div className={style.name} onClick={onScenarioClick} title={name}>
            {name}
          </div>
          <div
            className={style.categories}
            onClick={categories.length ? handleOpen : undefined}
          >
            <div className={style.categoryList}>{getScenarioCategories}</div>
            {getTruncatedText}
          </div>
        </div>
        <div className={style.date}>{formattedDate}</div>
      </div>
    </div>
  );
};
