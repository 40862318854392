import * as Const from "../constants";
import State from "../interfaces/state/funcParameter";

export const initState: State = {
  isLoading: false,
  isError: false,
  funcParameter: {},
};

export default function funcParameter(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.F_PARAM_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.F_PARAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        funcParameter: {
          ...state.funcParameter,
          [action.scenario_id]: {
            ...state.funcParameter[action.scenario_id],
            [action.block_id]: action.payload,
          },
        },
      };

    case Const.F_PARAM_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.F_PARAM_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
