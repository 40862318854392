import { Connector } from "interfaces/state/connectorItem";
import * as Const from "../constants";

export const setConnectorItemInit = () => {
  return {
    type: Const.CONNECTOR_ITEM_INIT,
  };
};

export const setConnectorItem = (payload: Connector | null) => {
  return {
    type: Const.CONNECTOR_ITEM_SUCCESS,
    payload,
  };
};

export const setConnectorItemFail = () => {
  return {
    type: Const.CONNECTOR_ITEM_FAIL,
  };
};

export const setConnectorItemLoading = () => {
  return {
    type: Const.CONNECTOR_ITEM_LOADING,
  };
};
