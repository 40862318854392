import React from "react";
import { Checkbox } from "components/checkbox";
import { Input } from "components/input";
import { Button, ButtonVariant } from "components/button";
import { useTranslation } from "react-i18next";
import { ModalWindow } from "components/modal";
import style from "./popup.module.scss";

interface IProps {
  inputValue?: string;
  disabled?: boolean;
  inputLabel?: string;
  isError?: boolean;
  errorMessage?: string;
  showCheckbox?: boolean;
  isChecked?: boolean;
  label?: string;
  placeholder?: string;
  checkboxText?: string;
  errorText?: string;
  title: string | JSX.Element;
  applyBtnText?: string;
  cancelBtnText?: string;
  hideApplyBtn?: boolean;
  hideCancelBtn?: boolean;
  hideInput?: boolean;
  mainBtnText?: string | React.ReactNode;
  mainBtnClassName?: string;
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  onApply?: () => void;
  onChange?: (value: string) => void;
  onChangeCheckbox?: () => void;
  onOpen?: () => void;
  maxInputLength?: number;
  minInputLength?: number;
  subtitle?: string | JSX.Element;
  isHideMainBtn?: boolean;
  disableOk?: boolean
}

export const Popup = ({
  showCheckbox,
  checkboxText,
  isChecked,
  inputValue,
  title,
  inputLabel,
  placeholder,
  isError,
  errorText,
  applyBtnText,
  cancelBtnText,
  hideCancelBtn,
  hideApplyBtn,
  hideInput,
  disabled,
  mainBtnText,
  mainBtnClassName,
  isOpen,
  minInputLength,
  maxInputLength,
  subtitle,
  isHideMainBtn,
  disableOk,
  onOpen,
  onClose,
  onSubmit,
  onChangeCheckbox,
  onApply,
  onChange,
}: IProps) => {
  const { t } = useTranslation();
  const [localInputError, setLocalInputError] = React.useState(false);
  const [localErrorText, setLocalErrorText] = React.useState("");
  const [isApplied, setApplied] = React.useState(false);

  React.useEffect(() => {
    if (inputValue || inputValue === "") {
      if (minInputLength && inputValue?.length < minInputLength) {
        setLocalInputError(true);
        setLocalErrorText(
          t("tooShort", {
            field: inputLabel || placeholder,
            min: minInputLength,
          })
        );
      } else {
        setLocalInputError(
          maxInputLength && inputValue?.length > maxInputLength
        );
        setLocalErrorText(errorText);
      }
    }
  }, [
    inputLabel,
    inputValue,
    isApplied,
    maxInputLength,
    minInputLength,
    placeholder,
    errorText,
    t,
  ]);

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (onSubmit && !localInputError) {
        onSubmit();
      }
    },
    [localInputError, onSubmit]
  );

  const handleChange = React.useCallback(
    (v: string) => {
      if (onChange) {
        onChange(v);
      }
    },
    [onChange]
  );

  const handleApply = React.useCallback(() => {
    setApplied(true);
    if (onApply && !localInputError) {
      onApply();
      setApplied(false);
    }
  }, [localInputError, onApply]);

  const handleClose = () => {
    if (onClose) {
      setApplied(false);
      onClose();
    }
  };

  return (
    <ModalWindow
      disabled={disabled}
      btnClassName={mainBtnClassName}
      btnValue={mainBtnText}
      isHideButton={isHideMainBtn}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      content={
        <div className={style.root}>
          <form className={style.box} onSubmit={handleSubmit}>
            <div className={style.head}>{title}</div>
            {subtitle && <div className={style.subhead}>{subtitle}</div>}
            {!hideInput && (
              <Input
                type="text"
                value={inputValue}
                onChange={handleChange}
                label={inputLabel}
                placeholder={placeholder}
                isError={isError || (localInputError && isApplied)}
                errorText={localInputError ? localErrorText : errorText}
                tabIndex={0}
                className={style.input}
                mainClassName={style.inputWrapper}
                containerClassName={style.inputContainer}
              />
            )}
            {showCheckbox && (
              <div className={style.checkbox}>
                <Checkbox
                  onChange={onChangeCheckbox}
                  label={checkboxText}
                  checked={isChecked}
                />
              </div>
            )}

            <div className={style.actions}>
              {!hideApplyBtn && (
                <Button
                  variant={ButtonVariant.BLUE_WHITE}
                  className={style.apply}
                  onClick={handleApply}
                  value={applyBtnText ? applyBtnText : "OK"}
                  isDisable={disableOk}
                />
              )}
              {!hideCancelBtn && (
                <Button
                  variant={ButtonVariant.WHITE_RED}
                  className={style.cancel}
                  onClick={handleClose}
                  value={cancelBtnText ? cancelBtnText : t("cancel")}
                />
              )}
            </div>
          </form>
        </div>
      }
    />
  );
};
