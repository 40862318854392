import State from "interfaces/state";

export const isError$ = (state: State) => state.methodReply.isError;
export const isLoading$ = (state: State) => state.methodReply.isLoading;
export const methodReply$ = (state: State) => state.methodReply.methodReply;
export const selectedOutputList$ = (state: State) =>
  state.methodReply.selectedOutputList;
export const pathToCompareKey$ = (state: State) =>
  state.methodReply.pathToCompareKey;
export const pathToList$ = (state: State) => state.methodReply.pathToList;
export const pathToValue$ = (state: State) => state.methodReply.pathToValue;
export const selectedCompareStr$ = (state: State) => {
  let data = "";
  pathToCompareKey$(state).forEach((strKey, index) => {
    const res = index === 0 ? strKey : "." + strKey;
    data += res;
  });

  return data;
};
