import store from "store";
import { AnswerData } from "../index";
import * as WebhookMethodActions from "actions/webhookMethod";

// WSApi.AllBlockMethods
export const serviceGetAllMethods = (data: AnswerData) => {
  if (data.error) {
    store.dispatch(WebhookMethodActions.setWebHookMethodFail());
  } else {
    store.dispatch(
      WebhookMethodActions.loadWebHookMethodSuccess(data.response)
    );
    store.dispatch(WebhookMethodActions.setWebHookMethodLoading(false));
  }
};
