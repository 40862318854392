export default interface ServiceParameterState {
  isLoading: boolean;
  isError: boolean;
  param: {
    [key: string]: { [key: string]: { [key: string]: ServiceParameterItem } };
  };
  keys: { [scenarioId: string]: { [blockId: string]: string[] } };
  inputParameter: {
    [scenarioId: string]: { [blockId: string]: IInputServiceParameter[] };
  };
  initialParameter: {
    [scenarioId: string]: { [blockId: string]: IInputServiceParameter[] };
  };
  parameter: {
    [scenarioId: string]: {
      [blockId: string]: { [key: string]: InputServiceParameter };
    };
  };
  parameterForComponent: {
    [scenarioId: string]: {
      [blockId: string]: { [key: string]: ServiceParameter };
    };
  };
  newParameters: {
    [blockId: string]: { [key: string]: ServiceParameter };
  };
}

export enum ServiceParameterType {
  STRING = "string",
  NUMBER = "number",
  NESTED_OBJECT = "nested_object",
  BOOLEAN = "boolean",
  NOT_EMPTY_LIST = "not_empty_list",
  INTEGER = "integer",
  LIST_OF_INTEGERS = "list_of_integers",
  ARRAY_OF_NESTED_OBJECTS = "array_of_nested_objects",
  ARRAY = "array",
  ENUM = "enum",
  DATE = "date",
  DATE_ISO_8601 = "date_iso_8601",
  BINARY = "binary",
  POSITIVE_INTEGER = "positive_integer",
  DECIMAL = "decimal",
  LIST_OF_STRINGS = "list_of_strings",
  LIST_OF_LISTS = "list_of_lists",
  EMAIL = "email",
  LIST_OF_NESTED_OBJECTS = "list_of_nested_objects",
  TIMESTAMP = "timestamp",
  LIST = "list",
  ONE_OF = "one_of",
  TEMPLATE = "template",
  INPUT = "input",
  VARIABLE = "variable",
}

export type AllParameterTypes = ServiceParameterType;

export interface InputServiceParameter {
  description: string;
  id: string;
  required: boolean;
  display_name: string;
  children: string[];
  type: AllParameterTypes;
  parent?: string;
  name: string;
}

export type ListWithVariables = string | string[];
export interface ServiceParameter {
  value: string | number | ListWithVariables[] | boolean;
  value_type:
    | "input"
    | "template"
    | "library"
    | "schema"
    | "list"
    | "variable"
    | "nested_object";
  id: string;
  children: string[];
  parent: string;
  type: AllParameterTypes;
  description: string;
  required: boolean;
  display_name: string;
  name: string;
}

export interface ServiceParameterAnswer {
  [key: string]: ServiceParameter;
}

export interface ServiceParameterItem {
  description: string;
  display_name: string;
  is_sensitive: boolean;
  required: boolean;
  type: ServiceParameterType;
  child_parameters?: { [key: string]: ServiceParameterItem };
  possible_entities?: ServiceParameterItem[];
}

export interface IInputServiceParameter {
  value:
    | string
    | IInputServiceParameter[]
    | number
    | ListWithVariables[]
    | boolean;
  value_type:
    | "input"
    | "template"
    | "library"
    | "schema"
    | "list"
    | "variable"
    | "nested_object";
  name?: string;
}
