import React from "react";
import styles from "./style.module.scss";
import cx from "classnames";
import { ReactComponent as CheckboxMark } from "../../assets/svg/checkmark.svg";

interface IProps {
  checked?: boolean;
  onChange?: () => void;
  label?: string;
}

export class Checkbox extends React.PureComponent<IProps> {
  render() {
    const { checked, label, onChange } = this.props;

    const CheckboxCX = cx(styles.checkbox, {
      [styles.checked]: checked,
    });
    return (
      <div className={styles.root} onClick={onChange}>
        <div className={CheckboxCX}>{checked && <CheckboxMark />}</div>
        <div className={styles.title}>{label ? label : null}</div>
      </div>
    );
  }
}
