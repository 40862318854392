import * as Const from "../constants";
import State from "../interfaces/state/dashboardInfo";

const stateCheckerByPayload = (
  state: State,
  updateKey: keyof State,
  payload: object | number | string | boolean
) => {
  return payload || payload === 0 ? payload : state[updateKey];
};

export const initState: State = {
  isLoading: false,
  isError: false,
  date: null,
  completed_tasks: 0,
  services: [],
  graph_by_tasks: [],
  graph_by_transactions: [],
  number_services: 0,
  number_services_max: 0,
  scenarios: 0,
  scenarios_max: 0,
  transactions: 0,
  transactions_per_month: 0,
};

export default function dashboardInfo(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.DASH_INFO_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.DASH_INFO_SUCCESS:
      const {
        completed_tasks,
        services,
        graph_by_tasks,
        graph_by_transactions,
        number_services,
        number_services_max,
        scenarios,
        scenarios_max,
        transactions,
        transactions_max,
        date,
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        completed_tasks: stateCheckerByPayload(
          state,
          "completed_tasks",
          completed_tasks
        ) as State["completed_tasks"],
        services: stateCheckerByPayload(
          state,
          "services",
          services
        ) as State["services"],
        graph_by_tasks: stateCheckerByPayload(
          state,
          "graph_by_tasks",
          graph_by_tasks
        ) as State["graph_by_tasks"],
        graph_by_transactions: stateCheckerByPayload(
          state,
          "graph_by_transactions",
          graph_by_transactions
        ) as State["graph_by_transactions"],
        number_services: stateCheckerByPayload(
          state,
          "number_services",
          number_services
        ) as State["number_services"],
        number_services_max: stateCheckerByPayload(
          state,
          "number_services_max",
          number_services_max
        ) as State["number_services_max"],
        scenarios: stateCheckerByPayload(
          state,
          "scenarios",
          scenarios
        ) as State["scenarios"],
        scenarios_max: stateCheckerByPayload(
          state,
          "scenarios_max",
          scenarios_max
        ) as State["scenarios_max"],
        transactions: stateCheckerByPayload(
          state,
          "transactions",
          transactions
        ) as State["transactions"],
        transactions_per_month: stateCheckerByPayload(
          state,
          "transactions_per_month",
          transactions_max
        ) as State["transactions_per_month"],
        date: stateCheckerByPayload(state, "date", date) as State["date"],
      };

    case Const.DASH_INFO_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.DASH_INFO_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.DASH_UPD_TRANSACTIONS:
      const transactionsList = [...state.graph_by_transactions];

      const todayTransactionIndex = transactionsList.findIndex(
        (tr) => tr.date === action.payload.date
      );

      if (todayTransactionIndex < 0) {
        const newTransactionList = [
          ...transactionsList,
          {
            date: action.payload.day_trans,
            transactions: action.payload.day_trans,
          },
        ];
        return {
          ...state,
          graph_by_transactions: newTransactionList,
          transactions: action.payload.month_trans,
        };
      } else {
        transactionsList[todayTransactionIndex].transactions =
          action.payload.day_trans;
        return {
          ...state,
          graph_by_transactions: transactionsList,
          transactions: action.payload.month_trans,
        };
      }

    case Const.DASH_UPD_TASKS:
      const tasksList = [...state.graph_by_tasks];

      const todayTaskIndex = tasksList.findIndex(
        (tr) => tr.date === action.payload.date
      );

      if (todayTaskIndex < 0) {
        const newTransactionList = [
          ...tasksList,
          {
            date: action.payload.day_trans,
            tasks: action.payload.day_tasks,
          },
        ];
        return {
          ...state,
          graph_by_tasks: newTransactionList,
          completed_tasks: action.payload.month_tasks,
        };
      } else {
        tasksList[todayTaskIndex].tasks = action.payload.day_tasks;

        return {
          ...state,
          graph_by_tasks: tasksList,
          completed_tasks: action.payload.month_tasks,
        };
      }

    default:
      return state;
  }
}
