import store from "store";
import { AnswerData } from "../index";
import * as VariablePanelActions from "actions/variablePanel";
import { searchResultByBlockId$ } from "selectors/variablePanel";

// WSApi.VariableSearch
export const variableSearch = (data: AnswerData) => {
  const { dispatch, getState } = store;
  if (!data?.error) {
    if (data?.response) {
      let result = data.response?.name
        ? {}
        : { ...searchResultByBlockId$(getState()) };

      data?.response?.items?.forEach(
        ({ block_id, parameters, method_reply, id }) =>
          (result = { ...result, [block_id || id]: parameters || method_reply })
      );

      dispatch(VariablePanelActions.setVariableResult(result));
    }
  }
  dispatch(VariablePanelActions.setVariablePanelLoading(false));
};
