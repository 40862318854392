import store from "store";
import { AnswerData } from "..";
import * as DebugActions from "actions/debug";
import * as ScenarioActions from "actions/scenario";

// WSApi.DiscardChanges
export const scenarioDebugReset = (data: AnswerData) => {
  store.dispatch(DebugActions.setDebugLoading(false));
  if (!data?.error && data?.response) {
    store.dispatch(
      ScenarioActions.setScenarioSuccess({
        ...data.response.scenario,
        scenario_status: data.response.scenario_status,
      })
    );
  }
};
