import State from "interfaces/state";

export const activeId$ = (state: State) => state.variablePanel.activeId;
export const isLoading$ = (state: State) => state.variablePanel.isLoading;
export const searchResultByBlockId$ = (state: State) =>
  state.variablePanel.searchResultByBlockId;
export const predictionBlocks$ = (state: State) =>
  state.variablePanel.prediction;
export const temporaryVariable$ = (state: State) =>
  state.variablePanel.temporaryVariable;

export const prevActiveId$ = (state: State) => state.variablePanel.prevActiveId;
export const activeParameterIndex$ = (state: State) =>
  state.variablePanel.activeParameterIndex;
