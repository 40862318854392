export enum WSApi {
  Init = "initialization",
  ChangeFirstName = "account.change_first_name",
  ChangeEmailFirstStep = "account.change_email.step_1",
  ChagePassword = "account.change_password",
  ResendChangeEmailLetter = "account.resend_change_email_letter",
  UpdateSubscription = "subscription.update_existing",
  CompanyGetMembers = "company.get_members",
  ChangeMemberRole = "company.change_member_role",
  AddMember = "company.add_member",
  DeleteMember = "company.delete_member",
  CompanyGetLinkInvitation = "company.get_invitation_link",
  GetScenario = "scenario.get",
  GetAllPayments = "payment.get_all",
  GetMainFolder = "folder.get_main",
  GetAllFolders = "folder.get_all",
  CreateFolder = "folder.create",
  CancelSubscription = "subscription.cancel",
  BillingInfo = "subscription.get_billing_info",
  CompanyRoles = "company.get_roles",
  LogOut = "account.log_out",
  DeletePayment = "payment.delete",
  BillingCSVLink = "payment.get_csv_history",
  DownloadPdfPaymentHistory = "payment.get_pdf_receipt",
  NewPayment = "payment.new",
  UpdateMemberData = "company.update_member_data",
  CompanyGetAvailable = "company.get_available",
  CompanyChange = "company.change",
  CompanyChangeName = "company.change_name",
  TemplatesList = "template.get_all",
  Template = "template.get",
  GetAllScenarioUsers = "scenario.get_all_users",
  AddNewUserToScenario = "scenario.add_user",
  DeleteUserFromScenario = "scenario.delete_user",
  RenameFolder = "folder.rename",
  DeleteFolder = "folder.delete",
  DeleteScenario = "scenario.delete",
  RenameScenario = "scenario.rename",
  SceanarioAddSnapsot = "scenario.add_snapshot",
  CompanyDelete = "company.delete",
  ScenarioCreateV1 = "scenario.create_v1",
  ScenarioCreate = "scenario.create",
  ScenarioServices = "service.get_all",
  UpdateInitBlock = "block.update_init",
  WebHooksMethods = "service.get_all_webhook_methods",
  ScenarioParameter = "service.get_all_parameters",
  AddFirstBlock = "block.add_first",
  AddBlock = "block.add",
  UpdateBlock = "block.update",
  AllBlockMethods = "service.get_all_methods",
  DeleteBlock = "block.delete",
  CreateVariable = "variable.create",
  UpdateVariable = "variable.update",
  AllBlockReplyMethods = "service.get_method_reply",
  BlockAddBranch = "block.add_branch",
  CreateFromTemplate = "scenario.create_from_template",
  ScenarioStart = "scenario.start",
  ScenarioStop = "scenario.stop",
  BlockUpdateCoordinates = "block.update_coordinates",
  WebhookServices = "service.get_webhook_services",
  UpdateBlockType = "block.update_type",
  StatTransAndTasks = "statistic.get_transactions_and_tasks",
  StatInfoByServices = "statistic.get_info_by_services",
  StatInfoByScenarios = "statistic.get_info_by_scenarios",
  BlockUnchainBranch = "block.unchain_branch",
  SensitiveDataSave = "sensitive_data.save",
  SensitiveDataGetAll = "sensitive_data.get_all",
  DashboarUpdateTask = "statistic.update_tasks",
  DashboarUpdateTransactions = "statistic.update_transactions",
  UpdateSubscriptionNew = "subscription.update",
  SubscriptionGetInfo = "subscription.get_info",
  GetLibraries = "library.get_all",
  GetFunctions = "library.get_functions",
  GetLibParameters = "library.get_parameters",
  GetAvailableVariables = "variable.get_available",
  SetAuthData = "service.set_authorization_data",
  ServiceAvaiableMethods = "service.get_available_method_reply",
  SensitiveDataDelete = "sensitive_data.delete",
  BlockAddOption = "block.add_parameter_option",
  DeleteUserAvatar = "account.delete_avatar",
  AccountChangeAvatar = "account.change_avatar",
  GetIndicators = "indicator.get",
  GetAvailableCounter = "counter.get_available",
  AddIndicator = "indicator.add",
  DeleteIndicator = "indicator.delete",
  UpdateIndicator = "indicator.update",
  UpdateIndicatorTransaction = "indicators.update_transactions",
  BlockGetInfoForSchedule = "block.get_info_for_schedule",
  GetDebugScenario = "scenario.debug.get",
  StartDebugScenario = "scenario.debug.start",
  ExitDebugScenario = "scenario.debug.exit",
  PassDebug = "scenario.debug.pass",
  DebugFinished = "scenario.debug.finished",
  SaveDebugChanges = "scenario.replace_with_debug",
  DiscardChanges = "scenario.debug.reset",
  CreatePaymentMethod = "payment_method.create",
  DefaultPaymentMethod = "payment_method.get_default",
  PaymentMethods = "payment_method.get",
  DeletePaymentMethod = "payment_method.delete",
  DefaultPaymentMethodSet = "payment_method.set_default",
  UpdatePaymentMethod = "payment_method.update",
  DeleteVariable = "variable.delete",
  TryAgainPayment = "invoice.pay_previous",
  GetScenarioVersionByTag = "scenario_version.get_by_tag",
  GetScenarioVersions = "scenario_version.get_all",
  CreateScenarioVersion = "scenario_version.create",
  SetActiveScenarioVersion = "scenario_version.set_active",
  SetTagScenarioVersion = "scenario_version.set_tag",
  DeleteScenarioTag = "scenario_version.delete_tag",
  DeleteScenarioVersion = "scenario_version.delete",
  ReloadPage = "page.reload",
  GetEditableScenario = "scenario_version.get_editable",
  SetBlockedVersion = "scenario_version.set_blocked",
  RestoreScenarioVersion = "scenario_version.restore_previous",
  AddNewScenarioVersion = "scenario_version.new",
  StatisticUpdateCounterTransactions = "statistic.update_counter_transactions",
  CheckInitAuth = "block.check_init_authorization",
  FolderScenarioSearch = "folder.search",
  FolderMoveFolderToFolder = "folder.move_folder_to_folder",
  FolderMoveScenarioToFolder = "folder.move_scenario_to_folder",
  GetAllScenarioCategories = "scenario_categories.get_all",
  AddScenarioCategory = "scenario_categories.add",
  DeleteScenarioCategory = "scenario_categories.remove",
  AddCompanyCategory = "company_categories.add",
  GetAllCompanyCategories = "company_categories.get_all",
  DeleteCompanyCategory = "company_categories.remove",
  RenameCompanyCategory = "company_categories.rename",
  ScenarioCheck = "scenario.check",
  CompanyChangeInvLink = "company.change_invitation_link",
  GetJournalEvents = "journal.get_events",
  BlockUpdateCoordinatesMultiple = "block.update_coordinates_multiple",
  AcctountDeleteCompany = "account.delete_company",
  CompanyChangeMemberAvatar = "company.change_member_avatar",
  JournalGetAvailableScenarios = "journal.get_available_scenarios",
  JournalSearchByAvailableScenarios = "journal.search_by_available_scenarios",
  JournalGetByEvent = "journal.get_by_event",
  VariablePrediction = "variable.search_prediction",
  VariableSearch = "variable.search",
  ScenarioUnlockVersion = "scenario.version_unlock",
  ConnectorsGet = "admin.connectors.get",
  ConnectorsSearch = "admin.connectors.search",
  ConnectorsMethodsGet = "admin.connectors.methods.get",
  ConnectorsMethodsSearch = "admin.connectors.methods.search",
  ConnectorsUpdateStatus = "admin.connectors.update_status",
  ConnectorsMethodUpdateStatus = "admin.connectors.methods.update_status",
  AdminConnectorCreate = "admin.connector.create",
  AdminConnectorDelete = "admin.connector.delete",
  AdminConnectorUpdate = "admin.connector.update",
}
