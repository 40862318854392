import coreUa from "./public/locales/ua/core.json";
import coreEn from "./public/locales/en/core.json";
import servicesEn from "./public/locales/en/services.json";
import servicesUa from "./public/locales/ua/services.json";
import landingEn from "./public/locales/en/landing.json";
import landingUa from "./public/locales/ua/landing.json";
import automationEn from "./public/locales/en/automation.json";
import automationUa from "./public/locales/ua/automation.json";
import settingsEn from "./public/locales/en/settings.json";
import settingsUa from "./public/locales/ua/settings.json";
import changeEmailConfirmEn from "./public/locales/en/changeEmailConfirm.json";
import changeEmailConfirmUa from "./public/locales/ua/changeEmailConfirm.json";
import checkInvitationEn from "./public/locales/en/checkInvitation.json";
import checkInvitationUa from "./public/locales/ua/checkInvitation.json";
import dashboardEn from "./public/locales/en/dashboard.json";
import dashboardUa from "./public/locales/ua/dashboard.json";
import setEmailEn from "./public/locales/en/setEmail.json";
import setEmailUa from "./public/locales/ua/setEmail.json";
import setNameEn from "./public/locales/en/setName.json";
import setNameUa from "./public/locales/ua/setName.json";
import subscriptionEn from "./public/locales/en/subscription.json";
import subscriptionUa from "./public/locales/ua/subscription.json";
import scenarioEn from "./public/locales/en/scenario.json";
import scenarioUa from "./public/locales/ua/scenario.json";
import supportEn from "./public/locales/en/support.json";
import supportUa from "./public/locales/ua/support.json";
import documentationEn from "./public/locales/en/documentation.json";
import documentationUa from "./public/locales/ua/documentation.json";
import versionEn from "./public/locales/en/version.json";
import versionUa from "./public/locales/ua/version.json";
import agreementUa from "./public/locales/ua/agreement.json";
import agreementEn from "./public/locales/en/agreement.json";
import journalEventsEn from "./public/locales/en/journalEvents.json";
import journalEventsUa from "./public/locales/ua/journalEvents.json";
import helpCenterEn from "./public/locales/en/helpCenter.json";
import helpCenterUa from "./public/locales/ua/helpCenter.json";
import privacyPolicyUa from "./public/locales/ua/privacyPolicy.json";
import privacyPolicyEn from "./public/locales/en/privacyPolicy.json";
import termsOfUseEn from "./public/locales/en/termsOfUse.json";
import termsOfUseUa from "./public/locales/ua/termsOfUse.json";
import servicePageUa from "./public/locales/ua/servicePage.json";
import servicePageEn from "./public/locales/en/servicePage.json";
import adminEn from "./public/locales/en/admin.json";
import adminUa from "./public/locales/ua/admin.json";
import addServiceEn from "./public/locales/en/addService.json";
import addServiceUa from "./public/locales/ua/addService.json";

export const en = {
  core: coreEn,
  services: servicesEn,
  landing: landingEn,
  automation: automationEn,
  settings: settingsEn,
  changeEmailConfirm: changeEmailConfirmEn,
  checkInvitation: checkInvitationEn,
  dashboard: dashboardEn,
  setEmail: setEmailEn,
  setName: setNameEn,
  subscription: subscriptionEn,
  scenario: scenarioEn,
  support: supportEn,
  documentation: documentationEn,
  version: versionEn,
  agreement: agreementEn,
  journalEvents: journalEventsEn,
  helpCenter: helpCenterEn,
  privacyPolicy: privacyPolicyEn,
  termsOfUse: termsOfUseEn,
  servicePage: servicePageEn,
  admin: adminEn,
  addService: addServiceEn
};

export const ua = {
  core: coreUa,
  services: servicesUa,
  landing: landingUa,
  automation: automationUa,
  settings: settingsUa,
  changeEmailConfirm: changeEmailConfirmUa,
  checkInvitation: checkInvitationUa,
  dashboard: dashboardUa,
  setEmail: setEmailUa,
  setName: setNameUa,
  subscription: subscriptionUa,
  scenario: scenarioUa,
  support: supportUa,
  documentation: documentationUa,
  version: versionUa,
  agreement: agreementUa,
  journalEvents: journalEventsUa,
  helpCenter: helpCenterUa,
  privacyPolicy: privacyPolicyUa,
  termsOfUse: termsOfUseUa,
  servicePage: servicePageUa,
  admin: adminUa,
  addService: addServiceUa
};

export const ns = [
  "core",
  "services",
  "landing",
  "automation",
  "settings",
  "changeEmailConfirm",
  "checkInvitation",
  "dashboard",
  "setEmail",
  "setName",
  "subscription",
  "scenario",
  "support",
  "documentation",
  "version",
  "agreement",
  "journalEvents",
  "helpCenter",
  "privacyPolicy",
  "termsOfUse",
  "servicePage",
  "admin",
  "addService"
];
