import * as Const from "../constants";
import State, { LANGUAGE } from "../interfaces/state/lang";

export const initState: State = {
  lang: LANGUAGE.EN,
};

export default function lang(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.LANG_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.LANG_CHANGED:
      return {
        ...state,
        lang: action.payload,
      };

    default:
      return state;
  }
}
