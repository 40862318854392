import store from "store";
import { AnswerData } from "../index";
import * as AvailableVariableAction from "actions/availableVariable";

// WSApi.GetAvailableVariables
export const variableGetAvailable = (data: AnswerData) => {
  if (!data?.error) {
    store.dispatch(
      AvailableVariableAction.setVariableList(
        data.response.result,
        data.response.scenario_id,
        data.response.block_id
      )
    );
  }
};
