import { FC, useCallback, useMemo, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { ReactComponent as MoreIcon } from "assets/svg/fi-more-horizontal.svg";
import { useAppDispatch } from "hooks/redux";
import { Folder } from "interfaces/state/folders";
import ScenarioHeaderPopover, {
  PopoverTheme,
} from "pages/scenario/components/scenarioHeaderPopover";
import { DropDownItem } from "pages/scenario/components/dropdownItem";
import { Popup } from "components/popup";
import * as FolderCreators from "creators/folders";
import style from "./folderModals.module.scss";

interface IProps {
  item: Folder;
  onOpen?: (value: boolean) => void;
}

export const FolderModals: FC<IProps> = ({ item, onOpen }) => {
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();
  const [name, setName] = useState(item.name);
  const [isOpenEdit, setOpenEdit] = useState<boolean>(false);
  const [isOpenDelete, setOpenDelete] = useState<boolean>(false);

  const handleCloseEdit = useCallback(() => {
    setOpenEdit(false);

    if (item.name !== name) {
      setName(item.name);
    }
  }, [name, item.name]);

  const handleCloseDelete = useCallback(() => setOpenDelete(false), []);
  const handleOpenEdit = useCallback(() => setOpenEdit(true), []);
  const handleOpenDelete = useCallback(() => setOpenDelete(true), []);

  const onSubmitRename = () => {
    dispatch(FolderCreators.renameFolder(item.id, name));
    setOpenEdit(false);
  };

  const handleNameChange = (name: string) => setName(name);

  const onSubmitDelete = () => {
    dispatch(FolderCreators.deleteFolder(item.id));
    handleCloseDelete();
  };

  const btns = useMemo(
    () => [
      {
        className: style.dropdownItem,
        text: t("automation:renameFolder"),
        onClick: handleOpenEdit,
      },
      {
        className: style.dropdownItem,
        text: t("automation:deleteFolder"),
        onClick: handleOpenDelete,
      },
    ],
    [handleOpenDelete, handleOpenEdit, t]
  );

  const MoreCX = (open: boolean) => cx(style.icon, { [style.open]: open });

  const overlay = useMemo(() => {
    const { language } = i18n;

    const ContentCX = cx(style.content, {
      [style.uk]: language === "uk",
      [style.en]: language === "en",
    });

    return (
      <div className={ContentCX}>
        <div className={style.triangle} />
        <div className={style.contentBtns}>
          {btns.map((button) => (
            <DropDownItem key={button.text} {...button} />
          ))}
        </div>
      </div>
    );
  }, [btns, i18n]);

  return (
    <>
      <ScenarioHeaderPopover
        theme={PopoverTheme.LIGHT}
        overlay={() => overlay}
        wrapperClassname={style.popoverWrapper}
        hideArrow
        getOpenToChild
        onOpen={onOpen}
      >
        {(open: boolean) => <MoreIcon className={MoreCX(open)} />}
      </ScenarioHeaderPopover>
      <Popup
        mainBtnClassName={style.createScenario}
        isHideMainBtn
        inputValue={name}
        inputLabel={t("folderName")}
        onChange={handleNameChange}
        isOpen={isOpenEdit}
        onClose={handleCloseEdit}
        title={t("changeFolderName")}
        onApply={onSubmitRename}
        maxInputLength={100}
        minInputLength={1}
        errorText={
          name.length > 100
            ? t("folderName100Characters")
            : name.length < 1
            ? t("tooShortName")
            : undefined
        }
        disableOk={!name.trim()}
      />

      <Popup
        mainBtnClassName={style.createScenario}
        isHideMainBtn
        hideInput
        isOpen={isOpenDelete}
        onClose={handleCloseDelete}
        title={t("deleteFolder")}
        subtitle={t("deleteFolderText", { folder: item.name })}
        onApply={onSubmitDelete}
      />
    </>
  );
};
