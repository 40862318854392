import store from "store";
import { AnswerData } from "../index";
import * as JournalEventsActions from "actions/journalEvents";
import { journalEvents$, limit$, offset$ } from "selectors/journalEvents";

// WSApi.GetJournalEvents
export const journalGetEvents = (data: AnswerData) => {
  const { dispatch, getState } = store;

  if (!data?.error) {
    const offset = offset$(getState());
    const journalEvents = journalEvents$(getState());
    const limit = limit$(getState());

    dispatch(
      JournalEventsActions.setHasMore(data.response.items.length === limit)
    );

    dispatch(
      JournalEventsActions.setEditableVersionId(
        data.response?.editable_version_id || null
      )
    );

    dispatch(
      JournalEventsActions.setjournalEvents(
        offset === 0
          ? data.response.items
          : [...journalEvents, ...data.response.items]
      )
    );
  }

  data?.error && dispatch(JournalEventsActions.setjournalEventsError());
};
