export const USER_INIT = "USER_INIT";
export const GOOGLE_AUTH_PUBLIC = "6Ld2TiAaAAAAAGSPGD4TXtevv1ShmC627J_gUwK9";
// export const GOOGLE_AUTH_PUBLIC = "6Ld23uEZAAAAACHpc-nMwqn0k2ZWTxvT2V0CW2n2";
export const GOOGLE_AUTH_PRIVATE = "6Ld2TiAaAAAAALFTbiEj9m7XqR5W2JD89q7Wn3Re";
// export const GOOGLE_AUTH_PRIVATE = "6Ld23uEZAAAAAHTv66xiypcfLFjt-a4OXWlzwEyB";

// Auth state
export const AUTH_INIT = "AUTH_INIT";
export const AUTH_PARAMS_SUCCESS = "AUTH_PARAMS_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_END_OF_LOADING = "AUTH_END_OF_LOADING";
export const AUTH_SESSION_STATUS = "AUTH_SESSION_STATUS";
export const AUTH_SET_NEW_NAME_FROM_WS = "AUTH_SET_NEW_NAME_FROM_WS";
export const LOG_OUT = "LOG_OUT";
export const AUTH_HIDE_HEADER = "AUTH_HIDE_HEADER";
export const AUTH_SET_EMAIL = "AUTH_SET_EMAIL";
export const AUTH_AVATAR_URL = "AUTH_AVATAR_URL";
export const AUTH_APPLY_COOKIE = "AUTH_APPLY_COOKIE";
export const AUTH_SET_COMPANY_NAME = "AUTH_SET_COMPANY_NAME";
export const AUTH_TOKEN_VALIDATION = "AUTH_TOKEN_VALIDATION";
export const AUTH_ERROR_PAYLOAD = "AUTH_ERROR_PAYLOAD";
export const AUTH_COMPANY_ROLE = "AUTH_COMPANY_ROLE";
export const AUTH_HELP_CENTER_MODAL = "AUTH_HELP_CENTER_MODAL";

// Create acc state
export const CREATE_ACC_INIT = "CREATE_ACC_INIT";
export const CREATE_ACC_SUCCESS = "CREATE_ACC_SUCCESS";
export const CREATE_ACC_FAIL = "CREATE_ACC_FAIL";
export const CREATE_ACC_LOADING = "CREATE_ACC_LOADING";
export const IS_NEED_TO_SHOW_CONFIRM_PAGE = "IS_NEED_TO_SHOW_CONFIRM_PAGE";
export const CREATE_ACC_CHANGE_EMAIL = "CREATE_ACC_CHANGE_EMAIL";

// Confirm email state
export const CONFIRM_INIT = "CONFIRM_INIT";
export const CONFIRM_PARAMS_SUCCESS = "CONFIRM_PARAMS_SUCCESS";
export const CONFIRM_FAIL = "CONFIRM_FAIL";
export const CONFIRM_LOADING = "CONFIRM_LOADING";
export const CONFIRM_SUCCESS = "CONFIRM_SUCCESS";
export const SET_UNCONFIRMED = "SET_UNCONFIRMED";
export const SET_CONFIRM_ERROR_TEXT = "SET_CONFIRM_ERROR_TEXT";

//Resend Email state
export const RESEND_EMAIL_INIT = "RESEND_EMAIL_INIT";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
export const RESEND_EMAIL_FAIL = "RESEND_EMAIL_FAIL";
export const RESEND_EMAIL_LOADING = "RESEND_EMAIL_LOADING";

// Send pass state
export const SEND_PASS_INIT = "SEND_PASS_INIT";
export const SEND_PASS_SUCCESS = "SEND_PASS_SUCCESS";
export const SEND_PASS_FAIL = "SEND_PASS_FAIL";
export const SEND_PASS_LOADING = "SEND_PASS_LOADING";

// Change email state
export const CHANGE_EMAIL_INIT = "CHANGE_EMAIL_INIT";
export const CHANGE_EMAIL_PARAMS_SUCCESS = "CHANGE_EMAIL_PARAMS_SUCCESS";
export const CHANGE_EMAIL_FAIL = "CHANGE_EMAIL_FAIL";
export const CHANGE_EMAIL_LOADING = "CHANGE_EMAIL_LOADING";
export const CHANGE_EMAIL_SUCCESS = "CHANGE_EMAIL_SUCCESS";

// Plans state
export const PLANS_INIT = "PLANS_INIT";
export const PLANS_SUCCESS = "PLANS_SUCCESS";
export const PLANS_FAIL = "PLANS_FAIL";
export const PLANS_LOADING = "PLANS_LOADING";

// Subscription state
export const SUBSCR_INIT = "SUBSCR_INIT";
export const SUBSCR_SUCCESS = "SUBSCR_SUCCESS";
export const SUBSCR_FAIL = "SUBSCR_FAIL";
export const SUBSCR_LOADING = "SUBSCR_LOADING";
export const SUBSCR_SELECTED_PARAMS = "SUBSCR_SELECTED_PARAMS";
export const SUBSCRIPTION_PARAMS = "SUBSCRIPTION_PARAMS";
export const SUBSCR_TRANSACTIONS = "SUBSCR_TRANSACTIONS";
export const SUBSCR_MAX_MEMBERS = "SUBSCR_MAX_MEMBERS";
export const SUBSCR_TEAM = "SUBSCR_TEAM";

// Questionnaire state
export const QUESTIONNAIRE_INIT = "QUESTIONNAIRE_INIT";
export const QUESTIONNAIRE_SET_STATUS = "QUESTIONNAIRE_SET_STATUS";
export const QUESTIONNAIRE_SET_DIRECTIONS = "QUESTIONNAIRE_SET_DIRECTIONS";
export const QUESTIONNAIRE_SET_ROLES = "QUESTIONNAIRE_SET_ROLES";
export const QUESTIONNAIRE_SET_TASKS = "QUESTIONNAIRE_SET_TASKS";
export const QUESTIONNAIRE_SET_TEAMS = "QUESTIONNAIRE_SET_TEAMS";
export const QUESTIONNAIRE_LOADED_SUCCESS = "QUESTIONNAIRE_LOADED_SUCCESS";
export const QUESTIONNAIRE_LOADING = "QUESTIONNAIRE_LOADING";
export const QUESTIONNAIRE_ERROR = "QUESTIONNAIRE_ERROR";

// Dashboard info state
export const DASH_INFO_INIT = "DASH_INFO_INIT";
export const DASH_INFO_SUCCESS = "DASH_INFO_SUCCESS";
export const DASH_INFO_FAIL = "DASH_INFO_FAIL";
export const DASH_INFO_LOADING = "DASH_INFO_LOADING";
export const DASH_UPD_TRANSACTIONS = "DASH_UPD_TRANSACTIONS";
export const DASH_UPD_TASKS = "DASH_UPD_TASKS";

// Team state
export const TEAM_INIT = "TEAM_INIT";
export const TEAM_SUCCESS = "TEAM_SUCCESS";
export const TEAM_FAIL = "TEAM_FAIL";
export const TEAM_LOADING = "TEAM_LOADING";
export const TEAM_CHANGE_MEMBER_ROLE = "TEAM_CHANGE_MEMBER_ROLE";
export const TEAM_CHANGE_MEMBER_ROLE_LOADING =
  "TEAM_CHANGE_MEMBER_ROLE_LOADING";
export const TEAM_CHANGE_MEMBER_ROLE_ERROR = "TEAM_CHANGE_MEMBER_ROLE_ERROR";
export const TEAM_CHANGE_MEMBER_ROLE_LIST = "TEAM_CHANGE_MEMBER_ROLE_LIST";
export const TEAM_MEMBER_COUNT = "TEAM_MEMBER_COUNT";
export const TEAM_COMPANY_ID = "TEAM_COMPANY_ID";
export const TEAM_COMPANY_NAME = "TEAM_COMPANY_NAME";
export const TEAM_ADD_NEW_MEMBER = "TEAM_ADD_NEW_MEMBER";
export const TEAM_INVITE_LINK = "TEAM_INVITE_LINK";
export const TEAM_DELETE_MEMBER = "TEAM_DELETE_MEMBER";
export const TEAM_UPDATE_MEMBER_DATA = "TEAM_UPDATE_MEMBER_DATA";
export const LOADING_INVITE = "LOADING_INVITE";

// Available roles state
export const AVAIL_ROLES_INIT = "AVAIL_ROLES_INIT";
export const AVAIL_ROLES_SUCCESS = "AVAIL_ROLES_SUCCESS";
export const AVAIL_ROLES_FAIL = "AVAIL_ROLES_FAIL";
export const AVAIL_ROLES_LOADING = "AVAIL_ROLES_LOADING";

// Confirm new user
export const CONFIRM_INIT_NEW_USER = "CONFIRM_INIT_NEW_USER";
export const CONFIRM_NEW_USER_FAIL = "CONFIRM_NEW_USER_FAIL";
export const CONFIRM_NEW_USER_LOADING = "CONFIRM_NEW_USER_LOADING";
export const CONFIRM_NEW_USER_SUCCESS = "CONFIRM_NEW_USER_SUCCESS";
export const SET_UNCONFIRMED_NEW_USER = "SET_UNCONFIRMED_NEW_USER";
export const SET_NEW_USER_CONFIRMED = "SET_NEW_USER_CONFIRMED";
export const SET_NEW_USER_EMAIL = "SET_NEW_USER_EMAIL";
export const SET_NEW_USER_ERROR_TEXT = "SET_NEW_USER_ERROR_TEXT";
export const SET_USER_ALREADY_REGISTERED = "SET_USER_ALREADY_REGISTERED";
export const SET_NEW_USER_NAME = "SET_NEW_USER_NAME";
export const SET_NEW_USER_COMPANY_NAME = "SET_NEW_USER_COMPANY_NAME";

// Main folder
export const MAIN_FOLDER_INIT = "MAIN_FOLDER_INIT";
export const MAIN_FOLDER_SUCCESS = "MAIN_FOLDER_SUCCESS";
export const MAIN_FOLDER_FAIL = "MAIN_FOLDER_FAIL";
export const MAIN_FOLDER_LOADING = "MAIN_FOLDER_LOADING";

// Folders
export const FOLDERS_INIT = "FOLDERS_INIT";
export const FOLDERS_SUCCESS = "FOLDERS_SUCCESS";
export const FOLDERS_FAIL = "FOLDERS_FAIL";
export const FOLDERS_LOADING = "FOLDERS_LOADING";
export const FOLDERS_ADD_NEW = "FOLDERS_ADD_NEW";
export const FOLDERS_DELETE = "FOLDERS_DELETE";
export const FOLDERS_MAX_VALUES = "FOLDERS_MAX_VALUES";
export const FOLDERS_ADD_SNAPSHOT_TO_SCENARIO =
  "FOLDERS_ADD_SNAPSHOT_TO_SCENARIO";

// Scenarios
export const SCENARIOS_INIT = "SCENARIOS_INIT";
export const SCENARIOS_SUCCESS = "SCENARIOS_SUCCESS";
export const SCENARIOS_FAIL = "SCENARIOS_FAIL";
export const SCENARIOS_LOADING = "SCENARIOS_LOADING";

// AutomationScenario
export const SCENARIO_ITEM_INIT = "SCENARIO_ITEM_INIT";
export const SCENARIO_ITEM_SUCCESS = "SCENARIO_ITEM_SUCCESS";
export const SCENARIO_ITEM_FAIL = "SCENARIO_ITEM_FAIL";
export const SCENARIO_ITEM_LOADING = "SCENARIO_ITEM_LOADING";
export const SCENARIO_LOADED = "SCENARIO_LOADED";
export const SCENARIO_RENAME = "SCENARIO_RENAME";
export const SCENARIO_CHANGE_INIT_BLOCK = "SCENARIO_CHANGE_INIT_BLOCK";
export const SCENARIO_SELECTED_CUBE_ID = "SCENARIO_SELECTED_CUBE_ID";
export const SELECT_CUBE = "SELECT_CUBE";
export const SCENARIO_TEMP_CUBE_ID = "SCENARIO_TEMP_CUBE_ID";
export const SCENARIO_DELETE = "SCENARIO_DELETE";
export const SCENARIO_PROCESSING = "SCENARIO_PROCESSING";
export const SCENARIO_GET_ERROR_WHEN_START = "SCENARIO_GET_ERROR_WHEN_START";
export const SCENARIO_METHOD_NAME = "SCENARIO_METHOD_NAME";
export const SCENARIO_DEBUG_ID = "SCENARIO_DEBUG_ID";
export const SCENARIO_BRANCHING_PANEL = "SCENARIO_BRANCHING_PANEL";
export const SCENARIO_ITEM_REFRESH = "SCENARIO_ITEM_REFRESH";
export const SCENARIO_UNFILLED_BLOCKS = "SCENARIO_UNFILLED_BLOCKS";
export const SCENARIO_EDITABLE_VERSION = "SCENARIO_EDITABLE_VERSION";
export const SCENARIO_ALLOWED_TO_EDIT = "SCENARIO_ALLOWED_TO_EDIT";
export const SCENARIO_ZOOM = "SCENARIO_ZOOM";
export const SCENARIO_INIT_AUTH_MODAL = "SCENARIO_INIT_AUTH_MODAL";
export const SCENARIO_CHECK_OBJECT = "SCENARIO_CHECK_OBJECT";
export const SCENARIO_UPDATE_VARIABLE = "SCENARIO_UPDATE_VARIABLE";
export const SCENARIO_UNAVAILABLE_SERVICE_MODAL =
  "SCENARIO_UNAVAILABLE_SERVICE_MODAL";
export const SCENARIO_UNAVAILABLE_SERVICE_VALUE =
  "SCENARIO_UNAVAILABLE_SERVICE_VALUE";

// Automation Routing
export const AUTOMATION_ROUTE_INIT = "AUTOMATION_ROUTE_INIT";
export const AUTOMATION_ROUTE_DELETE_FROM_TO =
  "AUTOMATION_ROUTE_DELETE_FROM_TO";

export const SET_CURRENT_AUTOMATION_ROUTE = "SET_CURRENT_AUTOMATION_ROUTE";
export const SET_AUTOMATION_ROUTES = "SET_AUTOMATION_ROUTES";
export const AUTOMATION_PAGINATION = "AUTOMATION_PAGINATION";
export const AUTOMATION_SIZE = "AUTOMATION_SIZE";
export const AUTOMATION_SEARCH = "AUTOMATION_SEARCH";

// Upload avatar
export const UPLOAD_AVATAR_INIT = "UPLOAD_AVATAR_INIT";
export const UPLOAD_AVATAR_SUCCESS = "UPLOAD_AVATAR_SUCCESS";
export const UPLOAD_AVATAR_FAIL = "UPLOAD_AVATAR_FAIL";
export const UPLOAD_AVATAR_LOADING = "UPLOAD_AVATAR_LOADING";

// BILLING_INFO
export const BILLING_INFO_INIT = "BILLING_INFO_INIT";
export const BILLING_INFO_PARAMS_SUCCESS = "BILLING_INFO_PARAMS_SUCCESS";
export const BILLING_INFO_FAIL = "BILLING_INFO_FAIL";
export const BILLING_INFO_LOADING = "BILLING_INFO_LOADING";
export const BILLINK_CSV_LINK = "BILLINK_CSV_LINK";

// CANCEL_SUBSCRIPTION
export const CANCEL_SUBSCRIPTION_INIT = "CANCEL_SUBSCRIPTION_INIT";
export const CANCEL_SUBSCRIPTION_FAIL = "CANCEL_SUBSCRIPTION_FAIL";
export const CANCEL_SUBSCRIPTION_LOADING = "CANCEL_SUBSCRIPTION_LOADING";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";

export const UPD_SUBS_INIT = "UPD_SUBS_INIT";
export const UPD_SUBS_SUCCESS = "UPD_SUBS_SUCCESS";
export const UPD_SUBS_FAIL = "UPD_SUBS_FAIL";
export const UPD_SUBS_LOADING = "UPD_SUBS_LOADING";

export const WS_CONNECT_INIT = "WS_CONNECT_INIT";
export const WS_CONNECT = "WS_CONNECT";
export const WS_CONNECT_SET_CONNECTED = "WS_CONNECT_SET_CONNECTED";
export const WS_CONNECT_WEBSOCKET_ITEM = "WS_CONNECT_WEBSOCKET_ITEM";
export const WS_CONNECT_QUEUE = "WS_CONNECT_QUEUE";

export const PAY_HISTORY_INIT = "PAY_HISTORY_INIT";
export const PAY_HISTORY_SUCCESS = "PAY_HISTORY_SUCCESS";
export const PAY_HISTORY_FAIL = "PAY_HISTORY_FAIL";
export const PAY_HISTORY_LOADING = "PAY_HISTORY_LOADING";
export const PAY_HISTORY_PAGINATION = "PAY_HISTORY_PAGINATION";
export const PAY_HISTORY_HAS_MORE = "PAY_HISTORY_HAS_MORE";
export const PAY_HISTORY_OFFSET = "PAY_HISTORY_OFFSET";

export const LANG_INIT = "LANG_INIT";
export const LANG_CHANGED = "LANG_CHANGED";

export const CHECK_INV_LINK_INIT = "CHECK_INV_LINK_INIT";
export const CHECK_INV_LINK_SUCCESS = "CHECK_INV_LINK_SUCCESS";
export const CHECK_INV_LINK_TOKEN = "CHECK_INV_LINK_TOKEN";
export const CHECK_INV_LINK_FAIL = "CHECK_INV_LINK_FAIL";
export const CHECK_INV_LINK_LOADING = "CHECK_INV_LINK_LOADING";

export const COMPANY_INIT = "COMPANY_INIT";
export const COMPANY_SUCCESS = "COMPANY_SUCCESS";
export const COMPANY_FAIL = "COMPANY_FAIL";
export const COMPANY_LOADING = "COMPANY_LOADING";
export const COMPANY_ADD_NEW = "COMPANY_ADD_NEW";

export const WS_CONNECTING = 0;
export const WS_OPEN = 1;
export const WS_CLOSING = 2;
export const WS_CLOSED = 3;

export const TEMPLATES_INIT = "TEMPLATES_INIT";
export const TEMPLATES_SUCCESS = "TEMPLATES_SUCCESS";
export const TEMPLATES_FAIL = "TEMPLATES_FAIL";
export const TEMPLATES_LOADING = "TEMPLATES_LOADING";
export const TEMPLATES_AMOUNT = "TEMPLATES_AMOUNT";

export const TEMPLATE_INIT = "TEMPLATE_INIT";
export const TEMPLATE_SUCCESS = "TEMPLATE_SUCCESS";
export const TEMPLATE_FAIL = "TEMPLATE_FAIL";
export const TEMPLATE_LOADING = "TEMPLATE_LOADING";

export const SCENARIO_USERS_INIT = "SCENARIO_USERS_INIT";
export const SCENARIO_USERS_ADD_USER = "SCENARIO_USERS_ADD_USER";
export const SCENARIO_USERS_FAIL = "SCENARIO_USERS_FAIL";
export const SCENARIO_USERS_LOADING = "SCENARIO_USERS_LOADING";
export const SCENARIO_USERS_ADD_ALL_USERS = "SCENARIO_USERS_ADD_ALL_USERS";
export const SCENARIO_USERS_DELETE_USER = "SCENARIO_USERS_DELETE_USER";

export const SNAPSHOT_INIT = "SNAPSHOT_INIT";
export const SNAPSHOT_SUCCESS = "SNAPSHOT_SUCCESS";
export const SNAPSHOT_FAIL = "SNAPSHOT_FAIL";
export const SNAPSHOT_LOADING = "SNAPSHOT_LOADING";

export const CREATE_SCENARIO_V1_INIT = "CREATE_SCENARIO_V1_INIT";
export const CREATE_SCENARIO_V1_SUCCESS = "CREATE_SCENARIO_V1_SUCCESS";
export const CREATE_SCENARIO_V1_FAIL = "CREATE_SCENARIO_V1_FAIL";
export const CREATE_SCENARIO_V1_LOADING = "CREATE_SCENARIO_V1_LOADING";

export const SERVICE_INIT = "SERVICE_INIT";
export const SERVICE_SUCCESS = "SERVICE_SUCCESS";
export const SERVICE_FAIL = "SERVICE_FAIL";
export const SERVICE_LOADING = "SERVICE_LOADING";

export const WHOOK_METHOD_INIT = "WHOOK_METHOD_INIT";
export const WHOOK_METHOD_SUCCESS = "WHOOK_METHOD_SUCCESS";
export const WHOOK_METHOD_FAIL = "WHOOK_METHOD_FAIL";
export const WHOOK_METHOD_LOADING = "WHOOK_METHOD_LOADING";

export const SERVICE_PARAM_INIT = "SERVICE_PARAM_INIT";
export const SERVICE_PARAM_SUCCESS = "SERVICE_PARAM_SUCCESS";
export const SERVICE_PARAM_FAIL = "SERVICE_PARAM_FAIL";
export const SERVICE_PARAM_LOADING = "SERVICE_PARAM_LOADING";
export const SERVICE_KEYS = "SERVICE_KEYS";
export const SERVICE_IN_PARAMETER = "SERVICE_IN_PARAMETER";
export const SERVICE_IN_PARAMETER_EDIT = "SERVICE_IN_PARAMETER_EDIT";
export const SERVICE_IN_PARAMETER_PUSH = "SERVICE_IN_PARAMETER_PUSH";
export const SERVICE_SET_INITIAL_PARAM = "SERVICE_SET_INITIAL_PARAM";
export const SERVICE_IN_PARAMETER_DELETE = "SERVICE_IN_PARAMETER_DELETE";
export const SERVICE_PARAMETERS_RECEIVE = "SERVICE_PARAMETERS_RECEIVE";
export const SERVICE_PARAMETERS_FOR_COMPONENT_RECEIVE =
  "SERVICE_PARAMETERS_FOR_COMPONENT_RECEIVE";
export const SERVICE_PARAMETERS_VALUE = "SERVICE_PARAMETERS_VALUE";
export const SERVICE_ADDED_NEW_PARAMS = "SERVICE_ADDED_NEW_PARAMS";

export const METHOD_REPLY_INIT = "METHOD_REPLY_INIT";
export const METHOD_REPLY_SUCCESS = "METHOD_REPLY_SUCCESS";
export const METHOD_REPLY_FAIL = "METHOD_REPLY_FAIL";
export const METHOD_REPLY_LOADING = "METHOD_REPLY_LOADING";
export const SELECTED_OUTPUT_LIST = "SELECTED_OUTPUT_LIST";
export const SET_PATH_TO_VALUE = "SET_PATH_TO_VALUE";
export const SET_PATH_TO_LIST = "SET_PATH_TO_LIST";
export const SET_PATH_TO_COMPARE_KEY = "SET_PATH_TO_COMPARE_KEY";

export const SENS_DATA_INIT = "SENS_DATA_INIT";
export const SENS_DATA_SUCCESS = "SENS_DATA_SUCCESS";
export const SENS_DATA_FAIL = "SENS_DATA_FAIL";
export const SENS_DATA_LOADING = "SENS_DATA_LOADING";

export const LIB_INIT = "LIB_INIT";
export const LIB_SUCCESS = "LIB_SUCCESS";
export const LIB_FAIL = "LIB_FAIL";
export const LIB_LOADING = "LIB_LOADING";
export const LIB_TYPE = "LIB_TYPE";
export const LIB_PANEL = "LIB_PANEL";
export const LIB_POSITION = "LIB_POSITION";
export const LIB_SCROLL_TO_TOP = "LIB_SCROLL_TO_TOP";

export const FUNC_INIT = "FUNC_INIT";
export const FUNC_SUCCESS = "FUNC_SUCCESS";
export const FUNC_FAIL = "FUNC_FAIL";
export const FUNC_LOADING = "FUNC_LOADING";

export const F_PARAM_INIT = "F_PARAM_INIT";
export const F_PARAM_SUCCESS = "F_PARAM_SUCCESS";
export const F_PARAM_FAIL = "F_PARAM_FAIL";
export const F_PARAM_LOADING = "F_PARAM_LOADING";

export const AVAIL_VAR_INIT = "AVAIL_VAR_INIT";
export const AVAIL_VAR_SUCCESS = "AVAIL_VAR_SUCCESS";
export const AVAIL_VAR_FAIL = "AVAIL_VAR_FAIL";
export const AVAIL_VAR_LOADING = "AVAIL_VAR_LOADING";
export const AVAIL_VAR_UUID = "AVAIL_VAR_UUID";
export const AVAIL_VAR_ID = "AVAIL_VAR_ID";
export const AVAIL_VAR_NTH = "AVAIL_VAR_NTH";
export const CURRENT_DRAG_VAR_ID = "CURRENT_DRAG_VAR_ID";

export const AVAIL_R_METHODS_INIT = "AVAIL_R_METHODS_INIT";
export const AVAIL_R_METHODS_SUCCESS = "AVAIL_R_METHODS_SUCCESS";
export const AVAIL_R_METHODS_FAIL = "AVAIL_R_METHODS_FAIL";
export const AVAIL_R_METHODS_LOADING = "AVAIL_R_METHODS_LOADING";

export const INDICATORS_INIT = "INDICATORS_INIT";
export const INDICATORS_SUCCESS = "INDICATORS_SUCCESS";
export const INDICATORS_FAIL = "INDICATORS_FAIL";
export const INDICATORS_LOADING = "INDICATORS_LOADING";
export const INDICATORS_LOADED = "INDICATORS_LOADED";

export const SCENARIO_COUNTERS_INIT = "SCENARIO_COUNTERS_INIT";
export const SCENARIO_COUNTERS_SUCCESS = "SCENARIO_COUNTERS_SUCCESS";
export const SCENARIO_COUNTERS_FAIL = "SCENARIO_COUNTERS_FAIL";
export const SCENARIO_COUNTERS_LOADING = "SCENARIO_COUNTERS_LOADING";

export const SCHEDULE_CONTENT_INIT = "SCHEDULE_CONTENT_INIT";
export const SCHEDULE_CONTENT_SUCCESS = "SCHEDULE_CONTENT_SUCCESS";
export const SCHEDULE_CONTENT_FAIL = "SCHEDULE_CONTENT_FAIL";
export const SCHEDULE_CONTENT_LOADING = "SCHEDULE_CONTENT_LOADING";

export const DEBUG_INIT = "DEBUG_INIT";
export const DEBUG_VALUE = "DEBUG_VALUE";
export const DEBUG_FAIL = "DEBUG_FAIL";
export const DEBUG_LOADING = "DEBUG_LOADING";
export const DEBUG_ERROR_REASON = "DEBUG_ERROR_REASON";
export const DEBUG_ID_WHERE_WAS_ERROR = "DEBUG_ID_WHERE_WAS_ERROR";
export const DEBUG_LIST = "DEBUG_LIST";

export const PAY_METHOD_INIT = "PAY_METHOD_INIT";
export const PAY_METHOD_SUCCESS = "PAY_METHOD_SUCCESS";
export const PAY_METHOD_FAIL = "PAY_METHOD_FAIL";
export const PAY_METHOD_LOADING = "PAY_METHOD_LOADING";
export const PAY_METHOD_DEFAULT = "PAY_METHOD_DEFAULT";
export const PAY_METHOD_ERROR_VALUE = "PAY_METHOD_ERROR_VALUE";

export const SCENARIO_VERSION_INIT = "SCENARIO_VERSION_INIT";
export const SCENARIO_VERSION_LIST = "SCENARIO_VERSION_LIST";
export const SCENARIO_VERSION_FAIL = "SCENARIO_VERSION_FAIL";
export const SCENARIO_VERSION_LOADING = "SCENARIO_VERSION_LOADING";
export const SCENARIO_VERSION_ITEMS = "SCENARIO_VERSION_ITEMS";
export const SCENARIO_VERSION_USERS = "SCENARIO_VERSION_USERS";
export const SCENARIO_VERSION_TAGS = "SCENARIO_VERSION_TAGS";
export const SCENARIO_VERSION_PAGE = "SCENARIO_VERSION_PAGE";
export const SCENARIO_VERSION_BRANCH = "SCENARIO_VERSION_BRANCH";
export const SCENARIO_VERSION_LAST_ITEM = "SCENARIO_VERSION_LAST_ITEM";

// Scenario categories state
export const SCENARIO_CATEGORIES_INIT = "SCENARIO_CATEGORIES_INIT";
export const SCENARIO_CATEGORIES_SUCCESS = "SCENARIO_CATEGORIES_SUCCESS";

// Journal events
export const JOURNAL_EVENTS_INIT = "JOURNAL_EVENTS_INIT";
export const JOURNAL_EVENTS_SUCCESS = "JOURNAL_EVENTS_SUCCESS";
export const JOURNAL_EVENTS_FAIL = "JOURNAL_EVENTS_FAIL";
export const JOURNAL_EVENTS_LOADING = "JOURNAL_EVENTS_LOADING";
export const JOURNAL_EVENTS_PAGINATION = "JOURNAL_EVENTS_PAGINATION";
export const JOURNAL_EVENTS_HAS_MORE = "JOURNAL_EVENTS_HAS_MORE";
export const JOURNAL_EVENTS_EDITABLE_VERSION =
  "JOURNAL_EVENTS_EDITABLE_VERSION";

// Scenario events
export const SCENARIO_EVENTS_INIT = "SCENARIO_EVENTS_INIT";
export const SCENARIO_EVENTS_SUCCESS = "SCENARIO_EVENTS_SUCCESS";
export const SCENARIO_EVENTS_FAIL = "SCENARIO_EVENTS_FAIL";
export const SCENARIO_EVENTS_LOADING = "SCENARIO_EVENTS_LOADING";
export const SCENARIO_EVENTS_PAGINATION = "SCENARIO_EVENTS_PAGINATION";
export const SCENARIO_EVENTS_HAS_MORE = "SCENARIO_EVENTS_HAS_MORE";

// Scenario events
export const JOURNAL_SCENARIOS_LIST_INIT = "JOURNAL_SCENARIOS_LIST_INIT";
export const JOURNAL_SCENARIOS_LIST_SUCCESS = "JOURNAL_SCENARIOS_LIST_SUCCESS";
export const JOURNAL_SCENARIOS_LIST_FAIL = "JOURNAL_SCENARIOS_LIST_FAIL";
export const JOURNAL_SCENARIOS_LIST_LOADING = "JOURNAL_SCENARIOS_LIST_LOADING";
export const JOURNAL_SCENARIOS_LIST_PAGINATION =
  "JOURNAL_SCENARIOS_LIST_PAGINATION";
export const JOURNAL_SCENARIOS_LIST_HAS_MORE =
  "JOURNAL_SCENARIOS_LIST_HAS_MORE";

// Variable panel
export const VARIABLE_PANEL_INIT = "VARIABLE_PANEL_INIT";
export const VARIABLE_PANEL_ID = "VARIABLE_PANEL_ID";
export const PREV_VARIABLE_PANEL_ID = "PREV_VARIABLE_PANEL_ID";
export const VARIABLE_PANEL_LOADING = "VARIABLE_PANEL_LOADING";
export const VARIABLE_PANEL_ERROR = "VARIABLE_PANEL_ERROR";
export const VARIABLE_PANEL_LIST = "VARIABLE_PANEL_LIST";
export const VARIABLE_PREDICTION = "VARIABLE_PREDICTION";
export const VARIABLE_SEARCH_RESULT = "VARIABLE_SEARCH_RESULT";
export const VARIABLE_SELECTED_BLOCK_ID = "VARIABLE_SELECTED_BLOCK_ID";
export const VARIABLE_PANEL_TEMP_VAR = "VARIABLE_PANEL_TEMP_VAR";

// Service Page
export const SERVICE_PAGE_INIT = "SERVICE_PAGE_INIT";
export const SERVICE_PAGE_SUCCESS = "SERVICE_PAGE_SUCCESS";
export const SERVICE_PAGE_FAIL = "SERVICE_PAGE_FAIL";
export const SERVICE_PAGE_LOADING = "SERVICE_PAGE_LOADING";
export const SERVICE_PAGE_STATUS = "SERVICE_PAGE_STATUS";
export const SERVICE_PAGE_SORT_BY = "SERVICE_PAGE_SORT_BY";
export const SERVICE_PAGE_SAVE_CHANGES = "SERVICE_PAGE_SAVE_CHANGES";
export const SERVICE_PAGE_HAS_DATA_TO_SAVE = "SERVICE_PAGE_HAS_DATA_TO_SAVE";
export const SERVICE_PAGE_TRY_TO_SAVE_DATA = "SERVICE_PAGE_TRY_TO_SAVE_DATA";

// Connector Item
export const CONNECTOR_ITEM_INIT = "CONNECTOR_ITEM_INIT";
export const CONNECTOR_ITEM_SUCCESS = "CONNECTOR_ITEM_SUCCESS";
export const CONNECTOR_ITEM_FAIL = "CONNECTOR_ITEM_FAIL";
export const CONNECTOR_ITEM_LOADING = "CONNECTOR_ITEM_LOADING";
export const VARIABLE_PANEL_ACTIVE_PARAMETER_INDEX =
  "VARIABLE_PANEL_ACTIVE_PARAMETER_INDEX";
