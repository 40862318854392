import React from "react";
import ReactTooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import cx from "classnames";
import { TooltipProps } from "rc-tooltip/lib/Tooltip";
import "./tooltip.scss";

export enum TooltipType {
  Error = "error",
  Dark = "dark",
  Success = "success",
  Warning = "warning",
  Info = "info",
  Light = "light",
  Invisible = "invisible",
  White = "white",
}

interface OwnProps {
  overlay: React.ReactNode;
  type?: TooltipType;
  children?: React.ReactElement;
  place?: TooltipProps["placement"];
  overlayInnerStyle?: TooltipProps["overlayInnerStyle"];
  overlayStyle?: TooltipProps["overlayStyle"];
  id: string;
  uuid?: string;
  withArrow?: boolean;
  arrowClassName?: string;
  customClassName?: string;
}

interface IProps extends OwnProps {}

export const Tooltip = (props: IProps) => {
  const {
    type,
    children,
    place,
    id,
    overlay,
    withArrow,
    arrowClassName,
    customClassName,
    overlayInnerStyle,
    overlayStyle,
  } = props;

  const styleCX = cx("root", {
    error: type === TooltipType.Error,
    dark: type === TooltipType.Dark,
    success: type === TooltipType.Success,
    warning: type === TooltipType.Warning,
    info: type === TooltipType.Info,
    light: type === TooltipType.Light,
    white: type === TooltipType.White,
    withArrow,
    [customClassName || ""]: !!customClassName,
  });

  const arrowCX = cx("arrow", {
    [arrowClassName ? arrowClassName : ""]: !!arrowClassName,
  });

  return (
    <ReactTooltip
      id={id}
      overlayStyle={overlayStyle}
      overlayInnerStyle={overlayInnerStyle}
      placement={place}
      arrowContent={!withArrow ? <span className={arrowCX}></span> : undefined}
      overlayClassName={styleCX}
      overlay={overlay}
      trigger={["hover"]}
      defaultVisible={false}
      mouseLeaveDelay={0.1}
      mouseEnterDelay={1}
    >
      {children}
    </ReactTooltip>
  );
};
