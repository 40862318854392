import store from "store";
import { AnswerData } from "../index";
import * as ScenarioActions from "actions/scenario";
import { scenarioItem$ } from "selectors/scenario";
import { AllScenarioBlocks } from "interfaces/state/scenario";

interface BlockCoordinates {
  id: string;
  x_coordinate: number;
  y_coordinate: number;
}

// WSApi.BlockUpdateCoordinatesMultiple
export const blockUpdateCoordinatesMultiple = (data: AnswerData) => {
  const { dispatch, getState } = store;

  dispatch(ScenarioActions.setLoadedScenario());
  if (data?.error) {
    dispatch(ScenarioActions.setScenarioError());
  } else {
    const scenarioItem = scenarioItem$(getState());

    if (
      scenarioItem?.scenario_id === data.response.scenario_id &&
      scenarioItem?.version_id === data.response.version_id
    ) {
      const newBlockCoordinates = data.response.blocks as BlockCoordinates[];
      let blockById: { [key: string]: AllScenarioBlocks } = {};
      scenarioItem?.scenario.forEach((block) => (blockById[block.id] = block));

      newBlockCoordinates.forEach(
        ({ id, x_coordinate, y_coordinate }) =>
          (blockById[id] = { ...blockById[id], x_coordinate, y_coordinate })
      );

      const scenario = Object.values(blockById);

      dispatch(
        ScenarioActions.setScenarioSuccess({ ...scenarioItem, scenario })
      );
    }
  }
};
