import * as Const from "../constants/index";
import { Periodicity } from "./billingInfo";

export const setSubscriptionInit = () => {
  return {
    type: Const.SUBSCR_INIT,
  };
};

export const fetchSubscriptionData = (data: string, signature: string) => {
  return {
    type: Const.SUBSCR_SUCCESS,
    data,
    signature,
  };
};

export const setSubscriptionError = () => {
  return {
    type: Const.SUBSCR_FAIL,
  };
};

export const setSubscriptionLoading = () => {
  return {
    type: Const.SUBSCR_LOADING,
  };
};

export const setSelectedParams = (
  selectedPlanId: null | number,
  selectedPeriod: null | Periodicity,
  selectedPlanName: null | string,
  selectedPlanPrice: null | number
) => {
  return {
    type: Const.SUBSCR_SELECTED_PARAMS,
    selectedPlanId,
    selectedPeriod,
    selectedPlanName,
    selectedPlanPrice,
  };
};

interface ISubscriptionParams {
  data: string;
  signature: string;
  end_date: string;
  periodicity: Periodicity;
  plan_id: number;
  plan_name: string;
  price: number;
  tranascations_max: number;
}

export const setSubscriptionInfo = (payload: ISubscriptionParams) => {
  return {
    type: Const.SUBSCRIPTION_PARAMS,
    payload,
  };
};

export const setTransactionsMax = (payload: number) => {
  return {
    type: Const.SUBSCR_TRANSACTIONS,
    payload,
  };
};

export const setMaxMembers = (payload: number | null) => {
  return {
    type: Const.SUBSCR_MAX_MEMBERS,
    payload,
  };
};

export const setTeamMembers = (payload: number) => {
  return {
    type: Const.SUBSCR_TEAM,
    payload,
  };
};
