import { privateRoute } from "routes";
import {
  automationRouting$,
  currentAutomationRoute$,
  limit$,
  offset$,
  page$,
  sortBy$,
  sortDirection$,
} from "selectors/automationRouting";
import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import { useLocation, useNavigate } from "react-router-dom";
import { folders$ } from "selectors/folders";
import { AutomationScenario } from "interfaces/state/scenarios";
import * as FoldersActions from "actions/folders";
import * as FolderCreators from "creators/folders";
import * as AutomationActions from "actions/automationRouting";
import { Folder } from "interfaces/state/folders";
import { AutomationRouteType } from "interfaces/state/automationRouting";
import { selectedCategories$ } from "selectors/scenarioCategories";

// WSApi.DeleteScenario
export const scenarioDelete = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { getState, dispatch } = store;

  if (data?.error) {
    errorFromResponse(data.error);
    dispatch(FoldersActions.setFoldersError());
  } else {
    const scenarioId = scenarioItem$(store.getState())?.scenario_id;
    const routing = automationRouting$(store.getState());
    const folders = folders$(getState());
    const page = page$(getState());

    const currentAutomationRoute = currentAutomationRoute$(getState());
    if (
      window.location.pathname.includes(privateRoute.scenario) ||
      window.location.pathname.includes("event")
    ) {
      if (routing.length > 1 && scenarioId === data.response.scenario_id) {
        navigate(`${privateRoute.automation}?id=${routing[0].id}`);
      } else {
        navigate(privateRoute.automation);
      }
    } else {
      const sort_by = sortBy$(getState());
      const sort_direction = sortDirection$(getState());
      const limit = limit$(getState());
      const offset = offset$(getState());
      const selectedCategories = selectedCategories$(getState());
      const currentRoute = currentAutomationRoute$(getState());

      const offsetByfoldersLength =
        folders.length === 1 && page !== 1 ? offset - limit : offset;

      if (folders.length === 1 && page !== 1) {
        dispatch(
          AutomationActions.updatePagination({
            page: page - 1,
            offset: offset - limit,
          })
        );
        navigate(
          `${privateRoute.automation}?id=${currentRoute?.id}&page=${
            folders.length === 1 && page !== 1 ? page - 1 : page
          }`
        );
      }

      currentAutomationRoute &&
        dispatch(
          FolderCreators.fetchAllAutomationFiles(
            +currentAutomationRoute.id,
            sort_by,
            sort_direction,
            limit,
            offsetByfoldersLength,
            selectedCategories
          )
        );
    }

    const newFolders = folders$(getState())?.filter(
      (it: AutomationScenario | Folder) => {
        const id =
          it.type === AutomationRouteType.FOLDER ? it.id : it.scenario_id;

        return id !== data.response.scenario_id;
      }
    );

    if (folders.length > 1 || page === 1) {
      store.dispatch(FoldersActions.setFoldersSuccess(newFolders));
    }
  }
};
