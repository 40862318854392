import * as React from "react";
import "./style.scss";

export class Spinner extends React.PureComponent {
  render() {
    return (
      <div className="spinner-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  }
}
