import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { AnswerData } from "../index";
import * as ScenarioActions from "actions/scenario";

// WSApi.DeleteBlock
export const blockDelete = (data: AnswerData) => {
  if (!data?.error) {
    const scenarioItem = scenarioItem$(store.getState());
    if (scenarioItem?.scenario_id === data.response?.scenario_id) {
      const newScenario = {
        scenario: data.response.scenario,
        pipeline: data.response.pipeline,
        variables: data?.response?.variables
          ? data.response.variables
          : scenarioItem?.variables,
      };

      store.dispatch(
        ScenarioActions.setScenarioSuccess({
          ...scenarioItem,
          ...newScenario,
        })
      );
    }
  }
};
