import * as Const from "../constants";
import State from "../interfaces/state/billingInfo";
import { Periodicity } from "actions/billingInfo";

export const initState: State = {
  isLoading: false,
  isError: false,
  plan_id: 0,
  plan_name: null,
  payment_system: null,
  mask: null,
  periodicity: Periodicity.MONTH,
  price: null,
  max_processing_speed_per_hour: null,
  max_members: null,
  exp_month: null,
  exp_year: null,
  number_of_members: null,
  status: null,
  max_processing_speed_per_second: null,
  max_request_size: null,
  next_payment: null,
  price_per_seat: null,
  seats: null,
  task_queue: null,
  transactions_per_month: null,
  csvHistoryLink: "",
};

export default function billingInfo(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.BILLING_INFO_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.BILLING_INFO_PARAMS_SUCCESS:
      const {
        plan_id,
        plan_name,
        payment_system,
        mask,
        periodicity,
        price,
        max_processing_speed_per_hour,
        max_members,
        exp_month,
        exp_year,
        number_of_members,
        status,
        max_processing_speed_per_second,
        max_request_size,
        next_payment,
        price_per_seat,
        seats,
        task_queue,
        transactions_per_month,
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        mask,
        periodicity,
        plan_id,
        plan_name,
        price,
        payment_system,
        max_processing_speed_per_hour,
        max_members,
        exp_month,
        exp_year,
        number_of_members,
        status,
        max_processing_speed_per_second,
        max_request_size,
        next_payment,
        price_per_seat,
        seats,
        task_queue,
        transactions_per_month,
      };

    case Const.BILLING_INFO_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.BILLING_INFO_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.BILLINK_CSV_LINK:
      return {
        ...state,
        csvHistoryLink: action.payload,
      };

    default:
      return state;
  }
}
