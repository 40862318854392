import React, { useEffect } from "react";
import { ModalWindow } from "components/modal";
import { DeleteBlockModal } from "../deleteCubeModal";
import { withTranslation, WithTranslation } from "react-i18next";
import style from "./deleteCubeButton.module.scss";
import * as ScenarioCreators from "creators/scenario";
import { connect } from "react-redux";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { setOpenBranchingRightPanel } from "actions/scenario";
import { currentBlock$, scenarioItem$ } from "selectors/scenario";

interface IStateProps {}
interface OwnProps {
  id: string | number;
  isCaseOf?: boolean;
  isOpen?: boolean;
  hideMainButton?: boolean;
  onClick?: () => void;
  onHideBtn?: (v: boolean) => void;
}

interface IDispatchProps {
  deleteBlock: () => void;
}

interface IProps
  extends IStateProps,
    OwnProps,
    IDispatchProps,
    WithTranslation {}

const DeleteBlockButtonInternal = ({
  t,
  deleteBlock,
  isCaseOf,
  isOpen,
  hideMainButton,
  onClick,
  onHideBtn,
}: IProps) => {
  const [isShowModal, setShowModal] = React.useState(false);
  const dispatch = useAppDispatch();
  const scenario = useAppSelector(scenarioItem$);
  const currentBlockId = useAppSelector(currentBlock$)?.id;

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
    }

    return () => setShowModal(false);
  }, [isOpen]);

  const onCloseModal = React.useCallback(() => {
    setShowModal(false);
    onHideBtn?.(false);
  }, []);

  const onApplyDelete = React.useCallback(() => {
    if (isCaseOf) {
      const hasBranches = !!scenario?.pipeline?.filter(
        (p) => p.in === currentBlockId
      )?.length;

      hasBranches ? dispatch(setOpenBranchingRightPanel(true)) : deleteBlock();
    } else {
      deleteBlock();
    }
    onCloseModal();
  }, [
    currentBlockId,
    deleteBlock,
    dispatch,
    isCaseOf,
    onCloseModal,
    scenario?.pipeline,
  ]);

  const onOpenModal = React.useCallback(() => {
    setShowModal(true);
    setTimeout(() => onClick?.(), 1000);
  }, [onClick]);

  return (
    <>
      {!hideMainButton && (
        <div className={style.text} onClick={onOpenModal}>
          {t("scenario:remove")}
        </div>
      )}
      <ModalWindow
        isOpen={isShowModal}
        isHideButton
        content={
          <DeleteBlockModal onClose={onCloseModal} onSubmit={onApplyDelete} />
        }
        onClose={onCloseModal}
      />
    </>
  );
};

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  props: OwnProps
) => {
  return {
    ...stateProps,
    deleteBlock: dispatchProps.deleteBlock,
    ...props,
  };
};

export const DeleteBlockButton = withTranslation()(
  connect(
    null,
    {
      deleteBlock: ScenarioCreators.deleteBlock,
    },
    mergeProps
  )(DeleteBlockButtonInternal)
);
