import State from "interfaces/state";
import { Action } from "redux";
import { isConfirmed$ } from "selectors/confirm";
import { post } from ".";
import * as AuthActions from "../actions/auth";
import { Api } from "../enums/api";
import { WSApi } from "../enums/wsApi";
import {
  changeTranslation,
  envLinkSetter,
  errorResponseNotification,
  errorFromResponse,
  getCookie,
  webSocketProxyHandler,
  sendWSMessage,
  wsURL,
} from "../utils";
import axios from "axios";
import * as ConfirmNewUserCreators from "creators/confirmNewUser";
import * as LangActions from "actions/lang";
import * as WSActions from "actions/wsConnect";
import { WebsocketItem$ } from "selectors/wsConnect";
import { isLoading$, plan_name$, status_session$ } from "selectors/auth";
import {
  privateRoute,
  privateRouteValues,
  publicRoute,
  publicRouteValues,
  routesWithoutRedirects,
  routeWithoutSessionStatus,
  routeWithoutSessionStatusValues,
} from "routes";
import { fetchBillingInfo } from "./billingInfo";
import { MemberRole } from "interfaces/state/team";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { StatusSession } from "interfaces/state/auth";
import i18n from "i18n";

let timer = 0;
export const getSessionStatus = (
  navigate: ReturnType<typeof useNavigate>,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  isNeedToLoading?: boolean,
  isNeedToReloadAfter?: boolean,
  noWSReconnect?: boolean
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    // eslint-disable-next-line no-lone-blocks
    {
      !isNeedToLoading && dispatch(AuthActions.setAuthLoading());
    }

    axios
      .post(Api.GetSessionStatus)
      .then((res) => {
        if (res?.data === null) {
          dispatch(AuthActions.setAuthFail());
          errorResponseNotification(res.data.status, 5000, res?.data?.error);
          dispatch(
            statusControl(res.status, navigate, location, searchParams) as any
          );
          dispatch(AuthActions.setSessionStatus("unknown"));
        } else {
          if (res.data.response?.language) {
            changeTranslation(res.data.response.language);
            i18n.changeLanguage(res.data.response.language);
            dispatch(LangActions.setLanguage(res.data.response.language));
          }

          if (res.data.response.status === "open") {
            // replace the native WebSocket with the proxy
            window.WebSocket = webSocketProxyHandler(
              dispatch as (action: Action) => void,
              getState as () => State,
              location,
              searchParams,
              navigate
            );

            if (res.data.response?.company_role === MemberRole.OWNER) {
              setTimeout(() => {
                dispatch(fetchBillingInfo() as any);
              }, 1000);
            } else {
              if (window.location.pathname === privateRoute.billing) {
                navigate(privateRoute.automation);
              }
            }
            if (noWSReconnect) {
              const WebsocketConnect = new WebSocket(wsURL);

              dispatch(WSActions.setWebsocketItem(WebsocketConnect));
            }
          }
          if (
            isConfirmed$(getState()) &&
            res.data.response.status === "unconfirmed_email"
          ) {
            const newResponse = { ...res.data.response };
            newResponse.session_status = "confirmed_email";
            dispatch(AuthActions.setAuthParams(newResponse));
          } else if (res.data.response.status === "closed") {
            dispatch(AuthActions.setAuthLoadingEnd());
            setTimeout(
              () =>
                dispatch(
                  getSessionStatus(
                    navigate,
                    location,
                    searchParams,
                    true
                  ) as any
                ),
              timer++ * 2000
            );
          } else {
            if (res.data.response?.avatar_url) {
              dispatch(AuthActions.setAuthAvatarUrl(undefined));
            }
            dispatch(AuthActions.setAuthParams(res.data.response));
          }
        }
      })
      .then(() => {
        if (isNeedToReloadAfter) {
          window.location.reload();
        }
      })
      .catch((error) => {
        errorResponseNotification(
          error?.response?.status,
          5000,
          error.data?.error
        );
        dispatch(
          statusControl(
            error?.response?.status,
            navigate,
            location,
            searchParams
          ) as any
        );
        dispatch(AuthActions.setSessionStatus("unknown"));
        dispatch(AuthActions.setAuthFail());
        navigate(publicRoute.logIn);

        console.error(error);
      });
  };
};

export const logIn = (
  navigate: ReturnType<typeof useNavigate>,
  email: string,
  password: string,
  long_session: boolean,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams
) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AuthActions.setAuthLoading());

    post(Api.LogIn, {
      email,
      password,
      long_session,
    })
      .then((res) => {
        if (res && "data" in res && "error" in res.data && res.data.error) {
          dispatch(AuthActions.setAuthFail());
          errorFromResponse(res.data.error);
        } else {
          dispatch(AuthActions.setAuthLoadingEnd());
          dispatch(
            getSessionStatus(
              navigate,
              location,
              searchParams,
              undefined,
              undefined,
              true
            ) as any
          );
        }
      })
      .catch((error) => {
        dispatch(AuthActions.setAuthFail());
        console.error(error);
      });
  };
};

export const resetPasswordStepOne = (
  email: string,
  navigate: ReturnType<typeof useNavigate>
) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AuthActions.setAuthLoading());

    const link = `${envLinkSetter()}/app/password-recovery/step-3?token={token}`;
    post(Api.RecoveryPassStepOne, {
      email,
      link,
    })
      .then((res) => {
        if (res && "data" in res && "error" in res.data && res.data.error) {
          dispatch(AuthActions.setAuthFail());
          errorResponseNotification(res.status, 5000, res?.data?.error);
        } else {
          dispatch(AuthActions.setAuthLoadingEnd());
          localStorage.setItem("reset_email", email);
          navigate(publicRoute.passRecoverySecondStep);
        }
      })
      .catch((error) => {
        dispatch(AuthActions.setAuthFail());
        console.error(error);
      });
  };
};

export const resetPasswordStepTwo = (
  password: string,
  token: string,
  redirect: () => void
) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AuthActions.setAuthLoading());

    const link = `${envLinkSetter()}/app/confirm?token={token}`;
    post(Api.RecoveryPassStepTwo, {
      password,
      token,
      link,
    })
      .then((res) => {
        if (res && "data" in res && "error" in res.data && res.data.error) {
          dispatch(AuthActions.setAuthFail());
          errorResponseNotification(res.status, 5000, res?.data?.error);
        } else {
          dispatch(AuthActions.setAuthLoadingEnd());
          redirect();
        }
      })
      .catch((error) => {
        dispatch(AuthActions.setAuthFail());
        console.error(error);
      });
  };
};

export enum LogOutDispatch {
  CONFIRM_BY_EMAIL = "confirm_joining",
  CONFIRM_BY_LINK = "confirm_join_by_link",
}

export const logOut = (
  location: ReturnType<typeof useLocation>,
  navigate: ReturnType<typeof useNavigate>,
  searchParams: URLSearchParams,
  token: string | null,
  withoutSessionStatus?: boolean,
  newDispatch?: LogOutDispatch,
  withoutReload?: boolean
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(AuthActions.setAuthLoading());

    post(Api.LogOut)
      .then(() => {
        sessionStorage.removeItem("redirectAfterLogin");
        dispatch(AuthActions.setAuthLoadingEnd());
        dispatch(AuthActions.setInitialApp());
        WebsocketItem$(getState())?.close();
        if (!withoutSessionStatus) {
          dispatch(getSessionStatus(navigate, location, searchParams) as any);
          setTimeout(() => window.location.reload(), 200);
        }

        if (newDispatch && newDispatch === LogOutDispatch.CONFIRM_BY_EMAIL) {
          dispatch(
            ConfirmNewUserCreators.confirmJoiningFromEmail(
              token!,
              location,
              navigate,
              searchParams
            ) as any
          );
        } else {
          navigate(publicRoute.logIn);
          window.location.reload();
        }
        if (!withoutReload) {
        }
      })
      .catch((error) => {
        dispatch(AuthActions.setInitialApp());
        console.error(error);
      });
  };
};

export const statusControl = (
  statusCode: number,
  navigate: ReturnType<typeof useNavigate>,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams
) => {
  return (dispatch: (action: Action) => void) => {
    if ((statusCode === 401 && !getCookie("sid")) || statusCode === 400) {
      setTimeout(
        () =>
          dispatch(
            getSessionStatus(
              navigate,
              location,
              searchParams,
              false,
              false
            ) as any
          ),
        timer++ * 1000
      );
    }
  };
};

export const getSystemLanguage = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    post(Api.GetSessionStatus).then(({ response }) => {
      const language = response?.language;
      if (language) {
        changeTranslation(language);
        dispatch(LangActions.setLanguage(language));
      }
    });
  };
};

export const signInFromRegistration = (
  navigate: ReturnType<typeof useNavigate>,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  token: string | null
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(
      logOut(
        location,
        navigate,
        searchParams,
        token,
        false,
        undefined,
        true
      ) as any
    );

    localStorage.removeItem("register-name");
    localStorage.removeItem("register-email");

    navigate(publicRoute.logIn);
    setTimeout(() => window.location.reload(), 300);
  };
};

export const applyUserCookie = (consent_on_cookie: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    axios
      .post(
        Api.CookieConsent,
        {
          consent_on_cookie,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then(() => {
        dispatch(AuthActions.setAuthLoadingEnd());
        dispatch(AuthActions.applyCookie(consent_on_cookie));
      })
      .catch((error) => {
        dispatch(AuthActions.setAuthFail());
        console.error(error);
        errorResponseNotification(error.status, 5000, error?.data?.error);
      });
  };
};

export const deleteUserAvatar = () => {
  return (_dispatch: (action: Action) => void, _getState: () => State) => {
    const action = WSApi.DeleteUserAvatar;

    sendWSMessage({ action });
  };
};

export const checkToken = (
  token: string,
  navigate: ReturnType<typeof useNavigate>
) => {
  return (dispatch: (action: Action) => void, _getState: () => State) => {
    dispatch(AuthActions.setAuthLoading());
    axios
      .post(Api.CheckToken, {
        token,
      })
      .then((res) => {
        dispatch(AuthActions.setAuthLoadingEnd());
        if (res.data.response === "not_valid") {
          dispatch(AuthActions.tokenValidation(false));

          navigate(privateRoute.notFound);
        } else {
          dispatch(AuthActions.tokenValidation(true));
        }
      })
      .catch((error) => {
        navigate(privateRoute.notFound);
        dispatch(AuthActions.tokenValidation(false));

        dispatch(AuthActions.setAuthFail());
        console.error(error);
      });
  };
};

export const mountApp = (
  navigate: NavigateFunction,
  searchParams: URLSearchParams,
  location: ReturnType<typeof useLocation>
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const isConfirmed = isConfirmed$(getState());
    const isLoading = isLoading$(getState());
    const { pathname } = window.location;
    const isRouteIncludes = routeWithoutSessionStatusValues.some((route) =>
      pathname.includes(route)
    );

    const params = searchParams.toString();
    const from = searchParams.get("page-opened-from");

    if (from) {
      localStorage.setItem("page-opened-from", from);
      searchParams.delete("page-opened-from");
    }

    const directlyTo = searchParams.get("directly-opened-url");

    if (directlyTo) {
      localStorage.setItem("directly-opened-url", directlyTo);
      searchParams.delete("directly-opened-url");
    }

    if (isRouteIncludes || isConfirmed) {
      if (
        (pathname === routeWithoutSessionStatus.confirm && !params.length) ||
        pathname === routeWithoutSessionStatus.confirmChangeEmail
      ) {
        !isLoading &&
          dispatch(
            getSessionStatus(
              navigate,
              location,
              searchParams,
              undefined,
              undefined,
              true
            ) as any
          );
      } else {
        dispatch(getSystemLanguage() as any);
        return;
      }
    } else {
      if (pathname !== routeWithoutSessionStatus.confirmJoining && !isLoading) {
        dispatch(
          getSessionStatus(
            navigate,
            location,
            searchParams,
            undefined,
            undefined,
            true
          ) as any
        );
      }
    }
  };
};

const {
  CONFIRMED_EMAIL,
  UNCONFIRMED_EMAIL,
  UNKNOWN,
  OPEN,
  PAID,
  QUESTIONNAIRE,
  CLOSED,
  PLAN_SELECTED,
  SET_NAME_AND_EMAIL,
  PLAN_SELECTION,
  SET_NAME_AND_PASS,
} = StatusSession;
const { confirm, setNameAndPass } = routeWithoutSessionStatus;
const {
  emailWasSent,
  logIn: login,
  registration,
  questionnaire,
  closed,
  plans,
  plan_selected,
  set_name_and_email,
} = publicRoute;
const {
  automation,
  journalEventLog,
  journalEvents,
  journalEventsDetails,
  connectorItem,
} = privateRoute;

const getUnknownStatusUrl = () => {
  const { pathname } = window.location;

  if (publicRouteValues.includes(pathname) && pathname !== "/") {
    return `${pathname}?${window.location.search}`.replace(/\?+/g, "?");
  } else {
    sessionStorage.setItem(
      "redirectAfterLogin",
      window.location.pathname + window.location.search
    );

    return login;
  }
};

const getOpenStatusUrl = (search?: string) => {
  const { pathname } = window.location;

  if (
    privateRouteValues.includes(pathname) ||
    [
      journalEventLog,
      journalEvents,
      journalEventsDetails,
      automation,
      connectorItem,
      "/app/admin/service/",
    ].some((url) => pathname.includes(url))
  ) {
    return `${pathname}${search}`;
  } else {
    return automation;
  }
};

const getStatusByType = (
  isConfirmed: boolean,
  search?: string,
  planSelected?: string
): { [key: string]: string } => ({
  [CONFIRMED_EMAIL]: confirm,
  [UNCONFIRMED_EMAIL]: isConfirmed ? confirm : emailWasSent,
  [UNKNOWN]: getUnknownStatusUrl(),
  [OPEN]: getOpenStatusUrl(search),
  [PAID]: registration,
  [QUESTIONNAIRE]: questionnaire,
  [CLOSED]: closed,
  [PLAN_SELECTED]: !planSelected ? plans : plan_selected,
  [SET_NAME_AND_EMAIL]: set_name_and_email,
  [SET_NAME_AND_EMAIL]: set_name_and_email,
  [PLAN_SELECTION]: plans,
  [SET_NAME_AND_PASS]: setNameAndPass,
});

export const updateAppByStatus = (navigate: NavigateFunction) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const sessionStatus = status_session$(getState());
    const isConfirmed = isConfirmed$(getState());
    const plan_name = plan_name$(getState());

    const result = getStatusByType(
      isConfirmed,
      window.location.search,
      plan_name
    )[sessionStatus];

    if (!routesWithoutRedirects.includes(window.location.pathname))
      navigate(result);
  };
};
