import React from "react";
import { AutomationRoute } from "interfaces/state/automationRouting";
import style from "./routeItem.module.scss";

interface IProps {
  index: number;
  route: AutomationRoute;
  onClick: (route: AutomationRoute, index: number) => void;
}

export class RouteItem extends React.PureComponent<IProps> {
  private onRouteClick = () => {
    const { route, onClick, index } = this.props;
    onClick(route, index);
  };

  render() {
    const {
      route: { title },
    } = this.props;

    return (
      <div className={style.root} onClick={this.onRouteClick}>
        {title}
      </div>
    );
  }
}
