import * as Const from "../constants";
import State from "../interfaces/state/scenarios";

export const initState: State = {
  isLoading: false,
  isError: false,
  list: [],
};

export default function scenarios(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.SCENARIOS_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.SCENARIOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: action.payload,
      };

    case Const.SCENARIOS_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.SCENARIOS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
