import { AutomationScenario } from "interfaces/state/scenarios";
import { currentAutomationRoute$ } from "selectors/automationRouting";
import store from "store";
import { formatDateShort, makeScenarioParams } from "utils";
import { AnswerData } from "../index";
import * as FolderActions from "actions/folders";
import { privateRoute } from "routes";
import { useLocation, useNavigate } from "react-router-dom";

// WSApi.CreateFromTemplate
export const scenarioCreateFromTemplate = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (!data.error) {
    const { dispatch, getState } = store;
    if (currentAutomationRoute$(getState())?.id === data.response.folder_id) {
      const scenario: Partial<AutomationScenario> = {
        scenario_id: data.response.scenario_id,
        name: data.response.name,
        modified: formatDateShort(),
      };

      dispatch(FolderActions.addNewFolder(scenario as AutomationScenario));
      if (data.response.is_open_after_creation) {
        const branch = searchParams.get("branch")!;

        navigate(
          `${privateRoute.scenario}?${makeScenarioParams({
            scenario_id: data.response.scenario_id,
            version_id: data.response.version_id,
            isEdit: true,
            isView: false,
            isDebug: false,
            isViewVersion: false,
            selectedBlock: null,
            branch,
          })}`
        );
      }
    }
  }
};
