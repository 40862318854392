import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import State from "interfaces/state";
import {
  AutomationRoute,
  AutomationRouteType,
} from "interfaces/state/automationRouting";
import {
  Folder,
  Folder as IFolder,
  FolderPath,
} from "interfaces/state/folders";
import { AutomationScenario } from "interfaces/state/scenarios";
import {
  folders$,
  isLoading$ as isLoadingFolders$,
  maxItemsLength$,
} from "selectors/folders";
import {
  automationRouting$,
  currentAutomationRoute$,
  fileSystemSize$,
  limit$,
  page$,
  search$,
} from "selectors/automationRouting";
import * as AuthActions from "actions/auth";
import * as AutomationRouteActions from "actions/automationRouting";
import * as MainFolderCreators from "creators/mainFolder";
import * as FolderCreators from "creators/folders";
import { RouteComponentProps, withRouter } from "utils/router";
import { AuthLoader } from "components/authLoader";
import { privateRoute } from "routes";
import { makeScenarioParams } from "utils";
import { FileSystemHeader } from "../fileSystemHeader";
import { FileSystemActions } from "../fileSystemActions";
import { AutomationFile } from "../automationFile";
import { TableHeader } from "../tableHeader";
import {
  Pagination,
  Variant,
} from "pages/scenario/components/versionPanel/components/pagination";
import style from "./fileSystem.module.scss";
import { mainFolderId$ } from "selectors/mainFolder";
import { EmptyFolder } from "../emptyFolder";
import { isLoading$ as mainFolderLoading$ } from "selectors/mainFolder";
import { ReactComponent as ArrowSmallRightIcon } from "assets/svg/new-svg/20x20/arrow-small-right.svg";

export enum AutomationFileSize {
  BLOCKS = "blocks",
  TABLE = "table",
}

interface IStateProps {
  currentAutomationRoute: AutomationRoute | null;
  isLoadingFolders: boolean;
  folders: (IFolder | AutomationScenario)[];
  maxItemsLength: number;
  fileSystemSize: AutomationFileSize;
  limit: number;
  page: number;
  automationRouting: AutomationRoute[];
  mainFolderId: number | null;
  mainFolderLoading: boolean;
  search: string;
}

interface IDispatchProps {
  setCurrentAutomationRoute: (payload: AutomationRoute) => void;
  renameFolder: (id: number, payload: string) => void;
  deleteFolder: (id: number) => void;
  fetchAllAutomationFiles: (
    id: number,
    s_by?: string,
    s_direction?: string,
    lim?: number,
    offs?: number,
    catgs?: number[],
    withoutSearch?: boolean
  ) => void;
  updateFilseSystemSize: (size: AutomationFileSize) => void;
  updatePagination: (
    size: AutomationRouteActions.AutomationPaginationPayload
  ) => void;
  hideHeader: (val: boolean) => void;
  fetchMainFolderData: () => void;
  moveFolderToAnotherFolder: (id: number, new_parent_id: number) => void;
  moveScenarioToAnotherFolder: (
    id: number,
    scenario_id: number | string
  ) => void;
}

interface OwnProps {
  isNeedName?: boolean;
}

interface IProps
  extends IStateProps,
    IDispatchProps,
    OwnProps,
    RouteComponentProps,
    WithTranslation {}

class FileSystem extends React.Component<IProps> {
  state = {
    isShowCreateFolderModal: false,
    isShowCreateScenarioModal: false,
    scenarioName: "",
    folderName: "",
    isOpenAfterCreate: true,
    isShowParentBack: false,
    draggingOverId: null,
    droppedId: null as null | string | number,
  };

  componentDidMount() {
    this.props.fetchMainFolderData();
    this.props.hideHeader(false);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      this.props.currentAutomationRoute?.id !==
        prevProps.currentAutomationRoute?.id ||
      (!this.props.folders?.length && this.state.isShowParentBack)
    ) {
      this.setState({ isShowParentBack: false });
    }
  }

  onOpenModal = () => {
    this.setState({ isShowCreateFolderModal: true });
  };

  onCloseModal = () => {
    this.setState({ isShowCreateFolderModal: false });
  };

  onOpenCreateScenarioModal = () => {
    this.setState({ isShowCreateScenarioModal: true });
  };

  onCloseCreateScenarioModal = () => {
    this.setState({ isShowCreateScenarioModal: false });
    this.defaultScenarioPopup();
  };

  handleFolderClick = (data: IFolder) => {
    const { fetchAllAutomationFiles, navigate, updatePagination } = this.props;

    const folder = {
      type: AutomationRouteType.FOLDER,
      title: data.name,
      id: data.id,
    };

    fetchAllAutomationFiles(
      data.id,
      undefined,
      undefined,
      8,
      0,
      undefined,
      true
    );
    navigate(`${privateRoute.automation}/?id=${folder.id}&page=1`);
    updatePagination({ limit: 8, offset: 0, page: 1 });
  };

  handleScenarioClick = (file: AutomationScenario) => {
    const { setCurrentAutomationRoute } = this.props;

    const data = {
      type: AutomationRouteType.FOLDER,
      title: file.name,
      id: file.scenario_id,
    };

    setCurrentAutomationRoute(data as AutomationRoute);

    this.props.navigate({
      pathname: privateRoute.scenario,
      search: makeScenarioParams({
        scenario_id: data.id,
        version_id: file.full_scenario.version_id,
        isEdit: true,
        isView: false,
        isDebug: false,
        isViewVersion: false,
        selectedBlock: null,
        branch: "devel",
      }),
    });
  };

  handleChangePaginationPage = (page: number) => {
    const { navigate, currentAutomationRoute, fetchAllAutomationFiles, limit } =
      this.props;
    const offset = page * limit;
    this.props.updatePagination({ page, offset: offset - limit });

    if (currentAutomationRoute?.id) {
      fetchAllAutomationFiles(
        +currentAutomationRoute.id,
        undefined,
        undefined,
        undefined,
        offset - limit
      );
      navigate({ search: `id=${currentAutomationRoute?.id}&page=${page}` });
    }
  };

  defaultScenarioPopup = () => {
    this.setState({
      scenarioName: "",
      isOpenAfterCreate: true,
    });
  };

  onChangeScenarioName = (scenarioName: string) => {
    this.setState({ scenarioName });
  };

  onChangeFolderName = (folderName: string) => {
    this.setState({ folderName });
  };

  handleChangeIsOenAfterCreate = () => {
    this.setState({ isOpenAfterCreate: !this.state.isOpenAfterCreate });
  };

  handleSizeChange = (size: AutomationFileSize) =>
    this.props.updateFilseSystemSize(size);

  handleShowParentFolder = (isShowParentBack: boolean) => {
    this.setState({ isShowParentBack });
  };

  handleDroppedId = (droppedId: string | null | number) => {
    this.setState({ droppedId });
  };

  handleDropToParentFolder = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const targetElement = e.currentTarget;
    const { clientX, clientY } = e;

    const rect = targetElement.getBoundingClientRect();
    const isInsideElement =
      clientX >= rect.left &&
      clientX <= rect.right &&
      clientY >= rect.top &&
      clientY <= rect.bottom;

    if (!isInsideElement) return;
    if (e.clientX !== e.pageX) return;
    const { automationRouting, folders, mainFolderId, currentAutomationRoute } =
      this.props;

    if (this.state.droppedId && currentAutomationRoute?.id !== mainFolderId) {
      const item = folders.find((file) => {
        return file.type === AutomationRouteType.FOLDER
          ? file.id === this.state.droppedId
          : file.scenario_id === this.state.droppedId;
      });

      const destinationId = automationRouting?.at(-2)?.id;

      if (item.type === AutomationRouteType.FOLDER) {
        this.props.moveFolderToAnotherFolder(
          this.state.droppedId as number,
          destinationId as number
        );
      } else {
        this.props.moveScenarioToAnotherFolder(
          destinationId as number,
          this.state.droppedId
        );
      }
    }
  };

  handleDropEnd = () => {
    this.setState({ isShowParentBack: false });
  };

  render() {
    const {
      isLoadingFolders,
      maxItemsLength,
      fileSystemSize,
      limit,
      folders,
      page,
      currentAutomationRoute,
      mainFolderId,
      mainFolderLoading,
      t,
    } = this.props;

    const ContentCX = cx(style.content, {
      [style.table]: fileSystemSize === AutomationFileSize.TABLE,
    });

    const WrapperContentCX = cx(style.wrapperContent, {
      [style.table]: fileSystemSize === AutomationFileSize.TABLE,
      [style.globalSearchWrapper]:
        this.props.search &&
        currentAutomationRoute?.id === mainFolderId &&
        fileSystemSize === AutomationFileSize.TABLE,
    });

    const BackCX = cx(style.back, {
      [style.table]: fileSystemSize === AutomationFileSize.TABLE,
    });

    const ParentCX = cx(style.parent, {
      [style.invisible]: !this.state.isShowParentBack,
      [style.table]: fileSystemSize === AutomationFileSize.TABLE,
    });

    const handleClickBack = () => {
      const {
        fetchAllAutomationFiles,
        navigate,
        updatePagination,
        automationRouting,
      } = this.props;

      if (!automationRouting.length) return;

      const prevAutomationRoute = automationRouting?.at(-2);

      const folder = {
        type: AutomationRouteType.FOLDER,
        title: prevAutomationRoute.title,
        id: prevAutomationRoute.id as number,
      };

      fetchAllAutomationFiles(
        folder.id as number,
        undefined,
        undefined,
        8,
        0,
        undefined,
        true
      );
      navigate(`${privateRoute.automation}/?id=${folder.id}&page=1`);
      updatePagination({ limit: 8, offset: 0, page: 1 });
    };

    const renderSortedValue = () => {
      if (currentAutomationRoute?.id === mainFolderId) {
        let result: {
          [key: string]: {
            list: Array<Folder | AutomationScenario>;
            path: FolderPath[];
          };
        } = {};

        folders.forEach((folder) => {
          const path = folder.path.map(({ name }) => name).join("/");

          if (!result[path]) {
            result[path] = { list: [], path: folder.path };
          }

          result[path].list = [...result[path].list, folder];
        });

        return result;
      }
    };

    return (
      <div className={style.root}>
        <FileSystemHeader
          size={fileSystemSize}
          onChangeSize={this.handleSizeChange}
        />
        <div className={style.wrapper}>
          <FileSystemActions />

          {fileSystemSize === AutomationFileSize.TABLE && <TableHeader />}

          <div className={WrapperContentCX}>
            {!folders.length && !isLoadingFolders && !mainFolderLoading && (
              <EmptyFolder
                onClick={handleClickBack}
                search={this.props.search}
                isNeedToShowBtn={
                  mainFolderId !== currentAutomationRoute?.id &&
                  !isLoadingFolders
                }
              />
            )}

            {this.props.search &&
            currentAutomationRoute?.id === mainFolderId &&
            fileSystemSize === AutomationFileSize.TABLE ? (
              <div className={style.globalSearch}>
                {Object.keys(renderSortedValue()).map((key) => {
                  return (
                    <div key={key} className={style.globalSearchItem}>
                      <div className={style.pathContainer}>
                        {renderSortedValue()[key]?.path?.map(
                          (valuePath, index, list) => {
                            return (
                              <div
                                key={valuePath.id}
                                className={style.pathWrapper}
                              >
                                <div className={style.pathName}>
                                  {valuePath.name === "start_folder"
                                    ? t("automation:yourScenarios")
                                    : valuePath.name}
                                </div>
                                {index !== list.length - 1 && (
                                  <ArrowSmallRightIcon
                                    className={style.pathIcon}
                                  />
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                      <div className={style.valueSortBody}>
                        {renderSortedValue()[key]?.list?.map((sortFile) => {
                          const id =
                            sortFile.type === AutomationRouteType.FOLDER
                              ? sortFile.id.toString()
                              : sortFile.scenario_id.toString();

                          return (
                            <AutomationFile
                              key={id}
                              onFolderClick={this.handleFolderClick}
                              onFolderRename={this.props.renameFolder}
                              onFolderDelete={this.props.deleteFolder}
                              onScenarioClick={this.handleScenarioClick}
                              onFolderToAnotherFolder={
                                this.props.moveFolderToAnotherFolder
                              }
                              onScenarioToAnotherFolder={
                                this.props.moveScenarioToAnotherFolder
                              }
                              id={id}
                              type={sortFile.type}
                              size={fileSystemSize}
                              handleShowParentFolder={
                                this.handleShowParentFolder
                              }
                              droppedId={this.state.droppedId}
                              handleDroppedId={this.handleDroppedId}
                              onDropEnd={this.handleDropEnd}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className={ContentCX} onDragOver={(e) => e.preventDefault()}>
                {currentAutomationRoute?.id !== mainFolderId && (
                  <div
                    className={ParentCX}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={this.handleDropToParentFolder}
                  >
                    <div className={BackCX}> Back</div>
                  </div>
                )}

                {folders.map((file) => {
                  const id =
                    file.type === AutomationRouteType.FOLDER
                      ? file.id.toString()
                      : file.scenario_id.toString();

                  return (
                    <AutomationFile
                      key={id}
                      onFolderClick={this.handleFolderClick}
                      onFolderRename={this.props.renameFolder}
                      onFolderDelete={this.props.deleteFolder}
                      onScenarioClick={this.handleScenarioClick}
                      onFolderToAnotherFolder={
                        this.props.moveFolderToAnotherFolder
                      }
                      onScenarioToAnotherFolder={
                        this.props.moveScenarioToAnotherFolder
                      }
                      id={id}
                      type={file.type}
                      size={fileSystemSize}
                      handleShowParentFolder={this.handleShowParentFolder}
                      droppedId={this.state.droppedId}
                      handleDroppedId={this.handleDroppedId}
                      onDropEnd={this.handleDropEnd}
                    />
                  );
                })}
              </div>
            )}

            {!!(isLoadingFolders || mainFolderLoading) && (
              <AuthLoader className={style.loading} />
            )}
          </div>
          <div className={style.pagination}>
            <Pagination
              onClick={this.handleChangePaginationPage}
              count={maxItemsLength}
              page={page}
              itemsPerPage={limit}
              variant={Variant.FILESYSTEM}
              isLoading={isLoadingFolders}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    folders: folders$(state),
    currentAutomationRoute: currentAutomationRoute$(state),
    isLoadingFolders: isLoadingFolders$(state),
    maxItemsLength: maxItemsLength$(state),
    fileSystemSize: fileSystemSize$(state),
    limit: limit$(state),
    page: page$(state),
    automationRouting: automationRouting$(state),
    mainFolderId: mainFolderId$(state),
    mainFolderLoading: mainFolderLoading$(state),
    search: search$(state),
  };
};

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  props: OwnProps
) => {
  return {
    ...stateProps,
    setCurrentAutomationRoute: dispatchProps.setCurrentAutomationRoute,
    renameFolder: dispatchProps.renameFolder,
    deleteFolder: dispatchProps.deleteFolder,
    fetchAllAutomationFiles: dispatchProps.fetchAllAutomationFiles,
    updateFilseSystemSize: dispatchProps.updateFilseSystemSize,
    updatePagination: dispatchProps.updatePagination,
    hideHeader: dispatchProps.hideHeader,
    fetchMainFolderData: dispatchProps.fetchMainFolderData,
    moveFolderToAnotherFolder: dispatchProps.moveFolderToAnotherFolder,
    moveScenarioToAnotherFolder: dispatchProps.moveScenarioToAnotherFolder,
    ...props,
  };
};

export default withTranslation(["automation", "core"])(
  withRouter(
    connect(
      mapStateToProps,
      {
        setCurrentAutomationRoute:
          AutomationRouteActions.setCurrentAutomationRoute,
        renameFolder: FolderCreators.renameFolder,
        deleteFolder: FolderCreators.deleteFolder,
        fetchAllAutomationFiles: FolderCreators.fetchAllAutomationFiles,
        updateFilseSystemSize: AutomationRouteActions.updateFilseSystemSize,
        updatePagination: AutomationRouteActions.updatePagination,
        hideHeader: AuthActions.hideHeader,
        fetchMainFolderData: MainFolderCreators.fetchMainFolderData,
        moveFolderToAnotherFolder: FolderCreators.moveFolderToAnotherFolder,
        moveScenarioToAnotherFolder: FolderCreators.moveScenarioToAnotherFolder,
      },
      mergeProps
    )(FileSystem)
  )
);
