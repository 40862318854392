import { RootState } from "reducers";

export const scenarioCategories$ = (state: RootState) =>
  state.scenarioCategories.list;
export const isError$ = (state: RootState) => state.scenarioCategories.isError;
export const isLoading$ = (state: RootState) =>
  state.scenarioCategories.isLoading;
export const companyCategories$ = (state: RootState) =>
  state.scenarioCategories.company_list;
export const selectedCategories$ = (state: RootState) =>
  state.scenarioCategories.selected_list;
