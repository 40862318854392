import store from "store";
import { AnswerData } from "../index";
import * as PaymentMethodActions from "actions/paymentMethod";
import { PaymentMethod } from "interfaces/state/paymentMethod";

// WSApi.UpdatePaymentMethod
export const paymentMethodUpdate = (data: AnswerData) => {
  const { dispatch } = store;
  if (!data?.error) {
    const response: PaymentMethod[] | undefined = data?.response;
    if (response?.length) {
      dispatch(PaymentMethodActions.setPaymentList(response));

      const defaultMethod = response.find((method) => method.default_method);

      defaultMethod &&
        dispatch(PaymentMethodActions.setDefaultPayment(defaultMethod));
    }
  }
};
