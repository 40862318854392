import * as Const from "../constants";
import State, {
  SortBy,
  SortDirection,
} from "../interfaces/state/automationRouting";
import { AutomationFileSize } from "pages/automation/containers/fileSystem";

export const initState: State = {
  list: [],
  current: null,
  sort_by: SortBy.NAME,
  sort_direction: SortDirection.UP,
  limit: 8,
  offset: 0,
  size: AutomationFileSize.BLOCKS,
  search: "",
  page: 1,
};

export default function automatinRouting(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.AUTOMATION_ROUTE_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
        current: state.current,
        size: state.size,
      };

    case Const.AUTOMATION_ROUTE_DELETE_FROM_TO:
      const availableRoutes = [...state.list];
      availableRoutes.splice(action.payload + 1);
      return {
        ...state,
        list: [...availableRoutes],
      };

    case Const.SET_CURRENT_AUTOMATION_ROUTE:
      return {
        ...state,
        current: action.payload,
      };

    case Const.SET_AUTOMATION_ROUTES:
      const list = action.payload;

      if (list.length <= 0) {
        return { ...state };
      }

      return {
        ...state,
        list,
        current: list[list.length - 1],
      };

    case Const.AUTOMATION_PAGINATION:
      const { sort_by, sort_direction, limit, offset, page } = action.payload;

      return {
        ...state,
        sort_by: sort_by || state.sort_by,
        sort_direction: sort_direction || state.sort_direction,
        limit: typeof limit === "number" ? limit : state.limit,
        offset: typeof offset === "number" ? offset : state.offset,
        page: typeof page === "number" ? page : state.page,
      };

    case Const.AUTOMATION_SIZE:
      return {
        ...state,
        size: action.payload,
      };

    case Const.AUTOMATION_SEARCH:
      return {
        ...state,
        search: action.payload,
      };

    default:
      return state;
  }
}
