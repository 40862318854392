import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  MouseEvent,
} from "react";
import cx from "classnames";
import style from "./block.module.scss";
import { handleBlockIcon, handleBlockName } from "./utils";
import {
  AllScenarioBlocks,
  BlockExecuteMethod,
  Branch,
  ScenarioInit,
} from "interfaces/state/scenario";
import { ReactComponent as FiMoreHorizontalIcon } from "assets/svg/fi-more-horizontal.svg";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { scenarioItem$, selectedBlockId$ } from "selectors/scenario";
import { ServiceIcon } from "components/serviceIcon";
import { getBlocksWithErrors, replaceSymbolFromStr } from "utils";
import { addBlock, addBlockFromCaseOf, addFirstBlock } from "creators/scenario";
import { list$ } from "selectors/debug";
import { company_role$, plan_name$ } from "selectors/auth";
import { MemberRole } from "interfaces/state/team";
import { DeleteBlockButton } from "../deleteCubeButton";
import { INIT_BLOCK } from "pages/scenario/constants";
import { X_STEP } from "pages/scenario/utils";
import { Controlls } from "./components/controlls";
import { useSearchParams } from "react-router-dom";

export interface IProps {
  isEdit: boolean;
  isDebug: boolean;
  item?: AllScenarioBlocks;
  init?: ScenarioInit;
  setBlock: (id: string | number) => void;
}

export const Block: FC<IProps> = ({
  item,
  init,
  isEdit,
  isDebug,
  setBlock,
}) => {
  const dispatch = useAppDispatch();
  const selectedBlockId = useAppSelector(selectedBlockId$);
  const scenario = useAppSelector(scenarioItem$);
  const debugList = useAppSelector(list$);
  const company_role = useAppSelector(company_role$);
  const planName = useAppSelector(plan_name$);
  const [isOpen, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [hideDeleteBtn, setHideDeleteBtn] = useState<boolean>(false);

  const id = useMemo(
    () => (init ? INIT_BLOCK : `${item?.id}`),
    [init, item?.id]
  );

  const handleOpen = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  }, []);

  const selected = useMemo(
    () =>
      selectedBlockId === item?.id ||
      (!!init && selectedBlockId === INIT_BLOCK),
    [init, item?.id, selectedBlockId]
  );

  useEffect(() => {
    !selected && isOpen && setOpen(false);
  }, [item?.id, selectedBlockId, isOpen, selected]);

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (!!init) {
      const key =
        scenario?.init?.branch === Branch.TESTING && planName === "Enterprise"
          ? "testing_start_opts"
          : "start_opts";

      const args = scenario?.init[key];

      if (args) {
        getBlocksWithErrors(args, setError);
      }
    }
  }, [init, planName, scenario]);

  const itemKey = useMemo(() => {
    if (!!init) return null;

    return (item as BlockExecuteMethod)?.branch === Branch.TESTING &&
      planName === "Enterprise"
      ? "testing_arguments"
      : "arguments";
  }, [init, item, planName]);

  useEffect(() => {
    if (item?.type === "execute_method" && itemKey) {
      item[itemKey] && getBlocksWithErrors(item[itemKey]!, setError);
    }
  }, [item, init, itemKey]);

  const isDebugError = useMemo(
    () => debugList.some((el) => el.block_id === item?.id && el?.isError),
    [debugList, item?.id]
  );

  const isSuccessDebug = useMemo(
    () => debugList.some((el) => el.block_id === item?.id && !el?.isError),
    [debugList, item?.id]
  );

  const getService = useMemo(
    () => (
      <ServiceIcon
        variant="xsmall"
        service={
          !!init
            ? init.event_service
            : (item as BlockExecuteMethod)?.method_service
        }
      />
    ),
    [init, item]
  );

  const getName = useCallback(
    (name?: string) => (name ? replaceSymbolFromStr(" ", "_", name) : null),
    []
  );

  const getServiceMethod = useMemo(
    () =>
      item?.method_name
        ? getName(item?.method_name)
        : getName(init?.method_name),
    [getName, init?.method_name, item?.method_name]
  );

  const RootCX = cx(style.root, {
    [style.error]: (isDebugError && searchParams.get("debug") === "1") || error,
    [style.success]: isSuccessDebug && searchParams.get("debug") === "1",
    [style.selected]: selected,
    [style.hasService]: item?.method_name || init?.event_service,
    [style.grey]: !!init && isDebug && searchParams.get("debug") === "1",
  });

  const handleLeftClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (!!init) return;
    const child_block_id = item?.id;

    const fileteredNextKeysById = scenario?.pipeline?.filter(
      (p) => p?.id === item?.id
    );

    const parent_id =
      fileteredNextKeysById.length > 1
        ? scenario?.pipeline?.find((p) => p?.id === item?.id && !p?.next)?.in ||
          INIT_BLOCK
        : scenario?.pipeline?.find((p) => p?.id === item?.id)?.in || INIT_BLOCK;

    const coefficient = parent_id === INIT_BLOCK ? 0 : 2;

    if (child_block_id && parent_id) {
      const parentBlockType = scenario?.scenario.find(
        (b) => b.id === parent_id
      )?.type;

      const isSourceB = scenario?.pipeline?.find(
        (p) => p.id === child_block_id && p.source_position === "b"
      );

      const caseOfCoefficient =
        parentBlockType === "case_of" && isSourceB ? 1 : 0;

      const x = coefficient
        ? item?.x_coordinate! - X_STEP * coefficient + caseOfCoefficient
        : scenario?.init?.x_coordinate;

      const position = {
        x,
        y: item?.y_coordinate!,
      };

      dispatch(
        addBlock(
          position,
          child_block_id as string,
          parent_id as string,
          !!init ? 0 : 1
        )
      );
    }
  };

  const handleRightClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (item?.type === "case_of") {
        const position = {
          x: item?.x_coordinate || 300,
          y: item?.y_coordinate || 60,
        };
        dispatch(addBlockFromCaseOf("b", position));
      } else if (init) {
        if (scenario?.scenario?.length) {
          const childBlockId = scenario?.pipeline?.find((p) => p?.first)?.id;

          if (childBlockId) {
            const position = {
              x: init?.x_coordinate! - X_STEP,
              y: init?.y_coordinate!,
            };

            dispatch(addBlock(position, childBlockId));
          }
        } else {
          const position = init?.x_coordinate
            ? { x: init?.x_coordinate, y: init?.y_coordinate }
            : undefined;
          dispatch(addFirstBlock(position));
        }
      } else if (
        (!init && ["execute_method", "counter"].includes(item?.type)) ||
        !item?.type
      ) {
        const currentPipeline = scenario?.pipeline?.find(
          (p) => p?.id === item?.id
        );

        const childBlockId = currentPipeline?.next
          ? currentPipeline?.next
          : undefined;

        const position = {
          x: childBlockId ? item?.x_coordinate! - X_STEP : item?.x_coordinate!,
          y: item?.y_coordinate!,
        };

        dispatch(addBlock(position, childBlockId));
      }
    },
    [
      dispatch,
      init,
      item?.id,
      item?.type,
      item?.x_coordinate,
      item?.y_coordinate,
      scenario,
    ]
  );

  const handleBottomClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const position = {
        x: (item?.x_coordinate || 300) + X_STEP,
        y: item?.y_coordinate || 60,
      };
      dispatch(addBlockFromCaseOf("c", position));
    },
    [dispatch, item?.x_coordinate, item?.y_coordinate]
  );

  const handleTopClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const position = {
        x: (item?.x_coordinate || 300) + X_STEP,
        y: item?.y_coordinate || 60,
      };
      dispatch(addBlockFromCaseOf("a", position));
    },
    [dispatch, item?.x_coordinate, item?.y_coordinate]
  );

  const isAllowedToEdit = useMemo(
    () => !!scenario?.is_allowed_to_edit && company_role !== MemberRole.USER,
    [company_role, scenario?.is_allowed_to_edit]
  );

  const MenuIconCX = cx(style.icon, {
    [style.open]: isOpen,
  });

  const handleClick = useCallback(
    () => (item?.id || !!init) && setBlock(init ? INIT_BLOCK : item?.id),
    [init, item, setBlock]
  );

  const handleCloseDelete = () => {
    setHideDeleteBtn(!hideDeleteBtn);
    !isOpen && setOpen(true);
  };

  return (
    <>
      <div className={RootCX} onClick={handleClick}>
        {!!isEdit && isAllowedToEdit && (
          <Controlls
            item={item}
            init={init}
            id={id}
            selected={selected}
            onBottomClick={handleBottomClick}
            onLeftClick={handleLeftClick}
            onRightClick={handleRightClick}
            onTopClick={handleTopClick}
          />
        )}
        <div className={style.header}>
          {handleBlockIcon(
            init ? "initial_block" : item?.type,
            init?.type === "schedule",
            (isDebugError && searchParams.get("debug") === "1") || error,
            isSuccessDebug && searchParams.get("debug") === "1",
            !!init && isDebug
          )}

          <div className={style.block}>
            {handleBlockName(
              init ? "initial_block" : item?.type,
              init?.type === "schedule"
            )}
          </div>

          {isOpen && !init && (
            <div className={style.panel} onClick={handleCloseDelete}>
              <DeleteBlockButton
                id={item?.id}
                isCaseOf={item?.type === "case_of"}
                hideMainButton={hideDeleteBtn}
                onHideBtn={(val: boolean) => {
                  setHideDeleteBtn(val);
                  setOpen(val);
                }}
              />
            </div>
          )}

          {!init && isEdit && scenario?.is_allowed_to_edit && (
            <div onClick={handleOpen} className={style.menu}>
              <FiMoreHorizontalIcon className={MenuIconCX} />
            </div>
          )}
        </div>
        <div className={style.body}>
          <div className={style.top}>
            <div className={style.service}>
              {(init?.type === "webhook" || item?.type === "execute_method") &&
                getService}
            </div>
            <div className={style.name} title={init ? init?.name : item?.name}>
              {init ? init?.name : item?.name}
            </div>
          </div>
          <div className={style.bottom}>
            {item?.type === "execute_method" || init?.type === "webhook"
              ? getServiceMethod
              : null}
          </div>
        </div>
      </div>
    </>
  );
};
