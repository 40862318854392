import React, { memo, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SupportLink } from "components_new/supportLink";
import { isLoading$, status_session$ } from "selectors/auth";
import { AuthLoader } from "components/authLoader";
import { StatusSession } from "interfaces/state/auth";
import { isMobile, withSuspense } from "utils";
import * as LangActions from "actions/lang";
import * as AuthCreators from "creators/auth";
import LangState, { LANGUAGE } from "interfaces/state/lang";
import { privateRoute, routesWithoutRedirects } from "routes";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { lang$ } from "selectors/lang";

const unUpdatedRoutes = [
  "email-was-sent",
  "confirm-change-email",
  "app/change-email",
  privateRoute.support,
];

const MobilePage = withSuspense(
  React.lazy(() =>
    import("pages/mobile").then(({ Mobile }) => ({ default: Mobile }))
  )
);

const PrivateContainer = React.lazy(() => import("modules/private"));

const SuspendedPrivate = withSuspense(PrivateContainer);

const App = memo(() => {
  const status_session = useAppSelector(status_session$);
  const isLoading = useAppSelector(isLoading$);
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const language = useAppSelector(lang$);
  const langRef = useRef<string>(LANGUAGE.UA);

  useEffect(() => {
    if (language !== langRef.current) {
      language && i18n.changeLanguage(language);
      langRef.current = language;
    }
  }, [language, i18n]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      const currentLanguage = i18n.language;
      const twoLetterCode = currentLanguage.split("-")[0];
      dispatch(LangActions.setLanguage(twoLetterCode as LangState["lang"]));
      dispatch(AuthCreators.mountApp(navigate, searchParams, location));
    }

    return () => {
      ignore = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      if (
        ((!unUpdatedRoutes.some((route) => pathname.includes(route)) &&
          !searchParams.get("token")) ||
          !routesWithoutRedirects.includes(pathname)) &&
        status_session !== StatusSession.INITIAL
      ) {
        dispatch(AuthCreators.updateAppByStatus(navigate));
      }
    }

    return () => {
      ignore = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status_session]);

  const renderLoading = useMemo(() => isLoading && <AuthLoader />, [isLoading]);

  const resultRender = useMemo(
    () =>
      isMobile() ? (
        <MobilePage />
      ) : (
        <>
          <SupportLink />
          <SuspendedPrivate />
          {renderLoading}
        </>
      ),
    [renderLoading]
  );

  return <div className="App">{resultRender}</div>;
});

export default App;
