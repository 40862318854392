import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as CompanyActions from "actions/company";
import store from "store";

// WSApi.CompanyGetAvailable
export const companyGetAvailable = (data: AnswerData) => {
  if (data.error) {
    errorFromResponse(data.error);
  } else {
    store.dispatch(CompanyActions.setCompanyList(data.response));
  }
};
