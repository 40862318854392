import * as Const from "../constants";

export const setMainFolderInit = () => {
  return {
    type: Const.MAIN_FOLDER_INIT,
  };
};

export const setMainFolderSuccess = (folder_id: number | null) => {
  return {
    type: Const.MAIN_FOLDER_SUCCESS,
    folder_id,
  };
};

export const setMainFolderError = () => {
  return {
    type: Const.MAIN_FOLDER_FAIL,
  };
};

export const setMainFolderLoading = () => {
  return {
    type: Const.MAIN_FOLDER_LOADING,
  };
};
