import State from "interfaces/state";
import { createSelector } from "reselect";

export const folder_id = (state: State) => state.mainFolder.folder_id;
export const isLoading$ = (state: State) => state.mainFolder.isLoading;
export const mainFolderId$ = createSelector(
  [folder_id],
  (folder_id) => folder_id
);
export const folder_name = (state: State) => state.mainFolder.folder_name;
export const mainFolderName$ = createSelector(
  [folder_name],
  (folder_name) => folder_name
);
