import { AnswerData } from "..";
import * as ScenarioActions from "actions/scenario";
import * as ScenarioCreators from "creators/scenario";
import store from "store";
import { useLocation, useNavigate } from "react-router-dom";
import { privateRoute } from "routes";
import { getPaginationItemsCount, makeScenarioParams } from "utils";
import { scenarioItem$ } from "selectors/scenario";
import { page$ } from "selectors/scenarioVersion";

// WSApi.AddNewScenarioVersion
export const scenarioVersionNew = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const scenarioItem = scenarioItem$(store.getState());
  if (!data?.error && data.response.scenario_id === scenarioItem?.scenario_id) {
    const isViewVersion = document.getElementById("version-panel");

    if (!isViewVersion) {
      const searchParams = new URLSearchParams(window.location.search);
      const scenario_id = searchParams.get("id");
      const isDebug = searchParams.get("debug") === "1";
      const selectedBlock = searchParams.get("selectedBlock");
      const branch = searchParams.get("branch")!;

      store.dispatch(
        ScenarioActions.setScenarioSuccess({
          ...scenarioItem,
          is_allowed_to_edit: true,
        })
      );

      navigate(
        `${privateRoute.scenario}?${makeScenarioParams({
          scenario_id,
          version_id: data.response.id,
          isEdit: true,
          isView: false,
          isDebug,
          isViewVersion: false,
          selectedBlock,
          branch,
        })}`
      );
      store.dispatch(
        ScenarioCreators.fetchScenarioById(scenario_id!, true, data.response.id)
      );
    } else {
      store.dispatch(ScenarioActions.openAllowedToEditModal(true));
    }

    const currentPage = page$(store.getState()) || 1;

    const containerHeight =
      document.getElementById("version-container")?.clientHeight;

    const maxItemsPerPage = getPaginationItemsCount(containerHeight!);

    setTimeout(
      () =>
        store.dispatch(
          ScenarioCreators.fetchAllScenarioVersions(
            maxItemsPerPage,
            (Number(currentPage) - 1) * maxItemsPerPage
          )
        ),
      500
    );
  }
};
