import { WSApi } from "enums/wsApi";
import { useLocation, useNavigate } from "react-router-dom";
import { privateRoute } from "routes";
import store from "store";
import { AnswerData } from "../index";
import * as ServicePageActions from "actions/servicesPage";

// WSApi.ConnectorsSearch
export const adminConnectorsSearch = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  _navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch } = store;
  if (!data?.error) {
    dispatch(ServicePageActions.loadServicePageSuccess(data.response));
  } else {
    dispatch(ServicePageActions.setServicePageFail());
  }
};
