import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as BillingInfoActions from "actions/billingInfo";

// WSApi.BillingCSVLink
export const paymentGetCsvHistory = (data: AnswerData) => {
  if (data.error) {
    errorFromResponse(data.error);
  } else {
    store.dispatch(BillingInfoActions.setBillingCSVLink(data.response));

    if (data.response) {
      const filename = `csv-history-${new Date()}.csv`;

      const link = document.createElement("a");
      link.href = data.response;
      link.setAttribute("download", filename);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
};
