import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { getBlockName } from "utils";
import { AnswerData } from "../index";
import * as ScenarioActions from "actions/scenario";
import * as ScenarioCreators from "creators/scenario";
import { BlockCaseOf, CaseofTypes } from "interfaces/state/scenario";

// WSApi.BlockAddBranch || WSApi.BlockUnchainBranch;
export const blockAddBranch = (data: AnswerData) => {
  if (!data?.error) {
    const scenarioItem = scenarioItem$(store.getState());
    if (scenarioItem?.scenario_id === data.response?.scenario_id) {
      const newScenario = {
        scenario: data.response.scenario,
        pipeline: data.response.pipeline,
      };
      store.dispatch(
        ScenarioActions.setScenarioSuccess({
          ...scenarioItem,
          ...newScenario,
        })
      );
      store.dispatch(ScenarioActions.setTempBlockId(data.response.block_id));

      const parentBlockId = data.response.pipeline.find(
        (p) => p.id === data.response.block_id
      )?.in;

      if (parentBlockId) {
        const caseOfParentBlock = data.response.scenario.find(
          (b) => b.id === parentBlockId
        );

        if (caseOfParentBlock as BlockCaseOf) {
          const cubePatterns = caseOfParentBlock?.patterns;

          const newPattern = {
            destination_block: data.response.block_id,
            pattern_type: "equal",
            args: [
              {
                value_type: "input",
                name: "value",
                value: "",
              },
            ],
          };

          store.dispatch(
            ScenarioCreators.updateBlock({
              patterns: [...(cubePatterns || []), newPattern] as CaseofTypes,
            })
          );
        }
      }

      if (data.response.scenario) {
        store.dispatch(
          ScenarioCreators.updateBlock(
            {
              id: data.response.block_id as string,
              name: getBlockName(
                data.response.scenario.length + 1,
                data.response.scenario
              ) as string,
            },
            {
              scenario_id: scenarioItem?.scenario_id!,
              version_id: scenarioItem?.version_id,
            }
          )
        );
      }
    }
  }
};

export const blockUchainBranch = blockAddBranch;
