import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as FoldersActions from "actions/folders";
import * as AutomationRouteActions from "actions/automationRouting";
import { makeFolders } from "./utils";

// WSApi.GetAllFolders
export const folderGetAll = (data: AnswerData) => {
  const { dispatch } = store;

  if (data.error) {
    errorFromResponse(data.error);
    dispatch(FoldersActions.setFoldersError());
  } else {
    const { path, items, max_folders, max_items_length, max_scenarios } =
      data.response;
    const automationRouting = makeFolders(path.reverse());

    const newItems = items.map((i) => ({
      ...i,
      path: (i?.path || [])?.reverse(),
    }));

    dispatch(
      FoldersActions.updateMaxValues({
        max_folders,
        max_items_length,
        max_scenarios,
      })
    );

    dispatch(AutomationRouteActions.setAutomationRouting(automationRouting));
    dispatch(
      AutomationRouteActions.setCurrentAutomationRoute(
        automationRouting[automationRouting.length - 1]
      )
    );
    dispatch(FoldersActions.setFoldersSuccess(newItems));
  }
};
