import store from "store";
import { AnswerData } from "../index";
import * as FoldersActions from "actions/folders";
import * as ScenarioActions from "actions/scenario";
import { scenarioItem$ } from "selectors/scenario";
import { AutomationScenario } from "interfaces/state/scenarios";
import { folders$ } from "selectors/folders";
import { Folder } from "interfaces/state/folders";
import { AutomationRouteType } from "interfaces/state/automationRouting";

// WSApi.RenameScenario
export const scenarioRename = (data: AnswerData) => {
  const { dispatch, getState } = store;

  if (data?.error) {
    dispatch(FoldersActions.setFoldersError());
  } else {
    const folders = [...folders$(getState())];
    const index = folders.findIndex((it: AutomationScenario | Folder) => {
      const id =
        it.type === AutomationRouteType.FOLDER ? it.id : it.scenario_id;

      return id === data.response.scenario_id;
    });

    if (index >= 0) {
      folders[index].name = data.response.new_scenario_name;
      dispatch(FoldersActions.setFoldersSuccess(folders));
    }

    if (scenarioItem$(getState())?.scenario_id === data.response?.scenario_id) {
      dispatch(ScenarioActions.scenarioRename(data.response.new_scenario_name));
    }
  }
};
