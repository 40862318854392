import { scenarioItem$ } from "selectors/scenario";
import { scenarioVersions$, tags$ } from "selectors/scenarioVersion";
import store from "store";
import { AnswerData } from "../index";
import * as ScenarioVersionActions from "actions/scenarioVersion";

// WSApi.DeleteScenarioTag
export const scenarioVersionDeleteTag = (data: AnswerData) => {
  if (!data?.error) {
    const scenario = scenarioItem$(store.getState());

    if (scenario?.scenario_id === data.response.scenario_id) {
      let tag = "";
      const scenarioVersions = scenarioVersions$(store.getState());
      const newScenarioVersions = scenarioVersions.map((version) => {
        if (data.response.version_id === version.id) tag = version.tag!;
        const res =
          data.response.version_id === version.id
            ? { ...version, tag: undefined }
            : version;
        return res;
      });

      const tags = tags$(store.getState());
      const newTags = tags.filter((t) => t !== tag);
      store.dispatch(ScenarioVersionActions.setScenarioVersionTags(newTags));

      store.dispatch(
        ScenarioVersionActions.setScenarioVersionList(newScenarioVersions)
      );
      store.dispatch(ScenarioVersionActions.setScenarioVersionLoading(false));
    }
  }
};
