import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as FoldersActions from "actions/folders";

//  WSApi.SceanarioAddSnapsot
export const scenarioAddSnapshot = (data: AnswerData) => {
  if (data.error) {
    errorFromResponse(data.error);
  } else {
    store.dispatch(
      FoldersActions.addSnapshotToScenarioInFolder(
        data.response.scenario_id,
        data.response.scenario_snapshot
      )
    );
  }
};
