import { ServiceParameter } from "interfaces/state/serviceParameter";
import State from "interfaces/state";
import {
  scenarioItem$,
  currentBlock$,
  method_name$,
  selectedBlockId$,
} from "selectors/scenario";
import { Action } from "redux";
import * as ParameterActions from "actions/serviceParam";
// import { childRender, inputParameterRender } from "utils";
import {
  AllScenarioBlocks,
  Branch,
  BlockExecuteMethod,
} from "interfaces/state/scenario";
import { INIT_BLOCK } from "pages/scenario/constants";
import { parameterForComponent$ } from "selectors/serviceParameter";
import { parameterConstructor } from "utils";
import { updateBlock, updateInitBlock } from "creators/scenario";
import uniq from "lodash-es/uniq";
import { plan_name$ } from "selectors/auth";

export const updateBlockInputParameters =
  (
    data: { [key: string]: ServiceParameter },
    scenarioId: string,
    blockId: string,
    is_update?: boolean
  ) =>
  (state: State, dispatch: (action: Action) => void) => {
    const currentScenario = scenarioItem$(state);
    const planName = plan_name$(state);
    if (scenarioId === currentScenario?.scenario_id) {
      const currentBlock = currentBlock$(state) as BlockExecuteMethod;
      const selectedBlockId = selectedBlockId$(state);
      const method_name = method_name$(state);
      const scenario = currentScenario?.scenario;
      const blockFromScenario = scenario?.find(
        (item) => item?.id === selectedBlockId
      ) as AllScenarioBlocks;

      const key =
        currentBlock?.branch === Branch.TESTING && planName === "Enterprise"
          ? "testing_arguments"
          : "arguments";

      const initBlock = currentScenario?.init;

      const allDataForComponent = currentBlock?.[key]
        ? { ...data, ...currentBlock?.[key] }
        : {};

      const initMethodName = initBlock?.method_name;
      const initServiceName = initBlock?.event_service;

      const initKey =
        initBlock?.branch === Branch.TESTING && planName === "Enterprise"
          ? "testing_start_opts"
          : "start_opts";

      const initMethodNameSaved =
        initMethodName && initServiceName
          ? initMethodName + initServiceName
          : "";

      const isSameBlock =
        (currentBlock && currentBlock?.method_name === method_name) ||
        (selectedBlockId === INIT_BLOCK &&
          initMethodNameSaved === method_name &&
          blockId === INIT_BLOCK);

      const isArgumentsInside = !!(
        blockFromScenario &&
        (blockFromScenario as BlockExecuteMethod)?.[key] &&
        Object.keys((blockFromScenario as BlockExecuteMethod)[key] || {})
          ?.length
      );

      const isBlockWithArgs = !!(isSameBlock && isArgumentsInside);
      const isInitBlockWithArgs = !!(
        isSameBlock &&
        initBlock?.[initKey] &&
        Object.keys(initBlock[initKey])?.length &&
        blockId === INIT_BLOCK
      );

      const setInititalDataForParameters = () => {
        dispatch(
          ParameterActions.receiveServiceParametersForComponent(
            scenarioId,
            blockId,
            Object.keys(data)?.length ? parameterConstructor(data) : {}
          )
        );
      };

      if (isBlockWithArgs) {
        dispatch(
          ParameterActions.receiveServiceParameters(scenarioId, blockId, data)
        );

        if (currentBlock?.[key] && isBlockWithArgs) {
          if (Object.keys(currentBlock[key] as any).length) {
            const newParameters = parameterConstructor(
              allDataForComponent!
            ) as {
              [key: string]: ServiceParameter;
            };

            dispatch(
              ParameterActions.setServiceParameters(
                scenarioId,
                blockId,
                newParameters
              )
            );
          } else {
            setInititalDataForParameters();
          }
        }
      } else if (isInitBlockWithArgs) {
        if (scenarioItem$(state)?.init?.[initKey]) {
          if (Object.keys(scenarioItem$(state)!.init![initKey])) {
            const allInitDataForComponent = {
              ...data,
              ...scenarioItem$(state)?.init?.[initKey],
            };
            dispatch(
              ParameterActions.receiveServiceParameters(
                scenarioId,
                blockId,
                data
              )
            );
            dispatch(
              ParameterActions.setServiceParameters(
                scenarioId,
                blockId,
                parameterConstructor(
                  allInitDataForComponent as {
                    [key: string]: ServiceParameter;
                  }
                )
              )
            );
          } else {
            dispatch(
              ParameterActions.receiveServiceParameters(
                scenarioId,
                blockId,
                data
              )
            );

            setInititalDataForParameters();
          }
        } else {
          dispatch(
            ParameterActions.receiveServiceParameters(scenarioId, blockId, data)
          );

          setInititalDataForParameters();
        }
      } else {
        dispatch(
          ParameterActions.receiveServiceParameters(
            scenarioId,
            blockId,
            parameterConstructor(data)
          )
        );

        setInititalDataForParameters();
      }
    }

    if (is_update) {
      dispatch(
        ParameterActions.setAddedNewParameters(
          blockId,
          parameterConstructor(data) ? parameterConstructor(data) : {}
        )
      );
    }
  };

export const addNewOption =
  (response: {
    data: { [key: string]: ServiceParameter };
    scenario_id: string;
    block_id: string;
    parent_id: string;
  }) =>
  (state: () => State, dispatch: (action: Action) => void) => {
    const scenarioItem = scenarioItem$(state());
    const { block_id, scenario_id, data, parent_id } = response;
    if (block_id && scenario_id && parent_id) {
      const parameterForComponent = parameterForComponent$(
        scenario_id,
        block_id
      )(state());

      const parentParameter = parameterForComponent![parent_id];
      const newIds = Object.keys(data);

      let value = [] as string[];
      newIds.forEach((id) => {
        if (data[id].parent === parent_id) {
          value = [...value, id];
        }
      });
      parentParameter.value =
        parentParameter.type === "one_of"
          ? value
          : Array.isArray(parentParameter?.value)
          ? uniq([...parentParameter.value, ...value])
          : value;

      const newParameters = {
        ...parameterForComponent,
        ...data,
        ...{ [parent_id]: parentParameter },
      };

      dispatch(
        ParameterActions.setServiceParameters(
          scenario_id,
          block_id,
          newParameters
        )
      );

      if (block_id === INIT_BLOCK) {
        const key =
          scenarioItem?.init?.branch === Branch.TESTING &&
          plan_name$(state()) === "Enterprise"
            ? "testing_start_opts"
            : "start_opts";

        dispatch(updateInitBlock({ [key]: newParameters }) as any);
      } else {
        const index = scenarioItem?.scenario?.findIndex(
          ({ id }) => id === block_id
        );

        if (typeof index === "number") {
          const currentBlock = scenarioItem?.scenario[
            index
          ] as BlockExecuteMethod;

          const branch = currentBlock?.branch || Branch.LIVE;

          const key =
            branch === Branch.TESTING && plan_name$(state()) === "Enterprise"
              ? "testing_arguments"
              : "arguments";

          dispatch(updateBlock({ [key]: newParameters, id: block_id }) as any);
        }
      }
    }
  };
