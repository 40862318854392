import { FC, memo, useMemo } from "react";
import { AutomationRouteType } from "interfaces/state/automationRouting";
import { Folder as IFolder } from "interfaces/state/folders";
import { Folder } from "components/folder";
import { Scenario } from "components/scenario";
import { AutomationScenario } from "interfaces/state/scenarios";
import { AutomationFileSize } from "../fileSystem";
import { TableFolder } from "../tableFolder";
import { TableScanerio } from "../tableScenario";
import { folders$ } from "selectors/folders";
import { useAppSelector } from "hooks/redux";
import style from "./automationFile.module.scss";

interface IProps {
  id: string;
  type: AutomationRouteType;
  size: AutomationFileSize;
  droppedId: null | string | number;
  onFolderClick: (folder: IFolder) => void;
  onFolderRename: (id: number, payload: string) => void;
  onFolderDelete: (id: number) => void;
  onScenarioClick: (scenario: AutomationScenario) => void;
  handleShowParentFolder: (value: boolean) => void;
  onFolderToAnotherFolder: (id: number, new_parent_id: number) => void;
  onScenarioToAnotherFolder: (id: number, scenario: number | string) => void;
  handleDroppedId: (val: null | string | number) => void;
  onDropEnd: () => void;
}

export const AutomationFile: FC<IProps> = memo(
  ({
    id,
    type,
    size,
    droppedId,
    onFolderClick,
    onFolderDelete,
    onFolderRename,
    onScenarioClick,
    handleShowParentFolder,
    onFolderToAnotherFolder,
    handleDroppedId,
    onScenarioToAnotherFolder,
    onDropEnd,
  }) => {
    const folders = useAppSelector(folders$);
    const handleFolderById = useMemo(() => {
      let result: { [key: string]: IFolder | AutomationScenario } = {};

      const getId = (i: IFolder | AutomationScenario): string =>
        (i.type === AutomationRouteType.FOLDER
          ? i.id
          : i.scenario_id
        )?.toString();

      folders.forEach((file) => (result[getId(file)] = file));

      return result;
    }, [folders]);

    const item = useMemo(() => handleFolderById[id], [id, handleFolderById]);
    const renderBlockSize = () =>
      type === AutomationRouteType.FOLDER ? (
        <Folder
          onClick={onFolderClick}
          folder={item as IFolder}
          rename={onFolderRename}
          deleteFolder={onFolderDelete}
          handleShowParentFolder={handleShowParentFolder}
          onFolderToAnotherFolder={onFolderToAnotherFolder}
          handleDroppedId={handleDroppedId}
          droppedId={droppedId}
          onScenarioToAnotherFolder={onScenarioToAnotherFolder}
          onDropEnd={onDropEnd}
        />
      ) : (
        <Scenario
          onClick={onScenarioClick}
          scenario={item as AutomationScenario}
          onScenarioToAnotherFolder={onScenarioToAnotherFolder}
          droppedId={droppedId}
          handleShowParentFolder={handleShowParentFolder}
          handleDroppedId={handleDroppedId}
          onDropEnd={onDropEnd}
        />
      );

    const renderTableSize = () =>
      type === AutomationRouteType.FOLDER ? (
        <TableFolder
          item={item as IFolder}
          onClick={onFolderClick}
          handleShowParentFolder={handleShowParentFolder}
          onFolderToAnotherFolder={onFolderToAnotherFolder}
          handleDroppedId={handleDroppedId}
          droppedId={droppedId}
          onScenarioToAnotherFolder={onScenarioToAnotherFolder}
        />
      ) : (
        <TableScanerio
          item={item as AutomationScenario}
          onClick={onScenarioClick}
          onScenarioToAnotherFolder={onScenarioToAnotherFolder}
          droppedId={droppedId}
          handleShowParentFolder={handleShowParentFolder}
          handleDroppedId={handleDroppedId}
        />
      );

    return (
      <div className={style.root}>
        {size === AutomationFileSize.BLOCKS && renderBlockSize()}
        {size === AutomationFileSize.TABLE && renderTableSize()}
      </div>
    );
  }
);
