export default interface TeamState {
  isLoading: boolean;
  isError: boolean;
  list: Member[];
  company_id: null | number;
  company_name: null | string;
  number_of_members: null | number;
  isLoadingChangeRole: boolean;
  isErrorChangeRole: boolean;
  inviteLink: string | null;
  isLoadingInvite: boolean;
}

export type Member = {
  member_id: number;
  first_name: string;
  avatar_url: string;
  email: string;
  role: MemberRole;
  status: MemberStatus;
};

export enum MemberRole {
  OWNER = "owner",
  ADMIN = "admin",
  USER = "user",
}

export type MemberStatus =
  | "authorized"
  | "waiting_for_authorization"
  | "confirmed"
  | "unconfirmed";
