import { scenarioItem$, selectedBlockId$ } from "selectors/scenario";
import store from "store";
import { AnswerData } from "../index";
import * as ScenarioActions from "actions/scenario";
import * as VariablePanelActions from "actions/variablePanel";
import { successNotification } from "utils";
import i18n from "i18n";
import { parameterForComponent$ } from "selectors/serviceParameter";
import { BlockExecuteMethod, Branch } from "interfaces/state/scenario";
import { updateBlock } from "creators/scenario";
import { activeId$, activeParameterIndex$ } from "selectors/variablePanel";

//  WSApi.CreateVariable
export const variableCreate = (data: AnswerData) => {
  if (!data?.error) {
    const { dispatch, getState } = store;
    const scenarioItem = scenarioItem$(store.getState());
    if (
      scenarioItem &&
      scenarioItem?.scenario_id === data.response?.scenario_id
    ) {
      const activeId = activeId$(getState());

      if (["case-of", "case-of-condition"].includes(activeId as string)) {
        dispatch(VariablePanelActions.setTempVariable(data.response.id));
      }

      const variable = data.response;
      const newScenario = {
        variables: [variable, ...scenarioItem.variables],
      };

      dispatch(
        ScenarioActions.setScenarioSuccess({
          ...scenarioItem,
          ...newScenario,
        })
      );

      successNotification(i18n.t("varWasCreated"));

      const selectedBlockId = selectedBlockId$(getState());
      const parameterForComponent = parameterForComponent$(
        scenarioItem?.scenario_id,
        selectedBlockId as string
      )(getState());

      if (parameterForComponent && activeId && activeId !== "case-of") {
        const index = scenarioItem?.scenario?.findIndex(
          ({ id }) => id === selectedBlockId
        );

        const currentBlock = scenarioItem.scenario[index] as BlockExecuteMethod;

        let newPararameterPart = parameterForComponent[activeId];

        const activeParameterIndex = activeParameterIndex$(getState());

        const branch = currentBlock?.branch || Branch.LIVE;

        const key =
          branch === Branch.TESTING ? "testing_arguments" : "arguments";

        if (activeParameterIndex >= 0 && activeParameterIndex !== null) {
          newPararameterPart["value"] = [
            ...(newPararameterPart["value"] as []).slice(
              0,
              activeParameterIndex || 0
            ),
            [data.response.id],
            ...(newPararameterPart["value"] as []).slice(
              activeParameterIndex || 0
            ),
          ];

          newPararameterPart["value_type"] = data.response.value_type;

          const newParameters = {
            ...parameterForComponent,
            [activeId]: newPararameterPart,
          };

          dispatch(updateBlock({ [key]: newParameters, id: selectedBlockId }));
          dispatch(VariablePanelActions.setActiveParameterIndex(null));
        } else {
          newPararameterPart["value"] = ["boolean", "integer"].includes(
            newPararameterPart.type
          )
            ? [data.response.id]
            : [
                ...((newPararameterPart["value"] || []) as any),
                [data.response.id],
              ];

          newPararameterPart["value_type"] = data.response.value_type;

          const newParameters = {
            ...parameterForComponent,
            [activeId]: newPararameterPart,
          };

          dispatch(updateBlock({ [key]: newParameters, id: selectedBlockId }));
        }
      }
    }
  }
};
