import * as Const from "../constants";
import { IVariable } from "interfaces/state/scenario";

export const setVariableInit = () => {
  return {
    type: Const.CONFIRM_INIT,
  };
};

export const setVariableList = (
  payload: IVariable[],
  scenario_id: string,
  block_id: string
) => {
  return {
    type: Const.AVAIL_VAR_SUCCESS,
    payload,
    scenario_id,
    block_id,
  };
};

export const setVariableFail = () => {
  return {
    type: Const.AVAIL_VAR_FAIL,
  };
};

export const setVariableLoading = () => {
  return {
    type: Const.AVAIL_VAR_LOADING,
  };
};

export const setVariableUuid = (payload: null | string) => {
  return {
    type: Const.AVAIL_VAR_UUID,
    payload,
  };
};

export const setVariableId = (payload: null | string) => {
  return {
    type: Const.AVAIL_VAR_ID,
    payload,
  };
};

export const setShowNth = (payload: boolean) => {
  return {
    type: Const.AVAIL_VAR_NTH,
    payload,
  };
};

export const setDragVariableID = (payload: string) => {
  return {
    type: Const.CURRENT_DRAG_VAR_ID,
    payload,
  };
};
