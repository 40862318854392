import { AllScenarioBlocks, Pipeline } from "interfaces/state/scenario";
import { CASE_OF_BLOCK } from "pages/scenario/constants";
import { X_STEP } from "pages/scenario/utils";

export interface RelatedResult {
  id: string;
  x_coordinate: string | number;
  y_coordinate: string | number;
}

export const findAllUpdatedBlocksAfterId = (
  insertBlockId: string,
  pipeline: Pipeline[],
  blockById: { [key: string]: AllScenarioBlocks }
): Pipeline[] => {
  const insertBeforePipelineBlockId = pipeline.find(
    (p) => p.id === insertBlockId && !p.source_position
  )?.in;

  const parentBlock = insertBeforePipelineBlockId
    ? blockById[insertBeforePipelineBlockId]
    : null;

  const findBlockById = (id: string) => pipeline.find((pipe) => pipe.id === id);

  let result: Pipeline[] = [];
  let currentBlockId: string | undefined = insertBlockId;

  while (currentBlockId) {
    const currentBlock = findBlockById(currentBlockId);

    if (!currentBlock) break;

    result.push(currentBlock);
    currentBlockId = currentBlock.next;

    if (
      !parentBlock ||
      currentBlock.id !== parentBlock.id ||
      (currentBlock?.id === parentBlock?.id &&
        parentBlock?.type !== CASE_OF_BLOCK)
    ) {
      const resultA = findAllUpdatedBlocksAfterId(
        currentBlock?.next,
        pipeline,
        blockById
      );

      result = [...result, ...resultA];
    } else {
      break;
    }
  }

  const uniqueResult = result.filter(
    (block, index, self) => index === self.findIndex((b) => b.id === block.id)
  );

  return uniqueResult;
};

export const filterArrayFromId = (arr: Pipeline[], startId: string) => {
  const result = [];
  const visited = new Set();

  function findElementsWithInId(inId) {
    return arr.filter((element) => element.in === inId);
  }

  function processElements(inId) {
    const elements = findElementsWithInId(inId);
    for (const element of elements) {
      visited.add(element.id);
      result.push(element);
      processElements(element.id);
    }
  }

  const startElement = arr.find((element) => element.id === startId);

  if (startElement) {
    visited.add(startElement.id);
    result.push(startElement);
    processElements(startElement.id);
  }

  return result;
};

export function insertBlockBetween(
  insertBlockId: string,
  pipeline: Pipeline[],
  blocks: AllScenarioBlocks[],
  nextBlockId: string
): RelatedResult[] {
  const blockToInsert = pipeline.find((p) => p.id === insertBlockId);
  if (!blockToInsert) return;

  const blockById: { [key: string]: AllScenarioBlocks } = {};
  blocks.forEach((b) => (blockById[b.id] = b));
  const newPipeline = filterArrayFromId(pipeline, insertBlockId);

  let result: RelatedResult[] = [];

  newPipeline.forEach((pipe) => {
    const newBlock = blockById[pipe.id];

    const newItem: RelatedResult = {
      id: pipe.id,
      x_coordinate:
        insertBlockId === pipe.id && pipe?.first
          ? newBlock.x_coordinate
          : newBlock.x_coordinate + X_STEP,
      y_coordinate: newBlock.y_coordinate,
    };

    result.push(newItem);
  });

  return result.filter(
    (block, index, self) => index === self.findIndex((b) => b.id === block.id)
  );
}
