import State from "interfaces/state";
import { Action } from "redux";
import {
  ScenarioInit,
  IVariable,
  Branch,
  BlockExecuteMethod,
  AllScenarioBlocks,
} from "interfaces/state/scenario";
import { INIT_BLOCK } from "pages/scenario/constants";
import { scenarioItem$, selectedBlockId$ } from "selectors/scenario";
import * as ScenarioActions from "actions/scenario";
import * as ParameterActions from "actions/serviceParam";
import * as ScenarioCreators from "creators/scenario";
import { isEqual } from "lodash-es";
import store from "store";
import { useNavigate } from "react-router-dom";
import { privateRoute } from "routes";
import { makeScenarioParams, parameterConstructor } from "utils";
import { sensitiveDataList$ } from "selectors/sensitiveData";
import { fetchSensitiveDataList } from "creators/sensitiveData";
import { plan_name$ } from "selectors/auth";
import { setTempVariable } from "actions/variablePanel";

export const updateInitBlock =
  (
    scenarioId: string,
    data: ScenarioInit,
    variables: IVariable[],
    scenario: AllScenarioBlocks[]
  ) =>
  (state: State, dispatch: (action: Action) => void) => {
    const currentScenario = scenarioItem$(state);

    if (scenarioId === currentScenario?.scenario_id) {
      dispatch(ScenarioActions.updateScenarioInitBlock(data));

      const newAuthorization = data?.authorization_id;
      const sensitiveData = sensitiveDataList$(state);
      if (newAuthorization) {
        const isAuthInSDS = sensitiveData.some(
          (sds) => sds.id === newAuthorization
        );

        if (!isAuthInSDS) {
          dispatch(fetchSensitiveDataList(data.event_service!) as any);
        }
      }
      const key =
        data?.branch === Branch.TESTING && plan_name$(state) === "Enterprise"
          ? "testing_start_opts"
          : "start_opts";

      if (
        (!data?.authorization_id && !data?.testing_authorization_id) ||
        !data?.method_name ||
        (!data?.start_opts && !data?.testing_start_opts)
      ) {
        // dispatch(
        //   ParameterActions.setServiceParameters(scenarioId, INIT_BLOCK, {})
        // );
        // dispatch(
        //   ParameterActions.setInputParameter(scenarioId, INIT_BLOCK, {})
        // );
      }

      if (data?.[key] && Object.keys(data?.[key])?.length) {
        const blockId = INIT_BLOCK;
        dispatch(
          ParameterActions.setInputParameter(
            scenarioId,
            blockId,
            data?.[key] as any
          )
        );
        dispatch(ScenarioActions.setLoadedScenario());
      }

      if (!variables.length && currentScenario?.variables?.length) {
        if (scenario.length) {
          scenario.forEach((sc) => {
            if (sc.type === "execute_method" && sc.arguments) {
              const newData = parameterConstructor(sc.arguments, true);

              dispatch(
                ParameterActions.receiveServiceParameters(
                  currentScenario.scenario_id,
                  sc.id as string,
                  newData
                )
              );

              dispatch(
                ParameterActions.receiveServiceParametersForComponent(
                  currentScenario.scenario_id,
                  sc.id as string,
                  newData
                )
              );

              dispatch(
                ParameterActions.setServiceParameters(
                  currentScenario.scenario_id,
                  sc.id as string,
                  newData
                )
              );
            }
          });
        }
      }

      dispatch(
        ScenarioActions.setScenarioSuccess({
          ...currentScenario,
          variables,
          init: data,
          scenario: scenario ? scenario : currentScenario.scenario,
        })
      );
    }
  };

export const isEqualParamsByBranch = (
  state: State,
  dispatch: (action: Action) => void,
  blockId: string,
  data: BlockExecuteMethod
) => {
  const scenarioItem = scenarioItem$(state);

  const index = scenarioItem?.scenario.findIndex(
    (block) => block.id === blockId
  );

  if (typeof index === "number" && index >= 0) {
    const currentBlock = scenarioItem?.scenario[index] as BlockExecuteMethod;
    const scenarioId = scenarioItem?.scenario_id!;
    const key =
      currentBlock?.branch === Branch.TESTING &&
      plan_name$(state) === "Enterprise"
        ? "testing_arguments"
        : "arguments";

    const isEqualData = isEqual(data[key], currentBlock[key]);
    if (!isEqualData) {
      // dispatch(ParameterActions.setServiceParamInit());
      dispatch(
        ParameterActions.setServiceParameters(
          scenarioId,
          blockId,
          data?.[key] as any
        )
      );
    }
  }
};

export const updateBlock =
  (
    scenarioId: string,
    blockId: string,
    data: BlockExecuteMethod,
    variables: IVariable[]
  ) =>
  (state: State, dispatch: (action: Action) => void) => {
    const currentScenario = scenarioItem$(state);

    dispatch(setTempVariable(""));
    if (scenarioId === currentScenario?.scenario_id) {
      const index = currentScenario?.scenario.findIndex(
        (block) => block.id === blockId
      );

      if (index >= 0) {
        const newScenario = JSON.parse(JSON.stringify(currentScenario));
        newScenario.scenario[index] = data;
        newScenario.variables = variables ? variables : newScenario.variables;

        dispatch(ScenarioActions.setScenarioSuccess(newScenario));
      }

      isEqualParamsByBranch(state, dispatch, blockId, data);
    }
  };

export const getUnfilledBlocks = (
  unfilled_blocks: [],
  scenario_id: string,
  editable_version_id: string,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch, getState } = store;

  if (unfilled_blocks) {
    dispatch(ScenarioActions.getScenarioErrors(unfilled_blocks));
    dispatch(ScenarioActions.openEmptyBlocksErrorModal(true));
    const searchParams = new URLSearchParams(window.location.search);
    const selectedBlock = selectedBlockId$(getState());

    const isEdit = searchParams.get("edit") === "1";
    const isView = searchParams.get("view") === "1";
    const isDebug = searchParams.get("debug") === "1";
    const branch = searchParams.get("branch")!;
    const isViewVersion = searchParams.get("viewVersion")! === "1";
    const scenario = searchParams.get("id");

    navigate(
      `${privateRoute.scenario}?${makeScenarioParams({
        scenario_id: scenario_id || scenario,
        version_id: editable_version_id,
        isEdit,
        isView,
        isDebug,
        isViewVersion,
        selectedBlock,
        branch,
      })}`
    );

    dispatch(
      ScenarioCreators.fetchScenarioById(
        scenario_id,
        true,
        editable_version_id
      ) as any
    );
  }
};
