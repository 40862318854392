import store from "store";
import { AnswerData } from "../index";
import * as VariablePanelActions from "actions/variablePanel";
import { activeId$ } from "selectors/variablePanel";

// WSApi.VariablePrediction
export const variableSearchPrediction = (data: AnswerData) => {
  if (!data?.error) {
    const { dispatch, getState } = store;

    const activeId = activeId$(getState());

    dispatch(VariablePanelActions.setVariablePanelLoading(false));
    dispatch(VariablePanelActions.setPredictionVariables(data.response));

    dispatch(VariablePanelActions.setPrevVariablePanelId(activeId));
  }
};
