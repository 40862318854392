import store from "store";
import { AnswerData } from "../index";
import * as PaymentHistoryActions from "actions/paymentHistory";
import { paymentsHistory$ } from "selectors/paymentHistory";

// WSApi.NewPayment
export const paymentNew = (data: AnswerData) => {
  const { dispatch, getState } = store;
  if (!data.error) {
    const paymentHistory = paymentsHistory$(getState());

    const newPaymentHistory = [data.response.new_payment, ...paymentHistory];

    dispatch(PaymentHistoryActions.setPaymentHistorySuccess(newPaymentHistory));
  }
};
