import { TemplateType } from "interfaces/state/templateItem";
import * as Const from "../constants";

export const setTemplateItemInit = () => {
  return {
    type: Const.TEMPLATE_INIT,
  };
};

export const setTemplateItem = (payload: TemplateType) => {
  return {
    type: Const.TEMPLATE_SUCCESS,
    payload,
  };
};

export const setTemplateItemFail = () => {
  return {
    type: Const.TEMPLATE_FAIL,
  };
};

export const setTemplateItemLoading = () => {
  return {
    type: Const.TEMPLATE_LOADING,
  };
};
