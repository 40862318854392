import store from "store";
import { AnswerData } from "../index";
import * as ServiceActions from "actions/service";

// WSApi.ScenarioServices || WSApi.WebhookServices;
export const serviceGetAll = (data: AnswerData) => {
  if (data?.error) {
    store.dispatch(ServiceActions.setServiceFail());
  } else {
    store.dispatch(ServiceActions.loadServiceSuccess(data.response));
  }
};

export const serviceGetWebhookServices = serviceGetAll;
