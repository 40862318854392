import store from "store";
import { AnswerData } from "../index";
import * as FuncParameterActions from "actions/funcParameter";

// WSApi.GetLibParameters
export const libraryGetParameters = (data: AnswerData) => {
  if (!data?.error) {
    store.dispatch(
      FuncParameterActions.setFParamList(
        data.response.result,
        data.response.scenario_id,
        data.response.block_id
      )
    );
  }
};
