import store from "store";
import { AnswerData } from "../index";
import { folders$ } from "selectors/folders";
import { AutomationScenario } from "interfaces/state/scenarios";
import * as FolderActions from "actions/folders";

// WSApi.AddScenarioCategory
export const scenarioCategoriesAdd = (data: AnswerData) => {
  if (!data?.error) {
    const { dispatch, getState } = store;
    const { scenario_id, categories } = data.response;

    const folders = [...folders$(getState())] as AutomationScenario[];
    const currentFolderIndex = folders.findIndex(
      (folder: AutomationScenario) => folder.scenario_id === scenario_id
    );

    folders[currentFolderIndex].categories = categories;

    dispatch(FolderActions.setFoldersSuccess(folders));
  }
};
