import { sid$ } from "selectors/auth";
import { Action } from "redux";
import State from "interfaces/state";
import * as AuthCreators from "creators/auth";
import {
  envLinkSetter,
  errorNotification,
  sendWSMessage,
  successNotification,
} from "../utils";
import { WSApi } from "enums/wsApi";
import { WebsocketItem$ } from "selectors/wsConnect";
import i18n from "i18n";
import axios from "axios";
import { Api } from "enums/api";
import { useLocation, useNavigate } from "react-router-dom";

export const setNewFirstName = (first_name: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.ChangeFirstName;
    const message =
      process.env.NODE_ENV !== "production"
        ? { action, sid: sid$(getState()), first_name }
        : { action, first_name };

    sendWSMessage(message);
  };
};

export const changeEmailStep_1 = (
  location: ReturnType<typeof useLocation>,
  navigate: ReturnType<typeof useNavigate>,
  searchParams: URLSearchParams,
  password: string,
  new_email: string,
  func: (step: number, isErrorMessage: boolean, errorMessage: string) => void
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const email = new_email.replaceAll("+", "%2B");
    const link = `${envLinkSetter()}/app/confirm-change-email?token={token}&new_email=${email}`;
    const action = WSApi.ChangeEmailFirstStep;
    const message =
      process.env.NODE_ENV !== "production"
        ? { action, sid: sid$(getState()), password, new_email, link }
        : { action, password, new_email, link };

    sendWSMessage(message);

    WebsocketItem$(getState())?.addEventListener("message", (answer) => {
      const resp = JSON.parse(answer.data);
      if (resp.action !== action) return;

      if (!resp || "error" in resp) {
        const step = 0;
        const isError = true;
        const errorMessage = () => {
          if (resp.error === "wrong_password" || resp.error === "wrong_data") {
            return i18n.t("wrong_password");
          } else if (resp.error === "email_already_used") {
            return i18n.t("alreadyInUseEmail");
          } else if (resp.error === "unauthorized") {
            errorNotification(i18n.t("unauthorized"));
            dispatch(
              AuthCreators.logOut(
                location,
                navigate,
                searchParams,
                searchParams.get("token")
              ) as any
            );
            return i18n.t("unauthorized");
          } else if (resp.error === "forbidden") {
            return i18n.t("forbidden");
          } else if (resp.error === "not_found") {
            return i18n.t("not_found");
          } else {
            return i18n.t("errorOccurred");
          }
        };
        func(step, isError, errorMessage());
      }
      if (resp.response === "ok") {
        const step = 1;
        const isError = false;
        const errorMessage = "";

        func(step, isError, errorMessage);

        successNotification(i18n.t("confirmationHasBeenSent"));
      }
    });
  };
};

export const confirmChangeEmail = (
  location: ReturnType<typeof useLocation>,
  navigate: ReturnType<typeof useNavigate>,
  searchParams: URLSearchParams,
  token: string,
  new_email: string,
  func: (
    isError: boolean,
    errorMessage: string,
    isConfirmed: boolean,
    new_email: string
  ) => void
) => {
  return (dispatch: (action: Action) => void, _getState: () => State) => {
    const email = new_email.replaceAll("+", "%2B");
    const link = `${envLinkSetter()}/app/confirm-change-email?token={token}&new_email=${email}`;

    axios
      .post(
        Api.ChangeEmailSecondStep,
        {
          token,
          new_email,
          link,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((resp) => {
        const isError = false;
        const errorMessage = "";
        const isConfirmed = true;

        func(isError, errorMessage, isConfirmed, resp.data.response.new_email);

        successNotification(i18n.t("mailChangeSuccess"), "", 4000);
      })
      .catch((error) => {
        const resp = error.response.data.error;
        if (resp) {
          const isError = true;
          const isConfirmed = false;
          const errorMessage = () => {
            if (resp === "token_was_used") {
              return i18n.t("token_was_used");
            } else if (resp === "unauthorized") {
              errorNotification(i18n.t("unauthorized"));
              dispatch(
                AuthCreators.logOut(
                  location,
                  navigate,
                  searchParams,
                  token
                ) as any
              );
              return i18n.t("unauthorized");
            } else if (resp === "forbidden") {
              return i18n.t("forbidden");
            } else if (resp === "not_found") {
              return i18n.t("not_found");
            } else {
              return i18n.t("errorOccurred");
            }
          };
          func(isError, errorMessage(), isConfirmed, "");
        }
      });
  };
};

export const changePassword = (
  location: ReturnType<typeof useLocation>,
  navigate: ReturnType<typeof useNavigate>,
  searchParams: URLSearchParams,
  password: string,
  new_password: string,
  func: (step: number, isErrorMessage: boolean, errorMessage: string) => void
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.ChagePassword;
    const message =
      process.env.NODE_ENV !== "production"
        ? { action, sid: sid$(getState()), password, new_password }
        : { action, password, new_password };

    sendWSMessage(message);

    WebsocketItem$(getState())?.addEventListener("message", (answer) => {
      const resp = JSON.parse(answer.data);
      if (resp.action !== action) return;

      if (!resp || "error" in resp) {
        const isError = true;
        const step = 0;
        const errorMessage = () => {
          if (resp.error === "token_was_used") {
            return i18n.t("token_was_used");
          } else if (resp.error === "unauthorized") {
            errorNotification(i18n.t("unauthorized"));
            dispatch(
              AuthCreators.logOut(
                location,
                navigate,
                searchParams,
                searchParams.get("token")
              ) as any
            );
            return i18n.t("unauthorized");
          } else if (resp.error === "forbidden") {
            return i18n.t("forbidden");
          } else if (resp.error === "not_found") {
            return i18n.t("not_found");
          } else {
            return i18n.t("errorOccurred");
          }
        };
        func(step, isError, errorMessage());
      } else {
        const isError = false;
        const errorMessage = "";
        const step = 1;

        func(step, isError, errorMessage);

        successNotification(i18n.t("passChangeSuccess"), "", 4000);
      }
    });
  };
};

export const resendChangeEmail = (
  location: ReturnType<typeof useLocation>,
  navigate: ReturnType<typeof useNavigate>,
  searchParams: URLSearchParams,
  password: string,
  new_email: string
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.ResendChangeEmailLetter;
    const email = new_email.replaceAll("+", "%2B");
    const link = `${envLinkSetter()}/app/confirm-change-email?token={token}&new_email=${email}`;
    const message =
      process.env.NODE_ENV !== "production"
        ? { action, sid: sid$(getState()), password, new_email, link }
        : { action, password, new_email, link };

    sendWSMessage(message);

    WebsocketItem$(getState())?.addEventListener("message", (answer) => {
      const resp = JSON.parse(answer.data);
      if (resp.action !== action) return;

      if (!resp || "error" in resp) {
        const errorMessage = () => {
          if (resp.error === "token_was_used") {
            return i18n.t("token_was_used");
          } else if (resp.error === "unauthorized") {
            errorNotification(i18n.t("unauthorized"));
            dispatch(
              AuthCreators.logOut(
                location,
                navigate,
                searchParams,
                searchParams.get("token")
              ) as any
            );
            return i18n.t("unauthorized");
          } else if (resp.error === "forbidden") {
            return i18n.t("forbidden");
          } else if (resp.error === "not_found") {
            return i18n.t("not_found");
          } else {
            return i18n.t("errorOccurred");
          }
        };
        errorNotification(errorMessage());
      } else {
        successNotification(i18n.t("confirmationEmailResent"), "", 4000);
      }
    });
  };
};

export const eventHandler = (data: any) => {
  const { action } = data;
  const uppercaseFirstLetter = (v: string, i: number) =>
    i !== 0 ? v[0].toUpperCase() + v.substring(1) : v;
  const event = action
    .split(".")
    .map(uppercaseFirstLetter)
    .join("")
    .split("_")
    .map(uppercaseFirstLetter)
    .join("");

  return {
    event,
    data,
  };
};
