import * as Const from "../constants";
import State from "../interfaces/state/templateItem";

export const initState: State = {
  isLoading: false,
  isError: false,
  template: null,
};

export default function templateItem(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.TEMPLATE_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        template: action.payload,
      };

    case Const.TEMPLATE_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.TEMPLATE_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
