import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "..";
import * as ScenarioUsersActions from "actions/scenarioUsers";
import { scenarioUsers$ } from "selectors/scenarioUsers";

// WSApi.AddNewUserToScenario
export const scenarioAddUser = (data: AnswerData) => {
  const { getState, dispatch } = store;
  if (data.error) {
    dispatch(ScenarioUsersActions.setScenarioUsersError());
    errorFromResponse(data.error);
  } else {
    const scenarioItem = scenarioItem$(getState());
    const { user_avatar_url, user_email, username } = data.response;

    const scenarioUser = {
      user_avatar_url,
      user_email,
      username,
    };

    if (scenarioItem?.scenario_id === data.response?.scenario_id) {
      const scenarioUsers = scenarioUsers$(getState());
      const isUserInScenario = !!scenarioUsers[scenarioItem!.scenario_id!].find(
        (user) => user.user_email === data.response.user_email
      );

      !isUserInScenario &&
        dispatch(
          ScenarioUsersActions.addNewUserToScenario(
            scenarioUser,
            data.response.scenario_id
          )
        );
    }
  }
};
