import State from "interfaces/state";
import { createSelector } from "reselect";

export const list = (state: State) => state.folders.list;
export const folders$ = createSelector([list], (list) => list);
export const isError$ = (state: State) => state.folders.isError;
export const isLoading$ = (state: State) => state.folders.isLoading;
export const maxFolders$ = (state: State) => state.folders.max_folders;
export const maxItemsLength$ = (state: State) => state.folders.max_items_length;
export const maxScenarios$ = (state: State) => state.folders.max_scenarios;
