import State from "interfaces/state";

import { envLinkSetter, sendWSMessage } from "utils";
import * as TeamActions from "actions/team";
import { Action } from "redux";
import { WSApi } from "enums/wsApi";
import ReduxAction from "interfaces/reduxAction";
import { isLoading$ } from "selectors/team";

export const fetchTeamList = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    if (isLoading$(getState())) return;
    dispatch(TeamActions.setTeamListLoading());
    const action = WSApi.CompanyGetMembers;

    sendWSMessage({ action });
  };
};

export const changeMemberRole = (
  member_id: number,
  role_id: number,
  _role: string
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(TeamActions.setChangeMemeberRoleLoading());
    const action = WSApi.ChangeMemberRole;

    sendWSMessage({ action, member_id, role_id });
  };
};

export const addNewMember = (role_id: number, email: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(TeamActions.setLoadingInvite(true));
    const action = WSApi.AddMember;

    sendWSMessage({
      action,
      email,
      role_id,
      link: `${envLinkSetter()}/app/confirm_joining?token={token}`,
    });
  };
};

export const deleteMember = (member_id: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.DeleteMember;

    sendWSMessage({
      action,
      member_id,
    });
  };
};

export const fetchInvitationLink = (changeLink?: boolean): ReduxAction => {
  return (dispatch, getState): void => {
    const action = changeLink
      ? WSApi.CompanyChangeInvLink
      : WSApi.CompanyGetLinkInvitation;
    const link = `${envLinkSetter()}/app/check-invitation?token={token}`;

    sendWSMessage({ action, link });
  };
};
