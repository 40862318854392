import * as Const from "../constants";
import State, { Member } from "../interfaces/state/team";

export const initState: State = {
  isLoading: false,
  isError: false,
  company_id: null,
  company_name: "",
  number_of_members: null,
  list: [],
  isLoadingChangeRole: false,
  isErrorChangeRole: false,
  inviteLink: null,
  isLoadingInvite: false,
};

export default function team(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.TEAM_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.TEAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: action.payload,
      };

    case Const.TEAM_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.TEAM_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.TEAM_CHANGE_MEMBER_ROLE:
      const members = [...state.list];
      const memberIndex = members.findIndex(
        (member) => member.member_id === action.member_id
      );
      if (memberIndex < 0) {
        return {
          ...state,
          isLoadingChangeRole: false,
          isErrorChangeRole: false,
        };
      }
      members[memberIndex].role = action.role;

      return {
        ...state,
        list: [...members],
        isLoadingChangeRole: false,
        isErrorChangeRole: false,
      };

    case Const.TEAM_CHANGE_MEMBER_ROLE_LOADING:
      return {
        ...state,
        isLoadingChangeRole: true,
        isErrorChangeRole: false,
      };

    case Const.TEAM_CHANGE_MEMBER_ROLE_LIST:
      const team = [...state.list];
      const newRoles = action.payload;

      team.map((it, ind: number) =>
        newRoles.forEach((role: any) => {
          if (role.member_id === it.member_id) {
            team[ind].role = role.role;
          }
        })
      );

      return {
        ...state,
        list: team,
      };

    case Const.TEAM_CHANGE_MEMBER_ROLE_ERROR:
      return {
        ...state,
        isLoadingChangeRole: false,
        isErrorChangeRole: true,
      };

    case Const.TEAM_MEMBER_COUNT:
      return {
        ...state,
        number_of_members: action.payload,
      };

    case Const.TEAM_COMPANY_ID:
      return {
        ...state,
        company_id: action.payload,
      };

    case Const.TEAM_COMPANY_NAME:
      return {
        ...state,
        company_name: action.payload,
      };

    case Const.TEAM_ADD_NEW_MEMBER:
      return {
        ...state,
        list: [...state.list, action.payload],
      };

    case Const.TEAM_INVITE_LINK:
      return {
        ...state,
        inviteLink: action.payload,
      };

    case Const.TEAM_DELETE_MEMBER:
      return {
        ...state,
        list: [...state.list].filter(
          (member) => member.member_id !== action.member_id
        ),
      };

    case Const.TEAM_UPDATE_MEMBER_DATA:
      const data = action.payload;
      const copyMembers = [...state.list];
      const memberInd = copyMembers.findIndex(
        (member) => member.member_id === data.member_id
      );

      if (memberInd < 0) {
        return {
          ...state,
        };
      }

      const newMember = copyMembers[memberInd] as any;

      for (const [key, value] of Object.entries(data)) {
        newMember[key as keyof Member] = value;
      }

      return {
        ...state,
        list: copyMembers,
      };

    case Const.LOADING_INVITE:
      return {
        ...state,
        isLoadingInvite: action.payload,
      };

    default:
      return state;
  }
}
