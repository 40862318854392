import { indicators$ } from "selectors/indicators";
import store from "store";
import { AnswerData } from "../index";
import * as IndicatorActions from "actions/indicators";

// WSApi.UpdateIndicator
export const indicatorUpdate = (data: AnswerData) => {
  const { dispatch, getState } = store;
  if (!data?.error) {
    const indicators = [...indicators$(getState())];
    const index = indicators.findIndex(
      (indicator) => indicator?.id === data.response.id
    );
    if (index >= 0) {
      indicators[index] = data.response;
      dispatch(IndicatorActions.setIndicators(indicators));
    } else {
      dispatch(IndicatorActions.setIndicators([...indicators, data.response]));
    }
  }

  dispatch(IndicatorActions.setIndicatorsLoaded());
};
