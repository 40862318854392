import { useLocation, useNavigate } from "react-router-dom";
import { privateRoute } from "routes";
import {
  currentAutomationRoute$,
  limit$,
  offset$,
  sortBy$,
  sortDirection$,
} from "selectors/automationRouting";
import store from "store";
import { errorFromResponse, makeScenarioParams } from "utils";
import { AnswerData } from "../index";
import * as FolderCreators from "creators/folders";
import { selectedCategories$ } from "selectors/scenarioCategories";

// WSApi.ScenarioCreate
export const scenarioCreate = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (data?.error) {
    errorFromResponse(data.error);
  } else {
    const { dispatch, getState } = store;
    if (currentAutomationRoute$(getState())?.id === data.response.folder_id) {
      const {
        response: { is_open_after_creation },
      } = data;

      const branch = searchParams.get("branch")!;
      if (is_open_after_creation) {
        navigate(
          `${privateRoute.scenario}?${makeScenarioParams({
            scenario_id: data?.response?.scenario_id,
            version_id: data?.response?.version_id,
            isEdit: true,
            isView: false,
            isDebug: false,
            isViewVersion: false,
            selectedBlock: null,
            branch,
          })}`
        );
      } else {
        const sort_by = sortBy$(getState());
        const sort_direction = sortDirection$(getState());
        const limit = limit$(getState());
        const offset = offset$(getState());
        const selectedCategories = selectedCategories$(getState());

        dispatch(
          FolderCreators.fetchAllAutomationFiles(
            +currentAutomationRoute$(getState())?.id!,
            sort_by,
            sort_direction,
            limit,
            offset,
            selectedCategories
          )
        );
      }
    }
  }
};
