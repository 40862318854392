import store from "store";
import { AnswerData } from "../index";
import * as ScenarioCategoriesActions from "actions/scenarioCategories";
import { fetchCompanyCategories } from "creators/scenarioCategories";
import {
  currentAutomationRoute$,
  limit$,
  offset$,
  sortBy$,
} from "selectors/automationRouting";
import { fetchAllAutomationFiles } from "creators/folders";
import { selectedCategories$ } from "selectors/scenarioCategories";

// WSApi.DeleteCompanyCategory
export const companyCategoriesRemove = (data: AnswerData) => {
  if (!data?.error) {
    const { dispatch, getState } = store;

    const currentAutomationRoute = currentAutomationRoute$(getState());
    const sortBy = sortBy$(getState());
    const limit = limit$(getState());
    const offset = offset$(getState());
    const selectedCategories = selectedCategories$(getState());

    Array.isArray(data?.response) &&
      dispatch(
        ScenarioCategoriesActions.setScenarioCategories({
          isLoading: false,
          isError: false,
          company_list: data.response,
        })
      );

    dispatch(fetchCompanyCategories());

    if (currentAutomationRoute) {
      const newSelectedCategories = [];
      data.response.forEach((c) => {
        if (selectedCategories.includes(c.id)) {
          newSelectedCategories.push(c.id);
        }
      });

      dispatch(
        ScenarioCategoriesActions.setScenarioCategories({
          isLoading: false,
          isError: false,
          selected_list: newSelectedCategories,
        })
      );
      
      dispatch(
        fetchAllAutomationFiles(
          +currentAutomationRoute.id!,
          sortBy,
          undefined,
          limit,
          offset,
          newSelectedCategories
        )
      );
    }
  }
};
