import store from "store";
import { AnswerData } from "../index";
import * as AuthActions from "actions/auth";
import * as TeamActions from "actions/team";
import { team$ } from "selectors/team";
import { member_id$ } from "selectors/auth";

// WSApi.CompanyChangeMemberAvatar
export const companyChangeMemberAvatar = (data: AnswerData) => {
  const { dispatch, getState } = store;
  if (!data?.error) {
    const teamList = team$(getState());
    const member_id = member_id$(getState());

    const index = teamList.findIndex(
      (member) => member.member_id === data.response.member_id
    );

    if (index >= 0) {
      const newList = [...teamList];

      newList[index].avatar_url = data.response.avatar_url;

      dispatch(TeamActions.setTeamList(newList));
    }

    if (member_id === data.response.member_id) {
      dispatch(AuthActions.setAuthAvatarUrl(data.response.avatar_url));
    }
  }
};
