import { INIT_BLOCK } from "pages/scenario/constants";
import { scenarioItem$, selectedBlockId$ } from "selectors/scenario";
import { sensitiveDataList$ } from "selectors/sensitiveData";
import store from "store";
import * as SensitiveDataActions from "actions/sensitiveData";
import * as ScenarioCreators from "creators/scenario";

import { AnswerData } from "../index";
import { plan_name$ } from "selectors/auth";

// WSApi.SensitiveDataSave
export const sensitiveDataSave = (data: AnswerData) => {
  const { getState, dispatch } = store;
  if (!data.error) {
    const sensitiveData = sensitiveDataList$(getState());
    const planName = plan_name$(getState());

    if (
      data?.response?.block_id === selectedBlockId$(getState()) &&
      data?.response?.scenario_id === scenarioItem$(getState())?.scenario_id
    ) {
      const name = data.response?.name;
      const authorization_id = data.response?.result?.data_id;

      dispatch(
        SensitiveDataActions.setSensDataList([
          { name, id: authorization_id },
          ...sensitiveData,
        ])
      );

      const key =
        data?.response?.branch === "testing" && planName === "Enterprise"
          ? "testing_authorization_id"
          : "authorization_id";

      if (data?.response?.block_id === INIT_BLOCK) {
        store.dispatch(
          ScenarioCreators.updateInitBlock({
            [key]: authorization_id,
          }) as any
        );
      } else {
        store.dispatch(
          ScenarioCreators.updateBlock({ [key]: authorization_id }) as any
        );
      }
    }
  }
};
