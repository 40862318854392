import { WSApi } from "enums/wsApi";
import { useLocation, useNavigate } from "react-router-dom";
import { privateRoute } from "routes";
import store from "store";
import { AnswerData } from "../index";
import * as ServicePageActions from "actions/servicesPage";
import { services$, service_count$ } from "selectors/servicesPage";

// WSApi.AdminConnectorDelete
export const adminConnectorDelete = (
  data: AnswerData,
  location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch, getState } = store;
  if (!data?.error) {
    const services = services$(getState());
    const responseId = data.response?.id;
    const service_count = service_count$(getState());

    const newServices = services.filter(({ id }) => id === responseId);
    dispatch(
      ServicePageActions.loadServicePageSuccess({
        connectors: newServices,
        total_connectors: service_count - 1 || 0,
      })
    );

    if (responseId && location.pathname.includes(responseId)) {
      navigate(privateRoute.services);
    }
  } else {
    dispatch(ServicePageActions.setServicePageFail());
  }
};
