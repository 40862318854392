import { scenarioItem$, selectedBlockId$ } from "selectors/scenario";
import store, { AppDispatch } from "store";
import { AnswerData } from "../index";
import * as SensitiveDataActions from "actions/sensitiveData";
import * as ScenarioActions from "actions/scenario";
import * as ScenarioCreators from "creators/scenario";
import { INIT_BLOCK } from "pages/scenario/constants";
import { sensitiveDataList$ } from "selectors/sensitiveData";
import { plan_name$ } from "selectors/auth";
import { BlockExecuteMethod } from "interfaces/state/scenario";

// WSApi.SensitiveDataDelete
export const sensitiveDataDelete = (data: AnswerData) => {
  const { dispatch, getState } = store;
  if (!data?.error) {
    const block_id = selectedBlockId$(getState());
    const scenario_id = scenarioItem$(getState())?.scenario_id;
    if (
      block_id === data?.response?.block_id &&
      scenario_id === data?.response?.scenario_id
    ) {
      const auth = data?.response?.data_id;
      const SDS = sensitiveDataList$(getState());

      const newSDS = [...SDS].filter((sds) => sds.id !== auth);

      dispatch(SensitiveDataActions.setSensDataList(newSDS));
      const scenarioItem = scenarioItem$(getState());
      const planName = plan_name$(getState());

      if (block_id === INIT_BLOCK) {
        const key = () => {
          if (scenarioItem?.init?.authorization_id === auth) {
            return "authorization_id";
          } else if (scenarioItem?.init?.testing_authorization_id === auth) {
            return planName === "Enterprise"
              ? "authorization_id"
              : "testing_authorization_id";
          } else {
            return "";
          }
        };

        if (key() && scenarioItem?.init) {
          dispatch(
            ScenarioActions.setScenarioSuccess({
              ...scenarioItem,
              ...{ init: { ...scenarioItem.init, [key()]: "" } },
            })
          );

          const opt_key =
            key() === "authorization_id" ? "start_opts" : "testing_start_opts";

          dispatch(
            ScenarioCreators.updateInitBlock(
              {
                [opt_key]: {},
                type: "webhook",
                name: scenarioItem.init.name,
              },
              true
            ) as AppDispatch
          );
        }
      } else {
        const scenarios = scenarioItem?.scenario;

        if (scenarios) {
          let key: any = "";
          const index = scenarios?.findIndex((block: any) => {
            if (block?.authorization_id === auth || planName === "Enterprise") {
              key = "authorization_id";
            } else if (block?.testing_authorization_id === auth) {
              key = "testing_authorization_id";
            }
            return (
              block?.authorization_id === auth ||
              block?.testing_authorization_id === auth
            );
          });

          if (index >= 0 && key) {
            scenarios[index][
              key as "authorization_id" | "testing_authorization_id"
            ] = "";

            dispatch(
              ScenarioActions.setScenarioSuccess({
                ...scenarioItem,
                ...{ scenario: [...scenarios] },
              })
            );

            const arg =
              (scenarios[index] as BlockExecuteMethod).branch === "live"
                ? "arguments"
                : "testing_arguments";

            dispatch(
              ScenarioCreators.updateBlock({
                [arg]: {},
              }) as AppDispatch
            );
          }
        }
      }
    }
  }
};
