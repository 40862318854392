import store from "store";
import { AnswerData } from "../index";
import { updateBlock } from "interceptors/scenario";
import { scenarioItem$ } from "selectors/scenario";
import * as ScenarioActions from "actions/scenario";

// WSApi.UpdateBlock
export const blockUpdate = (data: AnswerData) => {
  const { dispatch, getState } = store;
  dispatch(ScenarioActions.setLoadedScenario());
  if (data?.error) {
    dispatch(ScenarioActions.setScenarioError());
  } else {
    updateBlock(
      data?.response?.scenario_id,
      data?.response?.block?.id,
      data?.response?.block,
      data?.response?.variables
    )(getState(), dispatch);

    if (data?.response?.scenario) {
      const scenario = scenarioItem$(getState());
      if (scenario?.scenario_id === data.response?.scenario_id) {
        dispatch(
          ScenarioActions.setScenarioSuccess({
            ...scenario,
            scenario: data.response.scenario,
          })
        );
      }
    }
  }
};
