import { IIndicatorsAction } from "interfaces/state/indicators";
import * as Const from "../constants";

export const setIndicatorsInit = () => {
  return {
    type: Const.INDICATORS_INIT,
  };
};

export const setIndicators = (
  payload: IIndicatorsAction["payload"]
): IIndicatorsAction => {
  return {
    type: Const.INDICATORS_SUCCESS,
    payload,
  };
};

export const setIndicatorsPending = () => {
  return {
    type: Const.INDICATORS_LOADING,
  };
};

export const setIndicatorsError = () => {
  return {
    type: Const.INDICATORS_FAIL,
  };
};

export const setIndicatorsLoaded = () => {
  return {
    type: Const.INDICATORS_LOADED,
  };
};
