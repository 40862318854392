import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as FoldersActions from "actions/folders";
import * as FolderCreators from "creators/folders";
import { currentAutomationRoute$ } from "selectors/automationRouting";

// WSApi.FolderMoveScenarioToFolder
export const folderMoveScenarioToFolder = (data: AnswerData) => {
  const { dispatch, getState } = store;

  if (data.error) {
    errorFromResponse(data.error);
    dispatch(FoldersActions.setFoldersError());
  } else {
    data?.response === "ok" && currentAutomationRoute$(getState());
    dispatch(
      FolderCreators.fetchAllAutomationFiles(
        +currentAutomationRoute$(getState())!.id!
      )
    );
  }
};
