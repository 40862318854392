import * as Const from "../constants";
import State from "../interfaces/state/sensitiveData";
import {
  ActionAddSensitiveData,
  ActionDeleteSensitiveData,
} from "actions/sensitiveData";

export const initState: State = {
  isLoading: false,
  isError: false,
  list: [],
};

export default function sensitiveData(
  state: State = initState,
  action: ActionAddSensitiveData | ActionDeleteSensitiveData
): State {
  switch (action.type) {
    case Const.SENS_DATA_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.SENS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: action.payload as ActionAddSensitiveData["payload"],
      };

    case Const.SENS_DATA_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.SENS_DATA_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
