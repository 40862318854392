import State from "interfaces/state";
import { createSelector } from "reselect";

export const list = (state: State) => state.automationRouting.list;
export const current = (state: State) => state.automationRouting.current;
export const automationRouting$ = createSelector([list], (list) => list);
export const currentAutomationRoute$ = createSelector(
  [current],
  (current) => current
);
export const sortBy$ = (state: State) => state.automationRouting.sort_by;
export const sortDirection$ = (state: State) =>
  state.automationRouting.sort_direction;
export const limit$ = (state: State) => state.automationRouting.limit;
export const offset$ = (state: State) => state.automationRouting.offset;
export const fileSystemSize$ = (state: State) => state.automationRouting.size;
export const search$ = (state: State) => state.automationRouting.search;
export const page$ = (state: State) => state.automationRouting.page;
