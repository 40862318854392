import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga";
import App from "./App";
import store from "./store";
import "./index.css";
import "./App.scss";
import "i18next";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "components/errorboundary";

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement
);

ReactGA.initialize("UA-214961866-1", {});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();
