import { createSelector } from "reselect";
import State from "../interfaces/state";

export const isError$ = (state: State) => state.team.isError;
export const isLoading$ = (state: State) => state.team.isLoading;
export const team = (state: State) => state.team.list;
export const team$ = createSelector([team], (teams) => teams);
export const isErrorChangeRole$ = (state: State) =>
  state.team.isErrorChangeRole;
export const isLoadingChangeRole$ = (state: State) =>
  state.team.isLoadingChangeRole;
export const company_id$ = (state: State) => state.team.company_id;
export const company_name$ = (state: State) => state.team.company_name;
export const number_of_members$ = (state: State) =>
  state.team.number_of_members;

export const inviteLink$ = (state: State) => state.team.inviteLink;
export const isLoadingInvite$ = (state: State) => state.team.isLoadingInvite;
