import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { ScenarioCategory } from "interfaces/state/scenarioCategories";
import { isLoading$, maxScenarios$ } from "selectors/folders";
import {
  companyCategories$,
  selectedCategories$,
} from "selectors/scenarioCategories";
import { HeaderDropdown } from "../headerDropdown";
import { ReactComponent as SearchIcon } from "assets/svg/fi-search.svg";
import { ReactComponent as SmallCross } from "assets/svg/small-cross-old.svg";
import { updatePagination, updateSearch } from "actions/automationRouting";
import { fetchCompanyCategories } from "creators/scenarioCategories";
import {
  currentAutomationRoute$,
  limit$,
  search$,
  sortBy$,
  sortDirection$,
} from "selectors/automationRouting";
import { getSortDirection, sortItems } from "../utils";
import { fetchAllAutomationFiles } from "creators/folders";
import { isLoading$ as isLoadingMainFolder$ } from "selectors/mainFolder";
import { isLoading$ as isLoadingAuth$ } from "selectors/auth";
import { setScenarioCategories } from "actions/scenarioCategories";
import style from "./fileSystemInfo.module.scss";

export const FileSystemInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const maxScenarios = useAppSelector(maxScenarios$);
  const companyCategories = useAppSelector(companyCategories$);
  const selectedCategories = useAppSelector(selectedCategories$);
  const currentAutomationRoute = useAppSelector(currentAutomationRoute$);
  const limit = useAppSelector(limit$);
  const search = useAppSelector(search$);
  const isLoading = useAppSelector(isLoading$);
  const isLoadingMainFolder = useAppSelector(isLoadingMainFolder$);
  const isLoadingAuth = useAppSelector(isLoadingAuth$);
  const sortBy = useAppSelector(sortBy$);
  const sortDirection = useAppSelector(sortDirection$);

  const [currentCategory] = useState<ScenarioCategory | null>({
    id: 0,
    name: "categories",
  });
  const [currentSort, setSort] = useState<ScenarioCategory>(sortItems[0]);

  useEffect(() => {
    dispatch(fetchCompanyCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setSort((prev) => ({ ...prev, name: t(prev.name) }));
  }, []);

  useEffect(() => {
    let searchTimeout: string | number | NodeJS.Timeout | undefined;
    if (search) {
      searchTimeout = setTimeout(() => {
        currentAutomationRoute &&
          dispatch(
            fetchAllAutomationFiles(
              +currentAutomationRoute!.id,
              sortBy,
              sortDirection,
              8,
              0
            )
          );
        dispatch(updatePagination({ limit: 8, offset: 0 }));
      }, 600);
    }

    return () => clearTimeout(searchTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const count = useMemo(() => {
    return isLoading || isLoadingMainFolder || isLoadingAuth
      ? t("loading")
      : maxScenarios > 0
      ? maxScenarios === 1
        ? `${maxScenarios} ${t("automation:scenario")}`
        : `${maxScenarios} ${t("automation:scenarios")}`
      : t("automation:noScenarios");
  }, [isLoading, isLoadingAuth, isLoadingMainFolder, maxScenarios, t]);

  const handleSort = useCallback(
    (item: ScenarioCategory) => {
      setSort(item);

      const { type, direction } = getSortDirection(item.name);

      currentAutomationRoute?.id &&
        dispatch(
          fetchAllAutomationFiles(
            +currentAutomationRoute.id,
            type,
            direction,
            8,
            0
          )
        );
      dispatch(
        updatePagination({
          limit: 8,
          offset: 0,
          sort_by: type,
          sort_direction: direction,
          page: 1,
        })
      );
    },
    [currentAutomationRoute?.id, dispatch]
  );

  const handleSearch = useCallback(
    (value: string) => {
      dispatch(updateSearch(value));
      !value &&
        !!currentAutomationRoute?.id &&
        dispatch(
          fetchAllAutomationFiles(
            +currentAutomationRoute.id!,
            sortBy,
            sortDirection,
            8,
            0
          )
        );

      dispatch(updatePagination({ limit: 8, offset: 0, page: 1 }));
    },
    [currentAutomationRoute?.id, dispatch, sortBy, sortDirection]
  );

  const handleChangeCategory = ({ id }: ScenarioCategory) => {
    const newCategories = selectedCategories.includes(id)
      ? selectedCategories.filter((c) => c !== id)
      : [...selectedCategories, id];
    dispatch(
      setScenarioCategories({
        selected_list: newCategories,
        isLoading: false,
        isError: false,
      })
    );
    currentAutomationRoute &&
      dispatch(
        fetchAllAutomationFiles(
          +currentAutomationRoute.id!,
          sortBy,
          undefined,
          limit,
          0,
          newCategories
        )
      );
    dispatch(updatePagination({ limit: 8, offset: 0, page: 1 }));
  };

  const handleClearCategory = () => {
    dispatch(
      setScenarioCategories({
        selected_list: [],
        isLoading: false,
        isError: false,
      })
    );
    currentAutomationRoute &&
      dispatch(
        fetchAllAutomationFiles(
          +currentAutomationRoute.id!,
          sortBy,
          undefined,
          limit,
          0,
          []
        )
      );
    dispatch(updatePagination({ limit: 8, offset: 0, page: 1 }));
  };

  const handleSearchChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    handleSearch(value);
  };

  const handleClearSearch = useCallback(() => {
    handleSearch("");
  }, [handleSearch]);

  return (
    <div className={style.root}>
      <div className={style.count} title={count}>
        {count}
      </div>

      <HeaderDropdown
        value={currentCategory}
        items={companyCategories}
        onChange={handleChangeCategory}
        isEdit
        isNeedSearch
        categories={selectedCategories}
        onClear={handleClearCategory}
      />
      <HeaderDropdown
        value={currentSort}
        items={sortItems}
        onChange={handleSort}
      />
      <div className={style.searchContent}>
        <SearchIcon className={style.icon} />
        <input
          className={style.search}
          onChange={handleSearchChange}
          value={search}
          placeholder={t("automation:searchScenarioPlaceholder")}
        />
        {!!search && (
          <SmallCross className={style.crossIcon} onClick={handleClearSearch} />
        )}
      </div>
    </div>
  );
};
