import { getUnfilledBlocks } from "interceptors/scenario";
import { useLocation, useNavigate } from "react-router-dom";
import { errorFromResponse } from "utils";
import { AnswerData } from "..";
import * as DebugActions from "actions/debug";
import store from "store";

// WSApi.StartDebugScenario
export const scenarioDebugStart = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (data?.error?.unfilled_blocks) {
    getUnfilledBlocks(
      data?.error?.unfilled_blocks as [],
      data?.error.scenario_id as string,
      data?.error?.editable_version_id as string,
      navigate
    );
  }

  if (data?.error?.reason) {
    errorFromResponse(data.error?.reason);
  }

  const { dispatch } = store;

  if (data?.error) {
    dispatch(DebugActions.setDebugLoading(false));
  }
};
