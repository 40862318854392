import * as Const from "../constants";
import State from "../interfaces/state/scenarioUsers";

export const initState: State = {
  isLoading: false,
  isError: false,
  users: {},
};

export default function scenarioUsers(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.SCENARIO_USERS_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.SCENARIO_USERS_ADD_USER:
      const allUsers = state.users ? state.users : {};
      const oldUsers = allUsers[action.scenario_id]
        ? allUsers[action.scenario_id]
        : [];
      return {
        ...state,
        isLoading: false,
        isError: false,
        users: {
          ...state.users,
          [action.scenario_id]: [...oldUsers, action.payload],
        },
      };

    case Const.SCENARIO_USERS_ADD_ALL_USERS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        users: {
          ...state.users,
          [action.scenario_id]: [...action.payload],
        },
      };

    case Const.SCENARIO_USERS_DELETE_USER:
      if (!state.users) return { ...state };
      if (!state.users?.[action.scenario_id]) return { ...state };
      const newUsers = state.users[action.scenario_id].filter(
        (user) => user.user_email !== action.user_email
      );
      return {
        ...state,
        isLoading: false,
        isError: false,
        users: {
          ...state.users,
          [action.scenario_id]: newUsers,
        },
      };

    case Const.SCENARIO_USERS_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.SCENARIO_USERS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
