import LibraryState, { ILibrary } from "interfaces/state/library";
import * as Const from "../constants";

export const setLibInit = () => {
  return {
    type: Const.LIB_INIT,
  };
};

export const setLibList = (
  payload: ILibrary[],
  scenario_id: string,
  block_id: string
) => {
  return {
    type: Const.LIB_SUCCESS,
    payload,
    scenario_id,
    block_id,
  };
};

export const setLibError = () => {
  return {
    type: Const.LIB_FAIL,
  };
};

export const setLibLoading = () => {
  return {
    type: Const.LIB_LOADING,
  };
};

export const setLibType = (payload: LibraryState["libType"]) => {
  return {
    type: Const.LIB_TYPE,
    payload,
  };
};

export const setOpenLibPanel = (payload: boolean) => {
  return {
    type: Const.LIB_PANEL,
    payload,
  };
};

export const setPosition = (payload: number) => {
  return {
    type: Const.LIB_POSITION,
    payload,
  };
};

export const setToTop = (payload: number) => {
  return {
    type: Const.LIB_SCROLL_TO_TOP,
    payload,
  };
};
