import ScenarioState, {
  BlockCaseOf,
  BlockExecuteMethod,
  BlockExit,
  BlockFormBody,
  Scenario,
  IScenarioPropcessing,
  InitAuthModalData,
  BlockCounter,
} from "interfaces/state/scenario";

import * as Const from "../constants";

export const setScenarioInit = () => {
  return {
    type: Const.SCENARIO_ITEM_INIT,
  };
};

export const setScenarioSuccess = (
  payload: Partial<ScenarioState["scenarioItem"]>
) => {
  return {
    type: Const.SCENARIO_ITEM_SUCCESS,
    payload,
  };
};

export const setScenarioError = () => {
  return {
    type: Const.SCENARIO_ITEM_FAIL,
  };
};

export const setScenarioLoading = () => {
  return {
    type: Const.SCENARIO_ITEM_LOADING,
  };
};

export const setLoadedScenario = () => {
  return {
    type: Const.SCENARIO_LOADED,
  };
};

export const scenarioRename = (payload: string) => {
  return {
    type: Const.SCENARIO_RENAME,
    payload,
  };
};

export const updateScenarioInitBlock = (payload: Partial<Scenario["init"]>) => {
  return {
    type: Const.SCENARIO_CHANGE_INIT_BLOCK,
    payload,
  };
};

export const setBlockId = (payload: string | null | number) => {
  return {
    type: Const.SCENARIO_SELECTED_CUBE_ID,
    payload,
  };
};

export const selectBlock = (
  payload:
    | Partial<BlockExecuteMethod>
    | Partial<BlockFormBody>
    | Partial<BlockCaseOf>
    | Partial<BlockExit>
    | Partial<BlockCounter>
    | null
) => {
  return {
    type: Const.SELECT_CUBE,
    payload,
  };
};

export const setTempBlockId = (payload: string | null) => {
  return {
    type: Const.SCENARIO_TEMP_CUBE_ID,
    payload,
  };
};

export const deleteScenario = () => {
  return {
    type: Const.SCENARIO_DELETE,
  };
};

export const setScenarioProcessing = (payload: {
  [key: string]: IScenarioPropcessing;
}) => {
  return {
    type: Const.SCENARIO_PROCESSING,
    payload,
  };
};

export const getScenarioErrors = (
  payload: ScenarioState["blockWithErrors"]
) => {
  return {
    type: Const.SCENARIO_GET_ERROR_WHEN_START,
    payload,
  };
};

export const changeMethodName = (payload: string | null) => {
  return {
    type: Const.SCENARIO_METHOD_NAME,
    payload,
  };
};

export const setDebugScenarioId = (payload: null | string) => {
  return {
    type: Const.SCENARIO_DEBUG_ID,
    payload,
  };
};

export const setOpenBranchingRightPanel = (payload: boolean) => {
  return {
    type: Const.SCENARIO_BRANCHING_PANEL,
    payload,
  };
};

export const refreshScenario = () => {
  return {
    type: Const.SCENARIO_ITEM_REFRESH,
  };
};

export const openEmptyBlocksErrorModal = (payload: boolean) => {
  return {
    type: Const.SCENARIO_UNFILLED_BLOCKS,
    payload,
  };
};

export const setEditableScenario = (
  payload: ScenarioState["editableVersion"]
) => {
  return {
    type: Const.SCENARIO_EDITABLE_VERSION,
    payload,
  };
};

export const openAllowedToEditModal = (
  payload: ScenarioState["isOpenNotAllowedToEditModal"]
) => {
  return {
    type: Const.SCENARIO_ALLOWED_TO_EDIT,
    payload,
  };
};

export const updateZoom = (payload: number) => {
  return {
    type: Const.SCENARIO_ZOOM,
    payload,
  };
};

interface UpdateInitModalProps {
  isOpenInitAuthModal: boolean;
  initAuthModalData: null | InitAuthModalData;
}

export const updateScenarioInitModal = (payload: UpdateInitModalProps) => {
  return {
    type: Const.SCENARIO_INIT_AUTH_MODAL,
    payload,
  };
};

export const updateCheckInitOptions = (payload: ScenarioState["checkInit"]) => {
  return {
    type: Const.SCENARIO_CHECK_OBJECT,
    payload,
  };
};

export const setUpdateVariable = (payload: boolean) => ({
  type: Const.SCENARIO_UPDATE_VARIABLE,
  payload,
});

export const setOpenUnavailableServiceModal = (payload: boolean) => {
  return {
    type: Const.SCENARIO_UNAVAILABLE_SERVICE_MODAL,
    payload,
  };
};

export const setUnavailableServiceOrMethod = (
  payload: ScenarioState["unavailableServiceOrMethods"]
) => {
  return {
    type: Const.SCENARIO_UNAVAILABLE_SERVICE_VALUE,
    payload,
  };
};
