import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { AnswerData } from "..";
import * as ScenarioActions from "actions/scenario";
import { useLocation, useNavigate } from "react-router-dom";


// WSApi.SetBlockedVersion
export const scenarioVersionSetBlocked = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (!data?.error) {
    const scenario = scenarioItem$(store.getState());

    if (
      scenario?.scenario_id === data?.response?.scenario_id &&
      scenario?.is_allowed_to_edit
    ) {
      store.dispatch(
        ScenarioActions.setScenarioSuccess({
          ...scenario,
          is_allowed_to_edit: false,
        })
      );
    }
  }
};
