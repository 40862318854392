import i18n from "i18n";
import {
  AutomationRoute,
  AutomationRouteType,
} from "interfaces/state/automationRouting";

export interface FolderResponse {
  id: number;
  name: string;
}

export const makeFolders = (path: FolderResponse[]): AutomationRoute[] =>
  path.map((folder) => ({
    id: folder.id,
    type: AutomationRouteType.FOLDER,
    title:
      folder.name === "start_folder"
        ? i18n.t("automation:yourScenarios")
        : folder.name,
  }));
