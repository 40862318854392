import store from "store";
import { AnswerData } from "../index";
import * as WebhookMethodActions from "actions/webhookMethod";

//  WSApi.WebHooksMethods
export const serviceGetAllWebhookMethods = (data: AnswerData) => {
  if (!data?.error) {
    store.dispatch(
      WebhookMethodActions.loadWebHookMethodSuccess(data.response)
    );
  }
};
