import store from "store";
import { AnswerData } from "../index";
import * as ScenarioCategoryCreator from "creators/scenarioCategories";
import {
  currentAutomationRoute$,
  limit$,
  offset$,
  sortBy$,
} from "selectors/automationRouting";
import { selectedCategories$ } from "selectors/scenarioCategories";
import { fetchAllAutomationFiles } from "creators/folders";

// WSApi.RenameCompanyCategory
export const companyCategoriesRename = (data: AnswerData) => {
  if (!data?.error) {
    const { dispatch, getState } = store;

    const currentAutomationRoute = currentAutomationRoute$(getState());
    const sortBy = sortBy$(getState());
    const limit = limit$(getState());
    const offset = offset$(getState());
    const selectedCategories = selectedCategories$(getState());

    if (data.response?.id) {
      dispatch(ScenarioCategoryCreator.fetchCompanyCategories());
    }

    if (currentAutomationRoute) {
      dispatch(
        fetchAllAutomationFiles(
          +currentAutomationRoute.id!,
          sortBy,
          undefined,
          limit,
          offset,
          selectedCategories
        )
      );
    }
  }
};
