import cx from "classnames";
import { useAppSelector } from "hooks/redux";
import { AllScenarioBlocks, ScenarioInit } from "interfaces/state/scenario";
import { FC, MouseEvent, useMemo } from "react";
import { scenarioItem$ } from "selectors/scenario";
import { hasPrevBlock, isAlreadyCreatedRight } from "../../utils";
import style from "./controlls.module.scss";

interface IProps {
  id: string;
  init?: ScenarioInit;
  item?: AllScenarioBlocks;
  selected?: boolean;
  onTopClick: (e: MouseEvent<HTMLDivElement>) => void;
  onRightClick: (e: MouseEvent<HTMLDivElement>) => void;
  onBottomClick: (e: MouseEvent<HTMLDivElement>) => void;
  onLeftClick: (e: MouseEvent<HTMLDivElement>) => void;
}

export const Controlls: FC<IProps> = ({
  id,
  init,
  item,
  selected,
  onTopClick,
  onBottomClick,
  onLeftClick,
  onRightClick,
}) => {
  const scenario = useAppSelector(scenarioItem$);

  const getPrevBlock = useMemo(
    () => hasPrevBlock(scenario, item),
    [scenario, item]
  );

  const hasNextBlock = useMemo(
    () => isAlreadyCreatedRight(scenario, init, item),
    [init, item, scenario]
  );

  const ControllsCX = cx(style.root, {
    [style.withoutRight]: !!init && !!hasNextBlock,
    [style.selected]: selected,
    [style.visibleRight]: !hasNextBlock,
  });

  const LeftCX = cx(style.controlItem, style.left, {
    [style.invisible]: !!init,
    [style.alreadyCreated]: getPrevBlock,
    [style.createNew]: !getPrevBlock,
  });

  const TopCX = cx(style.controlItem, style.top, {
    [style.invisible]: item?.type !== "case_of",
    [style.createNew]: true,
  });

  const BottomCX = cx(style.controlItem, style.bottom, {
    [style.invisible]: item?.type !== "case_of",
    [style.createNew]: true,
  });

  const RightCX = cx(style.controlItem, style.right, {
    [style.invisible]:
      (!!init && hasNextBlock) || (item?.type === "case_of" && hasNextBlock),
    [style.alreadyCreated]: hasNextBlock,
    [style.createNew]: !hasNextBlock,
  });

  return (
    <div className={`${ControllsCX} ${style.controlls + id}`} title="">
      <div className={LeftCX} onClick={onLeftClick}>
        <div className={style.wrapper}>
          <div className={style.control}>+</div>
        </div>
      </div>
      <div className={TopCX} onClick={onTopClick}>
        <div className={style.wrapper}>
          <div className={style.control}>+</div>
        </div>
      </div>
      <div className={RightCX} onClick={onRightClick}>
        <div className={style.wrapper}>
          <div className={style.control}>+</div>
        </div>
      </div>
      <div className={BottomCX} onClick={onBottomClick}>
        <div className={style.wrapper}>
          <div className={style.control}>+</div>
        </div>
      </div>
    </div>
  );
};
