import store from "store";
import { AnswerData } from "../index";
import * as FolderActions from "actions/folders";
import { folders$ } from "selectors/folders";
import { AutomationScenario } from "interfaces/state/scenarios";
import { fetchAllAutomationFiles } from "creators/folders";
import {
  currentAutomationRoute$,
  limit$,
  offset$,
  page$,
  sortBy$,
} from "selectors/automationRouting";
import { updatePagination } from "actions/automationRouting";
import { selectedCategories$ } from "selectors/scenarioCategories";

// WSApi.DeleteScenarioCategory
export const scenarioCategoriesRemove = (data: AnswerData) => {
  if (!data?.error) {
    const { dispatch, getState } = store;
    const { scenario_id, categories } = data.response;

    const folders = [...folders$(getState())] as AutomationScenario[];
    const currentFolderIndex = folders.findIndex(
      (folder: AutomationScenario) => folder.scenario_id === scenario_id
    );

    folders[currentFolderIndex].categories = categories;

    const currentAutomationRoute = currentAutomationRoute$(getState());
    const sortBy = sortBy$(getState());
    const limit = limit$(getState());
    const offset = offset$(getState()) - 8;
    const page = page$(getState());
    const selectedCategories = selectedCategories$(getState());

    if (folders.length === 1) {
      dispatch(
        updatePagination({
          limit: 8,
          offset: offset >= 0 ? offset : 0,
          page: page !== 1 ? page - 1 : page,
        })
      );

      dispatch(FolderActions.setFoldersLoading());

      setTimeout(() => {
        dispatch(
          fetchAllAutomationFiles(
            +currentAutomationRoute.id!,
            sortBy,
            undefined,
            limit,
            offset,
            selectedCategories
          )
        );
      }, 100);
    }
  }
};
