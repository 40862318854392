export enum Api {
  GetSessionStatus = "/api/v1/session/get_status",
  CreateNewAccount = "/api/v1/account/create",
  LogIn = "/api/v1/account/log_in",
  LogOut = "/api/v1/account/log_out",
  Confirm = "/api/v1/account/confirm_email",
  SendPass = "/api/v1/account/set_password",
  ChangeEmail = "/api/v1/account/change_email",
  ResendEmail = "/api/v1/account/resend_email",
  GetPlans = "/api/v1/plan/get_available",
  CreateSubscription = "/api/v1/subscription/create",
  UppdateSubscription = "/api/v1/subscription/update",
  RecoveryPassStepOne = "/api/v1/account/reset_password/step_1",
  RecoveryPassStepTwo = "/api/v1/account/reset_password/step_2",
  GetQuestinnaireStatus = "/api/v1/questionnaire/get_status",
  SetQuestinnaireStatus = "/api/v1/questionnaire/change_status",
  SetQuestinnaireCompanyName = "/api/v1/questionnaire/change_company_name",
  SetQuestinnaireCompanySite = "/api/v1/questionnaire/add_company_site",
  GetQuestinnaireDirections = "/api/v1/questionnaire/get_directions",
  SetQuestinnaireDirections = "/api/v1/questionnaire/set_directions",
  GetQuestinnaireRoles = "/api/v1/questionnaire/get_roles",
  SetQuestinnaireRole = "/api/v1/questionnaire/set_role",
  GetQuestinnaireTasks = "/api/v1/questionnaire/get_tasks",
  SetQuestinnaireTasks = "/api/v1/questionnaire/set_tasks",
  GetQuestinnaireTeams = "/api/v1/questionnaire/get_teams",
  SetQuestinnaireTeam = "/api/v1/questionnaire/set_team",
  ConfirmJoining = "/api/v1/account/confirm_joining",
  SetNameAndPass = "/api/v1/account/set_name_and_pass",
  ConfirmByLink = "/api/v1/account/confirm_joining",
  UploadAvatar = "/api/v1/account/set_avatar",
  CreateAccByLink = "/api/v1/account/create_by_link",
  SessionSetLanguage = "/api/v1/session/set_language",
  AccountSetLanguage = "/api/v1/account/set_language",
  CheckInvitationLink = "/api/v1/company/check_invitation_link",
  SubscriptionGetInfo = "/api/v1/subscription/get_info",
  NotSelectedSubscription = "/api/v1/subscription/not_selected",
  ChangeEmailSecondStep = "/api/v1/account/change_email_step_2",
  CookieConsent = "/api/v1/session/set_consent_on_cookie",
  CheckToken = "/api/v1/token/check",
  GetSupportCategories = "/api/v1/support_form/get_available_categories",
  UploadServiceFile = "/api/v1/admin_panel/connector/icon/upload",
  GetServiceById = "/api/v1/admin/connector/get",
}
