import {
  PAY_METHOD_INIT,
  PAY_METHOD_LOADING,
  PAY_METHOD_FAIL,
  PAY_METHOD_SUCCESS,
  PAY_METHOD_DEFAULT,
  PAY_METHOD_ERROR_VALUE,
} from "constants/index";
import { PaymentMethod } from "interfaces/state/paymentMethod";

export const setPaymentMethodInit = () => {
  return {
    type: PAY_METHOD_INIT,
  };
};

export const setPaymentLoading = () => {
  return {
    type: PAY_METHOD_LOADING,
  };
};

export const setPaymentError = () => {
  return {
    type: PAY_METHOD_FAIL,
  };
};

export const setPaymentList = (payload: PaymentMethod[]) => {
  return {
    type: PAY_METHOD_SUCCESS,
    payload,
  };
};

export const setDefaultPayment = (payload: PaymentMethod | null) => {
  return {
    type: PAY_METHOD_DEFAULT,
    payload,
  };
};

export const setPaymentErrorValue = (payload: string) => {
  return {
    type: PAY_METHOD_ERROR_VALUE,
    payload,
  };
};
