import { useLocation, useNavigate } from "react-router-dom";
import store from "store";
import { AnswerData } from "../index";
import * as TeamActions from "actions/team";
import i18n from "i18n";
import { successNotification } from "utils";

// WSApi.CompanyChangeInvLink
export const companyChangeInvitationLink = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  const { dispatch } = store;
  if (!data?.error) {
    if (navigator && data.response) {
      navigator.clipboard.writeText(data.response);
      successNotification(i18n.t("settings:changedLinkText"));
    }

    dispatch(TeamActions.setInviteLink(data.response));
  }
};
