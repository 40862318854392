import * as Const from "../constants";
import State, { ServicePageItem } from "../interfaces/state/servicesPage";

export const initState: State = {
  isLoading: false,
  isError: false,
  list: [],
  status: "all",
  sort_by: "name",
  count: 0,
  isNeedToSaveChanges: false,
  isOpenSaveChangesPopup: false,
  nextRouteAfterSaveChanges: "",
  hasDataToSave: false,
  tryToSaveData: false,
};

export default function servicesPage(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.SERVICE_PAGE_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.SERVICE_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: action.payload.connectors as ServicePageItem[],
        count: action.payload.total_connectors as number,
      };

    case Const.SERVICE_PAGE_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.SERVICE_PAGE_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.SERVICE_PAGE_STATUS:
      return {
        ...state,
        status: action.payload,
      };

    case Const.SERVICE_PAGE_SORT_BY:
      return {
        ...state,
        sort_by: action.payload,
      };

    case Const.SERVICE_PAGE_SAVE_CHANGES:
      return {
        ...state,
        isNeedToSaveChanges: action.isNeedToSaveChanges,
        isOpenSaveChangesPopup: action.isOpenSaveChangesPopup,
        nextRouteAfterSaveChanges: action.nextRouteAfterSaveChanges,
      };

    case Const.SERVICE_PAGE_HAS_DATA_TO_SAVE:
      return {
        ...state,
        hasDataToSave: action.payload,
      };

    case Const.SERVICE_PAGE_TRY_TO_SAVE_DATA:
      return {
        ...state,
        tryToSaveData: action.payload,
      };

    default:
      return state;
  }
}
