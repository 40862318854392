import { FC, useState, useCallback, useEffect } from "react";
import cx from "classnames";
import ScenarioHeaderPopover, {
  PopoverTheme,
} from "pages/scenario/components/scenarioHeaderPopover";
import { AutomationScenario } from "interfaces/state/scenarios";
import { DropDownItem } from "pages/scenario/components/dropdownItem";
import { ModalWindow } from "components/modal";
import { ReactComponent as MoreIcon } from "assets/svg/fi-more-horizontal.svg";
import { Trans, useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  deleteScenario,
  fetchAllAutomationFiles,
  renameScenario,
} from "creators/folders";
import { EditCategories } from "pages/automation/containers/editCategories";
import { Popup } from "components/popup";
import style from "./scenarioModals.module.scss";
import {
  currentAutomationRoute$,
  limit$,
  offset$,
  sortBy$,
} from "selectors/automationRouting";
import { selectedCategories$ } from "selectors/scenarioCategories";

interface IProps {
  item: AutomationScenario;
  onOpen: (value: boolean) => void;
  isOpenChangeCategoryProp?: boolean;
  onOpenChangeCategory?: () => void;
}

export const ScenarioModals: FC<IProps> = ({
  item,
  isOpenChangeCategoryProp,
  onOpen,
  onOpenChangeCategory,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentAutomationRoute = useAppSelector(currentAutomationRoute$);
  const selectedCategories = useAppSelector(selectedCategories$);
  const sortBy = useAppSelector(sortBy$);
  const limit = useAppSelector(limit$);
  const offset = useAppSelector(offset$);
  const [name, setName] = useState<string>(item.name);
  const [isOpenEdit, setOpenEdit] = useState<boolean>(false);
  const [isOpenDelete, setOpenDelete] = useState<boolean>(false);
  const [isOpenChangeCategory, setOpenChangeCategory] =
    useState<boolean>(false);

  useEffect(() => {
    !!isOpenChangeCategoryProp && setOpenChangeCategory(true);
  }, [isOpenChangeCategoryProp]);

  useEffect(() => {
    setName(item.name);
  }, [item.name]);

  const handleEditOpen = useCallback(() => setOpenEdit(true), []);
  const handleEditClose = () => {
    setOpenEdit(false);
    setName(item.name);
  };
  const handleDeleteOpen = useCallback(() => setOpenDelete(true), []);
  const handleDeleteClose = useCallback(() => setOpenDelete(false), []);

  const onSubmitRename = () => {
    dispatch(renameScenario(item.scenario_id, name));
    setOpenEdit(false);
  };

  const onSubmitDelete = () => {
    dispatch(deleteScenario(item.scenario_id));
    handleDeleteClose();
  };

  const handleChangeCategory = () => setOpenChangeCategory(true);
  const handleCloseCategory = () => {
    setOpenChangeCategory(false);
    !!onOpenChangeCategory && onOpenChangeCategory();

    currentAutomationRoute?.id &&
      dispatch(
        fetchAllAutomationFiles(
          +currentAutomationRoute.id,
          sortBy,
          undefined,
          limit,
          offset,
          selectedCategories
        )
      );
  };
  const handleNameChange = (value: string) => setName(value);

  const btns = [
    {
      className: style.dropdownItem,
      text: t("automation:renameScenario"),
      onClick: handleEditOpen,
    },
    {
      className: style.dropdownItem,
      text: t("automation:changeCategory"),
      onClick: handleChangeCategory,
    },
    {
      className: style.dropdownItem,
      text: t("automation:deleteScenario"),
      onClick: handleDeleteOpen,
    },
  ];

  const overlay = () => {
    return (
      <div className={style.content}>
        <div className={style.triangle} />
        <div className={style.contentBtns}>
          {btns.map((button) => (
            <DropDownItem key={button.text} {...button} />
          ))}
        </div>
      </div>
    );
  };

  const IconCX = (open: boolean) => cx(style.icon, { [style.open]: open });

  return (
    <>
      <ScenarioHeaderPopover
        theme={PopoverTheme.LIGHT}
        overlay={() => overlay()}
        wrapperClassname={style.popoverWrapper}
        hideArrow
        getOpenToChild
        sideOffset={4}
        onOpen={onOpen}
      >
        {(open: boolean) => <MoreIcon className={IconCX(open)} />}
      </ScenarioHeaderPopover>

      <Popup
        mainBtnClassName={style.popup}
        isHideMainBtn
        inputValue={name}
        onChange={handleNameChange}
        isOpen={isOpenEdit}
        onClose={handleEditClose}
        title={t("changeScenarioName")}
        placeholder={t("scenarioName")}
        inputLabel={t("scenarioName")}
        onApply={onSubmitRename}
        maxInputLength={100}
        minInputLength={1}
        errorText={
          name.length > 100
            ? t("scenarioName100Characters")
            : name.length < 1
            ? t("tooShortName")
            : undefined
        }
        disableOk={!name.trim()}
      />

      <Popup
        mainBtnClassName={style.popup}
        isHideMainBtn
        hideInput
        isOpen={isOpenDelete}
        onClose={handleDeleteClose}
        title={t("deleteScenario")}
        subtitle={
          <Trans i18nKey="deleteScenarioText" values={{ scenario: name }}>
            <strong>{name}</strong>
          </Trans>
        }
        onApply={onSubmitDelete}
      />

      <ModalWindow
        isOpen={isOpenChangeCategory}
        isHideButton
        content={
          <EditCategories
            isChange
            scenarioCategories={item?.categories}
            scenario_id={item?.scenario_id}
            onClose={handleCloseCategory}
          />
        }
        onClose={handleCloseCategory}
      />
    </>
  );
};
