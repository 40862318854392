import * as BiliingInfoActions from "actions/billingInfo";
import State from "interfaces/state";
import { Action } from "redux";
import { WSApi } from "enums/wsApi";
import { sendWSMessage } from "utils";
import { csvHistoryLink$ } from "selectors/billingInfo";
import { company_role$ } from "selectors/auth";
import { MemberRole } from "interfaces/state/team";

export const fetchBillingInfo = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.BillingInfo;
    if (company_role$(getState()) !== MemberRole.OWNER) return;

    dispatch(BiliingInfoActions.setBillingInfoLoading());
    sendWSMessage({ action });
  };
};

export const fetchCSVLink = () => {
  return (_dispatch: (action: Action) => void, _getState: () => State) => {
    const action = WSApi.BillingCSVLink;

    sendWSMessage({ action });
  };
};

export const fetchCSVFile = () => {
  return (_dispatch: (action: Action) => void, getState: () => State) => {
    const filename = `csv-history-${new Date()}.csv`;
    if (csvHistoryLink$(getState())) {
      const link = document.createElement("a");
      link.href = csvHistoryLink$(getState());
      link.setAttribute("download", filename);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };
};
