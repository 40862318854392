import * as Const from "../constants";

export const setConfirmNewUserInit = () => {
  return {
    type: Const.CONFIRM_INIT_NEW_USER,
  };
};

export const setConfirmNewUserError = () => {
  return {
    type: Const.CONFIRM_NEW_USER_FAIL,
  };
};

export const setConfirmNewUserLoading = () => {
  return {
    type: Const.CONFIRM_NEW_USER_LOADING,
  };
};

export const setConfirmNewUserSuccess = () => {
  return {
    type: Const.CONFIRM_NEW_USER_SUCCESS,
  };
};

export const setUnconfirmedNewUser = () => {
  return {
    type: Const.SET_UNCONFIRMED_NEW_USER,
  };
};

export const setNewUserEmail = (payload: string) => {
  return {
    type: Const.SET_NEW_USER_EMAIL,
    payload,
  };
};

export const setNewUserConfirmErrorText = (payload: string) => {
  return {
    type: Const.SET_NEW_USER_ERROR_TEXT,
    payload,
  };
};

export const setRegisteredUser = () => {
  return {
    type: Const.SET_USER_ALREADY_REGISTERED,
  };
};

export const setIsconfirmedNewUser = () => {
  return {
    type: Const.SET_NEW_USER_CONFIRMED,
  };
};

export const setUserName = (payload: string) => {
  return {
    type: Const.SET_NEW_USER_NAME,
    payload,
  };
};

export const setCompanyName = (payload: string) => {
  return {
    type: Const.SET_NEW_USER_COMPANY_NAME,
    payload,
  };
};
