import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as ScenarioUsersActions from "actions/scenarioUsers";

// WSApi.GetAllScenarioUsers
export const scenarioGetAllUsers = (data: AnswerData) => {
  if (data.error) {
    store.dispatch(ScenarioUsersActions.setScenarioUsersError());
    errorFromResponse(data.error);
  } else {
    store.dispatch(
      ScenarioUsersActions.addAllUsersToScenario(
        data.response.users,
        data.response.scenario_id
      )
    );
  }
};
