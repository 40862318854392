import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { ReactComponent as ArrowIcon } from "assets/svg/fi-chevron-left.svg";
import { useAppSelector } from "hooks/redux";
import { num_of_items$ } from "selectors/scenarioVersion";
import { getPaginationItemsCount } from "utils";
import style from "./pagination.module.scss";

export enum Variant {
  FILESYSTEM = "fileSystem",
  SCENARIO = "scenario",
}

interface IProps {
  height?: number;
  onClick: (page: number, notAsync?: boolean) => void;
  page: number;
  count?: number;
  itemsPerPage?: number;
  variant?: Variant;
  isLoading?: boolean;
}

export const Pagination: FC<IProps> = ({
  height,
  onClick,
  page,
  count,
  itemsPerPage,
  variant = Variant.SCENARIO,
  isLoading,
}) => {
  const { t } = useTranslation();
  const maxItemsPerPage = useMemo(
    () =>
      typeof itemsPerPage === "number"
        ? itemsPerPage
        : getPaginationItemsCount(height!),
    [height, itemsPerPage]
  );
  const num_of_items = useAppSelector(num_of_items$);
  const itemsCount = typeof count === "number" ? count : num_of_items;
  const maxPages = useMemo(() => {
    return Math.ceil(itemsCount / maxItemsPerPage) || 1;
  }, [itemsCount, maxItemsPerPage]);

  const BtnLeftCX = cx(style.btn, style.left, {
    [style.disabled]: page === 1 || isLoading,
    [style.fileSystem]: variant === Variant.FILESYSTEM,
  });

  const BtnRightCX = cx(style.btn, {
    [style.disabled]: page >= +maxPages || isLoading,
  });

  const handleLeftBtnClick = () => {
    page !== 1 && onClick(page - 1);
  };

  const handleRightPageClick = () => {
    page !== +maxPages && onClick(page + 1);
  };

  const MaxPagesCountCX = cx(style.maxPages, {
    [style.loading]: isLoading,
  });

  const TextCX = cx(style.text, {
    [style.filesystem]: variant === Variant.FILESYSTEM,
  });

  return (
    <div className={style.root}>
      <button className={BtnLeftCX} onClick={handleLeftBtnClick}>
        <ArrowIcon className={style.icon} />
      </button>
      <div className={TextCX}>
        {`${t("version:page")}`}
        <span>{` ${page} `}</span>
        {`${t("version:of")}`}{" "}
        <span className={MaxPagesCountCX} id="max-pagination-page-count">
          {maxPages}
        </span>
      </div>
      <button className={BtnRightCX} onClick={handleRightPageClick}>
        <ArrowIcon className={style.icon} />
      </button>
    </div>
  );
};
