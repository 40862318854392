import { AnswerData } from "..";
import * as ScenarioActions from "actions/scenario";
import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { useLocation, useNavigate } from "react-router-dom";
import { privateRoute } from "routes";
import { makeScenarioParams } from "utils";

// WSApi.GetEditableScenario
export const scenarioVersionGetEditable = (
  data: AnswerData,
  _location: ReturnType<typeof useLocation>,
  _searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (!data?.error) {
    const scenario_id = scenarioItem$(store.getState())?.scenario_id;
    if (data?.response?.scenario_id === scenario_id) {
      const searchParams = new URLSearchParams(window.location.search);

      const isViewVersion = searchParams.get("viewVersion") === "1";
      if (!isViewVersion) {
        const scenario_id = searchParams.get("id");
        const isEdit = searchParams.get("edit") === "1";
        const isView = searchParams.get("view") === "1";
        const isDebug = searchParams.get("debug") === "1";
        const selectedBlock = searchParams.get("selectedBlock");
        const branch = searchParams.get("branch")!;

        navigate(
          `${privateRoute.scenario}?${makeScenarioParams({
            scenario_id,
            version_id: data.response.id,
            isEdit,
            isView,
            isDebug,
            isViewVersion,
            selectedBlock,
            branch,
          })}`
        );
      }
      store.dispatch(ScenarioActions.setEditableScenario(data.response));
    }
  }
};
