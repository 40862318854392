import * as Const from "../constants";
import State from "interfaces/state/subscription";
import { Periodicity } from "actions/billingInfo";

export const initState: State = {
  isLoading: false,
  isError: false,
  selectedPlanId: null,
  selectedPeriod: null,
  selectedPlanName: null,
  selectedPlanPrice: null,
  data: "",
  signature: "",
  end_date: "",
  periodicity: Periodicity.MONTH,
  plan_id: 0,
  plan_name: "",
  price: 0,
  tranascations_max: 0,
  max_members: null,
  canceled_date: "",
  teamCount: 0,
};

export default function subscription(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.SUBSCR_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.SUBSCR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.data,
        signature: action.signature,
      };

    case Const.SUBSCR_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.SUBSCR_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.SUBSCR_SELECTED_PARAMS:
      return {
        ...state,
        selectedPlanId: action.selectedPlanId,
        selectedPeriod: action.selectedPeriod,
        selectedPlanName: action.selectedPlanName,
        selectedPlanPrice: action.selectedPlanPrice,
      };

    case Const.SUBSCR_TRANSACTIONS:
      return {
        ...state,
        tranascations_max: action.payload,
      };

    case Const.SUBSCR_MAX_MEMBERS:
      return {
        ...state,
        max_members: action.payload,
      };

    case Const.SUBSCRIPTION_PARAMS:
      let {
        end_date,
        periodicity,
        plan_id,
        plan_name,
        price,
        transactions_per_month,
        data,
        signature,
        canceled_date,
        max_members,
      } = action.payload;

      return {
        ...state,
        end_date,
        periodicity,
        plan_id,
        plan_name,
        price,
        tranascations_max: transactions_per_month,
        data,
        signature,
        canceled_date,
        max_members,
      };

    case Const.SUBSCR_TEAM:
      return {
        ...state,
        teamCount: action.payload,
      };

    default:
      return state;
  }
}
