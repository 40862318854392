import * as React from "react";
import cx from "classnames";
import { ReactComponent as EyeIcon } from "assets/svg/eye.svg";
import { ReactComponent as EyeClosedIcon } from "assets/svg/eye-closed.svg";
import "./style.scss";

interface IProps {
  onChange: (value: string) => void;
  onClick?: () => void;
  onBlur?: () => void;
  value?: string;
  placeholder?: string;
  className?: string;
  type?: string;
  label?: string;
  errorText?: string;
  isError?: boolean;
  tabIndex?: number;
  isFocused?: boolean;
  isPassword?: boolean;
  containerClassName?: string;
  mainClassName?: string;
  disabled?: boolean;
  focusedOnStart?: boolean;
  isPositive?: boolean;
  minNumber?: number;
  disableAutocomplete?: boolean;
  maxLength?: number;
}

export class Input extends React.PureComponent<IProps> {
  public state = {
    isHideInput: true,
    isFocusedInput: false,
    isShowPass: false,
    isClicked: false,
  };

  componentDidMount() {
    const { onClick, tabIndex, focusedOnStart } = this.props;
    onClick && onClick();
    if (typeof tabIndex === "number") {
      this.setState({ isHideInput: false, isFocusedInput: true });

      this.refInput?.current?.focus();
    }

    if (focusedOnStart && this.refInput?.current) {
      this.refInput.current.focus();
    }
  }

  private refInput: React.RefObject<HTMLInputElement> = React.createRef();

  private handleFocus = () => {
    this.refInput?.current?.focus();
  };

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { maxLength, type, onChange } = this.props;

    const value = e.target?.value;
    if (type === "password" && value?.length > 100) return;

    if (type === "number") {
      const res = value.replace(/\D/g, "");
      onChange(res);
    } else {
      if (
        (typeof maxLength === "number" && value?.length <= maxLength) ||
        typeof maxLength === "undefined"
      ) {
        onChange(value);
      }
    }
  };

  private handleClick = () => {
    this.refInput?.current?.click();
  };

  private handleInputClick = () => {
    const { onClick } = this.props;
    this.handleFocus();
    this.handleClick();
    onClick && onClick();
    this.setState({
      isHideInput: false,
      isFocusedInput: true,
      isClicked: true,
    });
  };

  private handleInputFocus = () => {
    this.handleFocus();
    this.handleClick();
  };

  private handleInputMouseLeave = () => {
    const { onBlur, value } = this.props;
    if (onBlur) onBlur();

    this.setState({ isFocusedInput: false, isHideInput: value?.length });
  };

  private handleInputType = () => {
    const { type } = this.props;
    if (type && type === "password") {
      return this.state.isShowPass ? "text" : "password";
    } else {
      return "text";
    }
  };

  private handleChangeInputType = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    this.setState({ isShowPass: !this.state.isShowPass });
  };

  private renderPassIcon = () => {
    const { isPassword } = this.props;

    if (isPassword && this.state.isShowPass) {
      return (
        <div className="eye-container" onClick={this.handleChangeInputType}>
          <EyeClosedIcon className="eyeIcon" />
        </div>
      );
    } else if (isPassword && !this.state.isShowPass) {
      return (
        <div className="eye-container" onClick={this.handleChangeInputType}>
          <EyeIcon className="eyeIcon" />
        </div>
      );
    }
  };

  private plusOne = () => {
    const { value, onChange, disabled } = this.props;
    const numbericValue = Number(value);
    if (disabled) return;
    if (value && !isNaN(numbericValue)) {
      const result = numbericValue + 1;
      onChange(`${result}`);
    } else {
      onChange("1");
    }
  };

  private minusOne = () => {
    const { value, isPositive, onChange, disabled, minNumber } = this.props;
    if (disabled) return;

    const numbericValue = Number(value);
    if (value && !isNaN(numbericValue)) {
      if (
        typeof minNumber === "number" &&
        !isNaN(minNumber) &&
        +value <= minNumber
      )
        return;
      if (isPositive) {
        const result = numbericValue - 1;
        if (result >= 0) {
          onChange(`${result}`);
        }
      } else {
        const result = numbericValue - 1;
        onChange(`${result}`);
      }
    }
  };

  private renderNumIcons = () => {
    const { type } = this.props;

    if (type === "number") {
      return (
        <div className="number-container">
          <div className="number-up" onClick={this.plusOne} />
          <div className="number-down" onClick={this.minusOne} />
        </div>
      );
    }
  };

  render() {
    const {
      value,
      className,
      placeholder,
      label,
      errorText,
      isError,
      disabled,
      mainClassName,
      isPositive,
      minNumber,
      type,
      disableAutocomplete,
      focusedOnStart,
      tabIndex,
    } = this.props;

    const ContainerCX = cx("input-container ", {
      "input-container-focused": this.state.isFocusedInput || focusedOnStart,
      [this.props.containerClassName!]: this.props.containerClassName,
    });

    const InputCX = cx("input", {
      // "op-0": this.state.isHideInput,
      "op-1": !this.state.isHideInput,
      "input-focused": this.state.isFocusedInput || focusedOnStart,
      [className!]: className,
      "input-number": type === "number",
      "input-container-error": isError,
    });

    const WrapperCX = cx("input-wrapper d-flex flex-column shrink-0", {
      [mainClassName!]: mainClassName,
    });

    const min = () => {
      if (isPositive && !minNumber) {
        return 0;
      } else if (isPositive && minNumber) {
        return minNumber;
      } else {
        return undefined;
      }
    };

    return (
      <div className={WrapperCX}>
        <div
          className={ContainerCX}
          onClick={this.handleInputClick}
          onFocus={this.handleInputFocus}
        >
          <div
            className="input-label"
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
              e.preventDefault()
            }
          >
            {label}
          </div>
          <input
            autoComplete={disableAutocomplete ? "new-password" : "11"}
            ref={this.refInput}
            type={this.handleInputType()}
            value={value}
            className={InputCX}
            onChange={this.handleChange}
            placeholder={value || this.state.isFocusedInput ? "" : placeholder}
            onBlur={this.handleInputMouseLeave}
            onFocus={this.handleInputFocus}
            tabIndex={tabIndex}
            disabled={disabled}
            min={min()}
          />
          {this.renderPassIcon()}
          {this.renderNumIcons()}
        </div>
        {!!isError && (
          <div className="input-error-text" title={errorText}>
            {errorText ? errorText : null}
          </div>
        )}
      </div>
    );
  }
}
