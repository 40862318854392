import IMethodReplyState, { IReply } from "interfaces/state/methodReply";
import * as Const from "../constants";

export const setMethodReplyInit = () => {
  return {
    type: Const.METHOD_REPLY_INIT,
  };
};

export const setMethodReplySuccess = (
  payload: IMethodReplyState["methodReply"]
) => {
  return {
    type: Const.METHOD_REPLY_SUCCESS,
    payload,
  };
};

export const setMethodReplyError = () => {
  return {
    type: Const.METHOD_REPLY_FAIL,
  };
};

export const setMethodReplyLoading = () => {
  return {
    type: Const.METHOD_REPLY_LOADING,
  };
};

export const setCurrentOutputList = (payload: { [key: string]: IReply }) => {
  return {
    type: Const.SELECTED_OUTPUT_LIST,
    payload,
  };
};

export const setPathToValue = (payload: string[]) => {
  return {
    type: Const.SET_PATH_TO_VALUE,
    payload,
  };
};

export const setPathList = (payload: string[]) => {
  return {
    type: Const.SET_PATH_TO_LIST,
    payload,
  };
};

export const setPathToCompareKey = (payload: string[]) => {
  return {
    type: Const.SET_PATH_TO_COMPARE_KEY,
    payload,
  };
};
