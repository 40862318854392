import {
  useCallback,
  useMemo,
  useState,
  ReactNode,
  ReactElement,
  useRef,
} from "react";
import cx from "classnames";
import * as RadixPopover from "@radix-ui/react-popover";
import {
  useFloating,
  offset,
  shift,
  flip,
  autoUpdate,
} from "@floating-ui/react";
import { ReactComponent as ArrowDown } from "assets/svg/arrow-down-old.svg";
import style from "./scenarioHeaderPopover.module.scss";

export enum PopoverTheme {
  LIGHT = "light",
  DARK = "dark",
}

interface IProps {
  onOpen?: (value: boolean) => void;
  overlay: (isOpen: boolean) => ReactNode;
  children?: ReactElement | ((isOpen: boolean) => ReactElement);
  hideArrow?: boolean;
  theme?: PopoverTheme;
  sideOffset?: number;
  alignOffset?: number;
  wrapperClassname?: string;
  getOpenToChild?: boolean;
  arrowClassName?: string;
  rotateArrow?: boolean;
}

const ScenarioHeaderPopover = ({
  onOpen,
  overlay,
  children,
  hideArrow,
  theme = PopoverTheme.DARK,
  sideOffset = 23,
  alignOffset,
  wrapperClassname = "",
  getOpenToChild,
  arrowClassName = "",
  rotateArrow,
}: IProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const { refs, floatingStyles, update } = useFloating({
    middleware: [offset(sideOffset), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const handleOpen = useCallback(
    (open: boolean) => {
      setOpen(open);
      !!onOpen && onOpen(open);

      setTimeout(() => update(), 500);
    },
    [onOpen, update]
  );

  const IconCX = cx(style.icon, {
    [style.open]: isOpen,
    [arrowClassName]: arrowClassName,
    [style.rotateArrowUp]: rotateArrow && isOpen,
  });

  const childContent = useMemo(
    () =>
      getOpenToChild
        ? (children as (open: boolean) => ReactElement)(isOpen)
        : (children as ReactElement),
    [children, getOpenToChild, isOpen]
  );

  const anchor = useMemo(
    () =>
      hideArrow ? (
        <RadixPopover.Anchor className={style.anchor} ref={refs.setReference}>
          {childContent}
        </RadixPopover.Anchor>
      ) : (
        <>
          {childContent}
          <RadixPopover.Anchor className={style.anchor} ref={refs.setReference}>
            <ArrowDown className={IconCX} />
          </RadixPopover.Anchor>
        </>
      ),
    [IconCX, childContent, hideArrow, refs.setReference]
  );

  const TriggerCX = cx(style.trigger, {
    [style.withoutArrow]: hideArrow,
  });

  const trigger = useMemo(
    () => (
      <RadixPopover.Trigger asChild>
        <div className={TriggerCX}>{anchor}</div>
      </RadixPopover.Trigger>
    ),
    [TriggerCX, anchor]
  );

  const WrapperCX = cx(style.wrapper, {
    [style.light]: theme === PopoverTheme.LIGHT,
    [style.dark]: theme === PopoverTheme.DARK,
    [wrapperClassname]: !!wrapperClassname,
  });

  const ArrowCX = cx(style.arrow, {
    [style.light]: theme === PopoverTheme.LIGHT,
    [style.dark]: theme === PopoverTheme.DARK,
    [arrowClassName || ""]: !!arrowClassName,
  });

  return (
    <RadixPopover.Root onOpenChange={handleOpen}>
      {trigger}
      <RadixPopover.Portal>
        <RadixPopover.Content
          className={style.content}
          sideOffset={sideOffset}
          alignOffset={alignOffset}
          ref={refs.setFloating}
          style={floatingStyles}
        >
          <>
            <div className={WrapperCX}>{overlay(isOpen)}</div>
            <RadixPopover.Arrow className={ArrowCX} />
          </>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};

export default ScenarioHeaderPopover;
