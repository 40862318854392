import State from "interfaces/state";

export const isLoading$ = (state: State) => state.paymentMethod.isLoading;
export const isError$ = (state: State) => state.paymentMethod.isError;
export const paymentMethods$ = (state: State) => state.paymentMethod.list;
export const defaultMethod$ = (state: State) =>
  state.paymentMethod.defaultMethod;

export const errorWhileCreateNewMethod$ = (state: State) =>
  state.paymentMethod.errorWhileCreateNewMethod;
