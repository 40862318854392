import store from "store";
import { AnswerData } from "../index";
import * as ScenarioCategoriesActions from "actions/scenarioCategories";
import { companyCategories$ } from "selectors/scenarioCategories";

// WSApi.AddCompanyCategory
export const companyCategoriesAdd = (data: AnswerData) => {
  if (!data?.error) {
    const { dispatch, getState } = store;

    const companyCategories = companyCategories$(getState());

    dispatch(
      ScenarioCategoriesActions.setScenarioCategories({
        isLoading: false,
        isError: false,
        company_list: [data.response, ...companyCategories],
      })
    );
  }
};
