import * as Const from "../constants";
import State from "../interfaces/state/scenario";

export const initState: State = {
  isLoading: false,
  isError: false,
  scenarioItem: null,
  selectedBlockId: null,
  cube: null,
  tempBlockId: null,
  processing: {},
  blockWithErrors: [],
  method_name: null,
  debugScenarioId: null,
  isOpenSelectBranchRightPanel: false,
  isOpenEmptyBlocksErrorModal: false,
  isOpenNotAllowedToEditModal: false,
  isOpenUnavailableServicesModal: false,
  editableVersion: null,
  zoom: 100,
  isOpenInitAuthModal: false,
  initAuthModalData: null,
  initUpdates: null,
  checkInit: {},
  isUpdateVariable: false,
  unavailableServiceOrMethods: {},
};

export default function scenario(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.SCENARIO_ITEM_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.SCENARIO_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        scenarioItem: action.payload,
      };

    case Const.SCENARIO_ITEM_REFRESH:
      return {
        ...state,
        isLoading: false,
        isError: false,
        scenarioItem: state.scenarioItem
          ? {
              ...state.scenarioItem,
              pipeline: [],
              scenario: [],
            }
          : null,
      };

    case Const.SCENARIO_ITEM_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.SCENARIO_ITEM_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.SCENARIO_LOADED:
      return {
        ...state,
        isLoading: false,
      };

    case Const.SCENARIO_RENAME:
      if (!state.scenarioItem) {
        return { ...state };
      }
      return {
        ...state,
        scenarioItem: { ...state.scenarioItem, name: action.payload },
      };

    case Const.SCENARIO_CHANGE_INIT_BLOCK:
      if (!state.scenarioItem) {
        return { ...state };
      }
      return {
        ...state,
        scenarioItem: { ...state.scenarioItem, init: action.payload },
      };

    case Const.SCENARIO_SELECTED_CUBE_ID:
      return {
        ...state,
        selectedBlockId: action.payload,
      };

    case Const.SELECT_CUBE:
      const eventService = action.payload?.event_service;
      const method_name = eventService
        ? action.payload?.method_name + eventService
        : action.payload?.method_name;
      return {
        ...state,
        cube: action.payload,
        method_name,
      };

    case Const.SCENARIO_TEMP_CUBE_ID:
      return {
        ...state,
        tempBlockId: action.payload,
      };

    case Const.SCENARIO_DELETE:
      return {
        ...state,
        scenarioItem: null,
      };

    case Const.SCENARIO_PROCESSING:
      return {
        ...state,
        processing: { ...state.processing, ...action.payload },
      };

    case Const.SCENARIO_GET_ERROR_WHEN_START:
      return {
        ...state,
        blockWithErrors: action.payload,
      };

    case Const.SCENARIO_METHOD_NAME:
      return {
        ...state,
        method_name: action.payload,
      };

    case Const.SCENARIO_DEBUG_ID:
      return {
        ...state,
        debugScenarioId: action.payload,
      };

    case Const.SCENARIO_BRANCHING_PANEL:
      return {
        ...state,
        isOpenSelectBranchRightPanel: action.payload,
      };

    case Const.SCENARIO_UNFILLED_BLOCKS:
      return {
        ...state,
        isOpenEmptyBlocksErrorModal: action.payload,
      };

    case Const.SCENARIO_EDITABLE_VERSION:
      return {
        ...state,
        editableVersion: action.payload,
      };

    case Const.SCENARIO_ALLOWED_TO_EDIT:
      return {
        ...state,
        isOpenNotAllowedToEditModal: action.payload,
      };

    case Const.SCENARIO_ZOOM:
      return {
        ...state,
        zoom: action.payload,
      };

    case Const.SCENARIO_INIT_AUTH_MODAL:
      const {
        payload: { isOpenInitAuthModal, initAuthModalData },
      } = action;
      return {
        ...state,
        isOpenInitAuthModal,
        initAuthModalData,
      };

    case Const.SCENARIO_CHECK_OBJECT:
      return {
        ...state,
        checkInit: action.payload,
      };

    case Const.SCENARIO_UPDATE_VARIABLE:
      return {
        ...state,
        isUpdateVariable: action.payload,
      };

    case Const.SCENARIO_UNAVAILABLE_SERVICE_MODAL:
      return {
        ...state,
        isOpenUnavailableServicesModal: action.payload,
      };

    case Const.SCENARIO_UNAVAILABLE_SERVICE_VALUE:
      return {
        ...state,
        unavailableServiceOrMethods: action.payload,
      };

    default:
      return state;
  }
}
