import * as Const from "constants/index";
import { WSConnectState } from "interfaces/state/wsConnect";

export const initState: WSConnectState = {
  connect: "init",
  connected: false,
  WebsocketItem: null,
  queue: [],
};

export default function wsConnect(
  state: WSConnectState = initState,
  action: any
) {
  switch (action.type) {
    case Const.WS_CONNECT_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.WS_CONNECT:
      return {
        ...state,
        connect: action.payload,
      };

    case Const.WS_CONNECT_SET_CONNECTED:
      return {
        ...state,
        connected: action.payload,
      };

    case Const.WS_CONNECT_WEBSOCKET_ITEM:
      return {
        ...state,
        WebsocketItem: action.payload,
      };

    case Const.WS_CONNECT_QUEUE:
      return {
        ...state,
        queue: action.payload,
      };

    default:
      return state;
  }
}
