import { Company } from "interfaces/state/company";
import * as Const from "../constants";

export const setCompanyInit = () => {
  return {
    type: Const.CONFIRM_INIT,
  };
};

export const setCompanyList = (payload: Company[]) => {
  return {
    type: Const.COMPANY_SUCCESS,
    payload,
  };
};

export const setCompanyFail = () => {
  return {
    type: Const.COMPANY_FAIL,
  };
};

export const setCompanyLoading = () => {
  return {
    type: Const.COMPANY_LOADING,
  };
};
