import store from "store";
import { AnswerData } from "../index";
import * as DashboardInfoActions from "actions/dashboardInfo";

// WSApi.DashboarUpdateTransactions
export const statisticUpdateTransactions = (data: AnswerData) => {
  if (!data.error) {
    store.dispatch(DashboardInfoActions.updateDashTransactions(data.response));
  }
};
