import { Action } from "redux";
import State from "interfaces/state";
import { useNavigate } from "react-router-dom";
import * as ScenarioActions from "actions/scenario";
import { WSApi } from "enums/wsApi";
import {
  sendWSMessage,
  serializeUrlSearchParams,
  envLinkSetter,
  parseDataFromStorage,
  errorNotification,
  successNotification,
  alphabet,
} from "utils";
import * as ScenarioUsersCreators from "creators/scenarioUsers";
import { currentAutomationRoute$ } from "selectors/automationRouting";
import ScenarioState, {
  BlockCaseOf,
  BlockExecuteMethod,
  BlockExit,
  BlockFormBody,
  IVariable,
  ScenarioInit,
  IScenarioVariable,
  BlockCounter,
  BlockInit,
  Branch,
} from "interfaces/state/scenario";
import {
  currentBlock$,
  scenarioItem$,
  selectedBlockId$,
} from "selectors/scenario";
import { ServiceItem, ServiceObj } from "interfaces/state/service";
import { INIT_BLOCK } from "pages/scenario/constants";
import { sensitiveDataList$ } from "selectors/sensitiveData";
import { IInputServiceParameter } from "interfaces/state/serviceParameter";
import i18n from "i18n";
import { libFunction$ } from "selectors/libFunction";
import {
  pathToList$,
  pathToValue$,
  pathToCompareKey$,
} from "selectors/methodReply";
import * as MethodReplyActions from "actions/methodReply";
import * as DebugActions from "actions/debug";
import { sendEvent } from "./googleAnalytics";
import { X_STEP, Y_STEP } from "pages/scenario/utils";
import * as ScenarioVersionActions from "actions/scenarioVersion";
import * as VariablePanelActions from "actions/variablePanel";
import { company_role$, plan_name$ } from "selectors/auth";
import { MemberRole } from "interfaces/state/team";
import { insertBlockBetween } from "utils/childBlockIds";
import { activeId$, prevActiveId$ } from "selectors/variablePanel";

export const fetchScenarioById = (
  scenario_id: string,
  silent?: boolean,
  version_id?: string
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    if (!silent) {
      dispatch(ScenarioActions.setScenarioLoading());
      dispatch(ScenarioActions.deleteScenario());
      dispatch(
        ScenarioUsersCreators.fetchAllUsersByScenarioId(scenario_id) as any
      );
      dispatch(
        ScenarioUsersCreators.addNewUserByScenarioId(scenario_id) as any
      );
    }

    const action = WSApi.GetScenario;

    sendWSMessage(
      version_id
        ? {
            action,
            scenario_id,
            version_id,
          }
        : { action, scenario_id }
    );
  };
};

export const createNewScenario = (
  name: string,
  is_open_after_creation: boolean
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.ScenarioCreate;

    const folder_id = currentAutomationRoute$(getState())?.id;

    sendWSMessage({ action, folder_id, name, is_open_after_creation });
  };
};

export const updateInitBlock = (
  initParams: Partial<ScenarioInit>,
  deleteOldParams?: boolean
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.UpdateInitBlock;
    const scenario = scenarioItem$(getState());
    const company_role = company_role$(getState());
    const searchParams = new URLSearchParams(window.location.search);

    const isView = searchParams.get("view") === "1";
    if (!scenario || company_role === "user" || isView) return;
    dispatch(ScenarioActions.setScenarioLoading());

    const scenario_id = scenario?.scenario_id;
    const version_id = scenario?.version_id;

    const x_coordinate = scenario?.init?.x_coordinate
      ? scenario?.init?.x_coordinate
      : 100;
    const y_coordinate = scenario?.init?.y_coordinate
      ? scenario?.init?.y_coordinate
      : 100;

    const init = deleteOldParams
      ? { ...initParams, name: scenario.init?.name, x_coordinate, y_coordinate }
      : {
          ...scenario.init,
          ...initParams,
          x_coordinate,
          y_coordinate,
        };
    dispatch(ScenarioActions.updateScenarioInitBlock(init));
    if (!!initParams?.method_name) {
      dispatch(ScenarioActions.changeMethodName(initParams?.method_name));
    }

    sendWSMessage({ action, init, scenario_id, version_id });
  };
};

export const addFirstBlock = (position?: { x: number; y: number }) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ScenarioActions.setScenarioLoading());

    const action = WSApi.AddFirstBlock;
    const scenario = scenarioItem$(getState());
    if (!scenario) return;
    const scenario_id = scenario?.scenario_id;
    const version_id = scenario?.version_id;
    const { x, y } = setGridId({
      coordinates: position!,
      addType: "right",
      scenario,
    });

    const data = {
      action,
      scenario_id,
      x_coordinate: x,
      y_coordinate: y,
      version_id,
    };

    sendWSMessage(data);
  };
};

export const addBlock = (
  position?: { x: number; y: number },
  child_block_id?: string,
  parent_block?: string,
  coefficient?: number,
  notBranch?: boolean
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ScenarioActions.setScenarioLoading());

    const action = WSApi.AddBlock;
    const scenario = scenarioItem$(getState());
    if (!scenario) return;
    const scenario_id = scenario?.scenario_id;
    const version_id = scenario?.version_id;

    const parent_block_id = parent_block
      ? parent_block
      : selectedBlockId$(getState());

    const is_branching_block =
      notBranch && currentBlock$(getState())?.type === "case_of";

    const { x, y } = setGridId({
      coordinates: position!,
      addType: "right",
      scenario,
      withoutCheck: !!child_block_id,
    });

    const data = {
      action,
      scenario_id,
      parent_block_id,
      is_branching_block,
      x_coordinate: x,
      y_coordinate: y,
      source_position: "b",
      version_id,
      child_block_id,
    };

    sendWSMessage(data);
  };
};

export const updateMultipleBlockPositions = (
  block_id: string,
  nextBlockId: string
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.BlockUpdateCoordinatesMultiple;
    const scenarioItem = scenarioItem$(getState());
    const pipeline = scenarioItem?.pipeline;
    const scenario = scenarioItem?.scenario;

    if (pipeline?.length && scenario?.length) {
      const blocks = insertBlockBetween(
        block_id,
        pipeline,
        scenario,
        nextBlockId
      );

      const data = {
        action,
        blocks,
        scenario_id: scenarioItem?.scenario_id,
        version_id: scenarioItem?.version_id,
      };

      sendWSMessage(data);
    }
  };
};

export const updateBlock = (
  data_to_update:
    | Partial<BlockExecuteMethod>
    | Partial<BlockFormBody>
    | Partial<BlockCaseOf>
    | Partial<BlockExit>
    | Partial<BlockCounter>,
  additionalData?: { scenario_id: string; version_id?: string }
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const searchParams = new URLSearchParams(window.location.search);

    const isView = searchParams.get("view") === "1";
    const company_role = company_role$(getState());
    if (company_role === MemberRole.USER || isView) {
      return;
    }

    dispatch(ScenarioActions.setScenarioLoading());

    const action = WSApi.UpdateBlock;
    const scenario = scenarioItem$(getState());
    if (!scenario && !additionalData) return;
    const scenario_id = additionalData?.scenario_id || scenario?.scenario_id;
    const version_id = additionalData?.version_id || scenario?.version_id;
    const block_id = data_to_update?.id
      ? data_to_update!.id
      : selectedBlockId$(getState());

    if (!block_id) return;

    if (data_to_update?.method_name) {
      dispatch(ScenarioActions.changeMethodName(data_to_update.method_name));
    }
    sendWSMessage({
      action,
      scenario_id,
      block_id,
      data_to_update,
      version_id,
    });
  };
};

export const deleteBlock = (id?: string, branch_for_saving?: string | null) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ScenarioActions.setScenarioLoading());

    const action = WSApi.DeleteBlock;
    const scenario = scenarioItem$(getState());
    if (!scenario) return;
    const scenario_id = scenario?.scenario_id;
    const version_id = scenario?.version_id;

    const block_id = id ? id : selectedBlockId$(getState());

    sendWSMessage({
      action,
      scenario_id,
      block_id,
      branch_for_saving,
      version_id,
    });
  };
};

export const createVariable = (
  value_type: IScenarioVariable["value_type"],
  name: string,
  value: string[],
  isInit?: boolean,
  nth_element?: IScenarioVariable["nth_element"],
  data_type?: IScenarioVariable["data_type"],
  service?: string
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const company_role = company_role$(getState());

    if (company_role === MemberRole.USER) {
      // errorNotification(i18n.t("forbidden"));
      return;
    }
    dispatch(ScenarioActions.setScenarioLoading());

    const action = WSApi.CreateVariable;
    const scenario = scenarioItem$(getState());
    if (!scenario) return;
    const scenario_id = scenario?.scenario_id;
    const from_block_id = isInit ? INIT_BLOCK : selectedBlockId$(getState());
    const version_id = scenario?.version_id;

    const req = () => {
      if (value_type === "schema") {
        return {
          action,
          scenario_id,
          from_block_id,
          value_type,
          name,
          value,
          data_type,
          version_id,
          service,
        };
      } else if (value_type === "list_nth") {
        return {
          action,
          scenario_id,
          from_block_id,
          value_type,
          name,
          path_to_list: value,
          nth_element,
          data_type,
          version_id,
          service,
        };
      }
    };

    sendWSMessage({ ...req() });
  };
};

export const updateVariable = (
  id: string,
  data_to_update: Partial<IVariable>
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const company_role = company_role$(getState());
    if (company_role === MemberRole.USER) {
      // errorNotification(i18n.t("forbidden"));
      return;
    }

    const action = WSApi.UpdateVariable;
    const scenario = scenarioItem$(getState());
    if (!scenario) return;
    const scenario_id = scenario?.scenario_id;
    const version_id = scenario?.version_id;

    dispatch(ScenarioActions.setUpdateVariable(true));

    sendWSMessage({
      action,
      scenario_id,
      version_id,
      id,
      data_to_update,
    });
  };
};

export const getServiceOAuth2Data = (service: ServiceItem) => {
  return (_dispatch: (action: Action) => void, getState: () => State) => {
    const data = service.authorization_type.data as ServiceObj;
    const getUrl = data.get_code_req?.url;

    const getObject = { ...data.get_code_req };
    delete getObject.url;
    window.open(
      `${getUrl}?${serializeUrlSearchParams(
        getObject
      )}&redirect_uri=${envLinkSetter()}/app/automation/success/redirect/oauth2`
    );
    let xhr = new XMLHttpRequest();

    const src = `${getUrl}?${serializeUrlSearchParams(
      getObject
    )}&redirect_uri=${envLinkSetter()}/app/automation/success/redirect/oauth2`;

    xhr.open("GET", src);
    xhr.send();
    xhr.onload = function () {
      console.log(xhr.response, "get resp");
    };
    const serviceNumber = sensitiveDataList$(getState())?.length;

    localStorage.setItem("name-for-oauth", String(serviceNumber));

    localStorage.setItem("service-name", JSON.stringify(service?.name));
    localStorage.setItem(
      "scenario_id",
      JSON.stringify(scenarioItem$(getState())?.scenario_id)
    );

    localStorage.setItem(
      "block_id",
      JSON.stringify(
        selectedBlockId$(getState()) ? selectedBlockId$(getState()) : INIT_BLOCK
      )
    );

    const pathname = window.location.pathname.includes("localhost")
      ? "https://app.devel.digitalbpm.io/"
      : window.location.pathname;
    localStorage.setItem(
      "redirect-from-get",
      JSON.stringify(pathname + window.location.search)
    );
  };
};

export const addBlockFromCaseOf = (
  source_position: "a" | "b" | "c",
  position?: { x: number; y: number }
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ScenarioActions.setScenarioLoading());

    const action = WSApi.BlockAddBranch;
    const scenario = scenarioItem$(getState());
    if (!scenario) return;
    const version_id = scenario?.version_id;

    const scenario_id = scenario?.scenario_id;
    const parent_block_id = selectedBlockId$(getState());

    const setPosition = (p: "a" | "b" | "c"): SideType => {
      const res = {
        a: "top",
        b: "right",
        c: "bottom",
      };

      return res[p] as SideType;
    };

    const { x, y } = setGridId({
      coordinates: position!,
      addType: setPosition(source_position),
      scenario,
    });

    const data = {
      action,
      scenario_id,
      parent_block_id,
      source_position,
      x_coordinate: x,
      y_coordinate: y,
      version_id,
    };

    sendWSMessage(data);
  };
};

export const startScenario = (
  scenario_id: string,
  version_id?: string,
  branch: string = "live"
) => {
  return (dispatch: (action: Action) => void, _getState: () => State) => {
    if (branch === "live") {
      const processing = {
        [scenario_id]: {
          scenario_id,
          isLoading: true,
        },
      };
      dispatch(ScenarioActions.setScenarioProcessing(processing));
    }

    const action = WSApi.ScenarioStart;
    sendEvent("User", "Start scenario", "Start scenario id " + scenario_id, 1);

    sendWSMessage({
      action,
      scenario_id,
      version_id,
      branch: branch === "devel" ? "live" : branch,
    });
  };
};

export const stopScenario = (
  scenario_id: string,
  branch: string = "live",
  delete_after?: boolean,
  version_id?: string,
  tag?: string
) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ScenarioActions.setScenarioLoading());

    const action = WSApi.ScenarioStop;

    sendWSMessage({
      action,
      scenario_id,
      branch,
      delete_after,
      version_id,
      tag,
    });
  };
};

export const updateBlockCoordinates = (
  block_id: string,
  x: number,
  y: number
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ScenarioActions.setScenarioLoading());

    const action = WSApi.BlockUpdateCoordinates;

    const scenario = scenarioItem$(getState());
    if (!scenario) return;

    const currentBlock = currentBlock$(getState());

    const old_x = currentBlock?.x_coordinate;
    const old_y = currentBlock?.y_coordinate;

    const isCoordinateExist = scenario?.scenario?.some(
      ({ y_coordinate, x_coordinate }) =>
        x_coordinate === x && y_coordinate === y
    );
    const isInitCoordinateExist =
      scenario?.init?.y_coordinate === y && scenario?.init?.x_coordinate === x;

    if (isCoordinateExist || isInitCoordinateExist) {
      const scenario_id = scenario?.scenario_id;
      const version_id = scenario?.version_id;

      setTimeout(
        () =>
          sendWSMessage({
            action,
            scenario_id,
            block_id,
            x_coordinate: old_x?.toFixed(0),
            y_coordinate: old_y?.toFixed(0),
            version_id,
          }),
        200
      );
    }
    const scenario_id = scenario?.scenario_id;
    const version_id = scenario?.version_id;

    sendWSMessage({
      action,
      scenario_id,
      block_id,
      x_coordinate: x.toFixed(0),
      y_coordinate: y.toFixed(0),
      version_id,
    });
  };
};

export const updateBlockType = (
  new_type: string,
  branch_for_saving?: string | null
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ScenarioActions.setScenarioLoading());

    const action = WSApi.UpdateBlockType;
    const scenario = scenarioItem$(getState());
    if (!scenario) return;
    const scenario_id = scenario?.scenario_id;
    const version_id = scenario?.version_id;
    const block_id = selectedBlockId$(getState());

    sendWSMessage({
      action,
      scenario_id,
      new_type,
      block_id,
      branch_for_saving,
      version_id,
    });
  };
};

export const unchainBlockBranch = (child_block_id: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ScenarioActions.setScenarioLoading());

    const action = WSApi.BlockUnchainBranch;
    const scenario = scenarioItem$(getState());
    if (!scenario) return;
    const scenario_id = scenario?.scenario_id;
    const version_id = scenario?.version_id;
    const parent_block_id = selectedBlockId$(getState());

    sendWSMessage({
      action,
      scenario_id,
      child_block_id,
      parent_block_id,
      version_id,
    });
  };
};

export const fetchTokensOauth = (
  setIsRedirected: (v: boolean) => void,
  searchParams: URLSearchParams,
  navigate: ReturnType<typeof useNavigate>
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.SetAuthData;
    const service = parseDataFromStorage("service-name");
    setIsRedirected(true);
    const code = searchParams.get("code");

    sendWSMessage({
      action,
      service,
      code,
    });
    const redirectUrl = parseDataFromStorage("redirect-from-get");

    navigate(redirectUrl);

    if (redirectUrl) {
      setTimeout(() => {
        localStorage.removeItem("post-data");
        localStorage.removeItem("redirect-from-get");
      }, 5000);
    }
  };
};

export const fetchAvailableVariable = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ScenarioActions.setScenarioLoading());

    const action = WSApi.GetAvailableVariables;
    const scenario = scenarioItem$(getState());
    if (!scenario) return;
    const scenario_id = scenario?.scenario_id;
    const last_block_id = selectedBlockId$(getState());
    const version_id = scenario?.version_id;

    sendWSMessage({
      action,
      scenario_id,
      last_block_id,
      version_id,
    });
  };
};

export const createVariableLibrary = (
  name: string,
  lib_name: string,
  function_name: string,
  args: IInputServiceParameter[]
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const company_role = company_role$(getState());
    if (company_role === MemberRole.USER) {
      // errorNotification(i18n.t("forbidden"));
      return;
    }
    dispatch(ScenarioActions.setScenarioLoading());

    const hasValue = args.some((arg) => arg.value);
    if (!hasValue) {
      errorNotification(i18n.t("scenario:paramsCannotBeEmpty"));
    }

    const action = WSApi.CreateVariable;
    const scenario = scenarioItem$(getState());
    const scenario_id = scenario?.scenario_id;
    if (!scenario_id) return;
    const version_id = scenario?.version_id;
    const from_block_id = selectedBlockId$(getState()) as string;
    const libFunc = libFunction$(getState())?.[scenario_id!]?.[
      from_block_id!
    ].find((lib) => lib.function_name === function_name);
    if (!libFunc) return;
    const data_type = libFunc.response_type;

    const value = { lib_name, function_name, arguments: args };

    sendWSMessage({
      action,
      name,
      value,
      from_block_id,
      value_type: "library",
      scenario_id,
      data_type,
      version_id,
    });

    successNotification(i18n.t("scenario:varCreated", { name }));

    dispatch(fetchAvailableVariable() as any);
  };
};

export const createVariableFromListOfObjects = (
  name: string,
  value: string[],
  isInit?: boolean
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const company_role = company_role$(getState());
    if (company_role === MemberRole.USER) {
      // errorNotification(i18n.t("forbidden"));
      return;
    }
    dispatch(ScenarioActions.setScenarioLoading());

    const action = WSApi.CreateVariable;
    const path_to_list = pathToList$(getState());
    const path_to_value = pathToValue$(getState());
    const path_to_compare_key = pathToCompareKey$(getState());
    const scenario = scenarioItem$(getState());
    if (!scenario) return;
    const scenario_id = scenario?.scenario_id;
    const version_id = scenario?.version_id;
    const from_block_id = isInit ? INIT_BLOCK : selectedBlockId$(getState());
    successNotification(i18n.t("varWasCreated"));

    sendWSMessage({
      action,
      scenario_id,
      from_block_id,
      data_type: "string",
      value_type: "list_of_objects",
      path_to_list,
      path_to_value,
      path_to_compare_key: path_to_compare_key.slice(
        2,
        path_to_compare_key.length
      ),
      value_to_compare: {
        name: "value",
        value,
        value_type: "template",
      },
      name,
      version_id,
    });

    dispatch(MethodReplyActions.setCurrentOutputList({}));
    dispatch(MethodReplyActions.setPathToValue([]));
    dispatch(MethodReplyActions.setPathList([]));
    dispatch(MethodReplyActions.setPathToCompareKey([]));
  };
};

export const fetchAvailableReplyMethods = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.ServiceAvaiableMethods;
    const scenario_id = scenarioItem$(getState())?.scenario_id;
    const last_block_id = selectedBlockId$(getState());

    sendWSMessage({
      action,
      scenario_id,
      last_block_id,
    });
  };
};

const setY = (prevY: string, prevX: string, isAdd?: boolean) => {
  const MAX_ALPHABET_INDEX = 25;

  const nextY = isAdd ? Number(prevY) + 1 : Number(prevY) - 1;
  const setNewY = () => {
    if (isAdd && nextY === 0) {
      return 1;
    } else if (!isAdd && nextY === 0) {
      return -1;
    } else {
      return nextY;
    }
  };

  const prevXIndex = alphabet.findIndex(
    (letter) => letter === String(prevX?.slice(-1))
  );

  if (prevXIndex === MAX_ALPHABET_INDEX) {
    return `${prevX}A${setNewY()}`;
  } else {
    const newXWithoutLastLetter = prevX.slice(0, -1);
    const newLetter = alphabet[prevXIndex + 1];
    return `${newXWithoutLastLetter}${newLetter}${setNewY()}`;
  }
};

const setX = (prevX: string, prevY: string, position: "left" | "right") => {
  const MAX_ALPHABET_INDEX = 25;

  const prevXIndex = alphabet.findIndex(
    (letter) => letter === String(prevX?.slice(-1))
  );

  if (position === "right") {
    if (prevXIndex === MAX_ALPHABET_INDEX) {
      return `${prevX}A${prevY}`;
    } else {
      const newXWithoutLastLetter = prevX.slice(0, -1);
      const newLetter = alphabet[prevXIndex + 1];
      return `${newXWithoutLastLetter}${newLetter}${prevY}`;
    }
  } else {
    if (prevX.includes("-") && prevXIndex === MAX_ALPHABET_INDEX) {
      return `-${prevX}A${prevY}`;
    } else if (prevX.includes("-") && prevXIndex < MAX_ALPHABET_INDEX) {
      const newXWithoutLastLetter = prevX.slice(0, -1);
      const newLetter = alphabet[prevXIndex - 1];
      return `${newXWithoutLastLetter}${newLetter}${prevY}`;
    } else {
      const newXWithoutLastLetter = prevX.slice(0, -1);
      const newLetter = alphabet[prevXIndex - 1];
      return `${newXWithoutLastLetter}${newLetter}${prevY}`;
    }
  }
};

const stationingByPosition = (
  prevX: string,
  prevY: string,
  position: "left" | "right" | "top" | "bottom"
) => {
  return {
    left: setX(prevX, prevY, position as "left" | "right"),
    right: setX(prevX, prevY, position as "left" | "right"),
    top: setY(prevY, prevX, false),
    bottom: setY(prevY, prevX, true),
  };
};

export const makeGridId = (
  position: "left" | "right" | "top" | "bottom",
  prevGridId?: string,
  isPrevBlockInit?: boolean
) => {
  if (!prevGridId && !isPrevBlockInit) {
    return "A1";
  } else if (isPrevBlockInit) {
    return "B1";
  } else {
    if (prevGridId) {
      const values = prevGridId.split(/(\d+)/);
      const lastXCharacter = values[0].slice(-1);
      const prevX =
        lastXCharacter === "-" ? (values[0].slice(0, -1) as string) : values[0];
      const prevY = lastXCharacter === "-" ? "-" + values[1] : values[1];

      return stationingByPosition(prevX, prevY, position)[position];
    }
  }
};

export const setPositionByGridId = (grid_id: string) => {
  if (!grid_id) return;
  let x, y;

  const gridValues = grid_id.split(/(\d+)/);

  let lastGridValueXCharacter = gridValues[0].slice(-1);
  let gridValueX =
    lastGridValueXCharacter === "-"
      ? gridValues[0].slice(0, -1)
      : gridValues[0];
  let gridValueY =
    lastGridValueXCharacter === "-" ? "-" + gridValues[1] : gridValues[1];

  const isXNegative = gridValueX[0] === "-";
  const isYNegative = gridValueY[0] === "-";
  const computedXValue =
    gridValueX[0] === "-" ? gridValueX.slice(1) : gridValueX;

  let xValueIndex = 0;
  [...computedXValue].forEach((letter) => {
    const letterIndex = alphabet.findIndex((l) => l === letter);

    xValueIndex += letterIndex + 1;
  }, "");

  x = isXNegative
    ? Number(-xValueIndex * X_STEP)
    : Number(xValueIndex * X_STEP);
  y = isYNegative
    ? (Number(gridValueY) + 1) * Y_STEP
    : Number(gridValueY) * Y_STEP;
  return { x, y };
};

export type SideType = "right" | "bottom" | "top";
export interface GridBuilder {
  coordinates: { x: number; y: number };
  addType: SideType;
  scenario: ScenarioState["scenarioItem"];
  withoutCheck?: boolean;
}
export const setGridId = ({
  coordinates,
  addType,
  scenario,
  withoutCheck,
}: GridBuilder): { x: number; y: number } => {
  const blocks = scenario?.scenario.map((block) => ({
    id: block.id,
    coordinates: { x: block.x_coordinate, y: block.y_coordinate },
  }));

  const setNewCoordinatesByType = (type: GridBuilder["addType"]) => {
    const coordinatesBuilder = {
      right: (
        coord: GridBuilder["coordinates"]
      ): GridBuilder["coordinates"] => {
        const newCoordinates = { x: coord.x + X_STEP, y: coord.y };
        const isOcuppied = blocks?.find(
          (block) =>
            block.coordinates.x === newCoordinates.x &&
            block.coordinates.y === newCoordinates.y
        );
        if (isOcuppied && !withoutCheck) {
          return coordinatesBuilder.right(newCoordinates);
        } else {
          return newCoordinates;
        }
      },
      bottom: (
        coord: GridBuilder["coordinates"]
      ): GridBuilder["coordinates"] => {
        const newCoordinates = { x: coord.x, y: coord.y + Y_STEP };
        const isOcuppied = blocks?.find(
          (block) => block.coordinates.y === newCoordinates.y
        );
        if (isOcuppied && !withoutCheck) {
          return coordinatesBuilder.bottom(newCoordinates);
        } else {
          return newCoordinates;
        }
      },
      top: (coord: GridBuilder["coordinates"]): GridBuilder["coordinates"] => {
        const newCoordinates = { x: coord.x, y: coord.y - Y_STEP };
        const isOcuppied = blocks?.find(
          (block) => block.coordinates.y === newCoordinates.y
        );
        if (isOcuppied && !withoutCheck) {
          return coordinatesBuilder.top(newCoordinates);
        } else {
          return newCoordinates;
        }
      },
    };

    return coordinatesBuilder[type](coordinates);
  };

  return setNewCoordinatesByType(addType);
};

export const getGridId = ({ x, y }: GridBuilder["coordinates"]) => {
  return (_dispatch: (action: Action) => void, getState: () => State) => {
    const scenario = scenarioItem$(getState());
    const coordinates = scenario?.scenario
      ?.map((bl) => bl.y_coordinate as number)
      ?.filter((i) => !isNaN(i));

    if (!coordinates?.length) return "A1";

    const coordinatesWithInitBlock = [
      ...coordinates,
      scenario?.init?.y_coordinate,
    ].filter((i) => !isNaN(i!));
    const gridX = (x - 100) / X_STEP + 1;
    const firstYline = Math.min(...(coordinatesWithInitBlock as number[]));
    const firstLineIndex = (firstYline - 100) / Y_STEP;
    const currentLineIndex = (y - 100) / Y_STEP;
    if (firstLineIndex > 0) {
      const gridY = alphabet[currentLineIndex - firstLineIndex];
      return `${gridY}${gridX}`;
    } else if (firstLineIndex < 0) {
      const gridY = alphabet[currentLineIndex + -firstLineIndex];
      return `${gridY}${gridX}`;
    } else {
      const gridY = alphabet[currentLineIndex];

      return `${gridY}${gridX}`;
    }
  };
};

export const debugScenarioStart = (
  json: string,
  branch: string,
  version_id: string
) => {
  return (dispatch: (ac: Action) => void, getState: () => State) => {
    const action = WSApi.StartDebugScenario;
    const debug_scenario_id = scenarioItem$(getState())?.scenario_id;
    const selected_branch =
      plan_name$(getState()) === "Enterprise" ? branch : "live";

    if (!debug_scenario_id) return;
    dispatch(ScenarioActions.setBlockId(null));
    dispatch(ScenarioActions.selectBlock(null));
    dispatch(DebugActions.setDebugErrorReason(""));
    dispatch(DebugActions.setDebugIdWhereWasError(null));
    dispatch(DebugActions.setDebugList([]));
    dispatch(DebugActions.setDebugLoading(true));

    sendWSMessage({
      action,
      debug_scenario_id,
      json,
      version_id,
      branch: selected_branch,
    });
  };
};

export const deleteVariable = (variable_id: string) => {
  return (dispatch: (ac: Action) => void, getState: () => State) => {
    const action = WSApi.DeleteVariable;
    const company_role = company_role$(getState());
    if (company_role === MemberRole.USER) {
      // errorNotification(i18n.t("forbidden"));
      return;
    }
    const scenario = scenarioItem$(getState());
    const scenario_id = scenario?.scenario_id;
    const version_id = scenario?.version_id;
    if (!scenario_id) return;

    sendWSMessage({
      action,
      scenario_id,
      variable_id,
      version_id,
    });
  };
};

export const fetchScenarioVersionByTag = (tag: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.GetScenarioVersionByTag;
    const scenario = scenarioItem$(getState());
    if (!scenario) return;
    const { scenario_id } = scenario;

    sendWSMessage({
      action,
      scenario_id,
      tag,
    });
  };
};

export const fetchAllScenarioVersions = (
  limit: number,
  offset: number,
  date_from?: string,
  date_to?: string,
  author_to_select?: string,
  branch_param?: string,
  isLast?: boolean
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.GetScenarioVersions;
    const scenario = scenarioItem$(getState());
    const searchParams = new URLSearchParams(window.location.search);

    const branch = branch_param ? branch_param : searchParams.get("branch");
    if (!scenario) return;
    const { scenario_id } = scenario;
    dispatch(ScenarioVersionActions.setScenarioVersionLoading(true));

    if (isLast) {
      dispatch(ScenarioVersionActions.updateScenarioVersionPage(1));
      dispatch(ScenarioVersionActions.updateScenarioBranch(Branch.DEVEL));
    }

    sendWSMessage({
      action,
      scenario_id,
      limit,
      offset: isLast ? 0 : offset,
      date_from: date_from ? date_from : null,
      date_to: date_to ? date_to : null,
      author_to_select: author_to_select ? author_to_select : null,
      branch: isLast ? "devel" : branch,
    });
  };
};

export const createNewScenarioVersion = (
  from_version_id: string,
  tag: string | null,
  description: string
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.CreateScenarioVersion;
    const scenario = scenarioItem$(getState());
    if (!scenario) return;
    const { scenario_id } = scenario;
    dispatch(ScenarioVersionActions.setScenarioVersionLoading(true));

    sendWSMessage({
      action,
      scenario_id,
      description,
      from_version_id,
      tag,
    });
  };
};

export const runScenarioVersion = (version_id: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.SetActiveScenarioVersion;
    const company_role = company_role$(getState());
    if (company_role === MemberRole.USER) {
      // errorNotification(i18n.t("forbidden"));
      return;
    }
    const scenario = scenarioItem$(getState());

    if (!scenario) return;
    const { scenario_id } = scenario;
    dispatch(ScenarioVersionActions.setScenarioVersionLoading(true));

    sendWSMessage({
      action,
      scenario_id,
      version_id,
    });
  };
};

export const addNewTag = (
  version_id: string,
  tag: string,
  user_description?: string
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.SetTagScenarioVersion;
    const company_role = company_role$(getState());
    if (company_role === MemberRole.USER) {
      // errorNotification(i18n.t("forbidden"));
      return;
    }
    const scenario = scenarioItem$(getState());

    if (!scenario) return;
    const { scenario_id } = scenario;
    dispatch(ScenarioVersionActions.setScenarioVersionLoading(true));

    sendWSMessage({
      action,
      scenario_id,
      version_id,
      tag,
      user_description,
    });
  };
};

export const deleteTag = (tag: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.DeleteScenarioTag;
    const company_role = company_role$(getState());
    if (company_role === MemberRole.USER) {
      // errorNotification(i18n.t("forbidden"));
      return;
    }

    const scenario = scenarioItem$(getState());

    if (!scenario) return;
    const { scenario_id } = scenario;
    dispatch(ScenarioVersionActions.setScenarioVersionLoading(true));

    sendWSMessage({
      action,
      scenario_id,
      tag,
    });
  };
};
export const deleteScenarioVersion = (version_id: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.DeleteScenarioVersion;
    const company_role = company_role$(getState());
    if (company_role === MemberRole.USER) {
      // errorNotification(i18n.t("forbidden"));
      return;
    }
    dispatch(ScenarioVersionActions.setScenarioVersionLoading(true));

    const scenario = scenarioItem$(getState());

    if (!scenario) return;
    const { scenario_id } = scenario;

    sendWSMessage({
      action,
      version_id,
      scenario_id,
    });
  };
};

export const getEditableScenario = () => {
  return (_dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.GetEditableScenario;
    const scenario_id = scenarioItem$(getState())?.scenario_id;

    sendWSMessage({ action, scenario_id });
  };
};

export const restoreVersion = (from_version_id: string) => {
  return (_dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.RestoreScenarioVersion;
    const scenario_id = scenarioItem$(getState())?.scenario_id;
    const company_role = company_role$(getState());
    if (company_role === MemberRole.USER) {
      // errorNotification(i18n.t("forbidden"));
      return;
    }

    sendWSMessage({ action, scenario_id, from_version_id });
  };
};

export interface ICheckAuthOnInitBlock {
  init: BlockInit;
  version_id: string;
}

export const checkAuthOnInitBlock = ({
  init,
  version_id,
}: ICheckAuthOnInitBlock) => {
  return (_dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.CheckInitAuth;
    const scenario_id = scenarioItem$(getState())?.scenario_id;
    const branch = init.branch || "live";

    sendWSMessage({ action, scenario_id, init, version_id, branch });
  };
};

export interface ICheckScenario {
  version_id: string;
  branch: string;
}

export const checkScenario = ({ version_id, branch }: ICheckScenario) => {
  return (_dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.ScenarioCheck;
    const scenario_id = scenarioItem$(getState())?.scenario_id;

    sendWSMessage({ action, scenario_id, branch, version_id });
  };
};

export const fetchPredictVariableData = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.VariablePrediction;
    const scenario_id = scenarioItem$(getState())?.scenario_id;
    const version_id = scenarioItem$(getState())?.version_id;

    const searchParams = new URLSearchParams(window.location.search);
    const branch = searchParams.get("branch");
    const block_id = selectedBlockId$(getState());

    dispatch(VariablePanelActions.setVariablePanelLoading(true));

    const activeId = activeId$(getState());
    const prevActiveId = prevActiveId$(getState());

    if (activeId !== prevActiveId) {
      sendWSMessage({ action, scenario_id, branch, version_id, block_id });
    }
  };
};

export const searchVariables = (
  parent_block_id: string,
  name?: string,
  fetchAllItems?: boolean
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.VariableSearch;
    const scenario_id = scenarioItem$(getState())?.scenario_id;
    const version_id = scenarioItem$(getState())?.version_id;

    const searchParams = new URLSearchParams(window.location.search);

    const branch = searchParams.get("branch");
    const block_id = selectedBlockId$(getState());

    dispatch(VariablePanelActions.setVariablePanelLoading(true));

    const data =
      name || fetchAllItems
        ? {
            action,
            scenario_id,
            branch,
            version_id,
            name,
            parent_block_id,
          }
        : {
            action,
            scenario_id,
            branch,
            version_id,
            block_id,
            name,
            parent_block_id,
          };

    sendWSMessage(data);
  };
};

export const createVariableFromVariablePanel = (
  from_block_id: string,
  data_type: string,
  path: string
) => {
  return (_dispatch: (action: Action) => void, getState: () => State) => {
    const action = WSApi.CreateVariable;
    const scenario_id = scenarioItem$(getState())?.scenario_id;
    const version_id = scenarioItem$(getState())?.version_id;

    sendWSMessage({
      action,
      scenario_id,
      version_id,
      version: 2,
      from_block_id,
      data_type,
      path,
      value_type: "path",
    });
  };
};
