import { companyList$ } from "selectors/company";
import store from "store";
import { AnswerData } from "../index";
import { company_id$ } from "selectors/team";
import * as AuthActions from "actions/auth";
import * as CompanyActions from "actions/company";
import * as TeamActions from "actions/team";

//  WSApi.CompanyChangeName
export const companyChangeName = (data: AnswerData) => {
  const { dispatch, getState } = store;
  const handleCompanyName = () => {
    const newCompanyList = [...companyList$(getState())];
    const index = newCompanyList.findIndex(
      (company) => company.company_id === data.response.company_id
    );
    if (index < 0) return;
    dispatch(AuthActions.setCompanyName(data.response.company_name));
    newCompanyList[index].company_name = data.response.company_name;
    dispatch(CompanyActions.setCompanyList(newCompanyList));
  };
  if (company_id$(getState()) === data.response.company_id) {
    dispatch(TeamActions.setCompanyName(data.response.company_name));
    handleCompanyName();
  } else {
    handleCompanyName();
  }
};
