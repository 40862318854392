import * as Const from "../constants";
import State from "../interfaces/state/debug";

export const initState: State = {
  isLoading: false,
  isError: false,
  debugPanelValue: "",
  errorReason: "",
  idWhereWasError: null,
  list: [],
};

export default function debug(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.DEBUG_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.DEBUG_VALUE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        debugPanelValue: action.payload,
      };

    case Const.DEBUG_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.DEBUG_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case Const.DEBUG_ERROR_REASON:
      return {
        ...state,
        errorReason: action.payload,
      };

    case Const.DEBUG_ID_WHERE_WAS_ERROR:
      return {
        ...state,
        idWhereWasError: action.payload,
      };

    case Const.DEBUG_LIST:
      return {
        ...state,
        list: action.payload,
      };

    default:
      return state;
  }
}
