import State from "../interfaces/state";
import {
  IInputServiceParameter,
  InputServiceParameter,
  ServiceParameter,
} from "interfaces/state/serviceParameter";

export const isLoading$ = (state: State) => state.serviceParameter.isLoading;
export const isError$ = (state: State) => state.serviceParameter.isError;
export const serviceParameter$ = (state: State) => state.serviceParameter.param;
export const paramIds$ = (state: State) =>
  Object.keys(state.serviceParameter.param);

export const inputKeys$ =
  (scenarioId?: string, blockId?: string) =>
  (state: State): string[] | undefined =>
    state.serviceParameter.keys?.[scenarioId!]?.[blockId!];

export const inputParameter$ =
  (scenarioId?: string, blockId?: string) =>
  (state: State): IInputServiceParameter[] | undefined =>
    state.serviceParameter.inputParameter?.[scenarioId!]?.[blockId!];

export const initialInputParameter$ =
  (scenarioId?: string, blockId?: string) =>
  (state: State): IInputServiceParameter[] | undefined =>
    state.serviceParameter.initialParameter?.[scenarioId!]?.[blockId!];

export const parameter$ =
  (scenarioId?: string, blockId?: string) =>
  (state: State): { [key: string]: InputServiceParameter } | undefined =>
    state.serviceParameter.parameter?.[scenarioId!]?.[blockId!];

export const parameterForComponent$ =
  (scenarioId?: string, blockId?: string) =>
  (state: State): { [key: string]: ServiceParameter } | undefined =>
    state.serviceParameter.parameterForComponent?.[scenarioId!]?.[blockId!];

export const newParameters$ =
  (blockId?: string) =>
  (state: State): { [key: string]: ServiceParameter } | undefined =>
    state.serviceParameter.newParameters?.[blockId!];
