import { scenarioItem$ } from "selectors/scenario";
import store from "store";
import { errorFromResponse } from "utils";
import { AnswerData } from "../index";
import * as ScenarioUsersActions from "actions/scenarioUsers";

// WSApi.DeleteUserFromScenario
export const scenarioDeleteUser = (data: AnswerData) => {
  if (data.error) {
    store.dispatch(ScenarioUsersActions.setScenarioUsersError());
    errorFromResponse(data.error);
  } else {
    const scenarioItem = scenarioItem$(store.getState());
    if (scenarioItem?.scenario_id === data.response?.scenario_id) {
      store.dispatch(
        ScenarioUsersActions.deleteUserFromScenario(
          data.response.scenario_id,
          data.response.user_email
        )
      );
    }
  }
};
