import store from "store";
import { AnswerData } from "../index";
import * as ScenarioActions from "actions/scenario";
import { updateInitBlock } from "interceptors/scenario";

// WSApi.UpdateInitBlock
export const blockUpdateInit = (data: AnswerData) => {
  const { dispatch, getState } = store;

  dispatch(ScenarioActions.setLoadedScenario());
  if (data?.error) {
    dispatch(ScenarioActions.setScenarioError());
  } else {
    updateInitBlock(
      data?.response?.scenario_id,
      data?.response?.init,
      data.response?.variables,
      data.response?.scenario
    )(getState(), dispatch);
  }
};
