import * as Const from "../constants";
import State from "../interfaces/state/availableReplyMethod";

export const initState: State = {
  isLoading: false,
  isError: false,
  list: {},
};

export default function availableReplyMethod(
  state: State = initState,
  action: any
): State {
  switch (action.type) {
    case Const.AVAIL_R_METHODS_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
      };

    case Const.AVAIL_R_METHODS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: {
          ...state.list,
          [action.scenario_id]: {
            ...state.list[action.scenario_id],
            [action.block_id]: action.payload,
          },
        },
      };

    case Const.AVAIL_R_METHODS_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.AVAIL_R_METHODS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
